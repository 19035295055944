import Request from './request'
import state from '../store/state'

const PATH = {
  AUDIENCE: '/audience',
  DIFFUSION: '/audience/diffusion',
  PUSH: '/push'
}

import timeZones from '../constants/_TIME_ZONES.json'

function getTimeZone() {
  const index = timeZones.findIndex(
    item =>
      item.utc &&
      item.utc.indexOf(Intl.DateTimeFormat().resolvedOptions().timeZone) > -1
  )
  if (index > -1 && timeZones[index]) {
    return timeZones[index].text
  } else {
    const offset = (new Date().getTimezoneOffset() / 60) * -1
    const element = timeZones.find(e => e.offset === offset)
    if (element) {
      return element.text
    }
  }
  return undefined
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

function getHeaders() {
  return {
    authorization: `Bearer ${_getToken()}`,
    'X-Time-Zone': getTimeZone()
  }
}

const _getAudiences = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${state.config.konecta}${PATH.DIFFUSION}`, config)
}

const _saveAudience = audience => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${state.config.konecta}${PATH.AUDIENCE}`,
    audience,
    config
  )
}

const _deleteAudience = audienceId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    `${state.config.konecta}${PATH.AUDIENCE}/${audienceId}`,
    config
  )
}

const _downloadAudience = audienceId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.DIFFUSION}/${audienceId}`,
    config
  )
}

const _savePush = push => {
  const config = {
    headers: getHeaders()
  }
  const aux = JSON.parse(JSON.stringify(push))
  aux.hsm.type = (aux.hsm.type || 'HSM').toLowerCase()
  return Request.post(`${state.config.konecta}${PATH.PUSH}`, aux, config)
}

export default {
  getAudiences: _getAudiences,
  saveAudience: _saveAudience,
  deleteAudience: _deleteAudience,
  savePush: _savePush,
  downloadAudience: _downloadAudience
}
