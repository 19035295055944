export default {
  forkingTitle: {
    copy: {
      es: 'Copiando bot...',
      en: 'Copying bot...',
      pt: 'Copiando bot...'
    },
    template: {
      es: 'Agregando template al bot...',
      en: 'Adding template to bot...',
      pt: 'Adicionando modelo para bot...'
    }
  },
  forkingTableTitle: {
    es: 'Copiar Bot',
    en: 'Copy Bot',
    pt: 'Copiar Bot'
  },
  downloadTemplate: {
    es: 'Descargar Plantila',
    en: 'Download Template',
    pt: 'Baixe o Template'
  },
  importBot: {
    es: 'Importar / Exportar Bot',
    en: 'Import / Export Bot',
    pt: 'Import / Export Bot'
  },
  intentsTitle: {
    es: 'Intenciones',
    en: 'Intents',
    pt: 'Intenções'
  },
  intent: {
    es: 'Intención',
    en: 'Intent',
    pt: 'Intenções'
  },
  entitiesTitle: {
    es: 'Entidades',
    en: 'Entities',
    pt: 'Entidades'
  },
  entity: {
    es: 'Entidad',
    en: 'Entity',
    pt: 'Entidade'
  },
  status: {
    es: 'Estado',
    en: 'Status',
    pt: 'Status'
  },
  statusPending: {
    es: 'Pendiente',
    en: 'Pending',
    pt: 'Pendente'
  },
  statusDone: {
    es: 'Listo',
    en: 'Done',
    pt: 'Feito'
  },
  statusError: {
    es: 'Error',
    en: 'Error',
    pt: 'Erro'
  },
  selectBot: {
    es: 'Seleccione bot',
    en: 'Select bot',
    pt: 'Selecione o bot'
  },
  selectVersion: {
    es: 'Seleccione versión',
    en: 'Select version',
    pt: 'Selecione a versão'
  }
}
