import MetricsService from '@/services/metrics.service'

const actions = {
  getReportData({ state, commit }, { reportId, payload }) {
    if (state.cancelRequest[reportId]) {
      state.cancelRequest[reportId]('cancelled')
    }
    const executor = c => {
      commit('SET_CANCEL_REQUEST', { reportId, cancelRequest: c })
    }
    return MetricsService.getReportData(reportId, payload, executor)
  }
}

export default actions
