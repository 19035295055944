const _update = (item, property, value) => {
  let sessionItem = localStorage.getItem(item)
  if (sessionItem) {
    sessionItem = JSON.parse(sessionItem)
    localStorage.removeItem(item)
    sessionItem[property] = value
    localStorage.setItem(item, JSON.stringify(sessionItem))
  }
}

export default {
  update: _update
}
