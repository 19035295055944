export default {
  title: {
    es: 'Sesiones',
    en: 'Sessions',
    pt: 'Sessões'
  },
  agentMessages: {
    es: 'Mensajes de agente',
    en: 'Agent messages',
    pt: 'Mensagens de agente'
  },
  userMessages: {
    es: 'Mensajes de usuario',
    en: 'User messages',
    pt: 'Mensagens de usuário'
  },
  botResponses: {
    es: 'Respuestas de bot',
    en: 'Bot responses',
    pt: 'Respostas dos bots'
  },
  sessionStarts: {
    es: 'Comienzo de la sesión',
    en: 'Session starts',
    pt: 'Início da sessão'
  },
  download: {
    es: 'Descargar',
    en: 'Download',
    pt: 'Baixe'
  },
  attTime: {
    es: 'Tiempo de atención',
    en: 'Attention time',
    pt: 'Tempo de atendimento'
  },
  timeSinceFirst: {
    es: 'Tiempo desde primer mensaje',
    en: 'Time since first message',
    pt: 'Tempo desde a primeira mensagem'
  },
  agents: {
    es: 'Agentes',
    en: 'Agents',
    pt: 'Agentes'
  },
  tags: {
    es: 'Etiquetas',
    en: 'Tags',
    pt: 'Etiquetas'
  },
  export: {
    platform: {
      es: 'Canal',
      en: 'Channel',
      pt: 'Canal'
    },
    duration: {
      es: 'Duración',
      en: 'Duration',
      pt: 'Duração'
    },
    messages: {
      es: 'Mensajes',
      en: 'Messages',
      pt: 'Mensagens'
    },
    takeovers: {
      es: 'Tomas de control',
      en: 'Takeovers',
      pt: 'Aquisições'
    },
    derived: {
      es: '¿Fue derivado?',
      en: 'Transferred to Agent?',
      pt: 'Foi derivado?'
    },
    yes: {
      es: 'Sí',
      en: 'Yes',
      pt: 'Sim'
    },
    no: {
      es: 'No',
      en: 'No',
      pt: 'Não'
    }
  }
}
