import Helper from '../store/Helper'
import { TextMessageType } from './Messages/MessageTypes/TextMessageType'
import Validations from '../store/Validations'

/**
 * Message
 * Represents a message entity
 * @param {Number} isUser. Describes what type of user it is. The users can be 1 = bot and 2 = user.
 * @param {Boolean} firstMessage. True when the message is the root message
 * @param {String} type the type of message
 * @param {String} name the name of the message
 * @param {String} id the ID generated by the UI
 * @param {String} dialogSavedId the ID of the dialog stored in the database
 * @param {String} intentId the intent ID
 */
export class Message {
  constructor(id, dialogSavedId, type) {
    this.id = id ? id : Helper.generateID()
    this.dialogSavedId = dialogSavedId ? dialogSavedId : undefined
    this.dialogId = undefined
    this.nextMessage = undefined
    this.prevMessage = undefined
    this.type = type ? type : new TextMessageType()
    // this.name = name ? name : ''
    this.isEditing = false
    this.isSaved = false
    this.apiCall = undefined
  }

  isUserMessage() {/* isUserMessage */}
  isBotMessage() {/* isBotMessage */}

  generateMessageToSave() {/* generateMessageToSave */}

  save() {/* save */}

  update() {/* update */}

  delete() {/* delete */}

  isNameValid() {
    return !Validations.isEmpty(this.name)
  }
}
