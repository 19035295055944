export default {
  botMaker: {
    imageTab: {
      es: 'El tamaño del archivo no debe ser superior a %s',
      en: 'The file size must be no more than ',
      pt: 'O tamanho do arquivo não deve exceder %s',
    },
    derivateAgent: {
      es: 'Marca el box si esta condición requiere la derivación a un agente',
      en: 'Check the box if this condition requires referall to an agent',
      pt: 'Marque a caixa se esta condição requer referência a um agente',
    },
    replyOutsideBusinessHours: {
      es: 'Marca el box si el Bot debe responder normalmente fuera del horario de atención. Si está desactivado mostrará el mensaje definido en la configuración de disponibilidad',
      en: 'Check the box if the Bot should respond normally outside of business hours. If disabled, it will show the message defined in the availability configuration',
      pt: 'Marque a caixa se o Bot deve responder normalmente fora do horário de atendimento. Se desabilitado, mostrará a mensagem definida na configuração de disponibilidade',
    },
    defaultResponse: {
      es: 'Agrega la respuesta que debe dar el Bot si detecta la Condición. Puedes agregar una respuesta general o una específica para cada canal si lo necesitas.',
      en: 'Add the answer that the Bot should give if it detects the Condition. You can add a general answer or a specific one for each channel if you need it.',
      pt: 'Adicione a resposta que o Bot deve dar se detectar a Condição. Você pode adicionar uma resposta geral ou específica para cada canal, se for necessário.',
    },
    cardsTab: {
      es: 'Este componente no se visualizará en todos los canales.',
      en: 'This componente will not be displayed on all channels.',
      pt: 'Este componente não será exibido em todos os canais.',
    },
    buttonsTab: {
      es: 'Los botones no funcionarán correctamente si tienen respuestas debajo',
      en: 'Buttons will not work correctly if they have answers below them.',
      pt: 'Botões não funcionam corretamente se tiverem respostas abaixo deles.',
    },
    condition: {
      es: 'Agrega la Condición que debe detectar el Bot. Las condiciones pueden estar compuestas por Intenciones, Entidades y valores obtenidos durante los flujos de diálogo.',
      en: 'Add the Condition that the Bot should detect. The conditions can be made up of Intentions, Entities and values obtained during the dialog flows.',
      pt: 'Adicione a condição que o bot deve detectar. As condições podem ser feitas de Intenções, Entidades e valores obtidos durante os fluxos de diálogo.',
    },
    derivesToAgent: {
      es: 'Marca la casilla si esta condición requiere la derivación a un agente.',
      en: 'Check the box if this condition requires a referral to an agent.',
      pt: 'Marque a caixa se esta condição requer um encaminhamento para um agente.',
    },
    catalogs: {
      es: 'Crea tus Intenciones y Entidades para luego utilizarlas en la creación de Condiciones que deberá detectar tu bot.',
      en: 'Create your Intentions and Entities to later use them in the creation of Conditions that your bot should detect.',
      pt: 'Crie suas Intenções e Entidades para mais tarde usá-las na criação de Condições que seu bot deve detectar.',
    },
    intentionName: {
      es: 'Agregar preguntas o frases de ejemplo que ayuden al Bot a identificar la intención. El entrenamiento de tu Bot se basará en estos ejemplos.',
      en: "Add sample questions or phrases that help the Bot identify the intention. Your Bot's training will be based on these examples.",
      pt: 'Adicione exemplos de perguntas ou frases que ajudem o Bot a identificar a intenção. O treinamento do seu Bot será baseado nesses exemplos.',
    },
    entityName: {
      es: 'Agregar el valor y sus sinónimos, que ayuden al Bot a identificar la Entidad.',
      en: 'Add the value and its synonyms, which help the Bot to identify the Entity.',
      pt: 'Adicione o valor e seus sinônimos, que ajudam o Bot a identificar a Entidade.',
    },
  },
  metrics: {
    agents: {
      es: 'Los tiempos informados son tiempos promedio.',
      en: 'Reported times are average times.',
      pt: 'Os tempos relatados são tempos médios.',
    },
  },
  contact: {
    outdatedPriority: {
      es: 'Se ha actualizado la prioridad de este chat.',
      en: 'The priority of this chat has been updated.',
      pt: 'A prioridade deste chat foi atualizada.',
    },
  },
  surveys: {
    hsm: {
      es: 'Es la configuración para envío de mensajes activos en WhatsApp. Para más información, consulte a su proveedor.',
      en: 'It is the configuration for sending active messages in WhatsApp. For more information, ask your provider.',
      pt: 'É a configuração para enviar mensagens ativas no WhatsApp. Para obter mais informações, consulte seu provedor.',
    },
  },
}