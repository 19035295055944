import Vue from 'vue'
import IpRangesService from '../../services/ipRanges.service'

const actions = {
  GET_IP_RANGE({ state }) {
    return IpRangesService.getIpRanges()
      .then(response => {
        state.ipRanges = response.data
        Vue.$log.info(state.ipRanges)
      })
      .catch(error => error)
  },

  CREATE_IP_RANGE({ commit }, message) {
    return IpRangesService.createIpRange(message.body)
      .then(response => {
        commit('CREATE_IP_RANGE', response.data)
        return response.data
      })
      .catch(error => {
        Vue.$log.error(error)
        throw error
      })
  },

  UPDATE_IP_RANGE({ commit }, message) {
    return IpRangesService.updateIpRange(message._id, message.body)
      .then(response => {
        commit('UPDATE_IP_RANGE', response.data)
        return response.data
      })
      .catch(error => {
        Vue.$log.error(error)
        throw error
      })
  },

  DELETE_IP_RANGE({ commit }, message) {
    return IpRangesService.deleteIpRange(message._id)
      .then(response => {
        commit('DELETE_IP_RANGE', response.data)
        return response.data
      })
      .catch(error => {
        Vue.$log.error(error)
        throw error
      })
  }
}

export default actions
