import { USER_ROLES } from './Roles/UserRoles'

export default {
  [USER_ROLES.SYS_ADMIN]: {
    defaultName: 'company_sys_admin',
    name: {
      es: 'Sys Admin',
      en: 'Sys Admin'
    },
    id: '6077203875f88029147af507',
    canCreate: []
  },
  [USER_ROLES.SUPER_ADMIN]: {
    defaultName: 'company_superadmin',
    name: {
      es: 'Super Administrador',
      en: 'Super Admin'
    },
    id: '5cd9a8f9f99a38006e7b8b1c',
    canCreate: [
      USER_ROLES.EDITOR,
      USER_ROLES.SUPERVISOR,
      USER_ROLES.AGENT,
      USER_ROLES.ROLES_MANAGER
    ]
  },
  [USER_ROLES.EDITOR]: {
    defaultName: 'company_editor',
    name: {
      es: 'Editor',
      en: 'Editor'
    },
    id: '5ca511218f8f640063c52468',
    canCreate: []
  },
  [USER_ROLES.SUPERVISOR]: {
    defaultName: 'company_supervisor',
    name: {
      es: 'Supervisor',
      en: 'Supervisor'
    },
    id: '5cd09eb2e9d215006fff774b',
    canCreate: []
  },
  [USER_ROLES.AGENT]: {
    defaultName: 'company_agent',
    name: {
      es: 'Agente',
      en: 'Agent'
    },
    id: '5cd08f88e9d215006fff7716',
    canCreate: []
  },
  [USER_ROLES.ROLES_MANAGER]: {
    defaultName: 'company_roles_manager',
    name: {
      es: 'Administrador de Roles',
      en: 'Role Manager'
    },
    id: '5e98728cce9d06df60693b6a',
    canCreate: [USER_ROLES.EDITOR, USER_ROLES.SUPERVISOR, USER_ROLES.AGENT]
  }
}
