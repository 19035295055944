export default {
  bots: {
    title: {
      es: 'Bots',
      en: 'Bots',
      pt: 'Bots'
    },
    subtitle: {
      es:
        'Seleccione los bots mediante los cuales quiere distribuir su encuesta.',
      en: 'Select the bots through which you want to distribute your survey.',
      pt:
        'Selecione os bots através dos quais você quer distribuir sua pesquisa.'
    },
    list: {
      name: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome'
      },
      creation: {
        es: 'Creación',
        en: 'Creation',
        pt: 'Criação'
      }
    }
  },
  surveys: {
    title: {
      es: 'Encuestas',
      en: 'Surveys',
      pt: 'Pesquisas'
    },
    subtitle: {
      es: 'Seleccione la encuesta que desea enviar.',
      en: 'Select the survey you want to send.',
      pt: 'Selecione a pesquisa que você quer enviar.'
    },
    list: {
      name: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome'
      },
      creation: {
        es: 'Creación',
        en: 'Creation',
        pt: 'Criação'
      }
    }
  },
  audiences: {
    title: {
      es: 'Creación de audiencias',
      en: 'Audience creation',
      pt: 'Criação de público'
    },
    subtitle: {
      es: 'Muestra el progreso de la creación de audiencia en segundo plano.',
      en: 'Shows the progress of audience creation in the background.',
      pt: 'Mostra o progresso da criação de público em segundo plano.'
    },
    table: {
      progress: {
        es: 'Progreso',
        en: 'Progress',
        pt: 'Progresso'
      },
      channel: {
        es: 'Canal',
        en: 'Channel',
        pt: 'Canal'
      },
      schedule: {
        es: 'Cronograma',
        en: 'Schedule',
        pt: 'Cronograma'
      },
      updated: {
        es: 'Actualizado',
        en: 'Updated',
        pt: 'Atualizada'
      },
      created: {
        es: 'Creado',
        en: 'Created',
        pt: 'Criada'
      }
    }
  },
  versions: {
    title: {
      es: 'Versiones',
      en: 'Versions',
      pt: 'Versões'
    },
    subtitle: {
      es:
        'Seleccione la versión de la encuesta que desea evitar. Recuerda que la versión EN VIVO es la única que puede enviarse a usuarios.',
      en:
        'Select the survey version you want to avoid. Remember that the LIVE version is the only one that can be sent to users.',
      pt:
        'Selecione a versão da pesquisa que você deseja evitar. Lembre-se que a versão do LIVE é a única que pode ser enviada aos usuários.'
    },
    list: {
      tag: {
        es: 'Etiqueta',
        en: 'Tag',
        pt: 'Etiqueta'
      },
      status: {
        es: 'Estado',
        en: 'Status',
        pt: 'Status'
      },
      creation: {
        es: 'Creación',
        en: 'Creation',
        pt: 'Criação'
      }
    },
    status: {
      prod: {
        es: 'en vivo',
        en: 'online',
        pt: 'ao vivo'
      },
      dev: {
        es: 'desarrollo',
        en: 'development',
        pt: 'desenvolvimento'
      }
    }
  },
  covering: {
    title: {
      es: 'Cubrimiento',
      en: 'Covering',
      pt: 'Cobertura'
    },
    subtitle: {
      es:
        'Muestra la cantidad de personas de la audiencia que fueron alcanzadas con la encuesta. Esta métrica se calcula de la siguiente forma: Personas que respondieron / Total de personas que recibieron la encuesta.',
      en:
        'Shows the number of people in the audience that were reached with the survey. This metric is calculated as follows: People who responded / Total people who received the survey.',
      pt:
        'Mostra o número de pessoas na audiência que foram alcançadas com a pesquisa, esta métrica é calculada como segue: Pessoas que responderam / Total de pessoas que receberam a pesquisa.'
    },
    list: {
      completed: {
        es: 'Completado',
        en: 'Completed',
        pt: 'Completado'
      },
      notCompleted: {
        es: 'No completado',
        en: 'Not completed',
        pt: 'Não completado'
      }
    }
  },
  results: {
    title: {
      es: 'Resultados',
      en: 'Results',
      pt: 'Resultados'
    },
    subtitle: {
      es:
        'Aquí podrás revisar las respuestas y los resultados de las encuestas.',
      en: 'Here you can review the answers and the results of the surveys.',
      pt: 'Aqui você pode rever as respostas e os resultados das pesquisas.'
    },
    form: {
      switch: {
        es: 'Mostar preguntas',
        en: 'Show questions',
        pt: 'Mostrar perguntas'
      },
      table: {
        user: {
          es: 'Usuario',
          en: 'User',
          pt: 'Usuário'
        },
        ended: {
          es: 'Finalizado',
          en: 'Ended',
          pt: 'Finalizado'
        },
        firstMessage: {
          es: 'Primer respuesta',
          en: 'First answer',
          pt: 'Primeira resposta'
        }
      },
      search: {
        es: 'Buscar...',
        en: 'Search...',
        pt: 'Procure...'
      },
      refresh: {
        es: 'Actualizar',
        en: 'Refresh',
        pt: 'Atualizar'
      },
      exportCSV: {
        es: 'Exportar como CSV',
        en: 'Export as CSV',
        pt: 'Exportar como CSV'
      },
      exportCSVPrompt: {
        text: {
          es: 'Ingresa el correo electrónico donde deseas recibir el reporte:',
          en: 'Enter the email where you want to receive the report:',
          pt: 'Digite o e-mail no qual deseja receber o relatório:'
        },
        invalidEmail: {
          es: 'Correo electrónico inválido',
          en: 'Invalid email',
          pt: 'E-mail inválido'
        },
        accept: {
          es: 'Aceptar',
          en: 'Accept',
          pt: 'Aceitar'
        },
        cancel: {
          es: 'Cancelar',
          en: 'Cancel',
          pt: 'Cancelar'
        },
        sucess: {
          title: {
            es: 'Éxito',
            en: 'Success',
            pt: 'Sucesso'
          },
          text: {
            es:
              'Recibirás el reporte en el tu correo electrónico, esto puede tardar algunos minutos.',
            en:
              'You will receive the report in your email, this may take a few minutes.',
            pt:
              'Você receberá o relatório em seu e-mail, isso pode levar alguns minutos.'
          }
        }
      },
      outgoingRequests: {
        es: 'Llamadas salientes',
        en: 'Outgoing requests',
        pt: 'Chamadas externas'
      },
      showMore: {
        yes: {
          es: 'Mostrar más',
          en: 'Show more',
          pt: 'Mostrar mais'
        },
        not: {
          es: 'No hay más items por mostrar',
          en: 'No more items',
          pt: 'Não há mais itens para exibir'
        }
      }
    }
  },
  breadcrumb: {
    surveys: {
      es: 'Encuestas',
      en: 'Surveys',
      pt: 'Pesquisas'
    },
    surveyData: {
      es: 'Analítica',
      en: 'Analytics',
      pt: 'Análise'
    }
  },
  errors: {
    noSurveys: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!'
      },
      text: {
        es: 'No hay encuestas asociadas a éste bot.',
        en: 'There are no surveys associated with this bot.',
        pt: 'Não há pesquisas associadas a este bot.'
      }
    },
    noSurveysProd: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!'
      },
      text: {
        es: 'No hay versiones de producción asociadas a esta encuesta.',
        en: 'There are no production versions associated with this survey.',
        pt: 'Não existem versões de produção associadas a esta pesquisa.'
      }
    },
    generic: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!'
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'It seems that an error occurred.',
        pt: 'Parece que ocorreu um erro.'
      }
    }
  }
}
