import { MessageType } from '../MessageType'

export class CardMessageType extends MessageType {
  constructor(
    name,
    responseCard = [],
    platforms = ['all'],
    id = null,
    responseId,
    beforeAPICall
  ) {
    super(name, responseId)
    this.responseCard = responseCard
    this.platforms = platforms
    this._id = id
    this.beforeAPICall = beforeAPICall
  }

  getType() {
    return this.responseCard && this.responseCard.length > 1 ? 'slider' : 'card'
  }

  isTextMessage() {
    return false
  }
  isRegexMessage() {
    return false
  }
  isImageMessage() {
    return false
  }

  isMediaMessage() {
    return false
  }

  isIntentMessage() {
    return false
  }

  isButtonsMessage() {
    return false
  }

  isCardMessage() {
    return true
  }

  isSliderMessage() {
    return false
  }

  isGenericMessage() {
    return false
  }

  isValid() {
    super.isNameValid()
  }

  setMessage(item) {
    this.responseCard = item
  }

  generateMessageToSave() {
    return {
      platforms: this.platforms,
      responseText: this.name,
      responseCard: (this.responseCard || []).map(item => {
        const newItem = { ...item }
        newItem.header.url = /\/bucket/.test(newItem.header.url)
          ? `/bucket${newItem.header.url.split('/bucket')[1]}`
          : newItem.header.url
        return newItem
      }),
      responseId: this.responseId,
      beforeAPICall: this.beforeAPICall
    }
  }

  save() {
    return Promise.resolve()
  }

  update() {
    /* update */
  }
}
