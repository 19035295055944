import Vue from 'vue'
import IntegrationsService from '../../services/integrations.service'
import ChannelService from '../../services/channels.service'
import Helper from '../Helper'

const actions = {
  GET_CHANNELS({state}){
    return ChannelService.getChannels()
    .then(response => {
      let allChannels = [{
        type: 'web',
        name: 'Konecta',
        active: true,
        enabled: false
      }];
      let channels = response.data.map(channel => {
        return {
          id: channel._id,
          type: channel.type,
          name: channel.name,
          active: false,
          enabled: false
        }
      })
      allChannels = allChannels.concat(channels);
      state.botChannels = allChannels;
      Vue.$log.info(state.botChannels)
    })
    .catch(error => error)
  },
  GET_CHANNEL_OPTIONS({state}, channelId){
    return ChannelService.getChannelOptionsByChannelId(channelId)
    .then(response => {
      try {
      let channel = { id: channelId };
      channel.options = response.data;
      let botChannel = state.botChannels.find(c => c.id === channelId);
      channel.options.push({
        name: 'Thirdparty',
        type: botChannel.type,
        channel: channelId,
        isThirdparty: true,
        fields: [{
            key: "webhook",
            type: "input",
            required: true
        }, {
            key: "konectaWebhook",
            type: "readable",
            default: "webhook",
            webhook: "{{backendUrl}}/conversation/facebook/{{botId}}?integration={{integrationId}}&version={{versionId}}"
        }]
      });
      channel.name = botChannel.name;
      state.selectedChannel = channel;
      } catch (_) {
        return;
      }
    })
    .catch(error => error)
  },
  GET_INTEGRATIONS({ state }, params) {
    return IntegrationsService.getIntegrations(params || {})
      .then(response => {
        state.integrations = response.data.map(integration => {
          return {
            ...integration,
            knownType: Helper.getKnownChannelType(integration.type)
          }
        })
        Vue.$log.info(state.integrations)
      })
      .catch(error => error)
  },

  GET_INTEGRATION({ state }) {
    return IntegrationsService.getIntegration()
      .then(response => {
        state.integrations = response.data
      })
      .catch(error => error)
  },

  CREATE_INTEGRATION({ commit }, params) {
    return IntegrationsService.createIntegration(params)
      .then(response => {
        commit('CREATE_INTEGRATION', response.data)
        // commit('CREATE_INTEGRATION')
      })
  },

  UPDATE_INTEGRATION({ commit }, { params, id }) {
    return IntegrationsService.updateIntegration(params, id)
      .then(response => {
        commit('UPDATE_INTEGRATION', response.data)
        // commit('RESET_BOT_CHANNELS')
      })
  },

}

export default actions
