/**
 * Response
 * The response contains what the bot would say
 */
export class Response {
  constructor() {/* constructor */}

  getResponseToSave() {
    throw new Error('You have to implement the method doSomething!')
  }
}
