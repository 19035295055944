import Vue from 'vue'

const COLOR = {
  DANGER: 'danger',
  WARNING: 'warning',
  SUCCESS: 'success'
}

const ICON = {
  DANGER: 'error',
  WARNING: 'warning',
  SUCCESS: 'check_circle'
}

const callNotify = message => {
  Vue.prototype.$vs.notify(message)
}

const notify = {
  success(title, text) {
    callNotify({
      title: title,
      text: text,
      color: COLOR.SUCCESS,
      icon: ICON.SUCCESS
    })
  },
  warning(title, text) {
    callNotify({
      title: title,
      text: text,
      color: COLOR.WARNING,
      icon: ICON.WARNING
    })
  },
  error(title, text) {
    callNotify({
      title: title,
      text: text,
      color: COLOR.DANGER,
      icon: ICON.DANGER
    })
  }
}

export const notificationPlugin = {
  install() {
    Vue.notify = notify
    Vue.prototype.$notify = notify
  }
}
