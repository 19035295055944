import Request from './request'
import state from '../store/state'

const PATH = {
  CHANNEL: '/channel',
  CHANNEL_OPTIONS: '/channelOption'
}

/**
 * Get token
 *
 * @returns {String} with the token retrieved from the local storage
 */
const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

// function _getQuery(params) {
//   const services = params.services
//   const types = params.types

//   let query = ''
//   if (services && services.length) {
//     if (services.length === 1) {
//       query = `?where[service]=${services[0]}`
//     } else {
//       types.forEach(element => {
//         if (query.length === 0) {
//           query += `?where[type][$in]=${element}`
//         } else {
//           query += `&where[service][$in]=${element}`
//         }
//       })
//     }
//   }

//   if (types && types.length) {
//     if (types.length === 1) {
//       query +=
//         query.length > 0
//           ? `&where[type]=${types[0]}`
//           : `?where[type]=${types[0]}`
//     } else {
//       types.forEach(element => {
//         if (query.length === 0) {
//           query += `?where[type][$in]=${element}`
//         } else {
//           query += `&where[type][$in]=${element}`
//         }
//       })
//     }
//   }

//   return query
// }

const _getChannels = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.CHANNEL}`,
    config
  )
}

const _getChannelOptionsByChannelId = (channelId) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.CHANNEL_OPTIONS}?where[channel]=${channelId}`,
    config
  )
}

export default {
  getChannels: _getChannels,
  getChannelOptionsByChannelId: _getChannelOptionsByChannelId,
}
