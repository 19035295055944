import Vue from 'vue'

const mutations = {
  START_STORE(state) {
    state.nlu = []
    Vue.$log.info()
  },

  CREATE_NLU(state, credential) {
    state.nlu = [credential].concat(state.nlu)
  },

  UPDATE_NLU(state, credential) {
    let index = null
    // TODO: Sonar: refactor
    state.nlu.some((element, i) => {
      if (element._id === credential._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.nlu[index] = credential
      const aux = JSON.parse(JSON.stringify(state.nlu))
      state.nlu = aux
    }
  },

  DELETE_NLU(state, credential) {
    let index = null
    // TODO: Sonar: refactor
    state.nlu.some((element, i) => {
      if (element._id === credential._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.nlu.splice(index, 1)
    }
  }
}

export default mutations
