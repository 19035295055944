export default {
  options: {
    last7: {
      es: 'Últimos 7 días',
      en: 'Last 7 days',
      pt: 'Últimos 7 dias',
    },
    last28: {
      es: 'Últimos 28 días',
      en: 'Last 28 days',
      pt: 'Últimos 28 dias',
    },
    lastMonth: {
      es: 'Último mes',
      en: 'Last Month',
      pt: 'Último mês',
    },
    lastYear: {
      es: 'Último año',
      en: 'Last Year',
      pt: 'Último ano',
    },
  },
}