export default {
  title: {
    es: 'Agentes',
    en: 'Agents',
    pt: 'Agentes'
  },
  download: {
    es: 'Descargar',
    en: 'Download',
    pt: 'Baixe'
  },
  fields: {
    avgatttime: {
      es: 'Tiempo promedio de atención',
      en: 'Average attention time',
      pt: 'Tempo médio de atendimento'
    },
    liberados: {
      es: 'Liberados',
      en: 'Released',
      pt: 'Liberados'
    },
    resueltos: {
      es: 'Resueltos',
      en: 'Resolved',
      pt: 'Resolvido'
    }
  }
}
