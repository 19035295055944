export class Dialog {
  /**
   * Create dialog
   * @param {String} versionId the bot's active version ID
   * @param {String} name the dialog name
   * @param {Array<Response>} responses the bot responses
   * @param {String} intention the name of the intention
   * @param {String} regex the regex if applicable
   * @param {String} parent the parent dialog ID
   */
  constructor(
    versionId,
    name,
    responses = undefined,
    intention = undefined,
    regex = undefined,
    parent = undefined
  ) {
    this.version = versionId
    this.name = name
    this.type = 'node'
    if (intention) {
      this.condition = {
        params: [{ intent: intention ? intention : '' }],
        tree: {
          funcs: ['intent'],
          facts: [null],
          operator: '...'
        }
      }
      this.name = this.condition.params[0].intent
        ? `#${this.condition.params[0].intent}`
        : this.name
    } else {
      this.condition = {
        params: [
          {
            regex: regex ? regex : ''
            // intent: intention ? intention : ''
          }
        ],
        tree: {
          funcs: [],
          facts: [null],
          operator: '...'
        }
      }
      this.name = this.condition.params[0].regex
        ? `RegEx: ${this.condition.params[0].regex}`
        : this.name
    }
    if (responses) {
      this.responses = responses ? responses : []
    }
    if (parent) {
      this.parent = parent ? parent : ''
    }
  }
}
