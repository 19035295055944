import Vue from 'vue'
import AudiencesService from '../../services/audiences.service'

const actions = {
  // audiences
  getAudiences({ state }) {
    return AudiencesService.getAudiences()
      .then(response => {
        state.audiences = response.data.result
        Vue.$log.info(state.audiences)
      })
      .catch(error => {
        throw error
      })
  },

  saveAudience({ state }) {
    return AudiencesService.saveAudience(state.audience)
      .then(response => {
        Vue.$log.info(response)
        state.push.audiences[0].audience = response.data._id
        return response.data
      })
      .catch(error => {
        throw error
      })
  },

  deleteAudience(context, audienceId) {
    return AudiencesService.deleteAudience(audienceId)
      .then(response => {
        Vue.$log.info(response)
        return response
      })
      .catch(error => {
        throw error
      })
  },

  downloadAudience(context, audienceId) {
    return AudiencesService.downloadAudience(audienceId)
      .then(response => {
        Vue.$log.info(response)
        return response
      })
      .catch(error => {
        throw error
      })
  },

  // push
  savePush({ state }) {
    return AudiencesService.savePush(state.push)
      .then(response => {
        Vue.$log.info(response)
        return response
      })
      .catch(error => {
        throw error
      })
  }
}

export default actions
