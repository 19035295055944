export default {
  nameTooLong: {
    title: {
      es: 'Oops!',
      en: 'Oops!',
      pt: 'Oops!'
    },
    text: {
      es: 'El nombre del rol no debe exceder los 15 caracteres',
      en: 'The role name must not exceed 15 characters',
      pt: 'O nome da função não deve ter mais de 15 caracteres'
    }
  },
  passwordExpiration: {
    es: 'Expiración de contraseña',
    en: 'Password expiration',
    pt: 'Vencimento da senha'
  },
  uncheckAll: {
    es: 'Desmarcar todo',
    en: 'Uncheck all',
    pt: 'Desmarcar todos'
  },
  checkAll: {
    es: 'Marcar todo',
    en: 'Check all',
    pt: 'Marcar todos'
  },
  crateRole: {
    es: 'Editar nombre de rol',
    en: 'Edit role name',
    pt: 'Editar o nome da rol'
  },
  manageUsers: {
    title: {
      es: 'Administrar usuarios',
      en: 'Manage users',
      pt: 'Gerenciar usuários'
    },
    button: {
      es: 'Seleccionar usuarios',
      en: 'Select users',
      pt: 'Selecione usuários'
    }
  },
  editRoleName: {
    es: 'Editar nombre de rol',
    en: 'Edit role name',
    pt: 'Editar o nome da rol'
  },
  dialogSelectUsers: {
    title: {
      es: 'Usuarios',
      en: 'users',
      pt: 'Usuários'
    },
    alert: {
      es: 'Por favor selecciona uno o mas usuarios',
      en: 'Please select one or more users',
      pt: 'Por favor, selecione um ou mais usuários'
    },
    selectUsers: {
      es: 'Seleccionar usuarios',
      en: 'Select users',
      pt: 'Selecione usuários'
    }
  },
  addTitle: {
    es: 'Crear rol',
    en: 'Add role',
    pt: 'Adicionar rol'
  },
  editTitle: {
    es: 'Editar rol',
    en: 'Edit role',
    pt: 'Editar rol'
  },
  title: {
    es: 'Roles',
    en: 'Roles',
    pt: 'Roles'
  },
  accept: {
    es: 'Guardar',
    en: 'Save',
    pt: 'Salvar'
  },
  cancel: {
    es: 'Cancelar',
    en: 'Cancel',
    pt: 'Cancelar'
  },
  genericSuccess: {
    title: {
      es: 'Éxito!',
      en: 'Success',
      pt: 'Sucesso'
    },
    text: {
      es: 'Cambios guardados con éxito',
      en: 'Changes saved successfully',
      pt: 'Alterações salvas com sucesso'
    }
  },
  genericError: {
    title: {
      es: 'Oops!',
      en: 'Oops!',
      pt: 'Oops!'
    },
    text: {
      es: 'Parece que ocurrió un error.',
      en: 'It seems that an error occurred.',
      pt: 'Parece que ocorreu um erro.'
    }
  },
  deleteConfirmation: {
    title: {
      es: 'Atención!',
      en: 'Warning!',
      pt: 'Aviso!'
    },
    content: {
      es:
        'Estas por borrar un rol, esta acción es irreversible. ¿Estás seguro?',
      en:
        'You are about to erase a role, this action is irreversible. Are you sure?',
      pt:
        'Você está prestes a apagar um rol, esta ação é irreversível. Tem certeza?'
    }
  },
  addButton: {
    es: 'Crear rol',
    en: 'Add role',
    pt: 'Adicionar rol'
  },
  tableHeaders: {
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome'
    },
    permissions: {
      es: 'Permisos',
      en: 'Permissions',
      pt: 'Permissões'
    },
    users: {
      es: 'Usuarios',
      en: 'Users',
      pt: 'Usuários'
    },
    updated: {
      es: 'Actualizado',
      en: 'Updated',
      pt: 'Atualizado'
    }
  },
  tableMoreTooltip: {
    morePermissions: {
      es: 'Y XXX permisos más',
      en: 'And XXX permissions more',
      pt: 'E mais XXX permissões'
    },
    moreUsers: {
      es: 'Y XXX usuarios más',
      en: 'And XXX users more',
      pt: 'E mais XXX usuários'
    }
  },
  cannotDelete: {
    es: 'Tiene usuarios asignados, no puedes borrarlo',
    en: 'This role has assigned users, you cannot delete it',
    pt: 'Atribuiu usuários, você não pode excluí-lo'
  },
  cannotSelfEdit: {
    es: 'No puedes editar tu propio rol',
    en: 'You cannot edit your own role',
    pt: 'Você não pode editar sua própria função'
  }
}
