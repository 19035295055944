import Validations from '../../store/Validations'
import Vue from 'vue'
import store from '../../store/store'
import { BUSINESS_HOURS } from '../../constants/constants'
const moment = require('moment')

/**
 * Has time
 * @param {Array} exceptionsTime all the dates
 * @param {String} key the name of the key to search for
 * @param {Number} aTime
 * @return {undefined | Object}
 */
const _hasTime = (exceptionsTime, key, aTime) => {
  return Vue._.find(exceptionsTime, exception => {
    return exception[key] === aTime
  })
}

const date_sort_asc = function(date1, date2) {
  if (date1 > date2) return 1
  if (date1 < date2) return -1
  return 0
}

export class Availability {
  constructor(days = [], exceptions, message) {
    if (days && days.length > 0) {
      this.weekDays = days
        .filter(d => d.hasOwnProperty('start'))
        .map(d => d.day)
      // get saved days & hours
      this.weekHours = days.map(d =>
        d.start
          ? {
              start: d.start,
              end: d.end
            }
          : {
              start: BUSINESS_HOURS.start,
              end: BUSINESS_HOURS.end
            }
      )
    } else {
      this.weekDays = [0, 1, 2, 3, 4, 5, 6]
      // get default days & hours
      this.weekHours = this.weekDays.map(d => {
        return {
          day: d,
          start: BUSINESS_HOURS.start,
          end: BUSINESS_HOURS.end
        }
      })
    }

    this.exceptions = exceptions ? this._loadException(exceptions) : []
    this.message = message ? message : this._setUpMessage()
  }

  _setUpMessage() {
    const message = {
      es:
        'Estamos fuera del horario de atención de nuestro Contact Center. Un agente se comunicará a la brevedad posible.',
      en:
        'We are currently out of office hours. An agent will contact you as soon as available. Thank you.',
      pt:
        'Estamos fora do horário de funcionamento do nosso Contact Center. Um agente entrará em contato com você assim que possível.'
    }

    return message[store.state.newBotLanguage]
  }

  _loadException(exceptions) {
    let exceptionsTmp = []

    if (exceptions.length > 0) {
      exceptions.forEach(yearException => {
        yearException.months.forEach(monthException => {
          monthException.days.forEach(dayException => {
            exceptionsTmp.push(
              new Date(
                yearException.year,
                monthException.month - 1,
                dayException.day
              )
            )
          })
        })
      })
    }

    return exceptionsTmp
  }

  _generateExceptionsToSave() {
    let exceptions = []

    for (let j = 0; j < this.exceptions.length; j++) {
      let exceptionDate = this.exceptions[j]
      let year = exceptionDate.getYear() + 1900
      let month = exceptionDate.getMonth() + 1
      let day = exceptionDate.getDate()

      let yearToSave = _hasTime(exceptions, 'year', year)
      if (yearToSave) {
        let monthToSave = _hasTime(yearToSave.months, 'month', month)
        if (monthToSave) {
          let dayToSave = _hasTime(monthToSave.days, 'day', day)
          if (!dayToSave) {
            monthToSave.days.push({ day })
          }
        } else {
          // Add month
          yearToSave.months.push({ month, days: [{ day }] })
        }
      } else {
        // Add complete date
        exceptions.push({ year, months: [{ month, days: [{ day }] }] })
      }
    }

    return exceptions
  }

  isWeekDaysValid() {
    return !Validations.isEmpty(this.weekDays)
  }

  areHoursValid() {
    const emptyHours = this.weekDays.some(
      d =>
        this.weekHours[d] &&
        (this.weekHours[d].start === '' || this.weekHours[d].end === '')
    )
    return this.isWeekDaysValid() && !emptyHours
  }

  isMessageValid() {
    if (this.exceptions.length > 0) {
      return !Validations.isEmpty(this.message)
    }
  }

  addExceptionDate(date) {
    this.exceptions.push(date)
    this.exceptions.sort(date_sort_asc)
  }

  removeDateFromCalendar(index) {
    this.exceptions.splice(index, 1)
  }

  getAvailabilityToSave() {
    let availability = {}
    let days = []

    for (let i = 0; i < 7; i++) {
      let dateTmp = {
        day: i
      }
      if (this.weekDays.includes(i)) {
        dateTmp.start = this.weekHours[i].start
        dateTmp.end = this.weekHours[i].end
      }

      days.push(dateTmp)
    }

    availability.exceptions = this._generateExceptionsToSave()
    availability.message = this.message

    availability.days = days

    return availability
  }

  validate(validations) {
    validations.availability.weekDaysEmpty = !this.isWeekDaysValid()
    validations.availability.hoursEmpty = !this.areHoursValid()
    validations.availability.messageError =
      this.exceptions.length > 0 ? !this.isMessageValid() : false
  }

  /**
   * Determine if the date is already included in the exceptions
   * @param aDate {Date}
   * @returns {boolean}
   */
  hasDateAsException(aDate) {
    return this.exceptions.find(d => {
      let originalDAte = moment(d)
      return originalDAte.isSame(aDate)
    })
  }
}
