/*=========================================================================================
  File Name: themeConfig.js
  Description: Theme configuration
  ----------------------------------------------------------------------------------------
  Item Name: Modern Admin - Clean Bootstrap 4 Dashboard HTML Template
  Version: 1.1
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

// MAIN COLORS - VUESAX THEME COLORS
export const themeColors = {
  primary: '#0096B6', // linear-gradient(rgb(255, 0, 61), rgb(255, 94, 0))
  success: 'rgb(40, 199, 111)',
  danger: 'rgb(234, 84, 85)',
  warning: 'rgb(255, 159, 67)',
  dark: 'rgb(0, 0, 0)'
}

export const themes = ['light', 'dark', 'semi-dark']

// CONFIGS
export const themeConfig = {
  theme: 'semi-dark', // options[String]: 'light'(default), 'dark', 'semi-dark'
  sidebarCollapsed: true, // options[Boolean]: true, false(default)
  navbarColor: '#fff', // options[String]: HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
  navbarType: 'float', // options[String]: floating(default) / static / sticky / hidden
  footerType: 'static', // options[String]: static(default) / sticky / hidden
  routerTransition: 'zoom-fade', // options[String]: zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
  disableCustomizer: false // options[Boolean]: true, false(default)
}
