import { MessageType } from '../MessageType'

export class GenericMessageType extends MessageType {
  constructor(name) {
    super(name)
  }

  getType() {
    return 'generic'
  }

  isGenericMessage() {
    return true
  }

  isTextMessage() {
    return false
  }
  isRegexMessage() {
    return false
  }
  isImageMessage() {
    return false
  }

  isMediaMessage() {
    return false
  }
  isCardMessage() {
    return false
  }

  isSliderMessage() {
    return false
  }

  isIntentMessage() {
    return false
  }

  isButtonsMessage() {
    return false
  }

  isValid() {
    super.isNameValid()
  }

  save() {
    return Promise.resolve()
  }
}
