export default {
  backToLogin: {
    es: 'Volver al Login',
    en: 'Back To Login',
    pt: 'Voltar ao Login',
  },
  backToHome: {
    es: 'Volver al inicio',
    en: 'Back to home',
    pt: 'Voltar ao início',
  },
  title: {
    es: 'Oops!',
    en: 'Oops',
    pt: 'Oops!',
  },
  description: {
    es: 'Algo salió mal. Por favor intenta de vuelta mas tarde',
    en: 'Something went wrong. Please try again later',
    pt: 'Algo deu errado. Por favor, tente de novo mais tarde',
  },
  errorTitle: {
    e400: {
      es: 'Página no encontrada!',
      en: 'Page Not Found!',
      pt: 'Página não encontrada!',
    },
    e500: {
      es: 'Error inesperado!',
      en: 'Unexpected Error!',
      pt: 'Erro inesperado!',
    },
    e403: {
      es: '¡Usted no está autorizado!',
      en: 'You are not authorized!',
      pt: 'Você não está autorizado!',
    },
    multiple: {
      es: 'Hay varios errores',
      en: 'There are multiple errors',
      pt: 'Há vários erros',
    },
    duplicate_intent_name: {
      es: 'Nombre de intención duplicada',
      en: 'Duplicated intent name',
      pt: 'Nome de intenção duplicada',
    },
    duplicate_intent_examples: {
      es: 'Nombre de ejemplo duplicado',
      en: 'Duplicated example name',
      pt: 'Nome de exemplo duplicado',
    },
    company_exists: {
      es: 'Nombre de compañía duplicado',
      en: 'Duplicated company name',
      pt: 'Nome da empresa duplicado',
    },
    username_exists: {
      es: 'Nombre de usuario duplicado',
      en: 'Duplicated username',
      pt: 'Nome de usuário duplicado',
    },
    synonyms: {
      es: 'Nombre de sinónimo duplicado',
      en: 'Duplicated synonym name',
      pt: 'Nome de sinônimo duplicado',
    },
  },
  errorDescription: {
    e400: {
      es: 'Desafortunadamente, no pudimos encontrar la página que estaba buscando.',
      en: 'Unfortunately, we couldn’t find the page you were looking for.',
      pt: 'Infelizmente, não conseguimos encontrar a página que procurava.',
    },
    e500: {
      es: 'El servidor no puede manejar esta solicitud en este momento.',
      en: 'The server is currently unable to handle this request.',
      pt: 'O servidor não pode lidar com esta solicitação neste momento.',
    },
    e403: {
      es: 'Usted no está autorizado para acceder a esta página. Por favor, pongase en contacto con su administrador.',
      en: 'You are not authorized to access this page. Please contact your administrator.',
      pt: 'Você não está autorizado a acessar esta página. Entre em contato com o seu administrador.',
    },
    duplicate_intent_name: {
      es: 'Ya existe otra intención con ese nombre.',
      en: 'There is already another intention with that name.',
      pt: 'Já há outra intenção com esse nome.',
    },
    duplicate_intent_examples: {
      es: 'Un ejemplo agregado ya fue registrado en la intención',
      en: 'An added example was already registered by intention',
      pt: 'Um exemplo adicional já foi registrado por intenção',
    },
    company_exists: {
      es: 'Ya existe otra organización con el mismo nombre.',
      en: 'There is already another tenant with that name.',
      pt: 'Já existe outra organização com o mesmo nome.',
    },
    username_exists: {
      es: 'Ya existe otro usuario con el mismo nombre.',
      en: 'There is already another user with that name.',
      pt: 'Já há outro usuário com esse nome.',
    },
    synonyms: {
      es: 'Ya existe una entidad con el mismo sinónimo',
      en: 'There is already another entity with that synonym',
      pt: 'Já há outra entidade com esse sinônimo',
    },
  },
}