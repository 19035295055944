import Vue from 'vue'
import { audienceFactory, pushFactory } from '../factories'

const mutations = {
  // audience
  RESET_AUDIENCE: state => {
    state.audience = audienceFactory()
    state.push = pushFactory()
  },
  SET_AUDIENCE_ITEMS: (state, audienceItems) => {
    state.audience.audienceItems = audienceItems
  },

  // push
  SET_PUSH_INTEGRATION: (state, integration) => {
    state.push.audiences[0].integration = integration
    Vue.$log.info(integration)
  },
  SET_PUSH_SCHEDULE: (state, { schedule, timeZone }) => {
    state.push.schedule = schedule
    state.push.timeOffset = timeZone
    Vue.$log.info(schedule, timeZone)
  },
  SET_PUSH_NAME: (state, name) => {
    state.push.name = name
    Vue.$log.info(name)
  }
}

export default mutations
