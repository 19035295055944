export default {
  title: {
    es: 'Iniciar sesión',
    en: 'Login',
    pt: 'Acessar',
  },
  description: {
    es: 'Bienvenido de nuevo, por favor inicia sesión con tu cuenta.',
    en: 'Welcome back, please login to your account.',
    pt: 'Bem-vindo de volta, por favor, faça login na sua conta.',
  },
  rememberMe: {
    es: 'Recordarme',
    en: 'Remember me',
    pt: 'Lembre-se de mim',
  },
  forgotPassword: {
    es: '¿Olvidaste tu contraseña?',
    en: 'Forgot your password?',
    pt: 'Esqueceu sua senha?',
  },
  goBackText: {
    es: 'Iniciando sesión como ',
    en: 'Logging in as ',
    pt: 'Entrando como',
  },
  goBackQuestion: {
    es: 'No eres tu?',
    en: 'Not you?',
    pt: 'Não é você?',
  },
  form: {
    email: {
      es: 'Correo Electrónico o Nombre de Usuario',
      en: 'Email or Username',
      pt: 'E-mail ou nome de usuário',
    },
    password: {
      es: 'Contraseña',
      en: 'Password',
      pt: 'Senha',
    },
    loginButton: {
      es: 'Entrar',
      en: 'Login',
      pt: 'Acessar',
    },
    continueButton: {
      es: 'Continuar',
      en: 'Continue',
      pt: 'Continuar',
    },
    selectDomainLabel: {
      es: 'Dominios',
      en: 'Domains',
      pt: 'Domínios',
    },
    response: {
      success: {
        title: {
          es: 'Bienvenido',
          en: 'Welcome',
          pt: 'Bem-vindo',
        },
        description: {
          es: 'Has accedido exitosamente',
          en: 'You have logged in successfully',
          pt: 'Você se logou com sucesso',
        },
      },
      error400: {
        title: {
          es: 'Incorrecto',
          en: 'Incorrect',
          pt: 'Incorreto',
        },
        description: {
          es: 'Correo electrónico o contraseña no coinciden',
          en: 'Wrong email or password',
          pt: 'E-mail ou senha errados',
        },
      },
    },
  },
}