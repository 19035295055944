/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
            path => router path
            name => router name
            component(lazy loading) => component to load
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Version: 1.1
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'

import { USER_ROLES } from './models/Roles/UserRoles'
import { User } from './models/User'
import { FILTERS } from './constants/constants'

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('./views/home/Main.vue'),
      name: 'home',
      meta: {
        canAccess: [
          USER_ROLES.SYS_ADMIN,
          USER_ROLES.SUPER_ADMIN,
          USER_ROLES.SUPERVISOR,
          USER_ROLES.EDITOR,
          USER_ROLES.AGENT,
          USER_ROLES.ROLES_MANAGER
        ],
        staffManagement: {
          canAccess: ['*']
        }
      },
      beforeEnter(to, from, next) {
        if (store.state.changePassword) {
          next({
            path: '/pages/change-password'
          })
        } else if (localStorage.sessionStatus !== 'true') {
          next({
            path: '/pages/login'
          })
        } else {
          next()
        }
      },
      children: [
        {
          path: 'companies',
          component: () => import('./views/home/companies/Main'),
          meta: {
            pageTitle: 'Companies',
            canAccess: [USER_ROLES.SYS_ADMIN],
            staffManagement: {
              canAccess: []
            }
          },
          children: [
            {
              path: '/',
              component: () => import('./views/home/companies/Companies'),
              meta: {
                pageTitle: '',
                canAccess: [USER_ROLES.SYS_ADMIN],
                staffManagement: {
                  canAccess: []
                }
              }
            },
            {
              path: '/companies/add',
              component: () => import('./views/home/companies/CreateCompany'),
              meta: {
                pageTitle: 'Add Company',
                canAccess: [USER_ROLES.SYS_ADMIN],
                staffManagement: {
                  canAccess: []
                }
              }
            }
          ]
        },
        {
          path: 'attention-requests',
          name: 'attention-requests',
          component: () => import('./views/home/attention-requests/Main.vue'),
          meta: {
            pageTitle:
              'lang.attentionRequests.title[store.state.languageSelected]',
            canAccess: [
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.AGENT
            ],
            staffManagement: {
              canAccess: ['konecta.pending_chats.list']
            }
          }
        },
        {
          path: 'all-chats',
          name: 'all-chats',
          component: () => import('./views/home/all-chats/Main.vue'),
          meta: {
            pageTitle:
              'lang.attentionRequests.title[store.state.languageSelected]',
            canAccess: [
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.AGENT
            ],
            staffManagement: {
              canAccess: ['konecta.all_chats.list']
            }
          }
        },
        {
          path: 'attention-requests-settings',
          name: 'attention-requests-settings',
          component: () =>
            import('./views/home/attention-requests-settings/Main.vue'),
          meta: {
            pageTitle:
              'lang.attentionRequests.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
            staffManagement: {
              canAccess: ['konecta.pending_chats_settings.view']
            }
          }
        },
        {
          path: 'surveys',
          name: 'surveys',
          component: () =>
            import('./views/home/survey-maker/surveys-view/Main.vue'),
          meta: {
            pageTitle: 'lang.surveyMaker.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
            staffManagement: {
              canAccess: ['konecta.surveys.list']
            }
          },
          beforeEnter(to, from, next) {
            const showAudiences = JSON.parse(localStorage.session)
              .audiencesAndPush
            if (showAudiences) {
              next()
            } else {
              next('/')
            }
          }
        },
        {
          path: '/surveys-integrations',
          component: () => import('./views/home/survey-send-view/Main.vue'),
          meta: {
            pageTitle: 'Survey Integrations',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
            staffManagement: {
              canAccess: ['konecta.surveys.send']
            }
          },
          beforeEnter(to, from, next) {
            const showAudiences = JSON.parse(localStorage.session)
              .audiencesAndPush
            if (showAudiences) {
              next()
            } else {
              next('/')
            }
          }
        },
        {
          name: 'surveys-data',
          path: 'surveys-data',
          component: () => import('./views/home/survey-data-view/Main.vue'),
          meta: {
            pageTitle: 'lang.surveyMaker.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
            staffManagement: {
              canAccess: ['konecta.surveys.results']
            }
          },
          beforeEnter(to, from, next) {
            const showAudiences = JSON.parse(localStorage.session)
              .audiencesAndPush
            if (showAudiences) {
              next()
            } else {
              next('/')
            }
          }
        },
        {
          name: 'surveys-metrics',
          path: 'surveys-metrics',
          component: () => import('./views/home/survey-metrics/Main.vue'),
          meta: {
            pageTitle: 'lang.surveyMaker.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
            staffManagement: {
              canAccess: ['konecta.surveys.metrics']
            }
          },
          beforeEnter(to, from, next) {
            const showAudiences = JSON.parse(localStorage.session)
              .audiencesAndPush
            if (showAudiences) {
              next()
            } else {
              next('/')
            }
          }
        },
        {
          path: '/bot-maker',
          component: () => import('./views/home/bot-maker/Main.vue'),
          redirect: '/bot-maker/bots',
          meta: {
            pageTitle:
              'lang.attentionRequests.title[store.state.languageSelected]',
            canAccess: [
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.EDITOR
            ],
            staffManagement: {
              canAccess: ['konecta.bots.list']
            }
          },
          children: [
            {
              path: '/bot-maker/bots',
              name: 'bots',
              component: () =>
                import('./views/home/bot-maker/bots-view/Bots.vue'),
              meta: {
                canAccess: [
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.SUPERVISOR,
                  USER_ROLES.EDITOR
                ],
                staffManagement: {
                  canAccess: ['konecta.bots.list']
                }
              }
            },
            {
              path: '/bot-maker/add',
              name: 'create-bot',
              component: () =>
                import('./views/home/bot-maker/add-bot/AddBot.vue'),
              meta: {
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
                staffManagement: {
                  canAccess: ['konecta.bots.add']
                }
              }
            },
            {
              path: '/bot-maker/bot/:bot',
              name: 'bot-editor',
              component: () =>
                import('./views/home/bot-maker/bot-editor/BotEditor.vue'),
              meta: {
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
                staffManagement: {
                  canAccess: ['konecta.bot_maker.edit_bot', 'konecta.bot_maker.view_bot']
                }
              }
            },
            {
              path: '/bot-maker/bot/:bot/edit',
              name: 'edit-bot',
              component: () =>
                import('./views/home/bot-maker/add-bot/AddBot.vue'),
              meta: {
                canAccess: [
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.SUPERVISOR,
                  USER_ROLES.EDITOR
                ],
                staffManagement: {
                  canAccess: ['konecta.bots.add', 'konecta.bot_maker.edit_bot_settings']
                }
              }
            },
            {
              path: '/bot-maker/bot/:bot/test-groups',
              name: 'test-groups',
              component: () =>
                import('./views/home/bot-maker/bot-test-groups/BotTestGroups.vue'),
              meta: {
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
                staffManagement: {
                  canAccess: ['konecta.test_groups.list']
                }
              }
            },
            {
              path: '/bot-maker/bot/:bot/test',
              name: 'test-bot',
              component: () =>
                import('./views/home/bot-maker/bot-test/BotTest.vue'),
              meta: {
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
                staffManagement: {
                  canAccess: ['konecta.bots.try_bot']
                }
              }
            },
            {
              path: '/bot-maker/bot/:bot/logs-stream',
              name: 'logs-bot',
              component: () =>
                import(
                  './views/home/bot-maker/bot-logs-stream/BotLogsStream.vue'
                ),
              meta: {
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
                staffManagement: {
                  canAccess: ['konecta.bot_maker.logs_stream']
                }
              }
            },
            {
              path: '/bot-maker/bot/:bot/integrations',
              name: 'integrations',
              component: () =>
                import('./views/home/integrations/Integrations.vue'),
              meta: {
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
                staffManagement: {
                  canAccess: ['konecta.bot_maker.integrations']
                }
              }
            },
            {
              path: '/bot-maker/bot/:bot/entities',
              name: 'entities',
              component: () => import('./views/home/entities/Entities.vue'),
              meta: {
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
                staffManagement: {
                  canAccess: ['konecta.bot_maker.catalogs']
                }
              }
            },
            {
              path: '/bot-maker/bot/:bot/catalogs',
              name: 'catalogs',
              component: () => import('./views/home/catalogs/Catalogs.vue'),
              meta: {
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
                staffManagement: {
                  canAccess: ['konecta.bot_maker.catalogs']
                }
              }
            },
            {
              path: '/bot-maker/bot/:bot/tree-view',
              name: 'tree-view',
              component: () => import('./views/home/tree-view/TreeView.vue'),
              meta: {
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
                staffManagement: {
                  canAccess: ['konecta.bot_maker.tree_view']
                }
              }
            },
            {
              path: '/bot-maker/bot/:bot/intentions',
              name: 'intentions',
              component: () =>
                import('./views/home/intentionsView/IntentionsView.vue'),
              meta: {
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EDITOR],
                staffManagement: {
                  canAccess: ['konecta.bot_maker.integrations']
                }
              }
            }
          ]
        },
        {
          path: '/dashboard',
          component: () => import('./views/home/metrics/Main.vue'),
          meta: {
            pageTitle: 'lang.metrics.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
            staffManagement: {
              canAccess: ['konecta.analytics.dashboard']
            },
            reportsFilter: FILTERS.DASHBOARD.REPORTS
          }
        },
        {
          path: '/metrics',
          component: () => import('./views/home/metrics/Main.vue'),
          meta: {
            pageTitle: 'lang.metrics.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
            staffManagement: {
              canAccess: ['konecta.analytics.general']
            },
            reportsFilter: FILTERS.METRICS.REPORTS
          }
        },
        {
          name: 'audit',
          path: '/audit',
          component: () => import('./views/home/audit/Main.vue'),
          meta: {
            pageTitle: 'lang.surveyMaker.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN],
            staffManagement: {
              canAccess: ['konecta.audit.list']
            },
          }
        },
        {
          path: '/knowledge',
          component: () => import('./views/home/metrics/Main.vue'),
          meta: {
            pageTitle: 'lang.metrics.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
            staffManagement: {
              canAccess: ['konecta.analytics.knowledge']
            },
            reportsFilter: FILTERS.KNOWLEDGE.REPORTS
          }
        },
        {
          path: '/sessions-flow',
          component: () =>
            import('./views/home/metrics/sessions-flow/Main.vue'),
          meta: {
            pageTitle:
              'lang.metricSessions.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
            staffManagement: {
              canAccess: ['konecta.analytics.session_flows']
            },
          }
        },
        {
          path: '/metric-sessions',
          component: () =>
            import('./views/home/metrics/metric-sessions/Main.vue'),
          meta: {
            pageTitle:
              'lang.metricSessions.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
            staffManagement: {
              canAccess: ['konecta.analytics.sessions']
            },
          }
        },
        {
          path: '/agents',
          component: () => import('./views/home/metrics/agents/Main.vue'),
          meta: {
            pageTitle: 'lang.metrics.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
            staffManagement: {
              canAccess: ['konecta.analytics.agents']
            },
          }
        },
        {
          path: '/plugins',
          component: () => import('./views/home/plugins/Main.vue'),
          meta: {
            pageTitle: 'Plugins',
            canAccess: [USER_ROLES.SUPER_ADMIN],
            staffManagement: {
              canAccess: []
            },
          }
        },
        {
          path: '/roles',
          component: () => import('./views/home/staffRoles/Main'),
          meta: {
            pageTitle: 'Roles',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
            staffManagement: {
              canAccess: ['konecta.roles.list']
            },
          },
          children: [
            {
              path: '',
              component: () => import('./views/home/staffRoles/StaffRoles'),
              meta: {
                pageTitle: '',
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
                staffManagement: {
                  canAccess: ['konecta.roles.list']
                },
              }
            },
            {
              path: '/roles/add',
              component: () => import('./views/home/staffRoles/AddStaffRole'),
              meta: {
                pageTitle: 'Add User',
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
                staffManagement: {
                  canAccess: ['konecta.roles.add']
                },
              }
            },
            {
              path: '/roles/:staffRoleId/edit',
              component: () => import('./views/home/staffRoles/AddStaffRole'),
              meta: {
                pageTitle: 'Edit User',
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
                staffManagement: {
                  canAccess: ['konecta.roles.edit']
                },
              }
            }
          ]
        },
        {
          path: '/users',
          component: () => import('./views/home/users/Main'),
          meta: {
            pageTitle: 'Users',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
            staffManagement: {
              canAccess: ['konecta.users.list']
            },
          },
          children: [
            {
              path: '',
              component: () => import('./views/home/users/Users'),
              meta: {
                pageTitle: '',
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
                staffManagement: {
                  canAccess: ['konecta.users.list']
                },
              }
            },
            {
              path: '/users/add',
              component: () => import('./views/home/users/AddUser'),
              meta: {
                pageTitle: 'Add User',
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
                staffManagement: {
                  canAccess: ['konecta.users.add']
                },
              }
            },
            {
              path: '/users/:userId/edit',
              component: () => import('./views/home/users/AddUser'),
              meta: {
                pageTitle: 'Edit User',
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
                staffManagement: {
                  canAccess: ['konecta.users.edit']
                },
              }
            }
          ]
        },
        {
          path: '/themes',
          component: () =>
            import('./views/home/configuration/themes/Themes.vue'),
          meta: {
            pageTitle: 'lang.themes.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN],
            staffManagement: {
              canAccess: ['konecta.themes.view']
            },
          }
        },
        {
          path: '/ip-ranges',
          component: () =>
            import('./views/home/security/ipRanges/IPRanges.vue'),
          meta: {
            pageTitle: 'lang.themes.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN],
            staffManagement: {
              canAccess: ['konecta.security.ip_ranges']
            },
          }
        },
        {
          path: '/nlu',
          component: () =>
            import('./views/home/configuration/credentials/nlu/NLU.vue'),
          meta: {
            pageTitle: 'lang.themes.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN],
            staffManagement: {
              canAccess: ['konecta.nlu_settings.view']
            },
          }
        },
        {
          path: '/diffusion',
          component: () => import('./views/home/diffusions/diffusion/Main'),
          meta: {
            pageTitle: 'lang.themes.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
            staffManagement: {
              canAccess: ['konecta.broadcast.list']
            },
          },
          beforeEnter(to, from, next) {
            const showAudiences = JSON.parse(localStorage.session)
              .audiencesAndPush
            if (showAudiences) {
              next()
            } else {
              next('/')
            }
          },
          children: [
            {
              path: '',
              component: () =>
                import('./views/home/diffusions/diffusion/Diffusion.vue'),
              meta: {
                pageTitle:
                  'lang.audiences.view.title[store.state.languageSelected]',
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
                staffManagement: {
                  canAccess: ['konecta.broadcast.list']
                },
              }
            },
            {
              path: '/diffusion/quick-message',
              component: () =>
                import(
                  './views/home/diffusions/quick-message/QuickMessage.vue'
                ),
              meta: {
                pageTitle:
                  'lang.audiences.view.title[store.state.languageSelected]',
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
                staffManagement: {
                  canAccess: ['konecta.broadcast.quick_message']
                },
              }
            }
          ]
        },
        {
          path: '/clustering',
          component: () => import('./views/home/training/clustering/Main.vue'),
          meta: {
            pageTitle: 'lang.metrics.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
            staffManagement: {
              canAccess: ['konecta.clusterization.list']
            },
          },
          children: [
            {
              path: '',
              component: () =>
                import(
                  './views/home/training/clustering/clusters-view/Clustering.vue'
                ),
              meta: {
                pageTitle: 'Clusters view',
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
                staffManagement: {
                  canAccess: ['konecta.clusterization.list']
                },
              }
            },
            {
              path: '/clustering/:clusterId/centers',
              component: () =>
                import(
                  './views/home/training/clustering/centers/ClusterCenters.vue'
                ),
              meta: {
                pageTitle: 'Clusters centers',
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
                staffManagement: {
                  canAccess: ['konecta.clusterization.list']
                },
              }
            }
          ],
          beforeEnter(to, from, next) {
            const showClustering = JSON.parse(localStorage.session).clustering
            if (showClustering) {
              next()
            } else {
              next('/')
            }
          }
        },
        {
          path: '/ai-trainer',
          component: () => import('./views/home/training/ai-trainer/Main.vue'),
          meta: {
            pageTitle: 'lang.metrics.title[store.state.languageSelected]',
            canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPERVISOR],
            staffManagement: {
              canAccess: ['konecta.trainer.view']
            },
          },
          children: [
            {
              path: '',
              component: () =>
                import(
                  './views/home/training/ai-trainer/trainer-view/AITrainer.vue'
                ),
              meta: {
                pageTitle: 'AI Trainer view',
                canAccess: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ROLES_MANAGER],
                staffManagement: {
                  canAccess: ['konecta.trainer.view']
                },
              }
            }
          ],
          beforeEnter(to, from, next) {
            const showClustering = JSON.parse(localStorage.session).clustering
            if (showClustering) {
              next()
            } else {
              next('/')
            }
          }
        }
      ]
    },
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/pages/login',
          name: 'pageLogin',
          component: () => import('@/views/pages/Login.vue'),
          meta: {
            canAccess: [
              USER_ROLES.SYS_ADMIN,
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.EDITOR,
              USER_ROLES.AGENT,
              USER_ROLES.ROLES_MANAGER
            ],
            staffManagement: {
              canAccess: ['*']
            },
          }
        },
        {
          path: '/pages/404',
          name: 'pageError404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            canAccess: [
              USER_ROLES.SYS_ADMIN,
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.EDITOR,
              USER_ROLES.AGENT,
              USER_ROLES.ROLES_MANAGER
            ],
            staffManagement: {
              canAccess: ['*']
            },
          }
        },
        {
          path: '/pages/500',
          name: 'pageError500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            canAccess: [
              USER_ROLES.SYS_ADMIN,
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.EDITOR,
              USER_ROLES.AGENT,
              USER_ROLES.ROLES_MANAGER
            ],
            staffManagement: {
              canAccess: ['*']
            },
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'pageNotAuthorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            canAccess: [
              USER_ROLES.SYS_ADMIN,
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.EDITOR,
              USER_ROLES.AGENT,
              USER_ROLES.ROLES_MANAGER
            ],
            staffManagement: {
              canAccess: ['*']
            },
          }
        },
        {
          path: '/pages/banned',
          name: 'Banned',
          component: () => import('@/views/pages/Banned.vue'),
          meta: {
            canAccess: [
              USER_ROLES.SYS_ADMIN,
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.EDITOR,
              USER_ROLES.AGENT,
              USER_ROLES.ROLES_MANAGER
            ],
            staffManagement: {
              canAccess: ['*']
            },
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            canAccess: [
              USER_ROLES.SYS_ADMIN,
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.EDITOR,
              USER_ROLES.AGENT,
              USER_ROLES.ROLES_MANAGER
            ],
            staffManagement: {
              canAccess: ['*']
            },
          }
        },
        {
          path: '/pages/recovery/:token',
          name: 'page-recovery',
          component: () => import('@/views/pages/SetPassword.vue'),
          meta: {
            canAccess: [
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.EDITOR,
              USER_ROLES.AGENT,
              USER_ROLES.ROLES_MANAGER
            ],
            staffManagement: {
              canAccess: ['*']
            },
          }
        },
        {
          path: '/pages/account-setup/:token',
          name: 'page-account-setup',
          component: () => import('@/views/pages/AccountSetup.vue'),
          meta: {
            canAccess: [
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.EDITOR,
              USER_ROLES.AGENT,
              USER_ROLES.ROLES_MANAGER
            ],
            staffManagement: {
              canAccess: ['*']
            },
          }
        },
        {
          path: '/pages/chargify-signup',
          name: 'page-chargify-signup',
          component: () => import('@/views/pages/ChargifySignup.vue'),
          meta: {
            canAccess: [
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.EDITOR,
              USER_ROLES.AGENT,
              USER_ROLES.ROLES_MANAGER
            ],
            staffManagement: {
              canAccess: ['*']
            },
          }
        },
        {
          path: '/pages/change-password',
          name: 'page-change-password',
          component: () => import('@/views/pages/ChangePassword.vue'),
          meta: {
            canAccess: [
              USER_ROLES.SYS_ADMIN,
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.SUPERVISOR,
              USER_ROLES.EDITOR,
              USER_ROLES.AGENT,
              USER_ROLES.ROLES_MANAGER
            ],
            staffManagement: {
              canAccess: ['*']
            },
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/404'
    }
  ]
})

/**
 * Determine if the current user role can access to the route
 * @param roles {Array<String>}
 * @param routeTo
 * @returns {boolean}
 */
const roleCanAccess = ({ roles, staffManagementPermissions, routeTo }) => {
  if (staffManagementPermissions) {
    return roles.concat('*').filter(x => routeTo.meta.staffManagement.canAccess.includes(x)).length > 0
  }
  return roles.filter(x => routeTo.meta.canAccess.includes(x)).length > 0
}

router.beforeEach((to, from, next) => {
  if (
    store.state &&
    store.state.session &&
    store.state.session.useStaffManagement
  ) {
    if (roleCanAccess({ roles: store.state.session.roles, staffManagementPermissions: true, routeTo: to })) {
      return next()
    } else {
      return next('/pages/not-authorized')
    }
  }
  if (
    store.state.session &&
    store.state.session.user &&
    store.state.session.user.roles
  ) {
    if (roleCanAccess({ roles: store.state.session.user.roles.ids, routeTo: to })) {
      return next()
    } else {
      return next('/pages/not-authorized')
    }
  } else {
    const sessionStorage = JSON.parse(localStorage.getItem('session'))
    if (sessionStorage && sessionStorage.useStaffManagement) {
      if (roleCanAccess({ roles: sessionStorage.roles, staffManagementPermissions: true, routeTo: to })) {
        return next()
      } else {
        return next('/pages/not-authorized')
      }
    }
    if (sessionStorage && sessionStorage.roles) {
      const user = new User(
        sessionStorage.user.name,
        sessionStorage.user.email,
        '',
        sessionStorage.roles
      )
      if (roleCanAccess({ roles: user.roles.ids, routeTo: to })) {
        return next()
      } else {
        return next('/pages/not-authorized')
      }
    } else if (
      to.path === '/pages/login' ||
      to.path === '/pages/reset-password' ||
      to.path === '/pages/chargify-signup' ||
      /^\/pages\/recovery\/.+/.test(to.path) ||
      /^\/pages\/account-setup\/.+/.test(to.path)
    ) {
      next()
    } else {
      next('/pages/login')
    }
  }
})

router.afterEach(() => {
  // remove tooltips
  const tooltips = document.querySelectorAll('.vs-tooltip')
  tooltips.forEach(tooltip => tooltip.remove())
})

export default router

Vue.use(Router)
