const state = {
  clusters: [],
  centers: {},
  questions: [],
  questionsLength: null,
  questionsBotVersions: {},
  versionsById: {}
}

export default state
