import { Message } from '../Message'
import { TextMessageType } from './MessageTypes/TextMessageType'

export class BotMessage extends Message {
  constructor(id, dialogSavedId, type) {
    if (!type) {
      type = new TextMessageType()
    }
    super(id, dialogSavedId, type)
  }

  isUserMessage() {
    return false
  }
  isBotMessage() {
    return true
  }
}
