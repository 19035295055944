export default {
  bot: {
    placeholder: {
      es: 'Buscar bot',
      en: 'Search bot',
      pt: 'Pesquisar bot',
    },
    selectedLabel: {
      es: 'Seleccionado',
      en: 'Selected',
      pt: 'Selecionado',
    },
    deselectLabel: {
      es: 'Presione enter para eliminar',
      en: 'Press enter to remove',
      pt: 'Pressione digitar para remover',
    },
    limitText: {
      pre: {
        es: 'y',
        en: 'and',
        pt: 'e',
      },
      post: {
        es: 'más',
        en: 'more',
        pt: 'mais',
      },
    },
  },
  report: {
    selectedLabel: {
      es: 'Seleccionado',
      en: 'Selected',
      pt: 'Selecionado',
    },
    deselectLabel: {
      es: 'Presione enter para eliminar',
      en: 'Press enter to remove',
      pt: 'Pressione digitar para remover',
    },
  },
  platform: {
    placeholder: {
      es: 'Buscar plataforma',
      en: 'Search platform',
      pt: 'Plataforma de pesquisa',
    },
    selectedLabel: {
      es: 'Seleccionada',
      en: 'Selected',
      pt: 'Selecionado',
    },
    deselectLabel: {
      es: 'Presione enter para eliminar',
      en: 'Press enter to remove',
      pt: 'Pressione digitar para remover',
    },
  },
}