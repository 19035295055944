import store from '../store/store'
import Validations from '../store/Validations'
import { Dialog } from './Dialog'
import { TextMessageResponse } from './Responses/TextMessage'
import BotMakerService from '../services/botMaker.service'
import timeZones from '../constants/_TIME_ZONES.json'
import botMaker from '../languages/botMaker'

const moment = require('moment')

const { isAuthorized } = require('../staffManagement/viewPermissionMapping');

function isAuthAux({ key, useStaffManagement, ifNoStaffManagement=false }) {
  if (!useStaffManagement) {
    return ifNoStaffManagement
  }
  const session = JSON.parse(localStorage.session)
  const roles = session && session.roles
  return isAuthorized({ key, permissions: roles })
}

export class Bot {
  /**
   * Create a bot
   * @param botService {BotService}
   * @param nlu {NLU}
   * @param noIntentMessage {String}
   * @param intentConfidence {string}
   * @param connectionErrorMessage {String}
   * @param availability {Availability}
   * @param intents {Array<Intent>}
   * @param rawIntents {Array<String>}
   * @param id {String} the bot ID
   * @param createdAt {Date} the date the bot was created
   * @param updatedAt {Date} the date the bot was updated
   * @param legacy {Boolean} true when the bot is a legacy one
   * @param activeVersion {String} the ID of the bot active version
   * @param passiveChat {Boolean} true when bot shows suggestions
   * @param checkAvailability {Boolean} true when bot don`t scale out of hour
   * @param timeOffset {String}
   * @param secondLevelMessage {String}
   * @param noIntentAction {String}
   *
   */
  constructor(
    botService,
    nlu,
    noIntentMessage,
    noIntentAction,
    intentConfidence,
    availability,
    connectionErrorMessage,
    intents = [],
    rawIntents = [],
    id = undefined,
    createdAt = undefined,
    updatedAt = undefined,
    legacy = false,
    activeVersion = undefined,
    passiveChat,
    checkAvailability = false,
    timeOffset,
    secondLevelMessage = undefined,
    bypass = false,
    inactivityTime = undefined,
    inactivityMessage = undefined,
    noIntentTimesToTakeOver = 0,
    platformsNoIntentTimesToTakeOver = [],
    noIntentMessage2 = undefined,
    sessionTimeoutStatus0 = undefined,
    sessionTimeoutStatus1 = undefined,
    sessionTimeoutStatus2 = undefined,
    sessionTimeoutStatus3 = undefined,
    intentsByPlatform = [],
    showEmotions = false,
    disambiguationAction = undefined,
    entityExtractor = false,
    useAsTemplate = false,
    notDialogDetected = {
      active: false,
      url: ''
    }
  ) {
    this.id = id
    this._botService = botService
    this._nlu = nlu
    this._noIntentMessage1 = noIntentMessage
      ? noIntentMessage
      : this._setNoIntentMessage()
    if (noIntentAction) {
      this._noIntentAction = noIntentAction
    } else if (noIntentMessage) {
      this._noIntentAction = {}
      this._noIntentAction.name =
        botMaker.botMessageValidation.form.answers.table.text[
        store.state.newBotLanguage
        ]
      this._noIntentAction.key = 'responseText'
      this._noIntentAction.args = [noIntentMessage]
    } else {
      this._noIntentAction = {}
      this._noIntentAction.name =
        botMaker.botMessageValidation.form.answers.table.text[
        store.state.newBotLanguage
        ]
      this._noIntentAction.key = 'responseText'
      this._noIntentAction.args = [this._setNoIntentMessage()]
    }
    this._connectionErrorMessage = connectionErrorMessage
      ? connectionErrorMessage
      : this._setConnectionErrorMessage()
    this._intentConfidence = intentConfidence
    this._availability = availability
    this.intents = intents
    this._rawIntents = rawIntents.map(i => i.toLowerCase())
    this._createdAt = moment(createdAt)
    this._updatedAt = moment(updatedAt)
    this.activeVersion = activeVersion
    this.isSaved = !!this.id
    this._legacy = legacy
    this._passiveChat = passiveChat
    this._entityExtractor = entityExtractor
    this._useAsTemplate = useAsTemplate
    this._timeOffset = timeOffset
    this._checkAvailability = checkAvailability
    this.secondLevelMessage = secondLevelMessage // This is the message to be shown when the bot does not understand
    this.bypass = bypass
    this.inactivityTime = inactivityTime ? inactivityTime / (60 * 1000) : 30
    this.inactivityMessage = inactivityMessage
    this.noIntentTimesToTakeOver = noIntentTimesToTakeOver
    this.platformsNoIntentTimesToTakeOver = platformsNoIntentTimesToTakeOver
    this.noIntentMessage2 = noIntentMessage2
      ? noIntentMessage2
      : this._setNoIntentMessage2()
    this.sessionTimeoutStatus0 = sessionTimeoutStatus0
      ? sessionTimeoutStatus0 / (60 * 1000)
      : 24 * 60
    this.sessionTimeoutStatus1 = sessionTimeoutStatus1
      ? sessionTimeoutStatus1 / (60 * 1000)
      : 24 * 60
    this.sessionTimeoutStatus2 = sessionTimeoutStatus2
      ? sessionTimeoutStatus2 / (60 * 1000)
      : 365 * 24 * 60
    this.sessionTimeoutStatus3 = sessionTimeoutStatus3
      ? sessionTimeoutStatus3 / (60 * 1000)
      : 24 * 60
    this.intentsByPlatform = intentsByPlatform
    this.showEmotions = showEmotions
    if (disambiguationAction) {
      this.disambiguationAction = disambiguationAction
    } else {
      this.disambiguationAction = {
        active: false,
        maxIntents: 3,
        message: this._setDisambiguationMessage(),
        anythingElseActions: {
          active: false,
          text: this._setAnythingElseMessage(),
          actions: [
            {
              name: 'Texto',
              key: 'responseText',
              args: [this._setAnythingElseResponseMessage()]
            }
          ]
        }
      }
    }
    if (notDialogDetected) {
      this.notDialogDetected = notDialogDetected
    } else {
      this.notDialogDetected = {
        active:false,
        url:undefined
      }
    }
  }

  get createdAt() {
    return this._createdAt
  }

  get service() {
    return this._botService
  }

  get nlu() {
    return this._nlu
  }

  get noIntentMessage() {
    return this._noIntentMessage1
  }

  get noIntentAction() {
    return this._noIntentAction
  }

  get connectionErrorMessage() {
    return this._connectionErrorMessage
  }

  get intentConfidence() {
    return this._intentConfidence
  }

  get availability() {
    return this._availability
  }

  get rawIntents() {
    return this._rawIntents
  }

  get legacy() {
    return this._legacy
  }

  get passiveChat() {
    return this._passiveChat
  }

  get entityExtractor() {
    return this._entityExtractor
  }

  get useAsTemplate() {
    return this._useAsTemplate
  }

  get checkAvailability() {
    return this._checkAvailability
  }

  get timeOffset() {
    return this._timeOffset
  }

  set nlu(value) {
    this._nlu = value
  }

  set noIntentMessage(value) {
    this._noIntentMessage1 = value
  }

  set noIntentAction(value) {
    this._noIntentAction = value
  }

  set connectionErrorMessage(value) {
    this._connectionErrorMessage = value
  }

  set intentConfidence(value) {
    this._intentConfidence = value
  }

  set availability(value) {
    this._availability = value
  }

  set passiveChat(value) {
    this._passiveChat = value
  }

  set entityExtractor(value) {
    this._entityExtractor = value
  }

  set useAsTemplate(value) {
    this._useAsTemplate = value
  }

  set checkAvailability(value) {
    this._checkAvailability = value
  }

  set timeOffset(value) {
    this._timeOffset = value
  }

  // Intent confidence
  /**
   * Determine if the intent confidence is valid
   * @returns {boolean}
   */
  isIntentConfidenceValid() {
    return !Validations.isEmpty(this._intentConfidence)
  }

  isInactivityTimeValid() {
    return !Validations.isEmpty(this.inactivityTime)
  }

  isInactivityMessageValid() {
    return !Validations.isEmpty(this.inactivityMessage)
  }

  isNoIntentTimesToTakeOverValid() {
    return !Validations.isEmpty(this.noIntentTimesToTakeOver)
  }

  isNoIntentMessage2Valid() {
    return !Validations.isEmpty(this.noIntentMessage2)
  }

  isNotDialogDetectedValid() {
    if(this.notDialogDetected.active) {
      return !Validations.isEmpty(this.notDialogDetected.url) // true
    }
    else
      return true
  }

  // Default intent message
  /**
   * Determine if the no intent message is valid
   * @returns {boolean}
   */
  isNoIntentMessageValid() {
    return !Validations.isEmpty(this._noIntentMessage1)
  }

  isNoIntentActionValid() {
    return (
      !Validations.isEmpty(this._noIntentAction) &&
      !Validations.isEmpty(this._noIntentAction.key) &&
      !Validations.isEmpty(this._noIntentAction.args) &&
      !Validations.isEmpty(this._noIntentAction.args[0])
    )
  }

  // Default connection error message
  /**
   * Determine if the connection error message is valid
   * @returns {boolean}
   */
  isConnectionErrorMessageValid() {
    return !Validations.isEmpty(this._connectionErrorMessage)
  }

  // Intents
  /**
   * Determine if the bot has a specific intent
   * @param name {String}
   * @returns {boolean}
   */
  hasIntent(name, update) {
    if (update) {
      return (
        this.intents.filter(intent => {
          return intent.name.toLowerCase() === name.toLowerCase()
        }).length > 1
      )
    }

    if (this.intents.isSaved) {
      return this.intents.find(intent => {
        return intent.name.toLowerCase() === name.toLowerCase()
      })
    } else {
      return false
    }
  }

  /**
   * Has intents
   * @returns {boolean}
   */
  hasIntents() {
    return this.intents.length > 0
  }

  /**
   * Has second level intents
   * @returns {number}
   */
  hasSecondLevelIntents() {
    return this.intents.filter(i => i.derivesToAgent).length
  }

  /**
   * Determine if the second level message is valid
   * @returns {boolean}
   */
  isSecondLevelMessageValid() {
    return !Validations.isEmpty(this.secondLevelMessage)
  }

  // Validations
  /**
   * Determine if the bot is valid
   * @returns {undefined|boolean}
   */
  isValid() {
    let intentsValid =
      !this.legacy && this.hasSecondLevelIntents()
        ? this.isSecondLevelMessageValid()
        : true
    return (
      this._botService.isValid() &&
      this.nlu.isValid() &&
      this.isNoIntentActionValid() &&
      this.isConnectionErrorMessageValid() &&
      intentsValid
    )
  }

  /**
   * Creates a new bot to save
   * @returns {{noIntentMessage1: *, connectionErrorMessage: *, intents: String[], service: {name, description, users}, intentConfidence: *, availability: {}} & {nlu: {culture: *, platform: *}}}
   */
  generateNewBotToSave(userRole, usersByChannel = null, useStaffManagement = false) {
    let bot = this.generateBotToUpdate(userRole, usersByChannel, useStaffManagement, false)

    // Add no intent message and NLU config
    bot = Object.assign(bot, {
      nlu: this.nlu.getNluToSave(),
      noIntentMessage1: this.noIntentMessage,
      noIntentAction: this.noIntentAction,
      connectionErrorMessage: this.connectionErrorMessage
    })

    if (!bot.timeOffset) {
      let index = null
      index = timeZones.findIndex(
        item =>
          item.utc &&
          item.utc.indexOf(Intl.DateTimeFormat().resolvedOptions().timeZone) >
          -1
      )
      if (index !== null && index > -1) {
        bot.timeOffset = timeZones[index].text
      } else {
        const offset = (new Date().getTimezoneOffset() / 60) * -1
        const element = timeZones.find(e => e.offset === offset)
        if (element) {
          bot.timeOffset = element.text
        }
      }
    }

    return bot
  }

  /**
   * Get second level intents
   * Filter all the intents that derive to agent
   * @returns {Intent|Array}
   * @private
   */
  _getSecondLevelIntents() {
    return this.intents.filter(i => i.derivesToAgent).map(i => i.name)
  }

  _getIntentsByPlatform() {
    let intentsByPlatform = []
    const intentsDerive = this.intents.filter(i => i.derivesToAgent)
    intentsDerive.forEach(id => {
      const ibp = id.intentsByPlatform
      intentsByPlatform.push({
        intent: id.name,
        platforms: ibp.map(p => p.value)
      })
    })
    return intentsByPlatform
  }

  getParsedUsersByChannel(users, usersByChannel) {
    let parsedUbc = []
    users.forEach(user => {
      const ubc = usersByChannel[user]
      if (ubc) {
        const platforms = ubc.map(ubcp => ubcp.value)
        parsedUbc.push({
          user,
          platforms
        })
      }
    })
    return parsedUbc
  }

  /**
   * Generate bot to update
   * @returns {{noIntentMessage1: *, connectionErrorMessage: *, intents: String[], service: {name, description, users}, intentConfidence: *, availability: {}}}
   */
  generateBotToUpdate(userRole, usersByChannel = null, useStaffManagement, isEditing) {
    let botToUpdate = {}

    if (isAuthAux({ key: `AddBot.generalSettings isEditing:${isEditing}`, useStaffManagement }) || userRole.canEditBot.generalSettings) {
      botToUpdate = Object.assign(botToUpdate, {
        service: this.service.getGeneralSettings()
      })
    }

    if (isAuthAux({ key: `AddBot.users isEditing:${isEditing}`, useStaffManagement }) || userRole.canEditBot.serviceUsers) {
      const users = this.service.getUsers()
      const _users = users.map(item => item.id)
      const parsedUbc = this.getParsedUsersByChannel(_users, usersByChannel)
      if (botToUpdate.service) {
        botToUpdate.service = Object.assign(botToUpdate.service, {
          users: _users,
          usersByChannel: parsedUbc
        })
      } else {
        botToUpdate = Object.assign(botToUpdate, {
          service: {
            users: _users,
            usersByChannel: parsedUbc
          }
        })
      }
    }
    if (isAuthAux({ key: `AddBot.isEngineSettingsEnabled isEditing:${isEditing}`, useStaffManagement }) || userRole.canEditBot.engineSettings) {
      if (this.disambiguationAction && this.disambiguationAction.maxIntents) {
        this.disambiguationAction.maxIntents = parseInt(
          this.disambiguationAction.maxIntents
        )
      }
      botToUpdate = Object.assign(botToUpdate, {
        noIntentMessage1: this._noIntentMessage1,
        noIntentAction: this._noIntentAction,
        connectionErrorMessage: this._connectionErrorMessage,
        intentConfidence: this._intentConfidence,
        inactivityTime: this.inactivityTime * 60 * 1000, // minutes to milliseconds
        inactivityMessage: this.inactivityMessage,
        noIntentTimesToTakeOver: parseInt(this.noIntentTimesToTakeOver),
        platformsNoIntentTimesToTakeOver: this.platformsNoIntentTimesToTakeOver,
        noIntentMessage2: this.noIntentMessage2,
        showEmotions: this.showEmotions,
        disambiguationAction: this.disambiguationAction,
        notDialogDetected: this.notDialogDetected,
      })
    }

    if (isAuthAux({ key: `AddBot.isSessionSettingsEnabled isEditing:${isEditing}`, useStaffManagement }) || userRole.canEditBot.sessionSettings) {
      botToUpdate = Object.assign(botToUpdate, {
        sessionTimeoutStatus0: this.sessionTimeoutStatus0
          ? this.sessionTimeoutStatus0 * 60 * 1000
          : undefined,
        sessionTimeoutStatus1: this.sessionTimeoutStatus1
          ? this.sessionTimeoutStatus1 * 60 * 1000
          : undefined,
        sessionTimeoutStatus2: this.sessionTimeoutStatus2
          ? this.sessionTimeoutStatus2 * 60 * 1000
          : undefined,
        sessionTimeoutStatus3: this.sessionTimeoutStatus3
          ? this.sessionTimeoutStatus3 * 60 * 1000
          : undefined
      })
    }

    if (isAuthAux({ key: `AddBot.intentions isEditing:${isEditing}`, useStaffManagement }) || userRole.canEditBot.intentions) {
      botToUpdate = Object.assign(botToUpdate, {
        intents: this.intents.length > 0 ? this._getSecondLevelIntents() : []
      })
      botToUpdate = Object.assign(botToUpdate, {
        intentsByPlatform:
          this.intents.length > 0 ? this._getIntentsByPlatform() : []
      })
      botToUpdate = Object.assign(botToUpdate, {
        defaultTakeoverMessage: this.secondLevelMessage
      })
    }

    if (isAuthAux({ key: `AddBot.botAvailability isEditing:${isEditing}`, useStaffManagement }) || userRole.canEditBot.botAvailability) {
      botToUpdate = Object.assign(botToUpdate, {
        availability: this.availability.getAvailabilityToSave()
      })
      botToUpdate = Object.assign(botToUpdate, {
        timeOffset: this._timeOffset
      })
    }

    botToUpdate = Object.assign(botToUpdate, {
      passiveChat: this._passiveChat
    })

    botToUpdate = Object.assign(botToUpdate, {
      entityExtractor: this._entityExtractor
    })

    botToUpdate = Object.assign(botToUpdate, {
      useAsTemplate: this._useAsTemplate
    })

    botToUpdate = Object.assign(botToUpdate, {
      checkAvailability: this._checkAvailability
    })

    return botToUpdate
  }

  /**
   * Validate bot
   * @param validations
   */
  validate(validations) {
    this.service.validate(validations)
    this.nlu.validate(validations)
    this.availability.validate(validations)
    //validations.noIntentMessage = !this.isNoIntentMessageValid()
    validations.noIntentAction = !this.isNoIntentActionValid()
    validations.connectionErrorMessage = !this.isConnectionErrorMessageValid()
    validations.intentConfidence = !this.isIntentConfidenceValid()
    validations.inactivityTime = !this.isInactivityMessageValid()
    validations.inactivityMessage = !this.isInactivityTimeValid()
    validations.noIntentTimesToTakeOver = !this.isNoIntentTimesToTakeOverValid()
    validations.noIntentMessage2 = !this.isNoIntentMessage2Valid()
    validations.notDialogDetected = !this.isNotDialogDetectedValid()
    if (!this.legacy) {
      let secondLevelIntents = this.intents.filter(i => i.derivesToAgent)
      validations.secondLevelMessage =
        secondLevelIntents.length > 0
          ? Validations.isEmpty(this.secondLevelMessage)
          : false
    }
  }

  /**
   * Generate bot response
   * @returns {TextMessageResponse}
   */
  generateBotResponse() {
    return new TextMessageResponse(this.secondLevelMessage)
  }

  /**
   * Generate dialog to save
   * @param intentionName {String} the name of the intention
   * @returns {Dialog}
   */
  generateDialogToSave(intentionName) {
    // Generate a bot response
    const botResponse = this.generateBotResponse()
    const responseToSave = botResponse.getResponseToSave()

    // Generate dialog to save
    return new Dialog(
      this.activeVersion,
      this.secondLevelMessage,
      [responseToSave],
      intentionName
    )
  }

  /**
   * Retrieve default message to be shown when there is not one already created for the bot
   * @returns {String}
   * @private
   */
  _setNoIntentMessage() {
    return botMaker.addBot.generalSettings.form.noIntentMessage[
      store.state.newBotLanguage
    ]
  }

  _setConnectionErrorMessage() {
    return botMaker.addBot.generalSettings.form.connectionErrorMessage[
      store.state.newBotLanguage
    ]
  }

  _setNoIntentMessage2() {
    return botMaker.addBot.generalSettings.form.noIntentMessage2[
      store.state.newBotLanguage
    ]
  }

  _setDisambiguationMessage() {
    return botMaker.addBot.generalSettings.form.disambiguationMessage[
      store.state.newBotLanguage
    ]
  }

  _setAnythingElseMessage() {
    return botMaker.addBot.generalSettings.form.anythingElseMessage[
      store.state.newBotLanguage
    ]
  }

  _setAnythingElseResponseMessage() {
    return botMaker.addBot.generalSettings.form.anythingElseResponseMessage[
      store.state.newBotLanguage
    ]
  }
  /**
   * Update bot intents
   * @returns {Promise<void>}
   */
  async updateIntentsBotResponse() {
    // let botResponse = {
    //   responses: {
    //     platforms: ['all'],
    //     responseText: this.secondLevelMessage
    //   }
    // }
    // let secondLevelIntents = this.intents.filter(
    //   intent => intent.derivesToAgent
    // )
    // for (let i = 0; i < secondLevelIntents.length; i++) {
    //   let intent = secondLevelIntents[i]
    //   if (intent.dialogId) {
    //     await BotMakerService.updateDialog(intent.dialogId, botResponse)
    //   } else {
    //     const dialog = this.generateDialogToSave(intent.name)
    //     await BotMakerService.createDialog(dialog)
    //   }
    // }
  }

  /**
   * Update bot
   * @returns {Promise<Bot>}
   */
  updateBot = (roles, usersByChannel, useStaffManagement = false) => {
    let botToUpdate = this.generateBotToUpdate(roles, usersByChannel, useStaffManagement, true)
    return BotMakerService.updateBot(botToUpdate, this.id)
  }
}
