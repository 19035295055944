export const EXCEL_TEMPLATES = {
  audiences: {
    name: {
      es: 'nombre',
      en: 'name',
      pt: 'nome'
    },
    number: {
      es: 'numero',
      en: 'number',
      pt: 'número'
    }
  }
}

export const PLATFORMS = [
  {
    name: 'WhatsApp',
    value: 'whatsapp',
    enabled: true
  },
  {
    name: 'Facebook',
    value: 'facebook',
    enabled: false
  },
  {
    name: 'Twitter',
    value: 'twitter',
    enabled: false
  },
  {
    name: 'Web',
    value: 'web',
    enabled: false
  },
  {
    name: 'RCS',
    value: 'rcs',
    enabled: false
  },
  {
    name: 'Signal',
    value: 'signal',
    enabled: false
  },
  {
    name: 'Slack',
    value: 'slack',
    enabled: false
  },
  {
    name: 'Skype',
    value: 'skype',
    enabled: false
  },
  {
    name: 'Google Assistant',
    value: 'google_assistant',
    enabled: false
  },
  {
    name: 'Alexa',
    value: 'alexa',
    enabled: false
  },
  {
    name: 'Voice',
    value: 'voice',
    enabled: false
  },
  {
    name: 'Workplace',
    value: 'workplace',
    enabled: false
  },
  {
    name: 'Hangouts',
    value: 'hangouts',
    enabled: false
  },
  {
    name: 'Telegram',
    value: 'telegram',
    enabled: false
  }
]

export const USERS_PLATFORMS = [
  {
    name: 'WhatsApp',
    value: 'whatsapp'
  },
  {
    name: 'Facebook',
    value: 'facebook'
  },
  {
    name: 'Twitter',
    value: 'twitter'
  },
  {
    name: 'Web',
    value: 'web'
  },
  {
    name: 'RCS',
    value: 'rcs'
  },
  {
    name: 'Signal',
    value: 'signal'
  },
  {
    name: 'Slack',
    value: 'slack'
  },
  {
    name: 'Google Assistant',
    value: 'google_assistant'
  },
  {
    name: 'Alexa',
    value: 'alexa'
  },
  {
    name: 'Voice',
    value: 'voice'
  },
  {
    name: 'Telegram',
    value: 'telegram'
  }
]

export const ALL_CHATS_PLATFORMS = [
  {
    name: 'Alexa',
    code: 'alexa'
  },
  {
    name: 'Voice',
    code: 'voice'
  },
  {
    name: 'Facebook',
    code: 'facebook'
  },
  {
    name: 'RCS',
    code: 'rcs'
  },
  {
    name: 'Signal',
    code: 'signal'
  },
  {
    name: 'Telegram',
    code: 'telegram'
  },
  {
    name: 'Twitter',
    code: 'twitter'
  },
  {
    name: 'Web',
    code: 'web'
  },
  {
    name: 'WhatsApp',
    code: 'whatsapp'
  }
]

export const PLATFORM_NAMES = {
  web: 'Web',
  whatsapp: 'WhatsApp',
  facebook: 'Facebook',
  rcs: 'RCS',
  signal: 'Signal',
  twitter: 'Twitter',
  slack: 'Slack',
  skype: 'Skype',
  google_assistant: 'Google Assistant',
  alexa: 'Alexa',
  voice: 'Voice',
  workplace: 'Workplace',
  hangouts: 'Hangouts',
  telegram: 'Telegram'
}

export const HSM = {
  elementName: 'chatclub_southcone_welcome_v1', //'chatclub_open_session_v2',
  languageCode: 'es'
}

export const FILTER_TYPES = {
  intent: 'intent',
  match: 'match',
  entity: 'entity',
  variable: 'type'
}

export const TYPES_SYMBOLS = {
  intent: '#',
  entity: '@',
  regex: '',
  match: '',
  exists: 'exists',
  notExists: 'notExists',
  equal: '=',
  notEqual: '≠',
  lessThan: '<',
  lessThanOrEqual: '<=',
  greaterThan: '>',
  greaterThanOrEqual: '>=',
  contains: 'contains',
  containsNumber: 'containsNumber',
  containsIndex: 'containsIndex'
}

export const CV_FXS = [
  'exists',
  'notExists',
  'equal',
  'notEqual',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'contains',
  'containsNumber',
  'containsIndex'
]

export const CV_TYPES_OPS = {
  exists: 1,
  noExists: 1,
  equal: 2,
  notEqual: 2,
  lessThan: 2,
  lessThanOrEqual: 2,
  greaterThan: 2,
  greaterThanOrEqual: 2,
  contains: 2,
  containsNumber: 2,
  containsIndex: 2
}

export const CV_UNARY_FXS = ['exists', 'notExists']

export const CV_TYPES_SYMBOLS = {
  ctx: '$',
  var: '',
  input: ''
}

export const SPECIAL_SYMBOLS = [
  '#',
  '@',
  'RegEx:',
  'exists',
  'notExists',
  '=',
  '≠',
  '<',
  '<=',
  '>',
  '>=',
  'contains',
  'containsNumber',
  'containsIndex',
  '$'
]

export const CV_OPTIONS = [
  {
    type: 'type',
    text: 'exists',
    value: '',
    value2: '',
    typeOp: 1 // 1=unary, 2=binary
  },
  {
    type: 'type',
    text: 'notExists',
    value: '',
    value2: '',
    typeOp: 1
  },
  {
    type: 'type',
    text: 'equal',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'notEqual',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'lessThan',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'lessThanOrEqual',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'greaterThan',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'greaterThanOrEqual',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'contains',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'containsNumber',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'containsIndex',
    value: '',
    value2: '',
    typeOp: 2
  }
]

export const OPS_OPTIONS = {
  intent: ['and', 'or'],
  match: ['and', 'or'],
  entity: ['and', 'or'],
  type: ['and', 'or']
}

export const CV_COMP_TYPES = ['ctx&ctx', 'ctx&var', 'ctx&input']

export const MEDIA_TYPES = [
  'image/*',
  'video/*',
  'application/pdf',
  '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
]

export const IMAGES_TYPE = ['image/*']

export const FILE_FORMATS_MAP = {
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  gif: 'image',
  bmp: 'image',
  mov: 'video',
  mp4: 'video',
  H264: 'video',
  H265: 'video',
  mpeg: 'video',
  ogg: 'video',
  pdf: 'application',
  json: 'application',
  doc: 'application',
  docx: 'application',
  xls: 'application',
  xlsx: 'application',
  txt: 'application',
  csv: 'application'
}

export const MAX_FILE_SIZE = 16 * 1024 * 1024 // 16 Mb

export const COLORS = {
  platform: {
    whatsapp: '#00bb2d', //'#b8c2cc'
    whatsapp2: '#1EBEA5',
    facebook: '#3b5998',
    facebook2: '#ffffff'
  }
}

export const MAILS = {
  support: 'support-konecta@kona.tech'
}

export const PLATFORMS_CONFIGURATION = ['web', 'facebook']

export const CHAT_STATUS = {
  INITIAL: 0,
  PENDING: 1,
  TAKEN: 2,
  RELEASED: 3
}

export const PLATFORM_BY_TAB = {
  buttons: ['facebook']
}

export const MESSAGE_TYPES_CHANNELS = {
  text: [
    'all',
    'facebook',
    'whatsapp',
    'twitter',
    'rcs',
    'signal',
    'slack',
    'skype',
    'google_assistant',
    'alexa',
    'voice',
    'workplace',
    'hangouts',
    'telegram'
  ],
  media: [
    'all',
    'facebook',
    'whatsapp',
    'twitter',
    'rcs',
    'slack',
    'skype',
    'workplace',
    'hangouts',
    'telegram'
  ],
  slider: ['all', 'facebook', 'twitter', 'rcs'],
  card: ['all', 'facebook', 'twitter', 'rcs'],
  buttons: ['all', 'facebook', 'twitter']
}

export const METRICS_REPORTS = [
  'histogram_by_platform_v1',
  'funnel_by_platform_v1',
  'pie_v1',
  'columns_v1',
  'line_chart_v1',
  'validation_pie_v1',
  'table_v1',
  'takeovers_table_v1',
  'table_duration_v1',
  'agents_attentions_table_v1',
  'tags_table_v1',
  'cards_v1',
  'cards_v2',
  'session_percentage_cards_v1',
  'card_line_v1',
  'takeovers_histogram_v1'
]

export const FILTERS = {
  METRICS: {
    REPORTS: [
      'Tags',
      // 'Tickets/Chats resueltos por usuario',
      'Messages per channel',
      'Messages that trigger human takeovers',
      'Tickets solved',
      'Human takeover funneling',
      'Average wait time (min)',
      'Chats with less than 15 minutes attention',
      'Chats with more than 15 minutes wait',
      'Chats with more than 15 minutes attention',
      'Chats with less than 15 minutes wait',
      'Chats attended by agent',
      'Min attention time (min)',
      'Max attention time (min)',
      'Topics of the conversations that had to be intervened',
      'Average attention time (min)',
      'Number of users per channel',
      'Outgoing messages creating sessions',
      'Time of response per chat',
      'Sessions',
      'Flow of intervened sessions',
      'Flow of proactive agent-driven sessions',
      'Flow of sessions that required agent attention',
      'Hourly AVG intervened sessions',
      'All chats'
    ]
  },
  KNOWLEDGE: {
    REPORTS: [
      'Most requested intentions',
      'Intents table',
      'Not detected questions',
      'Response validations',
      'Positive validations by topic',
      'Negative validations by topic',
      'Most consulted topics',
      'Non answered validations by topic'
    ]
  },
  DASHBOARD: {
    REPORTS: [
      'Total messages',
      'Total chats',
      'Total sessions',
      'Active users',
      'New users',
      'AVG new users (last 30 days)',
      'AVG active users (last week)',
      'AVG active users (last 30 days)',
      'Intervened sessions',
      'Intervened sessions by month'
    ]
  }
}

export const CHAT_EXPORT_HEADERS = {
  sender: {
    es: 'Usuario',
    en: 'User',
    pt: 'Usuario'
  },
  fullname: {
    es: 'Nombre completo',
    en: 'Full Name',
    pt: 'Nome completo'
  },
  picture: {
    es: 'Imagen',
    en: 'Picture',
    pt: 'Imagen'
  },
  botName: {
    es: 'Bot',
    en: 'Bot',
    pt: 'Bot'
  },
  platform: {
    es: 'Plataforma',
    en: 'Platform',
    pt: 'Plataforma'
  },
  status: {
    es: 'Estado',
    en: 'Status',
    pt: 'Status'
  },
  _id: {
    es: 'Id',
    en: 'Id',
    pt: 'Id'
  },
  bot: {
    es: 'Id Bot',
    en: 'Bot Id',
    pt: 'Id Bot'
  },
  service: {
    es: 'Id Servicio',
    en: 'Service Id',
    pt: 'Id Serviço'
  },
  tsCreated: {
    es: 'Fecha de Creación',
    en: 'Creation Date',
    pt: 'Data de Criação'
  },
  ts: {
    es: 'Fecha',
    en: 'Date',
    pt: 'Data'
  },
  version: {
    es: 'Id Versión',
    en: 'Version Id',
    pt: 'Id Versão'
  },
  staging: {
    es: 'Staging',
    en: 'Staging',
    pt: 'Staging'
  }
}

export const AGENTS_TIME_FIELDS = ['Atention time', 'First response time']

export const APEX_CHARTS = ['pie', 'bar']

export const NPS_VALUES = {
  promoters: ['9', '10'],
  detractors: ['1', '2', '3', '4', '5', '6']
}

// apex charts pallete1 to pallete6
// https://apexcharts.com/docs/options/theme/#palette
export const PALETTE = [
  '#008FFB',
  '#00E396',
  '#FEB019',
  '#FF4560',
  '#775DD0',
  '#3F51B5',
  '#03A9F4',
  '#4CAF50',
  '#F9CE1D',
  '#FF9800',
  '#33B2DF',
  '#546E7A',
  '#D4526E',
  '#13D8AA',
  '#A5978B',
  '#4ECDC4',
  '#C7F464',
  '#81D4FA',
  '#546E7A',
  '#FD6A6A',
  '#2B908F',
  '#F9A3A4',
  '#90EE7E',
  '#FA4443',
  '#69D2E7',
  '#449DD1',
  '#F86624',
  '#EA3546',
  '#662E9B',
  '#C5D86D'
]

export const BUSINESS_HOURS = {
  start: '09:00',
  end: '18:00'
}

export const TEST_CASE_ASSERTION_TYPES = [ 'include', 'equal']

export const CHAT_USER_STATUS ={
  online: 'online',
  idle: 'idle',
  offline: 'offline'
}

export default {
  EXCEL_TEMPLATES,
  HSM,
  PLATFORMS,
  USERS_PLATFORMS,
  ALL_CHATS_PLATFORMS,
  PLATFORM_NAMES,
  MEDIA_TYPES,
  // conditionals
  TYPES_SYMBOLS,
  CV_TYPES_SYMBOLS,
  CV_UNARY_FXS,
  SPECIAL_SYMBOLS,
  FILTER_TYPES,
  CV_FXS,
  CV_TYPES_OPS,
  CV_OPTIONS,
  OPS_OPTIONS,
  CV_COMP_TYPES,
  // MEDIA FILES
  FILE_FORMATS_MAP,
  MAX_FILE_SIZE,
  COLORS,
  MAILS,
  CHAT_STATUS,
  MESSAGE_TYPES_CHANNELS,
  PLATFORM_BY_TAB,
  METRICS_REPORTS,
  FILTERS,
  CHAT_EXPORT_HEADERS,
  AGENTS_TIME_FIELDS,
  // survey metrics
  APEX_CHARTS,
  NPS_VALUES,
  PALETTE,
  BUSINESS_HOURS,
  CHAT_USER_STATUS,
}
