import { MessageType } from '../MessageType'

export class TextMessageType extends MessageType {
  constructor(name, platforms = ['all'], id = null, responseId, beforeAPICall) {
    super(name, responseId)
    this.platforms = platforms
    this._id = id
    this.beforeAPICall = beforeAPICall
  }

  getType() {
    return 'text'
  }

  isTextMessage() {
    return true
  }
  isRegexMessage() {
    return false
  }
  isImageMessage() {
    return false
  }

  isMediaMessage() {
    return false
  }

  isIntentMessage() {
    return false
  }

  isCardMessage() {
    return false
  }

  isSliderMessage() {
    return false
  }

  isButtonsMessage() {
    return false
  }

  isGenericMessage() {
    return false
  }

  isValid() {
    super.isNameValid()
  }

  generateMessageToSave() {
    return {
      platforms: this.platforms,
      responseText: this.name,
      responseId: this.responseId,
      beforeAPICall: this.beforeAPICall
    }
  }

  save() {
    return Promise.resolve()
  }

  update() {/* update */}
}
