export default {
  apply: {
    es: 'Aplicar',
    en: 'Apply',
    pt: 'Aplicar',
  },
  reset: {
    es: 'Restaurar',
    en: 'Reset',
    pt: 'Restaurar',
  },
  save: {
    es: 'Guardar',
    en: 'Save',
    pt: 'Salvar',
  },
  cancel: {
    es: 'Cancelar',
    en: 'Cancel',
    pt: 'Cancelar',
  },
  continue: {
    es: 'Continuar',
    en: 'Continue',
    pt: 'Prosseguir',
  },
  copyToClipboard: {
    label: {
      es: 'Copiar',
      en: 'Copy',
      pt: 'Copiar',
    },
    success: {
      es: 'Copiado al portapapeles!',
      en: 'Copied to clipboard!',
      pt: 'Copiado para a prancheta!',
    },
    error: {
      es: 'Ha ocurrido un error al copiar al portapapeles. Por favor, intente nuevamente.',
      en: 'An orror ocurred copying to clipboard. Please, try again.',
      pt: 'Ocorreu um erro ao copiar para a prancheta. Por favor, tente novamente.',
    },
  },
  cultureOptions: {
    title: {
      es: 'Idioma',
      en: 'Language',
      pt: 'Idioma',
    },
    spanish: {
      es: 'Español',
      en: 'Spanish',
      pt: 'Espanhol',
    },
    english: {
      es: 'Inglés',
      en: 'English',
      pt: 'Inglês',
    },
    portuguese: {
      es: 'Espanhol',
      en: 'Inglês',
      pt: 'Português'
    }
  },
  noDataText: {
    es: 'No se encontraron resultados',
    en: 'No results found',
    pt: 'Não foram encontrados resultados',
  },
  export: {
    es: 'Exportar',
    en: 'Export',
    pt: 'Exportar',
  },
  filters: {
    es: 'Filtros',
    en: 'Filters',
    pt: 'Filtros',
  },
  notApplied: {
    es: 'Algunos cambios aún no han sido aplicados.',
    en: 'Some changes have not yet been applied.',
    pt: 'Algumas mudanças ainda não foram aplicadas.',
  },
  time: {
    years: {
      es: 'Años',
      en: 'Years',
      pt: 'Anos',
    },
    weeks: {
      es: 'Semanas',
      en: 'Weeks',
      pt: 'Semanas',
    },
    days: {
      es: 'Días',
      en: 'Days',
      pt: 'Dias',
    },
    hours: {
      es: 'Horas',
      en: 'Hours',
      pt: 'Horas',
    },
    minutes: {
      es: 'Minutos',
      en: 'Minutes',
      pt: 'Minutos',
    },
  },
}