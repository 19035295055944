const moment = require('moment')

function getBlob(data) {
  // fix special chars: https://github.com/eligrey/FileSaver.js/issues/28
  var BOM = '\uFEFF'
  var csvData = BOM + data

  return new Blob([csvData], {
    type: 'text/plain; encoding=utf-8'
  })
}

export const download = (data, fileName) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  const url = window.URL.createObjectURL(getBlob(data))
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
}

const sortDates = (prop, order) => (a, b) => {
  if (moment(a[prop]).isAfter(b[prop])){
    return order === 'desc' ? -1 : 1
  }
  if (moment(b[prop]).isAfter(a[prop])){
    return order === 'desc' ? 1 : -1
  }
  return 0
}

export const sortByDate = (items, prop, order = 'desc') => {
  return items.sort(sortDates(prop, order))
}
