export default {
  all: {
    es: 'Todos los Chats',
    en: 'All Chats',
    pt: 'Todas os Chats',
  },
  messages: {
    updateChatName: {
      success: {
        text: {
          es: 'Nombre de usuario actualizado exitosamente!',
          en: 'Username successfully updated!',
          pt: 'Nome de usuário atualizado com sucesso!',
        },
      },
      error: {
        text: {
          es: 'No se ha actualizado el nombre de usuario. Por favor, intente nuevamente.',
          en: 'The username has not been updated. Please, try again.',
          pt: 'O nome de usuário não foi atualizado. Por favor, tente novamente.',
        },
      },
    },
  },
  chatUserStatus:{
    online: {
      es: 'En línea',
      en: 'Online',
      pt: 'Online'
    },
    idle: {
      es: 'Inactivo',
      en: 'Idle',
      pt: 'Ocioso'
    },
    offline: {
      es: 'Desconectado',
      en: 'Offline',
      pt: 'Offline'
    }
  }
}