import { RegexMessageType } from './RegexMessageType'
import Validations from '../../../store/Validations'

export class MatchRegexMessageType extends RegexMessageType {
  constructor(name, examples = []) {
    super(name)
    this.exampleInput = ''
    this.examples = examples
  }

  getRegexToSave() {
    return '^(' + this.examples.join('|') + ')$'
  }

  areExamplesValid() {
    return !Validations.isEmpty(this.examples)
  }

  isValid() {
    return this.isNameValid() && this.areExamplesValid()
  }

  hasExampleInExampleInput() {
    return this.examples.includes(this.exampleInput)
  }

  addExample() {
    this.examples.push(this.exampleInput)
    this.resetExampleInput()
  }

  removeExample(exampleIndex) {
    this.examples.splice(exampleIndex, 1)
  }

  resetExampleInput() {
    this.exampleInput = ''
  }

  isMatchRegex() {
    return true
  }

  isRegExp() {
    return false
  }
}
