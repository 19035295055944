export default {
  audit: {
    title: {
      es: 'Auditoría',
      en: 'Audit',
      pt: 'Auditoria'
    }
  },
  training: {
    title: {
      es: 'Entrenamiento',
      en: 'Training',
      pt: 'Treinamento'
    },
    submenu: {
      clustering: {
        title: {
          es: 'Clusterización',
          en: 'Clustering',
          pt: 'Agrupamento'
        }
      },
      aiTrainer: {
        title: {
          es: 'Entrenador IA',
          en: 'AI Trainer',
          pt: 'Treinador de IA'
        }
      }
    },
    createBot: {
      es: 'Crear Bot',
      en: 'Create Bot',
      pt: 'Criar Bot'
    },
    dialogView: {
      es: 'Vista de Diálogo',
      en: 'Dialogue View',
      pt: 'Visão de Diálogo'
    },
    flowsDialog: {
      es: 'Vista de Flujos',
      en: 'Flows View',
      pt: 'Visão de fluxos'
    },
    languages: {
      es: 'Lenguajes',
      en: 'Languages',
      pt: 'Idiomas'
    },
    channels: {
      es: 'Canales',
      en: 'Canales',
      pt: 'Canales'
    },
    versions: {
      es: 'Versiones',
      en: 'Versions',
      pt: 'Versões'
    },
    knowledgeBases: {
      es: 'Bases de Conocimiento',
      en: 'Knowledge Bases',
      pt: 'Bases de Conhecimento'
    },
    intentions: {
      es: 'Intenciones',
      en: 'Intentions',
      pt: 'Intenções'
    },
    entities: {
      es: 'Entitidades',
      en: 'Entities',
      pt: 'Entidades'
    },
    activeLearning: {
      es: 'Aprendizaje Activo',
      en: 'Active Learning',
      pt: 'Aprendizagem Ativa'
    },
    genericConversations: {
      es: 'Conversaciones Genéricas',
      en: 'Generic Conversations',
      pt: 'Conversações Genéricas'
    },
    kbase: {
      es: 'KBASE',
      en: 'KBASE',
      pt: 'KBASE'
    },
    iaEngine: {
      es: 'Motores de IA',
      en: 'AI Engines',
      pt: 'Motores de IA'
    },
    integrations: {
      es: 'Integraciones',
      en: 'Integrations',
      pt: 'Integrações'
    }
  },
  surveys: {
    title: {
      es: 'Encuestas',
      en: 'Surveys',
      pt: 'Pesquisas'
    },
    results: {
      es: 'Resultados',
      en: 'Results',
      pt: 'Resultados'
    },
    metrics: {
      es: 'Métricas',
      en: 'Metrics',
      pt: 'Métricas'
    }
  },
  operations: {
    title: {
      es: 'Operaciones',
      en: 'Operations',
      pt: 'Operações'
    },
    agentsAvailability: {
      es: 'Atención de Agentes',
      en: 'Agents Attention',
      pt: 'Atendimento de Agentes'
    },
    attentionSchedule: {
      es: 'Horarios de atención',
      en: 'Attention schedule',
      pt: 'Horario de atendimento'
    }
  },
  analysis: {
    title: {
      es: 'Análisis',
      en: 'Analysis',
      pt: 'Análise'
    },
    controlPanel: {
      es: 'Panel de control',
      en: 'Control panel',
      pt: 'Painel de controle'
    },
    metrics: {
      es: 'Métricas',
      en: 'Metrics',
      pt: 'Métricas'
    },
    dashboard: {
      es: 'Dashboard',
      en: 'Dashboard',
      pt: 'Painel'
    },
    metricSessions: {
      es: 'Sesiones',
      en: 'Sessions',
      pt: 'Sessões'
    },
    metricsKnowledge: {
      es: 'Conocimiento',
      en: 'Knowledge',
      pt: 'Conhecimento'
    },
    agents: {
      es: 'Agentes',
      en: 'Agents',
      pt: 'Agentes'
    },
    reports: {
      es: 'Reportes',
      en: 'Reports',
      pt: 'Relatórios'
    },
    history: {
      es: 'Historial',
      en: 'History',
      pt: 'História'
    },
    sessionsFlow: {
      es: 'Flujo de sesiones',
      en: 'Sessions Flow',
      pt: 'Fluxo de sessões'
    }
  },
  store: {
    title: {
      es: 'Tienda',
      en: 'Store',
      pt: 'Loja'
    },
    shoppingHistory: {
      es: 'Histórico de compras',
      en: 'Shopping History',
      pt: 'Histórico de compras'
    }
  },
  security: {
    title: {
      es: 'Seguridad',
      en: 'Security',
      pt: 'Segurança'
    },
    ipRanges: {
      es: 'Rangos de IP',
      en: 'IP ranges',
      pt: 'IP ranges'
    }
  },
  settings: {
    title: {
      es: 'Configuración',
      en: 'Settings',
      pt: 'Configuração'
    },
    users: {
      es: 'Usuarios',
      en: 'Users',
      pt: 'Usuários'
    },
    complements: {
      es: 'Complementos',
      en: 'Complements',
      pt: 'Complementos'
    },
    billing: {
      es: 'Facturación',
      en: 'Billing',
      pt: 'Faturamento'
    },
    support: {
      es: 'Soporte',
      en: 'Support',
      pt: 'Suporte'
    },
    themes: {
      es: 'Temas',
      en: 'Themes',
      pt: 'Temas'
    },
    customization: {
      es: 'Customizaciones',
      en: 'Customization',
      pt: 'Personalização'
    },
    nlu: {
      es: 'NLU',
      en: 'NLU',
      pt: 'NLU'
    }
  },
  diffusion: {
    title: {
      es: 'Difusiones',
      en: 'Broadcasts',
      pt: 'Transmissões'
    },
    submenu: {
      audiences: {
        title: {
          es: 'Difusión',
          en: 'Diffusion',
          pt: 'Difusão'
        }
      },
      sendMessage: {
        title: {
          es: 'Mensaje rápido',
          en: 'Quick message',
          pt: 'Mensagem rápida'
        }
      },
      metrics: {
        title: {
          es: 'Métricas',
          en: 'Metrics',
          pt: 'Métricas'
        }
      },
      hsmCodes: {
        title: {
          es: 'Códigos HSM',
          en: 'HSM Codes',
          pt: 'Códigos HSM'
        }
      }
    }
  },
  bots: {
    title: {
      es: 'Bots',
      en: 'Bots',
      pt: 'Bots'
    }
  },
  contact: {
    title: {
      es: 'Contacto',
      en: 'Contacts',
      pt: 'Contatos'
    },
    attentionRequests: {
      es: 'Pedidos de atención',
      en: 'Pending chats',
      pt: 'Pedidos de atendimento'
    },
    allChats: {
      es: 'Todos los chats',
      en: 'All chats',
      pt: 'Todos os chats'
    },
    attentionRequestsSettings: {
      es: 'Configuración',
      en: 'Settings',
      pt: 'Configuração'
    }
  },
  company: {
    title: {
      es: 'Organizaciones',
      en: 'Tenants',
      pt: 'Organizações'
    }
  },
  help: {
    title: {
      es: 'Ayuda',
      en: 'Help',
      pt: 'Ajuda'
    }
  },
  tour: {
    title: {
      es: 'Ver tour',
      en: 'Take tour',
      pt: 'Veja o tour'
    }
  },
  logout: {
    title: {
      es: 'Cerrar sesión',
      en: 'Logout',
      pt: 'Sair'
    },
    confirm: {
      title: {
        es: 'Atención',
        en: 'Warning',
        pt: 'Aviso'
      },
      text: {
        es: '¿Está seguro de cerrar sesión?',
        en: 'Are you sure to logout?',
        pt: 'Tem certeza de que deseja sair?'
      },
      accept: {
        es: 'Aceptar',
        en: 'Accept',
        pt: 'Aceitar'
      },
      cancel: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      }
    }
  }
}
