const getters = {
  // Bots views
  getBots: state => {
    return state.bots
  },
  filters: state => {
    return state.filters
  },

  // Bot create or edit view
  bot: state => {
    return state.bot
  },

  // Bot validations
  validations: state => {
    return state.validations
  },

  // Create or edit user
  userModel: state => {
    return state.user
  },
  isAddUserPromptOpened: state => {
    return state.isAddUserPromptOpened
  },
  userToEdit: state => {
    return state.userToEdit
  },

  // Select users
  isSelectUsersPromptOpened: state => {
    return state.isSelectUsersPromptOpened
  },
  users: state => {
    return state.users
  },
  usersSelected: state => {
    return state.usersSelected
  },

  // Select version intentions
  isAddIntentOpen: state => {
    return state.isAddIntentOpen
  },
  existingIntentions: state => {
    if (!state.bot.intents && state.bot.intents.length > 0) {
      return []
    }

    let botIntentions = state.bot.intents.map(i => i.name)

    return state.existingIntentions.filter(eIntention => {
      return !botIntentions.includes(eIntention.name)
    })
  },
  defaultIntentions: state => {
    return state.defaultIntentions
  },
  editIntentionInternalId: state => {
    return state.editIntentionInternalId
  },
  sectionReady: state => {
    return state.sectionReady
  },
  usersByChannelMap: state => {
    return state.usersByChannelMap
  },
  botTimeoutMessageValidation: state => {
    return state.botTimeoutMessageValidation
  },
  botMessageInEdit: state => {
    return state.botMessageInEdit
  },
  hasTimeoutValidation: state => {
    return state.hasTimeoutValidation
  },
  hasNegativeSentimentOption: state => {
    return state.hasNegativeSentimentOption
  },
  negativeSentimentOption: state => {
    return state.negativeSentimentOption
  },
  isWidgetSettingsOpen: state => {
    return state.isWidgetSettingsOpen
  },
}

export default getters
