import Request from './request'
import state from '../store/state'

const PATH = {
  INTEGRATION: '/integration'
}

/**
 * Get token
 *
 * @returns {String} with the token retrieved from the local storage
 */
const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

function _getQuery(params) {
  const services = params.services
  const types = params.types

  let query = ''
  if (services && services.length) {
    if (services.length === 1) {
      query = `?where[service]=${services[0]}`
    } else {
      types.forEach(element => {
        if (query.length === 0) {
          query += `?where[type][$in]=${element}`
        } else {
          query += `&where[service][$in]=${element}`
        }
      })
    }
  }

  if (types && types.length) {
    if (types.length === 1) {
      query +=
        query.length > 0
          ? `&where[type]=${types[0]}`
          : `?where[type]=${types[0]}`
    } else {
      types.forEach(element => {
        if (query.length === 0) {
          query += `?where[type][$in]=${element}`
        } else {
          query += `&where[type][$in]=${element}`
        }
      })
    }
  }

  return query
}

const _getIntegrations = params => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const query = _getQuery(params)

  return Request.get(
    `${state.config.konecta}${PATH.INTEGRATION}${query}`,
    config
  )
}

const _getIntegration = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(state.config.konecta + PATH.INTEGRATION, config)
}

const _createIntegration = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${state.config.konecta}${PATH.INTEGRATION}`,
    payload,
    config
  )
}

const _updateIntegration = (payload, id) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    `${state.config.konecta}${PATH.INTEGRATION}/${id}`,
    payload,
    config
  )
}

export default {
  getIntegrations: _getIntegrations,
  getIntegration: _getIntegration,
  createIntegration: _createIntegration,
  updateIntegration: _updateIntegration
}
