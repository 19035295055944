export default {
  title: {
    es: '¡Bienvenido! 🎉',
    en: 'Welcome! 🎉',
    pt: 'Bem-vindo! 🎉',
  },
  subscriptionNumber: {
    es: 'Tu número de suscripción es ',
    en: 'Your suscription number is ',
    pt: 'Seu número de suscrição é:',
  },
  wait: {
    es: 'Por favor aguarda...',
    en: 'Please wait...',
    pt: 'Por favor, espere...',
  },
  description: {
    es: 'Acabamos de enviar un correo electrónico a <b><EMAIL></b> con los pasos a seguir, esto puede demorar unos minutos. Si tienes algún problema puedes comunicarte a <b>support@konecta.global</b>.',
    en: 'We have just sent an email to <b><EMAIL></b> with next steps, this may take a few minutes. If you have any problems you can contact <b>support@konecta.global</b>.',
    pt: 'Acabamos de enviar um e-mail para <b><EMAIL></b> com os próximos passos, isso pode levar alguns minutos. Se você tiver qualquer problema, pode entrar em contato com <b>support@konecta.global</b>.',
  },
  error: {
    generic: {
      es: 'Parece que ocurrió un error.',
      en: 'It seems that an error occurred.',
      pt: 'Parece que um erro ocorreu.',
    },
  },
  backToLogin: {
    es: 'Volver al Login',
    en: 'Back To Login',
    pt: 'Voltar ao Login',
  },
}