export default {
  surveyIntegrations: {
    es: 'Enviar encuesta',
    en: 'Send survey',
    pt: 'Enviar pesquisa'
  },
  production: {
    es: 'en vivo',
    en: 'live',
    pt: 'ao vivo'
  },
  development: {
    es: 'desarrollo',
    en: 'development',
    pt: 'desenvolvimento'
  },
  test: {
    es: 'Test',
    en: 'Test',
    pt: 'Teste'
  },
  title: {
    es: 'Encuestas',
    en: 'Surveys',
    pt: 'Pesquisas'
  },
  surveys: {
    title: {
      es: 'Encuestas',
      en: 'Surveys',
      pt: 'Pesquisas'
    },
    subtitle: {
      es: 'Crea tus encuestas.',
      en: 'Create your surveys.',
      pt: 'Crie suas pesquisas.'
    },
    table: {
      name: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome'
      },
      _createdAt: {
        es: 'Creación',
        en: 'Created on',
        pt: 'Criado em'
      }
    },
    addSurvey: {
      es: 'Agregar encuesta',
      en: 'Add survey',
      pt: 'Adicionar pesquisa'
    },
    addSurveyPopup: {
      title: {
        es: 'Agregar encuesta',
        en: 'Add survey',
        pt: 'Adicionar pesquisa'
      },
      formWizzard: {
        title: {
          es: 'Nueva encuesta',
          en: 'New survey',
          pt: 'Nova pesquisa'
        },
        subtitle: {
          es: null,
          en: null,
          pt: null
        },
        nextButtonText: {
          es: 'Siguiente',
          en: 'Next',
          pt: 'Próxima'
        },
        backButtonText: {
          es: 'Anterior',
          en: 'Back',
          pt: 'Anterior'
        },
        finishButtonText: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        },
        name: {
          label: {
            es: 'Nombre *',
            en: 'Name *',
            pt: 'Nome *'
          },
          placeholder: {
            es: 'Ingresa un nombre descriptivo para tu encuesta.',
            en: 'Enter a name for your survey.',
            pt: 'Digite um nome para sua pesquisa.'
          }
        },
        version: {
          label: {
            es: 'Etiqueta de versión por defecto (opcional)',
            en: 'Default version tag (optional)',
            pt: 'Etiqueta de versão padrão (opcional)'
          }
        },
        cantSurvey: {
          label: {
            es: 'Reenvíos (opcional)',
            en: 'Resends (optional)',
            pt: 'Reenvia (opcional)'
          }
        }
      },
      notify: {
        validationFail: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!'
          },
          text: {
            es: 'Debes completar todos los campos requeridos.',
            en: 'You must complete all required fields.',
            pt: 'Você deve completar todos os campos necessários.'
          }
        },
        success: {
          title: {
            es: 'Éxito',
            en: 'Success',
            pt: 'Sucesso'
          },
          text: {
            es: 'La encuesta fue creada.',
            en: 'The survey was created.',
            pt: 'A pesquisa foi criada.'
          }
        },
        error: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!'
          },
          text: {
            es: 'Parece que ocurrió un error.',
            en: 'Looks like something went wrong.',
            pt: 'Parece que algo deu errado.'
          }
        }
      }
    },
    deleteSurvey: {
      es: 'Eliminar encuesta',
      en: 'Delete survey',
      pt: 'Delete a pesquisa'
    },
    deleteSurveyDialog: {
      title: {
        es: 'Eliminar encuesta',
        en: 'Delete survey',
        pt: 'Delete a pesquisa'
      },
      deleteMessagesText: {
        es: 'Borrar todos los mensajes derivados de esta encuesta',
        en: 'Delete all messages related to this survey',
        pt: 'Apagar todas as mensagens relacionadas a esta pesquisa'
      },
      deleteMessagesTextAlert: {
        es:
          'Atención! Si esta opción está activada todos los mensajes asociados a la encuesta desaparecerán y no podrán ser recuperados. Desaparecerán también todas las métricas asociadas a esta encuesta',
        en: 'Delete all messages related to this survey',
        pt: 'Apagar todas as mensagens relacionadas a esta pesquisa'
      },
      text: {
        es: '¿Confirmas la eliminación de la encuesta XXX?', // XXX es reemplazado por el nombre de la encuesta
        en: '¿Confirm delete survey XXX?',
        pt: 'Você confirma a remoção da pesquisa XXX?'
      },
      accept: {
        es: 'Aceptar',
        en: 'Accept',
        pt: 'Aceitar'
      },
      cancel: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      },
      notify: {
        success: {
          title: {
            es: 'Éxito',
            en: 'Success',
            pt: 'Sucesso'
          },
          text: {
            es: 'La encuesta fue eliminada.',
            en: 'The survey was deleted.',
            pt: 'A pesquisa foi deletada.'
          }
        },
        error: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!'
          },
          text: {
            es: 'Parece que ocurrió un error.',
            en: 'Looks like something went wrong.',
            pt: 'Parece que algo deu errado.'
          }
        }
      }
    },
    preview: {
      es: 'Previsualizar',
      en: 'Preview',
      pt: 'Visualização'
    }
  },
  versions: {
    title: {
      es: 'Versiones',
      en: 'Versions',
      pt: 'Versões'
    },
    subtitle: {
      es:
        'Crea diferentes versiones de tu encuesta, cuando termines puedes promocionar las versiones para que queden en vivo.',
      en:
        'Here you can create many versions of your survey, when you are done you can promote it to go live.',
      pt:
        'Aqui você pode criar diferentes versões de sua pesquisa, quando você terminar você pode promovê-lo para ir ao vivo.'
    },
    noProductionVersionWarning: {
      es:
        'Advertencia: no existe una versión EN VIVO para esta encuesta, deberás promocionar una versión para poder enviarla a los usuarios.',
      en:
        'Warning: there is no LIVE version of this survey yet, you must promote a version to be able to send it to your users.',
      pt:
        'Aviso: não há nenhuma versão ao vivo desta pesquisa ainda, você deve promover uma versão para ser capaz de enviá-la para seus usuários.'
    },
    table: {
      tag: {
        es: 'Etiqueta',
        en: 'Tag',
        pt: 'Etiqueta'
      },
      status: {
        es: 'Estado',
        en: 'Status',
        pt: 'Status'
      },
      _createdAt: {
        es: 'Creación',
        en: 'Created on',
        pt: 'Criado em'
      },
      _updatedAt: {
        es: 'Modificación',
        en: 'Updated on',
        pt: 'Atualizado em'
      },
      edit: {
        es: 'Editar',
        en: 'Edit',
        pt: 'Editar'
      }
    },
    add: {
      es: 'Agregar versión',
      en: 'Add version',
      pt: 'Adicionar versão'
    },
    addPopup: {
      title: {
        es: 'Nueva versión',
        en: 'New version',
        pt: 'Nova versão'
      },
      formWizzard: {
        title: {
          es: '',
          en: '',
          pt: ''
        },
        subtitle: {
          es: null,
          en: null,
          pt: null
        },
        nextButtonText: {
          es: 'Siguiente',
          en: 'Next',
          pt: 'Próxima'
        },
        backButtonText: {
          es: 'Anterior',
          en: 'Back',
          pt: 'Anterior'
        },
        finishButtonText: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        },
        fork: {
          title: {
            es: 'Duplicar',
            en: 'Duplicate',
            pt: 'Duplicar'
          },
          table: {
            label: {
              es: 'Aquí puedes duplicar una versión de una encuesta.',
              en: 'Here you can duplicate a version of a survey.',
              pt: 'Aqui você pode duplicar uma versão de uma pesquisa.'
            },
            botLabel: {
              es: 'Aquí puedes duplicar una versión de un bot.',
              en: 'Here you can duplicate a version of a bot.',
              pt: 'Aqui você pode duplicar uma versão de um bot.'
            }
          },
          addSurvey: {
            label: {
              es: 'Aquí puedes duplicar una encuesta.',
              en: 'Here you can duplicate a survey.',
              pt: 'Aqui você pode duplicar uma pesquisa.'
            }
          }
        },
        settings: {
          title: {
            es: 'Configuración',
            en: 'Settings',
            pt: 'Configuração'
          },
          tag: {
            label: {
              es: 'Etiqueta (opcional)',
              en: 'Tag (optional)',
              pt: 'Etiqueta (opcional)'
            },
            labelCompulsory: {
              es: 'Etiqueta (requerido)',
              en: 'Tag (required)',
              pt: 'Etiqueta (necessário)'
            },
            placeholder: {
              es: 'Ingresa una etiqueta descriptiva (ej: Versión inicial)',
              en: 'Enter a descriptive tag (ex: Initial version)',
              pt: 'Digite uma etiqueta descritiva (ex: versão inicial)'
            }
          },
          status: {
            label: {
              es: 'Estado *',
              en: 'Status *',
              pt: 'Situação'
            },
            placeholder: {
              es: 'Ingresa una etiqueta descriptiva (ej: Versión inicial)',
              en: 'Enter a descriptive tag (ex: Initial version)',
              pt: 'Digite uma etiqueta descritiva (ex: versão inicial)'
            },
            alert: {
              es:
                '⚠️ Advertencia ¡Esta versión reemplazará la versión actual de en vivo!',
              en:
                '️⚠️ Warning! This version will replace the actual live version!',
              pt: '⚠️ Aviso! Esta versão vai substituir a versão atual ao vivo!'
            },
            alertHasJump: {
              es:
                '⚠️ El Salto a un diálogo cuando el Bot no identifica intención, en caso de no encontrar el diálogo del salto en la nueva versión, quedará sin efecto y tendrá que ser actualizado.',
              en:
                '️⚠️ The Jump to a dialog when the Bot is unable to identify an intention, in case of not finding the jump dialog in the new version, will be disabled and will have to be updated.',
              pt:
                '⚠️ O Salto para um diálogo quando o Bot é incapaz de identificar uma intenção, no caso de não encontrar o diálogo de salto na nova versão, será desativado e terá que ser atualizado.'
            }
          }
        }
      },
      notify: {
        success: {
          title: {
            es: 'Éxito',
            en: 'Success',
            pt: 'Sucesso'
          },
          text: {
            es: 'La version fue creada.',
            en: 'New version created.',
            pt: 'Nova versão criada.'
          }
        },
        error: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!'
          },
          text: {
            es: 'Parece que ocurrió un error.',
            en: 'Looks like something went wrong.',
            pt: 'Parece que algo deu errado.'
          }
        }
      }
    },
    editPopup: {
      title: {
        es: 'Editar versión',
        en: 'Edit version',
        pt: 'Editar a versão'
      },
      form: {
        save: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        },
        cancel: {
          es: 'Cancelar',
          en: 'Cancel',
          pt: 'Cancelar'
        }
      },
      notify: {
        getVersions: {
          error: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              es: 'Parece que ocurrió un error cargando versiones.',
              en: 'Looks like something went wrong loading versions.',
              pt: 'Parece que algo deu errado carregando versões.'
            }
          }
        },
        saveVersion: {
          success: {
            title: {
              es: 'Éxito',
              en: 'Success',
              pt: 'Sucesso'
            },
            text: {
              es: 'La version fue actualizada.',
              en: 'Version updated successfully.',
              pt: 'Versão atualizada com sucesso.'
            }
          },
          error: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              es: 'Parece que ocurrió un error.',
              en: 'Looks like something went wrong.',
              pt: 'Parece que algo deu errado.'
            }
          }
        }
      }
    },
    promote: {
      es: 'Promover versión',
      en: 'Promote version',
      pt: 'Promover versão'
    },
    promoteToTest: {
      es: 'Promover a versión de test',
      en: 'Promote to test version',
      pt: 'Promover a versão de teste'
    },
    promotePopup: {
      title: {
        es: 'Promover a en vivo',
        en: 'Promote to live',
        pt: 'Promover para ao vivo'
      },
      text: {
        es: '¿Estás seguro de querer promocionar la versión XXX a en vivo?',
        en: '️Do you want to promote the version XXX to live?',
        pt: 'Você quer promover a versão XXX para ao vivo?'
      },
      notify: {
        success: {
          title: {
            es: 'Éxito',
            en: 'Success',
            pt: 'Sucesso'
          },
          text: {
            es: 'La versión fue promovida a en vivo.',
            en: 'This version was promoted to live.',
            pt: 'Esta versão foi promovida para ao vivo.'
          }
        },
        error: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!'
          },
          text: {
            es: 'Parece que ocurrió un error.',
            en: 'Looks like something went wrong.',
            pt: 'Parece que algo deu errado.'
          }
        }
      }
    },
    promoteToTestPopup: {
      title: {
        es: 'Promover a versión de test',
        en: 'Promote to test version',
        pt: 'Promover para a versão de teste'
      },
      text: {
        es: '¿Estás seguro de querer promocionar la versión XXX a test?',
        en: '️Do you want to promote the version XXX to test?',
        pt: 'Você quer promover a versão XXX para ao teste?'
      },
      notify: {
        success: {
          title: {
            es: 'Éxito',
            en: 'Success',
            pt: 'Sucesso'
          },
          text: {
            es: 'La versión fue promovida a test.',
            en: 'This version was promoted to test.',
            pt: 'Esta versão foi promovida para ao test.'
          }
        },
        error: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!'
          },
          text: {
            es: 'Parece que ocurrió un error.',
            en: 'Looks like something went wrong.',
            pt: 'Parece que algo deu errado.'
          }
        }
      }
    },
    delete: {
      es: 'Eliminar versión',
      en: 'Delete version',
      pt: 'Apagar a versão'
    },
    deleteDialog: {
      title: {
        es: 'Eliminar Versión',
        en: 'Delete Version',
        pt: 'Apagar a versão'
      },
      text: {
        es: '¿Quieres eliminar la versión XXX?',
        en: 'Do you want to delete version XXX?',
        pt: 'Quer apagar a versão XXX?'
      },
      accept: {
        es: 'Aceptar',
        en: 'Accept',
        pt: 'Aceitar'
      },
      cancel: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      },
      notify: {
        success: {
          title: {
            es: 'Éxito',
            en: 'Success',
            pt: 'Sucesso'
          },
          text: {
            es: 'La versión fue eliminada.',
            en: 'This version was deleted.',
            pt: 'Esta versão foi deletada.'
          }
        },
        error: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!'
          },
          text: {
            es: 'Parece que ocurrió un error.',
            en: 'Looks like something went wrong.',
            pt: 'Parece que algo deu errado.'
          }
        }
      }
    }
  },
  editor: {
    question: {
      es: 'Pregunta',
      en: 'Question',
      pt: 'Pergunta'
    },
    requestion: {
      es: 'Cuando el usuario no ingresa el texto esperado responde:',
      en: 'Answer when the user does not use the expected answer format:',
      pt: 'Quando o usuário não insere o texto esperado, responde:'
    },
    answer: {
      es: 'Respuesta esperada',
      en: 'Expected answer',
      pt: 'Resposta esperada'
    },
    responseTypes: {
      open: {
        es: 'Abierta',
        en: 'Open',
        pt: 'Aberta'
      },
      numbers: {
        es: 'Números',
        en: 'Numbers',
        pt: 'Números'
      },
      options: {
        es: 'Opciones',
        en: 'Options',
        pt: 'Opções'
      }
    },
    saveAs: {
      es: 'Guardar respuesta como',
      en: 'Save the answer as',
      pt: 'Salvar a resposta como'
    },
    message: {
      es: 'Mensaje',
      en: 'Message',
      pt: 'Mensagem'
    },
    finalMessage: {
      es: 'Mensaje final 🎉',
      en: 'Final message 🎉',
      pt: 'Mensagem final 🎉'
    },
    changes: {
      save: {
        es: 'Guardar cambios',
        en: 'Save changes',
        pt: 'Salvar as mudanças'
      },
      cancel: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      }
    },
    newMessage: {
      title: {
        es: 'Nuevo mensaje',
        en: 'New message',
        pt: 'Nova mensagem'
      },
      subtitle: {
        es: null,
        en: null,
        pt: null
      },
      nextButtonText: {
        es: 'Siguiente',
        en: 'Next',
        pt: 'Próxima'
      },
      backButtonText: {
        es: 'Anterior',
        en: 'Back',
        pt: 'Anterior'
      },
      finishButtonText: {
        es: 'Guardar',
        en: 'Save',
        pt: 'Salvar'
      },
      tab1: {
        title: {
          es: null,
          en: null,
          pt: null
        },
        label: {
          es: 'Mensaje *',
          en: 'Message *',
          pt: 'Mensagem *'
        }
      }
    },
    editMessage: {
      title: {
        es: 'Editar mensaje',
        en: 'Edit message',
        pt: 'Editar mensagem'
      },
      subtitle: {
        es: null,
        en: null,
        pt: null
      },
      nextButtonText: {
        es: 'Siguiente',
        en: 'Next',
        pt: 'Próxima'
      },
      backButtonText: {
        es: 'Anterior',
        en: 'Back',
        pt: 'Anterior'
      },
      finishButtonText: {
        es: 'Guardar',
        en: 'Save',
        pt: 'Salvar'
      },
      tab1: {
        title: {
          es: null,
          en: null,
          pt: null
        },
        label: {
          es: 'Mensaje *',
          en: 'Message *',
          pt: 'Mensagem *'
        }
      }
    },
    newItem: {
      title: {
        es: 'Nueva pregunta',
        en: 'New question',
        pt: 'Nova pergunta'
      },
      subtitle: {
        es: null,
        en: null,
        pt: null
      },
      nextButtonText: {
        es: 'Siguiente',
        en: 'Next',
        pt: 'Próxima'
      },
      backButtonText: {
        es: 'Anterior',
        en: 'Back',
        pt: 'Anterior'
      },
      finishButtonText: {
        es: 'Guardar',
        en: 'Save',
        pt: 'Salvar'
      },
      tab1: {
        title: {
          es: 'Pregunta',
          en: 'Question',
          pt: 'Pergunta'
        },
        name: {
          es: 'Nombre *',
          en: 'Name *',
          pt: 'Nome *'
        },
        question: {
          es: 'Pregunta *',
          en: 'Question *',
          pt: 'Pergunta *'
        },
        requestion: {
          es:
            'Cuando el usuario no ingresa el texto esperado responde (opcional):',
          en:
            'Answer when the user does not use the expected answer format (optional):',
          pt:
            'Resposta quando o usuário não usar o formato de resposta esperado (opcional):'
        }
      },
      tab2: {
        title: {
          es: 'Respuesta',
          en: 'Answer',
          pt: 'Resposta'
        },
        responseTypes: {
          es: [
            {
              value: 1,
              text: 'Abierta'
            },
            {
              value: 2,
              text: 'Números'
            },
            {
              value: 3,
              text: 'Opciones'
            }
          ],
          en: [
            {
              value: 1,
              text: 'Free text'
            },
            {
              value: 2,
              text: 'Numbers'
            },
            {
              value: 3,
              text: 'Choice'
            }
          ],
          pt: [
            {
              value: 1,
              text: 'Aberta'
            },
            {
              value: 2,
              text: 'Números'
            },
            {
              value: 3,
              text: 'Opções'
            }
          ]
        },
        options: {
          label: {
            es: 'Opciones *',
            en: 'Options *',
            pt: 'Opções *'
          },
          placeholder: {
            es: 'Ingresa una opción',
            en: 'Enter an choice',
            pt: 'Digite uma escolha'
          }
        }
      },
      tab3: {
        title: {
          es: 'Lógica',
          en: 'Logic'
        }
      },
      tab4: {
        title: {
          es: 'Guardar respuesta',
          en: 'Save the answer',
          pt: 'Salvar resposta'
        },
        saveAs: {
          label: {
            es: 'Guardar respuesta como *',
            en: 'Save answer as *',
            pt: 'Salvar a resposta como *'
          },
          placeholder: {
            es: 'Ingresa un nombre clave descriptivo, (ej: nombre_completo).',
            en: 'Enter a variable name (ex: full_name).',
            pt: 'Digite uma variável nome (ex: full_name).'
          }
        }
      }
    },
    editItem: {
      title: {
        es: 'Editar pregunta',
        en: 'Edit question',
        pt: 'Editar pergunta'
      },
      subtitle: {
        es: null,
        en: null,
        pt: null
      },
      nextButtonText: {
        es: 'Siguiente',
        en: 'Next',
        pt: 'Próxima'
      },
      backButtonText: {
        es: 'Anterior',
        en: 'Back',
        pt: 'Anterior'
      },
      finishButtonText: {
        es: 'Guardar',
        en: 'Save',
        pt: 'Salvar'
      },
      tab1: {
        title: {
          es: 'Pregunta',
          en: 'Question',
          pt: 'Pergunta'
        },
        name: {
          es: 'Nombre *',
          en: 'Name *',
          pt: 'Nome *'
        },
        question: {
          es: 'Pregunta *',
          en: 'Question *',
          pt: 'Pergunta *'
        },
        requestion: {
          es:
            'Cuando el usuario no ingresa el texto esperado responde: (opcional)',
          en:
            'Answer when the user does not use the expected answer format (optional):',
          pt:
            'Resposta quando o usuário não usar o formato de resposta esperado (opcional):'
        }
      },
      tab2: {
        title: {
          es: 'Respuesta',
          en: 'Answer',
          pt: 'Resposta'
        },
        responseTypes: {
          es: [
            {
              value: 1,
              text: 'Abierta'
            },
            {
              value: 2,
              text: 'Números'
            },
            {
              value: 3,
              text: 'Opciones'
            }
          ],
          en: [
            {
              value: 1,
              text: 'Free text'
            },
            {
              value: 2,
              text: 'Numbers'
            },
            {
              value: 3,
              text: 'Options'
            }
          ],
          pt: [
            {
              value: 1,
              text: 'Aberta'
            },
            {
              value: 2,
              text: 'Números'
            },
            {
              value: 3,
              text: 'Opções'
            }
          ]
        },
        options: {
          label: {
            es: 'Opciones *',
            en: 'Options *',
            pt: 'Opções *'
          },
          placeholder: {
            es: 'Ingresa una opción',
            en: 'Enter a choice',
            pt: 'Digite uma escolha'
          }
        }
      },
      tab3: {
        title: {
          es: 'Lógica',
          en: 'Logic'
        }
      },
      tab4: {
        title: {
          es: 'Save as ',
          en: 'Persistence',
          pt: 'Salvar como'
        },
        saveAs: {
          label: {
            es: 'Guardar respuesta como *',
            en: 'Save answer as *',
            pt: 'Salvar a resposta como *'
          },
          placeholder: {
            es: 'Ingresa un nombre clave descriptivo, (ej: nombre_completo).',
            en: 'Enter a variable name (ex: full_name).',
            pt: 'Digite uma variável nome (ex: full_name).'
          }
        }
      }
    },
    editFinalMessage: {
      title: {
        es: 'Editar mensaje final',
        en: 'Edit final message',
        pt: 'Editar a mensagem final'
      },
      subtitle: {
        es: null,
        en: null,
        pt: null
      },
      nextButtonText: {
        es: 'Siguiente',
        en: 'Next',
        pt: 'Próxima'
      },
      backButtonText: {
        es: 'Anterior',
        en: 'Back',
        pt: 'Anterior'
      },
      finishButtonText: {
        es: 'Guardar',
        en: 'Save',
        pt: 'Salvar'
      },
      tab1: {
        title: {
          es: null,
          en: null,
          pt: null
        },
        label: {
          es: 'Mensaje *',
          en: 'Message *',
          pt: 'Mensagem *'
        }
      }
    },
    removeQuestion: {
      dialog: {
        title: {
          es: 'Eliminar Pregunta',
          en: 'Delete question',
          pt: 'Delete a pergunta'
        },
        text: {
          es: '¿Quieres eliminar esta pregunta?',
          en: 'Do you want to delete this question?',
          pt: 'Quer apagar esta pergunta?'
        }
      }
    },
    removeMessage: {
      dialog: {
        title: {
          es: 'Eliminar Mensaje',
          en: 'Delete message',
          pt: 'Apagar mensagem'
        },
        text: {
          es: '¿Quieres eliminar este mensaje?',
          en: 'Do you want to delete this message?',
          pt: 'Quer apagar essa mensagem?'
        }
      }
    },
    validationError: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!'
      },
      text: {
        es: 'Debes completar todos los campos requeridos.',
        en: 'You must complete all the required fields.',
        pt: 'Você deve completar todos os campos necessários.'
      }
    },
    validationErrorOptions: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!'
      },
      text: {
        es: 'Debes definir al menos dos opciones.',
        en: 'You must define at least two options.',
        pt: 'Você deve definir pelo menos duas opções.'
      }
    },
    validationErrorKey: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!'
      },
      text: {
        es: 'Nombre de variable inválido. Debe ser alfanumérico extendido.',
        en: 'Invalid variable name. Must be alphanumeric extended.',
        pt: 'Nome da variável inválido, deve ser alfanumérico estendido.'
      }
    }
  },
  defaultSurvey: {
    es: [
      {
        index: 0,
        name: 'NPS',
        question:
          'En una escala del 1 al 10 ¿Qué tanto recomendarías éste producto a un amigo o colega?',
        requestion:
          'Uy! Esa parece no ser una opción válida...\nEn una escala del 1 al 10 ¿Qué tanto recomendarías éste producto a un amigo o colega?',
        responseType: 3,
        options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        key: 'nps',
        finish: false
      },
      {
        index: 1,
        name: 'Mensaje Final',
        question: 'Eso es todo, muchas gracias! 😊',
        responseType: null,
        finish: true
      }
    ],
    en: [
      {
        index: 0,
        name: 'NPS',
        question:
          'How likely are you to recommend this product to a friend or colleague?',
        requestion:
          'Oops!\nHow likely are you to recommend this product to a friend or colleague?',
        responseType: 3,
        options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        key: 'nps',
        finish: false
      },
      {
        index: 1,
        name: 'Mensaje Final',
        question: 'Thank you! 😊',
        responseType: null,
        finish: true
      }
    ],
    pt: [
      {
        index: 0,
        name: 'NPS',
        question:
          'Numa escala de 1 a 10, quanto você recomendaria este produto a um amigo ou colega?',
        requestion:
          'Oops! Essa parece não ser uma opção válida...\nNuma escala de 1 a 10, quanto você recomendaria este produto a um amigo ou colega?',
        responseType: 3,
        options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        key: 'nps',
        finish: false
      },
      {
        index: 1,
        name: 'Mensagem final',
        question: 'Isso é tudo, muito obrigado! 😊',
        responseType: null,
        finish: true
      }
    ]
  },
  clusterizeAnswers: {
    es: 'Clusterizar respuestas',
    en: 'Clusterize answers',
    pt: 'Clusterizar respostas'
  },
  withClustering: {
    es: 'Con clusterización',
    en: 'With clustering',
    pt: 'Com o agrupamento'
  },
  groups: {
    addGroup: {
      es: 'Nuevo grupo',
      en: 'New group',
      pt: 'Novo grupo'
    },
    tooltip: {
      es:
        'Para crear un grupo es necesario asignarle un nombre y una primer pregunta',
      en:
        'To create a group it is necessary to assign a name and a first question',
      pt:
        'Para criar um grupo es necesario asignarle un nombre y una primer pregunta'
    }
  },
  conditions: {
    form: {
      function: {
        es: 'Función',
        en: 'Function',
        pt: 'Função'
      },
      args: {
        es: 'Argumentos',
        en: 'Arguments',
        pt: 'Argumentos'
      },
      jump: {
        es: 'Salto',
        en: 'Jump',
        pt: 'Pulo'
      }
    },
    title: {
      es: 'Condiciones',
      en: 'Conditions',
      pt: 'Condições'
    },
    tooltip: {
      es: 'Las condiciones se ejecutarán en orden descendente',
      en: 'Conditions will be executed in descending order',
      pt: 'As condições serão executadas em ordem decrescente'
    },
    conditionText: {
      if: {
        es: 'Si la respuesta es',
        en: 'If answer is',
        pt: 'Se a resposta for'
      },
      then: {
        es: ', continuar en el grupo',
        en: ', continue in group',
        pt: ', continuar no grupo'
      }
    },
    texts: {
      lessThan: {
        es: 'menor que',
        en: 'less than',
        pt: 'menor que'
      },
      greaterThan: {
        es: 'mayor que',
        en: 'greater than',
        pt: 'maior que'
      },
      equal: {
        es: 'igual a',
        en: 'equal to',
        pt: 'igual a'
      },
      match: {
        es: 'RegExp',
        en: 'RegExp',
        pt: 'RegExp'
      }
    },
    empty: {
      es: 'No hay condiciones',
      en: 'There are no conditions',
      pt: 'Não há condições'
    },
    add: {
      es: 'Agregar condición',
      en: 'Add contition',
      pt: 'Adicionar condição'
    },
    messages: {
      error: {
        missingFuncs: {
          es: 'Las funciones de las condiciones son requeridas',
          en: 'Missing conditions functions',
          pt: 'Funções de condições ausentes'
        },
        missingJumps: {
          es: 'Los saltos de las condiciones son requeridos',
          en: 'Missing conditions jumps',
          pt: 'Saltos de condições ausentes'
        },
        missingArgs: {
          es: 'Los argumentos de las condiciones son requeridos',
          en: 'Missing conditions arguments',
          pt: 'Argumentos de condições ausentes'
        },
        activeJumps: {
          es:
            'No es posible eliminar el item "XXX" debido a que es el último item del grupo y el/los items "YYY" tienen saltos hacia este grupo.',
          en:
            'It is not possible to delete the item "XXX" because it is the last item in the group and the item/items "YYY" have jumps to this group.',
          pt:
            'Não é possível deletar o item "XXX" porque é o último item do grupo e o item / itens "YYY" salta para este grupo.'
        }
      }
    }
  }
}
