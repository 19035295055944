import Validate from '../../store/Validations'

export class BotService {
  /**
   * Bot service
   * @param {String} name the name of the bot
   * @param {String} description the description of the bot
   * @param {Array<User>} users the users of the bot
   * @param {String} id the ID of the service
   */
  constructor(
    name = '',
    description = '',
    users = [],
    id = '',
    usersByChannel = []
  ) {
    this.name = name
    this.description = description
    this.id = id
    this.users = users
    this.usersByChannel = usersByChannel
  }

  /**
   * Is bot name valid
   * @returns {boolean}
   */
  isNameValid() {
    return !Validate.isEmpty(this.name)
  }

  /**
   * Is bot description valid
   * @returns {boolean}
   */
  isDescriptionValid() {
    return !Validate.isEmpty(this.description)
  }

  /**
   * Is bot service valid
   * @returns {boolean}
   */
  isValid() {
    return this.isNameValid() && this.isDescriptionValid()
  }

  /**
   * Get bot service to save
   * @returns {{name: String, description: String, users: String[]}}
   */
  getServiceToSave() {
    return {
      name: this.name,
      description: this.description,
      users: this.users.map(u => u.id)
    }
  }

  /**
   * Creates an object that return the two properties contained in the bot general settings - name and description
   * @returns {{name: String, description: String}}
   */
  getGeneralSettings() {
    return {
      name: this.name,
      description: this.description
    }
  }

  /**
   * Get user IDs
   * @returns {String[]}
   */
  getUsers() {
    return this.users // .map(u => u.id)
  }

  /**
   * Update User
   * @param aUser {User}
   */
  updateUser(aUser) {
    let userToUpdate = this.users.find(u => u.id === aUser.id)
    Object.assign(userToUpdate, aUser)
  }

  /**
   * Validate bo service and asssign properties to the validation object
   * @param validations
   */
  validate(validations) {
    validations.service.name = !this.isNameValid()
    validations.service.description = !this.isDescriptionValid()
  }
}
