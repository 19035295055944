import { Message } from '../Message'
import { GenericMessageType } from './MessageTypes/GenericMessageType'

export class UserMessage extends Message {
  constructor(id, dialogSavedId, type = new GenericMessageType()) {
    super(id, dialogSavedId, type)
    this.numberOfNextAltPaths = 0
    this.numberOfPrevAltPaths = 0
    this.nextAlternative = undefined
    this.prevAlternative = undefined
    this.alternativeMessage = undefined
  }

  isUserMessage() {
    return true
  }
  isBotMessage() {
    return false
  }
}
