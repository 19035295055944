export default {
  button: {
    es: 'Nuevo +',
    en: 'New +',
    pt: 'Novo +',
  },
  popup: {
    title: {
      es: 'Nuevo +',
      en: 'New +',
      pt: 'Novo +',
    },
  },
  wizard: {
    title: {
      es: 'Nuevo chat',
      en: 'New chat',
      pt: 'Novo chat',
    },
    subtitle: {
      es: '',
      en: '',
      pt: '',
    },
    platform: {
      es: 'Seleccionar canal',
      en: 'Select channel',
      pt: 'Selecione o canal',
    },
    bot: {
      es: 'Seleccionar bot',
      en: 'Select bot',
      pt: 'Selecione o bot',
    },
    messageTitle: {
      es: 'Ingresar mensaje',
      en: 'Add message',
      pt: 'Adicionar mensagem',
    },
    noBots: {
      en: 'It seems that there is no bots with an integration configured for the selected channel.',
      es: 'Parece que no hay ningún bot con una integración configurada para el canal seleccionado.',
      pt: 'Parece que não há bots com uma integração configurada para o canal selecionado.',
    },
    number: {
      es: 'Número de teléfono',
      en: 'Phone number',
      pt: 'Número de telefone',
    },
    message: {
      es: 'Mensaje',
      en: 'Message',
      pt: 'Mensagem',
    },
    messagePlaceholder: {
      es: '[Código de País][Código de área][Número de teléfono]',
      en: '[Country code][Area code][Phone number]',
      pt: '[Código do país] [Código de área] [Número de telefone]',
    },
    next: {
      es: 'Siguiente',
      en: 'Next',
      pt: 'Próxima',
    },
    back: {
      es: 'Anterior',
      en: 'Back',
      pt: 'Anterior',
    },
    finish: {
      es: 'Finalizar',
      en: 'Finish',
      pt: 'Finalizar',
    },
  },
  error: {
    bot: {
      validationFail: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!',
        },
        text: {
          es: 'Debes seleccionar un bot.',
          en: 'You must select a bot.',
          pt: 'Você deve selecionar um bot.',
        },
      },
    },
    senderId: {
      validationFail: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!',
        },
        text: {
          es: 'El número de teléfono no es válido',
          en: 'The phone number is not valid.',
          pt: 'O número de telefone não é válido.',
        },
      },
    },
    message: {
      validationFail: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!',
        },
        text: {
          es: 'Debes completar todos los campos requeridos.',
          en: 'You must complete all required fields.',
          pt: 'Você deve completar todos os campos necessários.',
        },
      },
    },
    generic: {
      validationFail: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!',
        },
        text: {
          es: 'Parece que ocurrió un error.',
          en: 'It seems that an error occurred.',
          pt: 'Parece que ocorreu um erro.',
        },
      },
    },
  },
}