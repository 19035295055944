import Request from './request'
import state from '../store/state'

const PATH = {
  SURVEY_METRICS: '/survey-metrics',
  QUERY: '/query',
  SURVEY: '/survey',
  SURVEY_EXTRA: '/audienceItem/survey-extra'
}

// TODO: refactor to constants
const TYPES_URL = {
  pie: '/pie-v1',
  bar: '/stacked-v1',
  cluster: '/clustering-v1'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const _getChartsByType = (type, service = null, surveyService = null) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let queryParams = `populate[path]=surveyService`
  queryParams +=
    service && service.length ? `&where[botService]=${service}` : ''

  queryParams +=
    surveyService && surveyService.length
      ? `&where[surveyService]=${surveyService}`
      : ''

  return Request.get(
    `${state.config.konecta}${PATH.SURVEY_METRICS}${TYPES_URL[type]}?${queryParams}`,
    config
  )
}

const _getChartsData = data => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const chartId = data.chartId
  const type = data.chartType
  const payload = data.payload

  return Request.post(
    `${state.config.konecta}${PATH.SURVEY_METRICS}${TYPES_URL[type]}${PATH.QUERY}/${chartId}`,
    payload,
    config
  )
}

const _getSurveyGroupOptions = surveyId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.SURVEY}/${surveyId}/active-survey-array`,
    config
  )
}

const _getSurveyExtraOptions = surveyId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let queryParams = surveyId ? `?where[meta.surveyService]=${surveyId}` : ''

  return Request.get(
    `${state.config.konecta}${PATH.SURVEY_EXTRA}${queryParams}`,
    config
  )
}

const _createNewChart = newChart => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const type = newChart.type
  const data = newChart.data

  return Request.post(
    `${state.config.konecta}${PATH.SURVEY_METRICS}${TYPES_URL[type]}`,
    data,
    config
  )
}

const _copyChart = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${state.config.konecta}${PATH.SURVEY_METRICS}/copy`,
    payload,
    config
  )
}

const _deleteChart = chart => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const id = chart.id
  const type = chart.type

  return Request.delete(
    `${state.config.konecta}${PATH.SURVEY_METRICS}${TYPES_URL[type]}/${id}`,
    config
  )
}

export default {
  getChartsByType: _getChartsByType,
  getChartsData: _getChartsData,
  getSurveyGroupOptions: _getSurveyGroupOptions,
  getSurveyExtraOptions: _getSurveyExtraOptions,
  createNewChart: _createNewChart,
  copyChart: _copyChart,
  deleteChart: _deleteChart
}
