export default {
  main: {
    metric: {
      loading: {
        es: 'Cargando...',
        en: 'Loading...',
        pt: 'Carregando...',
      },
      field: {
        es: 'Campo',
        en: 'Field',
        pt: 'Campo',
      },
      survey: {
        es: 'Encuesta',
        en: 'Survey',
        pt: 'Pesquisa',
      },
      total: {
        es: 'Total de encuestas',
        en: 'Survey total',
        pt: 'Pesquisas totais',
      },
      actions: {
        edit: {
          es: 'Editar',
          en: 'Edit',
          pt: 'Editar',
        },
        copy: {
          es: 'Copiar',
          en: 'Copy',
          pt: 'Copiar',
        },
        delete: {
          es: 'Eliminar',
          en: 'Delete',
          pt: 'Excluir',
        },
      },
      npsValue: {
        es: 'Valor NPS',
        en: 'NPS value',
        pt: 'Valor NPS',
      },
    },
    messages: {
      loadCharts: {
        error: {
          text: {
            es: 'Ha ocurrido un error obteniendo las métricas.',
            en: 'An error has ocurred while getting charts.',
            pt: 'Ocorreu um erro ao obter as métricas.',
          },
        },
      },
      emptyData: {
        es: 'No se encontraron resultados',
        en: 'No results found',
        pt: 'Não foram encontrados resultados',
      },
    },
  },
  create: {
    title: {
      es: 'Crear métrica',
      en: 'Create chart',
      pt: 'Criar métrica',
    },
    form: {
      name: {
        label: {
          es: 'Nombre de métrica',
          en: 'Chart name',
          pt: 'Nome da métrica',
        },
        placeholder: {
          es: 'Nombre de métrica',
          en: 'Chart name',
          pt: 'Nome da métrica',
        },
      },
      type: {
        label: {
          es: 'Tipo de métrica',
          en: 'Chart type',
          pt: 'Tipo de métrica',
        },
        placeholder: {
          es: 'Seleccione tipo de métrica',
          en: 'Select chart type',
          pt: 'Selecione o tipo de métrica',
        },
      },
      survey: {
        label: {
          es: 'Encuesta',
          en: 'Survey',
          pt: 'Pesquisa',
        },
        placeholder: {
          es: 'Seleccione encuesta',
          en: 'Select survey',
          pt: 'Selecione a pesquisa',
        },
      },
      npsfilter: {
        label: {
          es: 'Valor Filtro nps',
          en: 'Nps filter value',
          pt: 'Valor do filtro Nps',
        },
        placeholder: {
          es: 'Seleccione un valor',
          en: 'Select value',
          pt: 'Selecione o valor',
        },
        name: {
          es: 'Filtro NPS',
          en: 'NPS Filter',
          pt: 'Filtro NPS',
        },
        detractores: {
          es: 'Detractores',
          en: 'Detractors',
          pt: 'Detratores',
        },
        neutros: {
          es: 'Neutros',
          en: 'Neutral',
          pt: 'Neutros',
        },
        promotores: {
          es: 'Promotores',
          en: 'Promoters',
          pt: 'Promotores',
        },
        npsgroup: {
          es: 'Grupo NPS',
          en: 'NPS Group',
          pt: 'Grupo NPS',
        },
      },
      cluster: {
        label: {
          es: 'Cluster',
          en: 'Cluster',
          pt: 'Cluster',
        },
        placeholder: {
          es: 'Seleccione cluster',
          en: 'Select cluster',
          pt: 'Selecione o cluster',
        },
        clusterDisclaimer: {
          es: 'Esta encuesta no tiene preguntas clusterizables.',
          en: 'This survey has no clusterizable questions.',
          pt: 'Esta pesquisa não tem perguntas clusterizantes.',
        },
      },
      bot: {
        label: {
          es: 'Bot',
          en: 'Bot',
          pt: 'Bot',
        },
        placeholder: {
          es: 'Seleccione bot',
          en: 'Select bot',
          pt: 'Selecione o bot',
        },
      },
      groups: {
        title: {
          es: 'Grupos',
          en: 'Groups',
          pt: 'Grupos',
        },
        error: {
          es: 'Al menos un grupo requerido.',
          en: 'At least one group is required.',
          pt: 'Pelo menos um grupo é necessário.',
        },
        field: {
          label: {
            es: 'Campo del grupo',
            en: 'Group field',
            pt: 'Campo de grupo',
          },
          placeholder: {
            es: 'Seleccione campo',
            en: 'Select a field',
            pt: 'Selecione um campo',
          },
        },
        name: {
          label: {
            es: 'Nombre del grupo',
            en: 'Group name',
            pt: 'Nome do grupo',
          },
          placeholder: {
            es: 'Nombre del grupo',
            en: 'Group name',
            pt: 'Nome do grupo',
          },
        },
        values: {
          label: {
            es: 'Valores del grupo',
            en: 'Group values',
            pt: 'Valores do grupo',
          },
          placeholder: {
            es: 'Seleccione valores',
            en: 'Select all values',
            pt: 'Selecione valores',
          },
          comma: {
            es: 'Valores separados por coma',
            en: 'Comma separated values',
            pt: 'Valores separados por vírgula',
          },
        },
        existingGroup: {
          es: 'El nombre de grupo ya existe.',
          en: 'Existing group name.',
          pt: 'O nome do grupo já existe.',
        },
      },
      filters: {
        title: {
          es: 'Filtros',
          en: 'Filters',
          pt: 'Filtros',
        },
        type: {
          label: {
            es: 'Campo del filtro',
            en: 'Filter field',
            pt: 'Campo de filtro',
          },
          placeholder: {
            es: 'Seleccione un campo',
            en: 'Select field',
            pt: 'Selecione um campo',
          },
        },
        value: {
          label: {
            es: 'Valor del filtro',
            en: 'Filter value',
            pt: 'Valor do filtro',
          },
          placeholder: {
            es: 'Valor del filtro',
            en: 'Filter value',
            pt: 'Valor do filtro',
          },
        },
      },
      extraField: {
        label: {
          es: 'Campo extra',
          en: 'Extra field',
          pt: 'Campo extra',
        },
        placeholder: {
          es: 'Seleccione campo extra',
          en: 'Select an extra field',
          pt: 'Selecione um campo extra',
        },
      },
      optional: {
        es: 'Opcional',
        en: 'Optional',
        pt: 'Opcional',
      },
      actions: {
        create: {
          es: 'Crear métrica',
          en: 'Create chart',
          pt: 'Criar métrica',
        },
        cancel: {
          es: 'Cancelar',
          en: 'Cancel',
          pt: 'Cancelar',
        },
        reset: {
          es: 'Reset',
          en: 'Reiniciar',
          pt: 'Reiniciar',
        },
      },
    },
    messages: {
      success: {
        text: {
          es: 'Métrica creada exitosamente!',
          en: 'Chart created successfully!',
          pt: 'Métrica criado com sucesso!',
        },
      },
      error: {
        surveys: {
          es: 'Ha ocurrido un error al obtener las encuestas. Por favor, intente nuevamente',
          en: 'An error has ocurred getting surveys. Please, try again.',
          pt: 'Ocorreu um erro ao obter as pesquisas. Por favor, tente de novo.',
        },
        surveyOptions: {
          es: 'Ha ocurrido un error al obtener las opciones de encuestas. Por favor, intente nuevamente',
          en: 'An error has ocurred getting survey options. Please, try again.',
          pt: 'Ocorreu um erro ao obter as opções de pesquisa. Por favor, tente de novo.',
        },
        extraFilters: {
          es: 'Ha ocurrido un error al obtener los filtros extra. Por favor, intente nuevamente',
          en: 'An error has ocurred getting extra filters. Please, try again.',
          pt: 'Um erro ocorreu recebendo filtros extras. Por favor, tente de novo.',
        },
      },
    },
  },
  copy: {
    title: {
      es: 'Copiar métrica',
      en: 'Copy metric',
      pt: 'Copiar métrica',
    },
    messages: {
      success: {
        text: {
          es: 'Métrica copiada exitosamente!',
          en: 'Chart copied successfully!',
          pt: 'Métrica copiada com sucesso!',
        },
      },
      error: {
        text: {
          es: 'Ha ocurrido un error al copiar la métrica. Por favor, intente nuevamente',
          en: 'An error has ocurred copying chart. Please, try again.',
          pt: 'Ocorreu um erro ao copiar a métrica. Por favor, tente de novo.',
        },
      },
    },
  },
  delete: {
    messages: {
      success: {
        text: {
          es: 'Métrica eliminada exitosamente!',
          en: 'Chart deleted successfully!',
          pt: 'Métrica excluída com sucesso!',
        },
      },
      error: {
        text: {
          es: 'Ha ocurrido un error al eliminar la métrica. Por favor, intente nuevamente',
          en: 'An error has ocurred deleting chart. Please, try again.',
          pt: 'Ocorreu um erro ao remover a métrica. Por favor, tente de novo.',
        },
      },
    },
  },
}