import { sha256 } from 'js-sha256'
import Validations from '../store/Validations'
import { Role } from './Role'
import Roles from './Roles'
import Vue from 'vue'

/**
 * User class
 * This class represents each user available in the application
 */
export class User {
  /**
   * Create user
   * @param {String} name the user name
   * @param {String} email the user email
   * @param {String} password the user password
   * @param {Array<String>} roles the user roles
   * @param {String} company the company ID
   * @param {String} id the user ID
   * @param {String} username the user username
   */
  constructor(
    name = '',
    email = '',
    password = '',
    roles = [],
    company = undefined,
    id = undefined,
    passwordExpiration = undefined,
    username = undefined,
    staffRoles = []
  ) {
    this.name = name
    this.email = email
    this.password = password
    this.changePassword = false
    this.newPassword1 = ''
    this.newPassword2 = ''
    this.roles = this._generateRole(roles)
    this._company = company
    this.id = id
    this.passwordExpiration = passwordExpiration
    this.username = username
    this.staffRoles = staffRoles
  }

  /**
   * Generate roles
   * @param roles {Array<String>}
   * @returns {Role}
   * @private
   */
  _generateRole(roles) {
    if (roles instanceof Role) {
      return roles
    }

    if (roles.length > 0 && roles[0].includes('company')) {
      roles = roles.map(r => {
        let roleTmp = Vue._.find(Roles, rTmp => {
          return rTmp.defaultName === r
        })

        return roleTmp.id
      })
    }

    const aux = new Role(roles)
    return aux
  }

  get company() {
    return this._company
  }

  isSaved() {
    return !!this.id
  }

  getUserToSave() {
    if (this.isSaved()) {
      return {
        name: this.name,
        password: this.changePassword ? sha256(this.newPassword1) : undefined,
        roles: this.roles.ids,
        user: this.username
      }
    }

    return {
      name: this.name,
      email: this.email,
      user: this.username,
      password: sha256(this.password),
      roles: this.roles.ids
    }
  }

  isUserFormValid() {
    return (
      this.isNameValid() &&
      this.isEmailValid() &&
      ((!this.isSaved && this.isPasswordValid()) ||
        !this.changePassword ||
        (this.isSaved &&
          this.isNewPassword1Valid() &&
          this.isNewPassword1Valid())) &&
      this.isRolesValid()
    )
  }

  isNameValid() {
    return !Validations.isEmpty(this.name)
  }

  isEmailValid() {
    return (
      !Validations.isEmpty(this.email) && Validations.isValidEmail(this.email)
    )
  }

  isUsernamelValid() {
    return !Validations.isEmpty(this.username)
  }

  isPasswordValid() {
    return !Validations.isEmpty(this.password)
  }

  isNewPassword1Valid() {
    return (
      !Validations.isEmpty(this.newPassword1) &&
      this.newPassword1 === this.newPassword2
    )
  }

  isNewPassword2Valid() {
    return (
      !Validations.isEmpty(this.newPassword2) &&
      this.newPassword1 === this.newPassword2
    )
  }

  isRolesValid() {
    return !Validations.isEmpty(this.roles)
  }
}
