const getters = {
  charts: state => {
    return state.charts
  },

  chartsData: state => {
    return state.chartsData
  },

  surveyOptionsById: state => surveyId => {
    return state.surveyOptions[surveyId]
  },

  surveyExtraOptionsById: state => surveyId => {
    return state.surveyExtraOptions[surveyId]
  },

  chartById: state => chartId => {
    return state.charts.find(c => c._id === chartId)
  }
}

export default getters
