import Validations from '../store/Validations'
import Helper from '../store/Helper'

export class Intent {
  /**
   * Create an intent
   * @param {String} name the name of the intent
   * @param {Array<String>} examples the examples
   * @param {Boolean} derivesToAgent
   * @param {String} id the ID of the intent
   * @param dialogId {String} the dialog ID
   */
  constructor(
    name = '',
    examples = [],
    derivesToAgent = false,
    id = undefined,
    dialogId = undefined,
    intentsByPlatform = []
  ) {
    this.name = name
    this.examples = examples
    this.id = id
    this.isSaved = !!this.id
    this.derivesToAgent = derivesToAgent
    this.dialogId = dialogId
    this.internalId = Helper.generateID()
    this.intentsByPlatform = intentsByPlatform
  }

  isValid() {
    return this.isNameValid() && this.isExamplesValid()
  }

  isNameValid() {
    return !Validations.isEmpty(this.name)
  }

  isExamplesValid() {
    return !Validations.isEmpty(this.examples)
  }
  // TODO: move to utils
  normalizeString(string) {
    if (typeof string !== 'string') return null
    return string
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/\s\s+/g, ' ')
      .replace(/[\u0300-\u036f]/g, '')
  }

  hasExample(name) {
    const parsedExamples = this.examples.map(e => this.normalizeString(e))
    const parsedName = this.normalizeString(name)

    return parsedExamples.includes(parsedName)
  }
}
