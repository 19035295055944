const mutations = {
  SET_CLUSTERS: (state, clusters) => {
    state.clusters = clusters
  },

  SET_CENTERS: (state, payload) => {
    const clusterId = payload.clusterId
    const centers = payload.centers

    state.centers[clusterId] = centers
  },

  SET_QUESTIONS: (state, questions) => {
    state.questions = questions
  },

  SET_QUESTIONS_LENGTH: (state, questionsLength) => {
    state.questionsLength = questionsLength
  },

  SET_CANCEL_REQUEST: (state, cancelRequest) => {
    state.cancelRequest = cancelRequest
  },

  SET_QUESTIONS_BOT_VERSIONS: (state, payload) => {
    state.questionsBotVersions[payload.service] = payload.versions
  },

  SET_VERSIONS_BY_ID: (state, versions) => {
    versions.forEach(version => {
      state.versionsById[version._id] = version.tag
    })

    // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
    // Sometimes you may want to assign a number of properties to an existing object, for example using Object.assign() or _.extend(). However, new properties added to the object will not trigger changes. In such cases, create a fresh object with properties from both the original object and the mixin object:
    state.versionsById = Object.assign(
      {},
      state.versionsById,
      state.versionsById
    )
  }
}

export default mutations
