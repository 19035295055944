<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Version: 1.1
	Author: Pixinvent
	Author URL: hhttp://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="app" v-if="loadedTheme">
    <router-view :key="$route.path"></router-view>
    <!-- plugins -->
    <KBASE />
  </div>
</template>

<script>
import Vue from 'vue'
import VueSocketIOExt from 'vue-socket.io-extended'

import { io } from 'socket.io-client'

import { themeConfig } from '@/../themeConfig.js'

// plugins

import KBASE from '@/components/kona/plugins/KBASE.vue'

import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      loadedTheme: false
    }
  },
  components: {
    KBASE
  },
  computed: {
    ...mapState(['plugins', 'session'])
  },
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val)
    }
  },
  methods: {
    ...mapActions([
      'updateAndSaveSession',
      'getCompanySettings',
      'updateSocket'
    ]),
    toggleClassInBody(className) {
      if (className == 'dark') {
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className == 'semi-dark') {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark')
      }
    },
    restartSession() {
      try {
        const session = JSON.parse(localStorage.session)
        this.updateAndSaveSession(session) // guardando sesion
      } catch (_) {
        console.info('no session')
        this.$store.state.session = {}
      }
    },
    connectSockets() {
      const ioOpts = {
        transports: ['websocket', 'polling'],
        reconnection: true
      }
      if (/\/api$/.test(this.$store.state.config.konecta)) {
        ioOpts.path = '/api/socket.io'
      }
      Vue.use(
        VueSocketIOExt,
        io(
          this.$store.state.config.konecta
            .split('/')
            .slice(0, 3)
            .join('/'),
          ioOpts
        ),
        {
          store: this.$store
        }
      )
    },
    preventVuesaxErrors() {
      window.addEventListener('error', e => {
        // resize error
        if (
          e.origin === undefined && // Sonar
          e.filename.includes('vuesax/dist/vuesax.common.js') &&
          e.error.stack.includes('VueComponent.changeTdsWidth')
        ) {
          e.preventDefault()
        }
      })
    }
  },
  async mounted() {
    try {
      this.loadedTheme = false
      this.$store.commit('SET_BACKEND', window.config.backend)
      this.$store.commit('SET_WIDGET_URL', window.config.widgetUrl)
      this.$store.commit(
        'SET_WIDGET_STAGING',
        window.config.widgetStaging || 'true'
      )
      this.$store.commit('SET_CHAT', window.config.chat)
      this.$store.commit('SET_VIDEOCALL', window.config.videocall)
      // if user logged in
      if (localStorage.session) {
        await this.getCompanySettings()
      }
      this.$vs.theme(this.$store.state.themeColors)
      this.toggleClassInBody(themeConfig.theme)
      this.restartSession()
    } catch (error) {
      Vue.$log.error(error)
    } finally {
      this.loadedTheme = true
    }

    this.connectSockets()
    this.updateSocket(this.$socket)

    this.preventVuesaxErrors()
  },
  beforeDestroy() {
    this.$socket.close()
  }
}
</script>

<style lang="scss">
@import '../src/assets/scss/responsive.scss';
.tooltip {
  display: block !important;
  z-index: 1000000000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    // z-index: 1000000000;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>
