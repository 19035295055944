const isAuthorized = ({ key, permissions=[] }) => {
  if (key === '*') { // wildcard
    return true;
  }

  if (permissions.indexOf(key) > -1) {
    return true;
  }

  if(Array.isArray(key)) {
    if(key.some(k => permissions.includes(k))){
      return true
    }
  }

  if (/^AddBot/.test(key)) {
    const args = key.split(' isEditing:');
    const component = args[0];
    const isEditing = args[1] === 'true';
    switch (component) {
      case 'AddBot.generalSettings':
        return isEditing
          ? permissions.indexOf('konecta.bot_maker.edit_bot_settings') > -1
          : permissions.indexOf('konecta.bots.add') > -1
      case 'AddBot.isEngineSettingsEnabled':
        return isEditing
          ? permissions.indexOf('konecta.bot_maker.edit_bot_settings') > -1
          : permissions.indexOf('konecta.bots.add') > -1
      case 'AddBot.isSessionSettingsEnabled':
        return isEditing
          ? permissions.indexOf('konecta.bot_maker.edit_bot_settings') > -1
          : permissions.indexOf('konecta.bots.add') > -1
      case 'AddBot.intentions':
        return permissions.indexOf('konecta.bot_maker.catalogs') > -1
      case 'AddBot.botAvailability':
        return isEditing
          ? permissions.indexOf('konecta.bot_maker.edit_bot_settings') > -1
          : permissions.indexOf('konecta.bots.add') > -1
      case 'AddBot.users':
        return isEditing
          ? permissions.indexOf('konecta.bot_maker.edit_bot_settings') > -1
          : permissions.indexOf('konecta.bots.add') > -1
      default:
        return false;
    }
  }

  return false;
};

module.exports = {
  isAuthorized,
};
