export default {
  title: {
    es: 'Auditoría',
    en: 'Audit',
    pt: 'Auditoria'
  },
  event: {
    es: 'Evento',
    en: 'Event',
    pt: 'Evento'
  },
  details: {
    es: 'Detalles',
    en: 'Details',
    pt: 'Detalhes'
  },
  path: {
    es: 'Ruta',
    en: 'Path',
    pt: 'Caminho'
  },
  user: {
    es: 'Usuario',
    en: 'User',
    pt: 'Usuário'
  },
  date: {
    es: 'Fecha',
    en: 'Date',
    pt: 'Data'
  },
  bot: {
    es: 'Bot',
    en: 'Bot',
    pt: 'Bot'
  },
  error: {
    title: {
      es: 'Oops..',
      en: 'Oops',
      pt: 'Oops'
    },
    text: {
      es: 'Ha habido un error al obtener la Auditoría',
      en: 'There was an error getting the Audit',
      pt: 'Houve um erro em obter a Auditoria'
    }
  },
  events: {
    intent: {
      es: 'intención',
      en: 'intent',
      pt: 'intenções'
    },
    login: {
      es: 'inicio sesión',
      en: 'login',
      pt: 'login'
    },
    create: {
      es: 'crear',
      en: 'create',
      pt: 'criar'
    },
    delete: {
      es: 'borrar',
      en: 'delete',
      pt: 'deletar'
    },
    update: {
      es: 'actualizar',
      en: 'update',
      pt: 'atualização'
    },
    start: {
      es: 'comenzar',
      en: 'start',
      pt: 'começar'
    },
    finish: {
      es: 'finalizar',
      en: 'finsh',
      pt: 'finalizar'
    },
    takeover: {
      es: 'derivación a agente',
      en: 'takeover',
      pt: 'encaminhamento para agente'
    }
  }
}
