<template>
  <vs-prompt class="KBASE" :vs-active.sync="plugins.KBASE.data.activeModal">
    <div>
      {{ lang.plugins.KBASE.description[languageSelected] }}
      <vs-select
        :label="lang.plugins.KBASE.inputs.process[languageSelected]"
        v-model="process"
      >
        <vs-select-item value text="texto" />
        <vs-select-item value text="texto" />
        <vs-select-item value text="texto" />
      </vs-select>
      <vs-select
        class="selectExample"
        :label="lang.plugins.KBASE.inputs.topic[languageSelected]"
        v-model="topic"
      >
        <vs-select-item value text="texto" />
      </vs-select>
      <vs-select
        class="selectExample"
        :label="lang.plugins.KBASE.inputs.subtopic[languageSelected]"
        v-model="subtopic"
      >
        <vs-select-item value text="texto" />
      </vs-select>
      <b>{{ lang.plugins.KBASE.inputs.details.title[languageSelected] }}</b>
      <p>
        {{ lang.plugins.KBASE.inputs.details.description[languageSelected] }}
      </p>
      <vs-textarea v-model="details" />

      <!-- <vs-alert
        :vs-active="!validName"
        color="danger"
        vs-icon="new_releases"
      >Fields can not be empty please enter the data</vs-alert>-->
    </div>
  </vs-prompt>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      process: '',
      topic: '',
      subtopic: '',
      details: ''
    }
  },
  computed: {
    ...mapState(['plugins', 'lang', 'languageSelected'])
  }
}
</script>
<style>
.KBASE {
  z-index: 9000 !important;
}
</style>
