import { MessageType } from '../MessageType'

export class RegexMessageType extends MessageType {
  constructor(name, examples = []) {
    super(name)
    this.inputExample = ''
    this.examples = examples
  }

  isTextMessage() {
    return false
  }
  isRegexMessage() {
    return true
  }
  isImageMessage() {
    return false
  }

  isMediaMessage() {
    return false
  }

  isIntentMessage() {
    return false
  }

  isCardMessage() {
    return false
  }

  isSliderMessage() {
    return false
  }

  isButtonsMessage() {
    return false
  }

  isGenericMessage() {
    return false
  }

  isValid() {/* isValid */}

  generateMessageToSave() {
    return {
      condition: {
        params: {
          regex: this.getRegexToSave()
        },
        tree: {
          funcs: ['match'],
          facts: [null],
          operator: '...'
        }
      }
    }
  }

  getRegexToSave() {
    throw new Error('You have to implement the method doSomething!')
  }

  isMatchRegex() {
    throw new Error('You have to implement the method doSomething!')
  }

  isRegExp() {
    throw new Error('You have to implement the method doSomething!')
  }
}
