import ContactService from '@/services/contact.service'

const actions = {
  getChats({ state, commit }, queryParams) {
    if (state.cancelRequest) {
      state.cancelRequest('cancelled')
    }
    const executor = c => {
      commit('setCancelRequest', c)
    }
    return ContactService.getChats(queryParams, executor).catch(error => {
      throw error
    })
  },
  exportAll(context, queryParams) {
    return ContactService.exportAll(queryParams).catch(error => {
      throw error
    })
  }
}

export default actions
