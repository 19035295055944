import Vue from 'vue'
import Roles from '../models/Roles'
const moment = require('moment')

Vue.filter('capitalize', function(value) {
  if (!value) return ''
  value = value.toString()
  let arr = value.split(' ')
  let capitalized_array = []
  arr.forEach(word => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('truncate', function(value, limit) {
  return value.substring(0, limit)
})

Vue.filter('tailing', function(value, tail) {
  return value + tail
})

Vue.filter('time', function(value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value))
    let hours = date.getHours()
    const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    if (!is24HrFormat) {
      const time = hours > 12 ? 'AM' : 'PM'
      hours = hours % 12 || 12
      return hours + ':' + min + ' ' + time
    }
    return hours + ':' + min
  }
})

Vue.filter('date', function(value, fullDate = false) {
  if (!fullDate) return moment(value).format('DD MMM')
  return value ? moment(value).format('DD MMM YYYY') : ''
})

Vue.filter('csv', function(value) {
  return value.join(', ')
})

Vue.filter('filter_tags', function(value) {
  return value.replace(/<\/?[^>]+(>|$)/g, '')
})

Vue.filter('k_formatter', function(num) {
  return num > 999 ? (num / 1000).toFixed(1) + 'k' : num
})

Vue.filter('role_name', function(roleId, languageSelected) {
  if (!roleId) {
    return ''
  }

  return Roles[roleId].name[languageSelected]
})

Vue.filter('regex', function(value) {
  return value.replace('^(', '').replace(')$', '')
})

Vue.filter('highlight', function(word, query, styles = '') {
  const check = new RegExp(query, 'ig')
  return word.toString().replace(check, function(matchedText) {
    return '<span style="' + styles + '">' + matchedText + '</span>'
  })
})
