import Request from './request'
import state from '../store/state'

const PATH = {
  API_CALL: '/apiCall',
  BOT: '/bot',
  OPTION: '/option',
  BUCKET: '/bucket',
  DIALOG: '/dialog',
  ENTITY: '/entity',
  INTENT: '/intent',
  USER: '/user',
  INTEGRATION: {
    FACEBOOK: {
      APIKEY: '/integration/facebook/apikey'
    }
  },
  VERSION: '/version',
  TRAIN: '/train',
  DIALOG_USING: {
    INTENT: '/intent/using',
    ENTITY: '/entity/using'
  },
  VALIDATION: '/validation',
  DEFAULT_VALIDATION: '/set-default-validation',
  FAAS: '/faasfunctions/question-generator',
  TEMPLATES: '/template',
  TEMPLATE_FORK: '/template/fork',
  TESTS: {
    TEST_GROUP: '/testGroup',
    TEST_GROUP_CASE: '/testGroupCase',
    TEST_GROUP_CASE_STEP: '/testGroupCaseStep',
    TEST_GROUP_EXECUTION: '/testGroupExecution',
  }
}
/**
 * Get token
 *
 * @returns {String} with the token retrieved from the local storage
 */
const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

// Bots

/**
 * Get bots
 *
 * @returns {Promise<Array<Bot>>}
 */
const _getBots = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(state.config.konecta + PATH.BOT, config)
}

/**
 * Get Bot
 * @param {String} id the ID of the bot to be loaded
 * @returns {Promise}
 */
const _getBot = id => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(state.config.konecta + PATH.BOT + '/' + id, config)
}

/**
 * Create bot
 * @param {Bot} bot the bot to be saved
 * @returns {Promise<Bot>}
 */
const _createBot = bot => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(state.config.konecta + PATH.BOT, bot, config)
}

/**
 * Update Bot
 * @param {Object} bot the bot to the stored
 * @param {String} botId the ID of the bot that is going to be updated
 * @returns {Promise<Bot>}
 */
const _updateBot = (bot, botId) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(state.config.konecta + PATH.BOT + '/' + botId, bot, config)
}

/**
 * Create Option
 * @param {Object} option the option to the stored
 * @returns {Promise<Option>}
 */
const _createOption = option => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.post(state.config.konecta + PATH.OPTION, option, config)
}

/**
 * Update Option
 * @param {Object} option the option to the stored
 * @returns {Promise<Option>}
 */
const _updateOption = (option, id) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.OPTION + '/' + id,
    option,
    config
  )
}

const _deleteOption = id => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(state.config.konecta + PATH.OPTION + '/' + id, config)
}

/**
 * Get Option
 * @param {Object} option the option to the stored
 * @returns {Promise<Option>}
 */
const _getOption = (botId, type) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.get(
    state.config.konecta +
      PATH.OPTION +
      '?where[bot]=' +
      botId +
      '&where[type]=' +
      type,
    config
  )
}

/**
 * Delete Bot
 * @param {String} botId the ID of the bot that has to be deleted
 * @returns {Promise}
 */
const _deleteBot = botId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(state.config.konecta + PATH.BOT + '/' + botId, config)
}

// VERSIONS

const _getBotVersions = serviceId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.VERSION + '?where[service]=' + serviceId,
    config
  )
}

const _promoteBotVersion = (versionId, changeIntegrations) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const payload = { active: true, changeIntegrations }

  return Request.put(
    state.config.konecta + PATH.VERSION + '/' + versionId,
    payload,
    config
  )
}

const _promoteToTestBotVersion = versionId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const payload = { status: 'qa' }

  return Request.put(
    state.config.konecta + PATH.VERSION + '/' + versionId,
    payload,
    config
  )
}

const _createBotVersion = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const serviceId = payload && payload.serviceId ? payload.serviceId : null
  let forkServiceId =
    payload &&
    payload.forkingBot &&
    payload.forkingBot.service &&
    payload.forkingBot.service._id
      ? payload.forkingBot.service._id
      : null
  if (
    !forkServiceId &&
    payload &&
    payload.forkingBot &&
    payload.forkingBot.service
  ) {
    forkServiceId = payload.forkingBot.service
  }
  const forkVersionId =
    payload && payload.forkingBot && payload.forkingBot._id
      ? payload.forkingBot._id
      : null

  let newPayload = {
    service: serviceId,
    active: false,
    credential: payload.credential,
    tag: payload.tag
  }

  if (forkServiceId && forkVersionId) {
    newPayload.fork = {
      service: forkServiceId,
      version: forkVersionId,
      intents: true,
      entities: true
    }
  }

  return Request.post(state.config.konecta + PATH.VERSION, newPayload, config)
}

const _deleteBotVersion = versionId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    state.config.konecta + PATH.VERSION + '/' + versionId,
    config
  )
}

/**
 * Get active version
 *
 * @param {String} serviceId the ID of the service
 * @return {Promise<[Version]>} a version object
 */
const _getActiveVersion = serviceId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta +
      PATH.VERSION +
      '?where[service]=' +
      serviceId +
      '&where[active]=true',
    config
  )
}

/**
 * Train Bot
 * @param {String} versionId the bot version that wants to be trained
 * @returns {Promise}
 */
const _trainVersion = versionId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    state.config.konecta + PATH.VERSION + '/' + versionId + PATH.TRAIN,
    {},
    config
  )
}

/**
 * Create version
 *
 * @param {String} serviceId the service ID
 * @returns {Promise} with a version entity.
 */
const _createVersion = (serviceId, credential, fork) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const data = {
    service: serviceId,
    credential,
    active: true,
    tag: 'prod',
    fork:
      fork && fork.from && fork.from.service && fork.from.version
        ? {
            service: fork.from.service,
            version: fork.from.version,
            intents: fork.intents,
            entities: fork.entities
          }
        : undefined
  }

  return Request.post(state.config.konecta + PATH.VERSION, data, config)
}

// DIALOGS

/**
 * Get Dialog
 *
 * @param {String} versionId the version ID
 * @return {Promise<Dialog[]>}
 */
const _getDialogs = versionId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.DIALOG + '?where[version]=' + versionId,
    config
  )
}

/**
 * Create a dialog
 * @param {Object} dialog the dialog to be saved
 * @returns {Promise<Dialog>}
 */
const _createDialog = dialog => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(state.config.konecta + PATH.DIALOG, dialog, config)
}

/**
 * Update dialog
 * @param {String} dialogId the dialog ID
 * @param {Object} dialog the dialog data that needs to be updated
 * @returns {Promise<Dialog>}
 */
const _updateDialog = (dialogId, dialog) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.DIALOG + '/' + dialogId,
    dialog,
    config
  )
}

/**
 * Remove dialog
 * @param {String} dialogId the ID of the dialog that wants to be removed
 * @returns {Promise<String>}
 */
const _removeDialog = dialogId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    state.config.konecta + PATH.DIALOG + '/' + dialogId,
    config
  )
}

// INTENTS

/**
 * Get intent
 * @param {String} intentId the intent ID [Optional]
 * @returns {Promise<Intent>}
 */
const _getIntent = intentId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const intent = intentId ? '/' + intentId : ''

  return Request.get(state.config.konecta + PATH.INTENT + intent, config)
}

/**
 * Get intents
 * @returns {Array<Intent>}
 */
const _getIntents = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(state.config.konecta + PATH.INTENT, config)
}

/**
 * Get intentions per version ID
 * @param {String} versionId the active version ID
 * @returns {Promise<Array<Intent>>}
 */
const _getIntentionsPerVersion = versionId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.INTENT + '?where[version]=' + versionId,
    config
  )
}

/**
 * Create Intent
 * @param {String} versionId the version that the intent will have
 * @param {String} name the name of the intent
 * @param {Array<String>} examples the sample texts that the user can introduce
 * @return {Promise<Intent>}
 */
const _createIntent = (versionId, name, examples) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const data = {
    version: versionId,
    name: name,
    examples: examples
  }

  return Request.post(state.config.konecta + PATH.INTENT, data, config)
}

/**
 * Update intent
 * @param {String} intentId the intent ID
 * @param {Object} intent an intent
 * @returns {Promise<Intent>}
 */
const _updateIntent = (intentId, intent) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.INTENT + '/' + intentId,
    intent,
    config
  )
}

const _removeIntent = intentId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    state.config.konecta + PATH.INTENT + '/' + intentId,
    config
  )
}

const _getGeneratedExamples = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const data = {
    sentence: payload.intentName,
    num_sequences: 10,
    lang: payload.lang
  }

  return Request.post(state.config.konecta + PATH.FAAS, data, config)
}

/**
 * Get user by ID
 * @param userId {String} the user ID
 * @returns {*|AxiosPromise}
 * @private
 */
const _getUser = userId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(state.config.konecta + PATH.USER + '/' + userId, config)
}

/**
 * Get users
 * Retrieve all the users
 * @return {Promise<User>}
 */
const _getUsers = query => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(state.config.konecta + PATH.USER + (query || ''), config)
}

/**
 * Create user
 * @param {Object} user the user that wants to be created
 * @return {Promise<User>}
 */
const _createUser = user => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(state.config.konecta + PATH.USER, user, config)
}

/**
 * Delete user
 * @param userId {String} the user ID
 * @returns {*|AxiosPromise}
 * @private
 */
const _deleteUser = userId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(state.config.konecta + PATH.USER + '/' + userId, config)
}

/**
 * Update user
 * @param {String} id the user ID
 * @param {Object} user the user information that wants to be updated
 * @return {Promise<User>}
 */
const _updateUser = (id, user) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(state.config.konecta + PATH.USER + '/' + id, user, config)
}

/**
 * Expire user password
 * @param {String} userId the user ID that wants to password expired
 * @returns {Promise}
 */
const _expireUserPassword = userId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.USER + '/expire-password' + '/' + userId,
    {},
    config
  )
}

/**
 * Upload a file to the server
 * @param {FormData} data the file that needs to be uploaded
 * @param {String} botId the ID of the bot
 * @returns {Promise<Object>} that contains the URL of the file
 */
const _fileUpload = (data, botId) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
    // onUploadProgress: uploadEvent => {
    //   console.warn(
    //     'Progress: ' +
    //     Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
    //     '%'
    //   )
    // }
  }

  return Request.post(
    state.config.konecta + PATH.BUCKET + '/' + botId + '/undefined',
    data,
    config
  )
}

/**
 * Configure Whats App Proxy
 * @param bot {String} the bot ID
 * @param phone {String} the phone number
 * @returns {*|AxiosPromise}
 * @private
 */
const _configureWhatsAppProxy = (bot, phone) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }

  return Request.post(
    state.config.konecta + '/miscellaneous/configure-whatsapp-proxy',
    {
      destination: phone,
      bot
    },
    config
  )
}

const _getEntities = version => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.ENTITY + '?where[version]=' + version,
    config
  )
}

const _createEntity = entity => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(state.config.konecta + PATH.ENTITY, entity, config)
}

const _updateEntity = (entityId, entity) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.ENTITY + '/' + entityId,
    entity,
    config
  )
}

const _removeEntity = entityId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    state.config.konecta + PATH.ENTITY + '/' + entityId,
    config
  )
}

const _getApiCalls = version => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.API_CALL + '?where[version]=' + version,
    config
  )
}

const _createApiCall = apiCall => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(state.config.konecta + PATH.API_CALL, apiCall, config)
}

const _updateApiCall = (apiCallId, apiCall) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.API_CALL + '/' + apiCallId,
    apiCall,
    config
  )
}

const _removeApiCall = apiCallId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    state.config.konecta + PATH.API_CALL + '/' + apiCallId,
    config
  )
}

const _getDialogsUsing = (type, id) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.DIALOG_USING[type.toUpperCase()] + '/' + id,
    config
  )
}

/**
 * Upload a dialog to the server
 * @param {FormData} data the file that needs to be uploaded
 * @param {String} versionId the ID of the bots version
 * @param {String} serviceId the ID of the bots service version
 * @returns {Promise<Object>} that contains the URL of the file
 */
const _importEntity = (data, versionId, serviceId) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  }

  return Request.post(
    `${state.config.konecta}${PATH.BOT}/importEntity/${versionId}/${serviceId}`,
    data,
    config
  )
}

/**
 * Upload an intent to the server
 * @param {FormData} data the file that needs to be uploaded
 * @param {String} versionId the ID of the bot version's
 * @returns {Promise<Object>} that contains the URL of the file
 */
const _importIntent = (data, versionId) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  }

  return Request.post(
    `${state.config.konecta}${PATH.BOT}/importIntents/${versionId}`,
    data,
    config
  )
}

/**
 * Upload a dialog to the server
 * @param {FormData} data the file that needs to be uploaded
 * @param {String} versionId the ID of the bots version
 * @param {String} serviceId the ID of the bots service version
 * @returns {Promise<Object>} that contains the URL of the file
 */
const _importDialog = (data, versionId, serviceId) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  }

  return Request.post(
    `${state.config.konecta}${PATH.BOT}/importDialog/${versionId}/${serviceId}`,
    data,
    config
  )
}

const _getResponseValidations = botId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.VALIDATION}?where[bot]=${botId}`,
    config
  )
}

const _saveBotValidation = (validation, versionId) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }
  const data = {
    askAgain: validation.askAgain,
    answer: validation.answer,
    bot: validation.bot,
    question: validation.question,
    service: {
      name: validation.service.name
    },
    positive: {
      actions: validation.positive.actions,
      text: validation.positive.text
    },
    negative: {
      actions: validation.negative.actions,
      text: validation.negative.text
    },
    version: versionId,
    replyWhileWaitingForAgent: validation.replyWhileWaitingForAgent,
  }

  return Request.post(`${state.config.konecta}${PATH.VALIDATION}`, data, config)
}

const _updateBotValidation = (validationId, validation) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }
  const data = {
    askAgain: validation.askAgain,
    answer: validation.answer,
    question: validation.question,
    service: {
      name: validation.service.name
    },
    positive: {
      actions: validation.positive.actions,
      text: validation.positive.text
    },
    negative: {
      actions: validation.negative.actions,
      text: validation.negative.text
    },
    replyWhileWaitingForAgent: validation.replyWhileWaitingForAgent,
    expiration: validation.expiration,
  }
  return Request.put(
    `${state.config.konecta}${PATH.VALIDATION}/${validationId}`,
    data,
    config
  )
}

const _setValidationApplyAll = (versionId, validationId) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }

  const data = {
    validation: validationId
  }

  return Request.put(
    `${state.config.konecta}${PATH.VERSION}/${versionId}${PATH.DEFAULT_VALIDATION}`,
    data,
    config
  )
}

const _setFacebookApiKey = (integration, apikey) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const data = {
    apikey: apikey
  }

  return Request.post(
    `${state.config.konecta}${PATH.INTEGRATION.FACEBOOK.APIKEY}/${integration}`,
    data,
    config
  )
}

const _getTemplates = (culture, category) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(state.config.konecta + PATH.TEMPLATES + `?where[culture]=${culture}&where[category]=${category}`, config)
}

const _addTemplateToBot = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    state.config.konecta + PATH.TEMPLATE_FORK,
    payload,
    config
  )
}

const _createTestGroup = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.post(
    state.config.konecta + PATH.TESTS.TEST_GROUP,
    payload,
    config
  )
}

const _updateTestGroup = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  let id = payload.id;
  delete payload.id;
  return Request.put(
    state.config.konecta + PATH.TESTS.TEST_GROUP + `/${id}`,
    payload,
    config
  )
}


const _getTestsGroup =  serviceId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.TESTS.TEST_GROUP + '?where[service]=' + serviceId,
    config
  )
}

const _getTestGroupCasesById = testGroupId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.TESTS.TEST_GROUP_CASE + '?where[testGroup]=' + testGroupId,
    config
  )
}

const _getTestCasesStepsById = testCaseId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.TESTS.TEST_GROUP_CASE_STEP + '?where[testGroupCase]=' + testCaseId,
    config
  )
}

const _createTestCase = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.post(
    state.config.konecta + PATH.TESTS.TEST_GROUP_CASE,
    payload,
    config
  )
}

const _updateTestCase = payload => {
  const stepId = payload.id;
  delete payload.id;
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.put(
    state.config.konecta + PATH.TESTS.TEST_GROUP_CASE + `/${stepId}`,
    payload,
    config
  )
}


const _updateTestCaseSteps = payload => {
  const stepId = payload._id;
  delete payload._id;
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.put(
    state.config.konecta + PATH.TESTS.TEST_GROUP_CASE + `/${stepId}/steps`,
    payload,
    config
  )
}

const _createTestStep = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.post(
    state.config.konecta + PATH.TESTS.TEST_GROUP_CASE_STEP,
    payload,
    config
  )
}

const _updateTestStep = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const id = payload._id;
  delete payload._id;
  return Request.put(
    state.config.konecta + PATH.TESTS.TEST_GROUP_CASE_STEP + `/${id}`,
    payload,
    config
  )
}

const _deleteTestStep = stepId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.delete(
    state.config.konecta + PATH.TESTS.TEST_GROUP_CASE_STEP + `/${stepId}`,
    config
  )
}

const _sendExcecution = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.post(
    state.config.konecta + PATH.TESTS.TEST_GROUP_EXECUTION,
    payload,
    config
  )
}

const _deleteTestGroup = testGroupId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.delete(
    state.config.konecta + PATH.TESTS.TEST_GROUP + `/${testGroupId}`,
    config
  )
}

const _deleteTestCase = testCaseId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.delete(
    state.config.konecta + PATH.TESTS.TEST_GROUP_CASE + `/${testCaseId}`,
    config
  )
}

export default {
  createApiCall: _createApiCall,
  createBot: _createBot,
  createDialog: _createDialog,
  createIntent: _createIntent,
  createUser: _createUser,
  createVersion: _createVersion,
  deleteBot: _deleteBot,
  deleteUser: _deleteUser,
  fileUpload: _fileUpload,
  getBotVersions: _getBotVersions,
  promoteBotVersion: _promoteBotVersion,
  promoteToTestBotVersion: _promoteToTestBotVersion,
  createBotVersion: _createBotVersion,
  deleteBotVersion: _deleteBotVersion,
  getActiveVersion: _getActiveVersion,
  getEntities: _getEntities,
  createEntity: _createEntity,
  updateEntity: _updateEntity,
  removeEntity: _removeEntity,
  getApiCalls: _getApiCalls,
  getBot: _getBot,
  getBots: _getBots,
  getDialogs: _getDialogs,
  getIntent: _getIntent,
  getIntentionsPerVersion: _getIntentionsPerVersion,
  getIntents: _getIntents,
  getUser: _getUser,
  getUsers: _getUsers,
  removeApiCall: _removeApiCall,
  removeDialog: _removeDialog,
  removeIntent: _removeIntent,
  getGeneratedExamples: _getGeneratedExamples,
  trainVersion: _trainVersion,
  updateApiCall: _updateApiCall,
  updateBot: _updateBot,
  updateDialog: _updateDialog,
  updateIntent: _updateIntent,
  updateUser: _updateUser,
  configureWhatsAppProxy: _configureWhatsAppProxy,
  getDialogsUsing: _getDialogsUsing,
  expireUserPassword: _expireUserPassword,
  importIntent: _importIntent,
  importDialog: _importDialog,
  importEntity: _importEntity,
  getResponseValidations: _getResponseValidations,
  saveBotValidation: _saveBotValidation,
  updateBotValidation: _updateBotValidation,
  setValidationApplyAll: _setValidationApplyAll,
  createOption: _createOption,
  updateOption: _updateOption,
  getOption: _getOption,
  deleteOption: _deleteOption,
  setFacebookApiKey: _setFacebookApiKey,
  getTemplates: _getTemplates,
  addTemplateToBot: _addTemplateToBot,
  createTestGroup: _createTestGroup,
  getTestsGroup: _getTestsGroup,
  getTestGroupCasesById: _getTestGroupCasesById,
  createTestCase: _createTestCase,
  getTestCasesStepsById: _getTestCasesStepsById,
  createTestStep: _createTestStep,
  updateTestStep: _updateTestStep,
  deleteTestStep: _deleteTestStep,
  updateTestCaseSteps: _updateTestCaseSteps,
  updateTestCase: _updateTestCase,
  updateTestGroup: _updateTestGroup,
  sendExcecution: _sendExcecution,
  deleteTestGroup: _deleteTestGroup,
  deleteTestCase: _deleteTestCase,
}
