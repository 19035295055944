import SurveyMetricsService from '../../services/surveyMetrics.service'
import Helper from '../Helper'

// TODO: refactor to constants
const SURVEY_CHART_TYPES = ['pie', 'bar', 'cluster']

const CHART_TYPE_BY_MODEL = {
  PieV1: 'pie',
  StackedV1: 'bar',
  ClusteringV1: 'cluster'
}

const MODEL_BY_CHART_TYPE = {
  pie: 'PieV1',
  bar: 'StackedV1',
  cluster: 'ClusteringV1'
}

function getChartsByType(type, service, surveyService) {
  return SurveyMetricsService.getChartsByType(type, service, surveyService)
}

const actions = {
  async getCharts({ commit }, filter) {
    const promises = []
    SURVEY_CHART_TYPES.forEach(type => {
      promises.push(getChartsByType(type, filter.service, filter.surveyService))
    })

    return Promise.all(promises)
      .then(responses => {
        responses.forEach(r => {
          const charts = r.data.map(chartData => {
            const type = CHART_TYPE_BY_MODEL[chartData.model]

            return {
              ...chartData,
              type: type
            }
          })
          commit('SET_CHARTS', charts)
        })
      })
      .catch(error => {
        throw error
      })
  },

  getChartsData({ commit }, data) {
    return SurveyMetricsService.getChartsData(data)
      .then(response => {
        const chartData = {
          chartId: data.chartId,
          chartType: data.chartType,
          data: response.data
        }

        commit('SET_CHARTS_DATA', Helper.parseChart(chartData))
      })
      .catch(error => {
        throw error
      })
  },

  getSurveyGroupOptions({ commit }, surveyId) {
    return SurveyMetricsService.getSurveyGroupOptions(surveyId)
      .then(response => {
        const payload = {
          surveyId,
          options: response.data.filter(option => option.key)
        }
        commit('SET_SURVEY_OPTIONS', payload)
      })
      .catch(error => {
        throw error
      })
  },

  getExtraFiltersOptions({ commit }, surveyId) {
    return SurveyMetricsService.getSurveyExtraOptions(surveyId)
      .then(response => {
        const payload = {
          surveyId,
          options: response.data
        }
        commit('SET_SURVEY_EXTRA_OPTIONS', payload)
      })
      .catch(error => {
        throw error
      })
  },

  createNewChart(context, newChart) {
    return SurveyMetricsService.createNewChart(newChart).catch(error => {
      throw error
    })
  },

  copyChart(context, charts) {
    const payload = {
      name: charts.name,
      source: { chartId: charts.id, type: MODEL_BY_CHART_TYPE[charts.type] },
      target: {
        botService: charts.botService,
        surveyService: charts.surveyService
      }
    }
    return SurveyMetricsService.copyChart(payload).catch(error => {
      throw error
    })
  },

  deleteChart(context, chart) {
    return SurveyMetricsService.deleteChart(chart).catch(error => {
      throw error
    })
  }
}

export default actions
