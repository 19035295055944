export default {
  title: {
    es: 'Pedidos de atención',
    en: 'Pending chats',
    pt: 'Pedidos de atendimento'
  },
  searchPlaceholder: {
    es: 'Buscar chats',
    en: 'Search chats',
    pt: 'Pesquisar chats'
  },
  startConversation: {
    es: 'Comenzar un chat',
    en: 'Start chat',
    pt: 'Iniciar um chat'
  },
  sending: {
    es: 'enviando...',
    en: 'sending...',
    pt: 'enviando...'
  },
  sidenav: {
    activeChats: {
      es: 'En atención',
      en: 'Active',
      pt: 'Em atendimento'
    },
    otherChats: {
      es: 'Pendientes',
      en: 'Pending',
      pt: 'Pendente'
    },
    bot: {
      title: {
        es: 'Bot',
        en: 'Bot',
        pt: 'Bot'
      },
      selectBot: {
        es: 'Seleccione un chatbot',
        en: 'Select a chatbot',
        pt: 'Selecione um chatbot'
      }
    },
    showMoreChats: {
      es: 'Mostrar más',
      en: 'Show more',
      pt: 'Mostrar mais'
    },
    dropdown: {
      chats: {
        es: 'Chats',
        en: 'Chats',
        pt: 'Chats'
      },
      attentionRequest: {
        es: 'Pedidos',
        en: 'Requests',
        pt: 'Pedidos'
      }
    },
    noMoreChats: {
      title: {
        es: 'Eso es todo!',
        en: 'TODO',
        pt: 'Isso é tudo!'
      },
      text: {
        es: 'Parece que no hay más chats que cargar',
        en: 'TODO',
        pt: 'Parece não haver mais chats para carregar'
      }
    },
    takenByAnotherUser: {
      title: {
        es: 'Chat tomado',
        en: 'Chat taken',
        pt: 'Chat realizado'
      },
      text: {
        es: 'El chat acaba de ser tomado por otro usuario.',
        en: 'The chat has just been taken by another user.',
        pt: 'O chat acaba de ser acessado por outro usuário.'
      }
    },
    filters: {
      es: 'Filtros',
      en: 'Filters',
      pt: 'Filtros'
    },
    date: {
      title: {
        es: 'Fecha',
        en: 'Date',
        pt: 'Data'
      },
      from: {
        es: 'Desde',
        en: 'From',
        pt: 'Desde'
      },
      to: {
        es: 'Hasta',
        en: 'To',
        pt: 'Até'
      }
    },
    contains: {
      es: 'Contiene el texto',
      en: 'Contains text',
      pt: 'Contém o texto'
    },
    status: {
      title: {
        es: 'Estado',
        en: 'Status',
        pt: 'Status'
      },
      options: {
        initial: {
          es: 'Bot',
          en: 'Bot',
          pt: 'Bot'
        },
        pending: {
          es: 'Pendiente',
          en: 'Pending',
          pt: 'Pendente'
        },
        attended: {
          es: 'En atención',
          en: 'Active',
          pt: 'Em atendimento'
        },
        released: {
          es: 'Liberado',
          en: 'Released',
          pt: 'Liberado'
        }
      }
    },
    platform: {
      title: {
        es: 'Plataforma',
        en: 'Platform',
        pt: 'Plataforma'
      },
      options: {
        web: {
          es: 'Web',
          en: 'Web',
          pt: 'Web'
        },
        whatsapp: {
          es: 'WhatsApp',
          en: 'WhatsApp',
          pt: 'WhatsApp'
        },
        facebook: {
          es: 'Facebook',
          en: 'Facebook',
          pt: 'Facebook'
        },
        signal: {
          es: 'Signal',
          en: 'Signal',
          pt: 'Signal'
        },
        rcs: {
          es: 'RCS',
          en: 'RCS',
          pt: 'RCS'
        },
        twitter: {
          es: 'Twitter',
          en: 'Twitter',
          pt: 'Twitter'
        },
        telegram: {
          es: 'Telegram',
          en: 'Telegram',
          pt: 'Telegram'
        }
      }
    },
    selected: {
      es: 'Seleccionado',
      en: 'Selected',
      pt: 'Selecionado'
    },
    buttons: {
      export: {
        es: 'Exportar',
        en: 'Export',
        pt: 'Exportar'
      },
      exporting: {
        es: 'Exportando datos...',
        en: 'Exporting data...',
        pt: 'Exportando dados...'
      },
      exportText: {
        es: 'Esta operación puede demorar unos minutos.',
        en: 'This operation may take a few minutes.',
        pt: 'Esta operação pode levar alguns minutos.'
      },
      apply: {
        es: 'Aplicar',
        en: 'Apply',
        pt: 'Aplicar'
      }
    },
    chats: {
      es: 'Chats',
      en: 'Chats',
      pt: 'Chats'
    },
    anonymousUser: {
      es: 'Usuario Anónimo',
      en: 'Anonymous User',
      pt: 'Usuário Anônimo'
    },
    categoryFilter: {
      placeholder: {
        es: 'Filtrar por estado del chat',
        en: 'Filter by chat status',
        pt: 'Filtrar por status de chat'
      },
      options: {
        all: {
          es: 'Todos',
          en: 'All',
          pt: 'Todos'
        },
        released: {
          es: 'Liberados',
          en: 'Released',
          pt: 'Liberados'
        },
        sortedByPriority: {
          es: 'Ordenados por Prioridad',
          en: 'Sorted by Priority',
          pt: 'Ordenados por Prioridade'
        },
        xThreshold: {
          pre: {
            es: 'Entre',
            en: 'Between',
            pt: 'Entre'
          },
          and: {
            es: 'y',
            en: 'and',
            pt: 'e'
          },
          post: {
            es: 'min.',
            en: 'min.',
            pt: 'min.'
          }
        },
        yThreshold: {
          pre: {
            es: '>',
            en: '>',
            pt: '>'
          },
          post: {
            es: 'minutos',
            en: 'minutes',
            pt: 'minutos'
          }
        },
        inAttention: {
          es: 'En atención',
          en: 'Active',
          pt: 'Em atendimento'
        }
      }
    }
  },
  chatForm: {
    messageInputPlaceholder: {
      es: 'Escribe un mensaje',
      en: 'Write a message',
      pt: 'Escreva uma mensagem'
    },
    sendMessage: {
      es: 'Enviar',
      en: 'Send',
      pt: 'Enviar'
    },
    sendingMessage: {
      es: 'Enviando',
      en: 'Sending',
      pt: 'Enviando'
    },
    msgError: {
      es: 'Ocurrió un error al enviar el mensaje.',
      en: 'It seems that an error occurred.',
      pt: 'Um erro ocorreu ao enviar esta mensagem.'
    },
    bucketError: {
      es: 'Ocurrió un error al subir el archivo.',
      en: 'It seems that an error occurred.',
      pt: 'Ocorreu um erro ao enviar o arquivo.'
    }
  },
  chatStatus: [
    {
      es: 'Bot',
      en: 'Bot',
      pt: 'Bot'
    },
    {
      es: 'Pendiente',
      en: 'Pending',
      pt: 'Pendente'
    },
    {
      es: 'En atención',
      en: 'Active',
      pt: 'Em atendimento'
    },
    {
      es: 'Liberado',
      en: 'Released',
      pt: 'Liberado'
    }
  ],
  specialEvents: [
    {
      es: 'Chatbot detenido',
      en: 'Chatbot disengaged',
      pt: 'Chatbot parado'
    },
    {
      es: 'Chatbot iniciado',
      en: 'Chatbot engaged',
      pt: 'Chatbot iniciado'
    }
  ],
  showMoreMessage: {
    yes: {
      es: 'Mostrar más',
      en: 'Show more',
      pt: 'Mostrar mais'
    },
    not: {
      es: 'No hay más mensajes por mostrar',
      en: 'No more messages',
      pt: 'Não há mais mensagens para exibir'
    }
  },
  navbar: {
    maximize: {
      on: {
        es: 'Maximizar',
        en: 'Maximize',
        pt: 'Maximizar'
      },
      off: {
        es: 'Minimizar',
        en: 'Minimize',
        pt: 'Minimizar'
      }
    },
    export: {
      es: 'Exportar chat',
      en: 'Export chat',
      pt: 'Exportar chat'
    },
    transfer: {
      es: 'Liberar chat',
      en: 'Release chat',
      pt: 'Liberar chat'
    },
    expropriate: {
      es: 'Expropiar chat',
      en: 'Expropriate chat',
      pt: 'Expropiar chat'
    },
    using: {
      es: 'Este chat está tomado por el usuario XXX email <YYY>',
      en: 'This chat is taken by user XXX with email <YYY>',
      pt: 'Este chat é feito pelo usuário XXX com e-mail <YYY>'
    },
    switch: {
      title: {
        active: {
          es: 'Desactivar chatbot',
          en: 'Disengage chatbot',
          pt: 'Desativar o chatbot'
        },
        inactive: {
          es: 'Activar chatbot',
          en: 'Engage chatbot',
          pt: 'Ativar o chatbot'
        },
        whatsappLastUserMessageRestriction: {
          es: 'Han transcurrido más de 24 horas desde el último mensaje del usuario',
          en: 'More than 24 hours have passed since the user\'s last message',
          pt: 'Mais de 24 horas se passaram desde a última mensagem do usuário'
        }
      },
      chip: {
        active: {
          es: 'Chatbot activo',
          en: 'Chatbot engaged',
          pt: 'Chatbot ativo'
        },
        inactive: {
          es: 'Chatbot inactivo',
          en: 'Chatbot disengaged',
          pt: 'Chatbot inativo'
        }
      }
    },
    changeView: {
      es: 'Ver en panel de atención',
      en: 'See active panel',
      pt: 'Veja o painel ativo'
    },
    copyView: {
      es: 'Copiar URL',
      en: 'Copy URL',
      pt: 'Copiar URL'
    },
    copySuccess: {
      title: {
        es: 'Éxito!',
        en: 'Success!',
        pt: 'Sucesso!'
      },
      text: {
        es: 'La URL está disponible en el portapapeles',
        en: 'The URL is available in clipboard',
        pt: 'O URL está disponível no clipboard'
      }
    },
    editChatName: {
      es: 'Editar nombre',
      en: 'Edit name',
      pt: 'Editar nome'
    },
    takeovers: {
      es: 'Tomas de control',
      en: 'Takeovers',
      pt: 'Aquisições'
    }
  },
  suggestions: {
    es: 'Sugerencias para ',
    en: 'Suggestions for ',
    pt: 'Sugestões para'
  },
  hsmMessage: {
    es: 'Mensaje HSM',
    en: 'HSM Message',
    pt: 'Mensagem HSM'
  },
  forbbidenTitle: {
    es: 'No autorizado',
    en: 'Unauthorized',
    pt: 'Não autorizado'
  },
  forbbidenMessage: {
    es: 'No tienes acceso a los chats de este bot.',
    en: 'You do not have access to the chats of this bot.',
    pt: 'Você não tem acesso às conversas desse bot.'
  },
  location: {
    es: 'Ubicación',
    en: 'Location',
    pt: 'Localização'
  },
  confirmTakeover: {
    title: {
      es: 'Atención!',
      en: 'Warning!',
      pt: 'Aviso!'
    },
    text: {
      es: '¿Está seguro que desea reactivar el chatbot?',
      en: 'Are you sure you want to reactivate the chatbot?',
      pt: 'Tem certeza que quer reativar o chatbot?'
    },
    acceptText: {
      es: 'Activar chatbot',
      en: 'Active chatbot',
      pt: 'Chatbot ativo'
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar'
    }
  },
  errorSwitchTakeover: {
    es: 'Ha ocurrido un error al tomar el chat.',
    en: 'An error occurred while taking the chat.',
    pt: 'Ocorreu um erro ao acessar o chat.'
  },
  newMessage: {
    es: 'Nuevo mensaje',
    en: 'New message',
    pt: 'Nova mensagem'
  },
  newChat: {
    es: 'Nuevo chat',
    en: 'New chat',
    pt: 'Novo chat'
  },
  says: {
    es: 'dice',
    en: 'says',
    pt: 'diz'
  },
  userNotAllowed: {
    es:
      'Este chat se encuentra activo por el usuario XXX <YYY>. En caso que desee tomarlo, haga click en "Expropiar chat" de la barra superior o solicite ayuda a un supervisor.',
    en:
      'This chat is active by user XXX <YYY>. In case you want to take it, click on "Expropriate chat" of navigation bar or ask a supervisor for help.',
    pt:
      'Este chat é ativo pelo usuário XXX <YYY>. Caso você queira pegá-lo, clique em "Expropriar chat" da barra de navegação ou peça ajuda a um supervisor.'
  },
  errorGetPeople: {
    es:
      'Ha ocurrido un error al obtener los chats. Por favor, intente nuevamente.',
    en: 'An error has ocurred while getting chats. Please, try again.',
    pt: 'Ocorreu um erro ao obter os chats. Por favor, tente de novo.'
  },
  errorExport: {
    es:
      'Ha ocurrido un error al exportar chats. Por favor, intente nuevamente.',
    en: 'An error has ocurred while exporting chats. Please, try again.',
    pt:
      'Ocorreu um erro durante a exportação de chats. Por favor, tente de novo.'
  },
  resultsFoundFor: {
    es: 'resultados encontrados para',
    en: 'results found for',
    pt: 'resultados encontrados para'
  },
  allChatsShown: {
    es: 'No hay mas chats para mostrar',
    en: 'No more chats to show',
    pt: 'Não há mais chats para mostrar'
  },
  takeover : {
    started:{
      es: 'Iniciado',
      en: 'Started',
      pt: 'Iniciado'
    },
    ended:{
      es: 'Finalizado',
      en: 'Ended',
      pt: 'Finalizado'
    },
    duration: {
      es: 'Duración',
      en: 'Duration',
      pt: 'Duração'
    }
  }
}
