import Vue from 'vue'
import CredentialsService from '../../services/credentials.service'

const actions = {
  GET_NLU({ state }) {
    return CredentialsService.getNLU()
      .then(response => {
        state.nlu = response.data
        Vue.$log.info(state.nlu)
      })
      .catch(error => error)
  },

  CREATE_NLU({ commit }, message) {
    return CredentialsService.createCredential(message.body)
      .then(response => {
        commit('CREATE_NLU', response.data)
        return response.data
      })
      .catch(error => {
        Vue.$log.error(error)
        throw error
      })
  },

  UPDATE_NLU({ commit }, message) {
    return CredentialsService.updateCredential(message._id, message.body)
      .then(response => {
        commit('UPDATE_NLU', response.data)
        return response.data
      })
      .catch(error => {
        Vue.$log.error(error)
        throw error
      })
  },

  DELETE_NLU({ commit }, message) {
    return CredentialsService.deleteCredential(message._id)
      .then(response => {
        commit('DELETE_NLU', response.data)
        return response.data
      })
      .catch(error => {
        Vue.$log.error(error)
        throw error
      })
  }
}

export default actions
