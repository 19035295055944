function loadGTMScripts() {
  window.dataLayer = window.dataLayer || []
  function gtag() {
    // eslint-disable-next-line no-undef
    dataLayer.push(arguments)
  }
  gtag('js', new Date())

  gtag('config', 'UA-168730269-2')
}

if (process.env.NODE_ENV === 'production') {
  try {
    console.info('Loading GTM...')
    loadGTMScripts()
    console.info('GTM loaded successfully.')
  } catch (error) {
    console.error('Failed to load GTM: ', error)
  }
}
