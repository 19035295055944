import { themeConfig, themeColors } from '@/../themeConfig.js'
import { HSM } from '@/constants/constants'

const session = localStorage.session && JSON.parse(localStorage.session)

export function appActiveUserFactory() {
  return {
    id: 0,
    name: 'John Doe',
    about:
      'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    img: 'avatar-s-11.png',
    status: 'online'
  }
}

export function companyLogoFactory() {
  return session && session.theme && session.theme.companyLogo
    ? session.theme.companyLogo
    : ''
}

export function themeFactory() {
  return session && session.theme && session.theme.name !== ''
    ? session.theme.name
    : themeConfig.theme
}

export function themeColorsFactory() {
  return session && session.theme && session.theme.themeColors
    ? session.theme.themeColors
    : themeColors
}

export function themePrimaryColorFactory() {
  return session && session.theme && session.theme.themeColors
    ? session.theme.themeColors.primary
    : themeColors.primary
}

export function languagesFactory() {
  return [
    {
      title: 'Español',
      code: 'es'
    },
    {
      title: 'English',
      code: 'en'
    },
    {
      title: 'Português',
      code: 'pt'
    }
  ]
}

export function pluginsFactory() {
  return {
    KBASE: {
      active: false,
      data: {
        activeModal: false
      }
    }
  }
}

export function conditionsFactory() {
  return {
    types: [],
    operators: [],
    values: [],
    values2: [],
    cvTypes: []
  }
}

export function audienceFactory() {
  return {
    name: '',
    platform: 'whatsapp',
    audienceItems: []
    // service: '',
    // bot: '',
    // version: ''
  }
}

export function pushFactory() {
  return {
    name: null,
    // message: {
    //   text: ''
    // },
    hsm: {
      elementName: '',
      languageCode: HSM.languageCode,
      parameters: [],
      namespace: '',
      type: 'HSM',
      campaignAlias: undefined,
      header: {
        image: {}
      }
    },
    audiences: [
      {
        audience: '',
        integration: ''
      }
    ],
    schedule: null,
    timeOffset: null
  }
}

export function channelsFactory() {
  return [
    {
      type: 'web',
      name: 'Konecta',
      active: true,
      enabled: false
    },
    // {
    //   type: 'facebook',
    //   name: 'Facebook Messenger',
    //   active: false,
    //   enabled: true
    // },
    // {
    //   type: 'whatsapp',
    //   name: 'WhatsApp',
    //   active: false,
    //   enabled: true
    // },
    // {
    //   type: 'twitter',
    //   name: 'Twitter',
    //   active: false,
    //   enabled: true
    // },
    // {
    //   type: 'alexa',
    //   name: 'Amazon Alexa',
    //   active: false,
    //   enabled: true
    // },
    // {
    //   type: 'voice',
    //   name: 'Voice',
    //   active: false,
    //   enabled: true
    // },
    // {
    //   type: 'signal',
    //   name: 'Signal',
    //   active: false,
    //   enabled: true
    // },
    // {
    //   type: 'telegram',
    //   name: 'Telegram',
    //   active: false,
    //   enabled: true
    // },
    // {
    //   type: 'rcs',
    //   name: 'RCS',
    //   active: false,
    //   enabled: true
    // },
    // {
    //   type: 'slack',
    //   name: 'Slack',
    //   active: false,
    //   enabled: true
    // },
    // {
    //   type: 'workplace',
    //   name: 'Facebook Workplace',
    //   active: false,
    //   enabled: true
    // },
    // {
    //   type: 'skype',
    //   name: 'Skype',
    //   active: false,
    //   enabled: true
    // },
    // {
    //   type: 'google_assistant',
    //   name: 'Google Assistant',
    //   active: false,
    //   enabled: true
    // }
  ]
}

export function sendSurveySettingsFactory() {
  return {
    sendSurveySettings: {
      surveyEnabled: false,
      survey: '',
      surveyConfirmation: {},
      surveyHsm: {}
    }
  }
}

export function chatTransitionSettingsFactory() {
  return {
    transfer: {
      tagging: 'disable',
      taggingType: 'single',
      tags: [],
      comment: 'disable',
      transitionMessage: 'disable',
      defaultTransitionMessage: ''
    },
    finish: {
      tagging: 'disable',
      taggingType: 'single',
      tags: [],
      comment: 'disable',
      transitionMessage: 'disable',
      defaultTransitionMessage: ''
    }
  }
}

export function chatGeneralSettingsFactory() {
  return {
    releasedPriority: 0,
    xThreshold: 0,
    xyPriority: 0,
    yThreshold: 0,
    yzPriority: 0
  }
}

export function botMessageValidationFactory() {
  return {
    askAgain: 2,
    service: {
      name: ''
    },
    answer: 'buttons', // handled in backend
    bot: null, //botId
    question: '',
    positive: {
      text: 'Sí',
      actions: [
        {
          key: '',
          args: []
        }
      ]
    },
    negative: {
      text: 'No',
      actions: [
        {
          key: '',
          args: []
        }
      ]
    },
    replyWhileWaitingForAgent: false,
    expiration: {
      enabled: false,
      expirationTime: 60*24,
    }
  }
}

export function botTimeoutMessageValidationFactory() {
  return {
    askAgain: 2,
    trigger: {
      threshold: 60
    },
    type: 'longStandingTakeover',
    service: {
      name: ''
    },
    answer: 'buttons', // handled in backend
    bot: null, //botId
    question: '',
    positive: {
      text: 'Sí',
      actions: [
        {
          key: 'stopTakeover',
          args: []
        }
      ]
    },
    negative: {
      text: 'No',
      actions: [
        {
          key: 'stopTakeover',
          args: []
        }
      ]
    },
    replyWhileWaitingForAgent: false,
  }
}

export function negativeSentimentOptionFactory() {
  return {
    askAgain: 2,
    trigger: {
      cant: 1,
      sentimentLessThan: 3
    },
    type: 'negativeSentiment',
    service: {
      name: 'negativeSentiment'
    },
    answer: 'buttons', // handled in backend
    bot: null, //botId
    question: 'empty',
    positive: {
      text: 'Sí',
      actions: [
        {
          key: 'responseText',
          args: ['']
        }
      ]
    },
    negative: {
      text: 'No',
      actions: [
        {
          key: 'responseText',
          args: []
        }
      ]
    },
    replyWhileWaitingForAgent: false
  }
}
