export default {
  title: {
    es: 'Ayuda',
    en: 'Help',
    pt: 'Ajuda',
  },
  issue: {
    report: {
      es: 'Reportar un Error',
      en: 'Report an Issue',
      pt: 'Reporte um erro',
    },
  },
}