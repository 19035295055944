import Vue from 'vue'
import { User } from '../../models/User'
import { Intent } from '../../models/Intent'
import { generateBot } from '../../models/BotGenerator'
import BotMakerService from '../../services/botMaker.service'
import { PLATFORMS } from '@/constants/constants'
import Validations from '../Validations'
import botMaker from '../../languages/botMaker'
import {
  botTimeoutMessageValidationFactory,
  negativeSentimentOptionFactory
} from '../factories'

const actions = {
  async GET_BOTS({ commit }) {
    Vue.$log.info()

    try {
      let botsResponse = await BotMakerService.getBots()
      let bots = []
      for (let i = 0; i < botsResponse.data.length; i++) {
        let bot = botsResponse.data[i]
        bots.push(generateBot(bot))
      }

      const sortedBots = Vue._.orderBy(bots, 'createdAt', 'desc')
      commit('SET_BOTS', sortedBots)
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  // Users
  LOAD_USERS: ({ commit }) => {
    return BotMakerService.getUsers()
      .then(response => {
        let users = response.data.map(u => {
          return new User(
            u.name,
            u.email,
            u.password,
            u.roles,
            u.company,
            u._id
          )
        })
        commit('SET_USERS', users)
        return users
      })
      .catch(error => {
        Vue.$log.error(error)
        return error
      })
  },
  LOAD_AGENTS: ({ commit }, useStaffManagement) => {
    const path = useStaffManagement ? '/agents' : '?where[roles][$in]=5cd08f88e9d215006fff7716&where[roles][$in]=5cd08f88e9d215006fff7716&where[roles][$nin]=5cd9a8f9f99a38006e7b8b1c&where[roles][$nin]=5cd09eb2e9d215006fff774b'
    return BotMakerService.getUsers(path)
      .then(response => {
        let users = response.data.map(u => {
          console.log('NEW USER 3')
          return new User(
            u.name,
            u.email,
            u.password,
            u.roles,
            u.company,
            u._id
          )
        })
        commit('SET_USERS', users)
        return users
      })
      .catch(error => {
        Vue.$log.error(error)
        return error
      })
  },
  REMOVE_USER({ state, commit }, userEmail) {
    let user = Vue._.find(state.bot.service.users, u => {
      return u.email === userEmail
    })

    return BotMakerService.deleteUser(user.id)
      .then(response => {
        commit('removeUser', userEmail)
        return response.data
      })
      .catch(error => {
        Vue.$log.error(error)
        return error
      })
  },

  // Bot
  DELETE_BOT({ dispatch }, botId) {
    Vue.$log.info(botId)
    return BotMakerService.deleteBot(botId)
      .then(() => {
        return dispatch('GET_BOTS')
      })
      .catch(error => {
        return error
      })
  },

  SAVE_TIMEOUT_VALIDATION({ state }, botId) {
    try {
      if (state.hasTimeoutValidation) {
        let optionToSave = state.botTimeoutMessageValidation
        if (optionToSave.negative.actions[0].key === 'stopTakeoverAndJump') {
          optionToSave.negative.actions[1] = {
            key: 'jump',
            args: [optionToSave.negative.actions[0].args[0]]
          }
          optionToSave.negative.actions[0].args = []
          optionToSave.negative.actions[0].key = 'stopTakeover'
        }
        if (optionToSave.positive.actions[0].key === 'stopTakeoverAndJump') {
          optionToSave.positive.actions[1] = {
            key: 'jump',
            args: [optionToSave.positive.actions[0].args[0]]
          }
          optionToSave.positive.actions[0].args = []
          optionToSave.positive.actions[0].key = 'stopTakeover'
        }
        if (optionToSave.negative.actions[0].key === 'stopTakeoverAndText') {
          optionToSave.negative.actions[1] = {
            key: 'responseText',
            args: [optionToSave.negative.actions[0].args[0]]
          }
          optionToSave.negative.actions[0].args = []
          optionToSave.negative.actions[0].key = 'stopTakeover'
        }
        if (optionToSave.positive.actions[0].key === 'stopTakeoverAndText') {
          optionToSave.positive.actions[1] = {
            key: 'responseText',
            args: [optionToSave.positive.actions[0].args[0]]
          }
          optionToSave.positive.actions[0].args = []
          optionToSave.positive.actions[0].key = 'stopTakeover'
        }
        optionToSave.trigger.threshold =
          optionToSave.trigger.threshold * 60 * 1000
        if (optionToSave._id) {
          let id = optionToSave._id
          delete optionToSave._id
          delete optionToSave.data
          delete optionToSave.company
          delete optionToSave.bot
          delete optionToSave.type
          delete optionToSave._createdAt
          delete optionToSave._updatedAt
          delete optionToSave.__v
          delete optionToSave.positive._id
          delete optionToSave.negative._id
          optionToSave.service = {
            name: optionToSave.service.name
          }
          return BotMakerService.updateOption(optionToSave, id)
        } else {
          optionToSave.bot = botId
          optionToSave.type = 'longStandingTakeover'
          return BotMakerService.createOption(optionToSave)
        }
      } else {
        if (state.botTimeoutMessageValidation._id) {
          return BotMakerService.deleteOption(
            state.botTimeoutMessageValidation._id
          )
            .then(() => {
              state.botTimeoutMessageValidation = botTimeoutMessageValidationFactory()
              state.hasTimeoutValidation = false
              return
            })
            .catch(error => {
              return error
            })
        }
      }
    } catch (error) {
      // TODO: Refactor axios error response https://github.com/axios/axios/issues/960#issuecomment-320659373
      throw error.response.data[0]
    }
  },

  async SAVE_NEGATIVE_OPTION({ state }, botId) {
    try {
      state.negativeSentimentOption.trigger.cant = parseInt(
        state.negativeSentimentOption.trigger.cant
      )
      state.negativeSentimentOption.trigger.sentimentLessThan = parseInt(
        state.negativeSentimentOption.trigger.sentimentLessThan
      )
      if (state.hasNegativeSentimentOption) {
        let optionToSave = state.negativeSentimentOption
        if (optionToSave._id) {
          let id = optionToSave._id
          delete optionToSave._id
          delete optionToSave.data
          delete optionToSave.company
          delete optionToSave.bot
          delete optionToSave.type
          delete optionToSave._createdAt
          delete optionToSave._updatedAt
          delete optionToSave.__v
          delete optionToSave.positive._id
          delete optionToSave.negative._id
          optionToSave.service = {
            name: optionToSave.service.name
          }
          return BotMakerService.updateOption(optionToSave, id)
        } else {
          optionToSave.bot = botId
          optionToSave.type = 'negativeSentiment'
          return BotMakerService.createOption(optionToSave)
        }
      } else {
        if (state.negativeSentimentOption._id) {
          return BotMakerService.deleteOption(state.negativeSentimentOption._id)
            .then(() => {
              state.negativeSentimentOption = negativeSentimentOptionFactory()
              state.hasNegativeSentimentOption = false
              return
            })
            .catch(error => {
              return error
            })
        }
      }
    } catch (error) {
      // TODO: Refactor axios error response https://github.com/axios/axios/issues/960#issuecomment-320659373
      throw error.response.data[0]
    }
  },

  async GET_TIMEOUT_VALIDATION({ state }, botId) {
    try {
      const response = await BotMakerService.getOption(
        botId,
        'longStandingTakeover'
      )
      let option = response.data
      if (option.length > 0) {
        let optionToSave = option[0]
        if (
          optionToSave.negative.actions.length > 1 &&
          optionToSave.negative.actions[1].key === 'jump'
        ) {
          optionToSave.negative.actions = [
            {
              name: option[0].negative.actions[0].name,
              key: 'stopTakeoverAndJump',
              args: [option[0].negative.actions[1].args[0]]
            }
          ]
        }
        if (
          optionToSave.positive.actions.length > 1 &&
          optionToSave.positive.actions[1].key === 'jump'
        ) {
          optionToSave.positive.actions = [
            {
              name: option[0].positive.actions[0].name,
              key: 'stopTakeoverAndJump',
              args: [option[0].positive.actions[1].args[0]]
            }
          ]
        }
        if (
          optionToSave.negative.actions.length > 1 &&
          optionToSave.negative.actions[1].key === 'responseText'
        ) {
          optionToSave.negative.actions = [
            {
              name: option[0].negative.actions[0].name,
              key: 'stopTakeoverAndText',
              args: [option[0].negative.actions[1].args[0]]
            }
          ]
        }
        if (
          optionToSave.positive.actions.length > 1 &&
          optionToSave.positive.actions[1].key === 'responseText'
        ) {
          optionToSave.positive.actions = [
            {
              name: option[0].positive.actions[0].name,
              key: 'stopTakeoverAndText',
              args: [option[0].positive.actions[1].args[0]]
            }
          ]
        }
        optionToSave.trigger.threshold = option[0].trigger.threshold / 60 / 1000
        state.botTimeoutMessageValidation = optionToSave
        state.hasTimeoutValidation = true
      }
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async GET_NEGATIVE_OPTION({ state }, botId) {
    try {
      const response = await BotMakerService.getOption(
        botId,
        'negativeSentiment'
      )
      let option = response.data
      if (option.length > 0) {
        state.negativeSentimentOption = option[0]
        state.hasNegativeSentimentOption = true
      } else {
        state.negativeSentimentOption.positive.actions[0].args[0] =
          botMaker.addBot.engineSetting.negativeSentimentAction.firstMessage[
            state.bot.nlu.culture.split('-')[0]
          ]
      }
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  VALIDATE_NEGATIVE_OPTION({ state }) {
    if (state.hasNegativeSentimentOption) {
      if (
        Validations.isEmpty(
          state.negativeSentimentOption.trigger.sentimentLessThan
        ) ||
        parseInt(state.negativeSentimentOption.trigger.sentimentLessThan) > 5 ||
        parseInt(state.negativeSentimentOption.trigger.sentimentLessThan) < 1
      ) {
        state.validations.negativeSentiment.trigger.sentimentLessThan = true
      } else {
        state.validations.negativeSentiment.trigger.sentimentLessThan = false
      }
      if (
        Validations.isEmpty(state.negativeSentimentOption.trigger.cant) ||
        parseInt(state.negativeSentimentOption.trigger.cant) < 1
      ) {
        state.validations.negativeSentiment.trigger.cant = true
      } else {
        state.validations.negativeSentiment.trigger.cant = false
      }
      if (
        Validations.isEmpty(
          state.negativeSentimentOption.positive.actions[0].args[0]
        )
      ) {
        state.validations.negativeSentiment.message = true
      } else {
        state.validations.negativeSentiment.message = false
      }
    }
  },

  // VERSIONS
  GET_ACTIVE_VERSION_BOTS({ state }, [serviceId, queryVersion]) {
    return BotMakerService.getActiveVersion(serviceId)
      .then(response => {
        if (response.data.length > 0) {
          let version = response.data[0]._id
          if (queryVersion) {
            state.bot.activeVersion = queryVersion
          } else {
            state.bot.activeVersion = version
          }
          return version
        } else {
          return undefined
        }
      })
      .catch(error => {
        console.error(error)
        return error
      })
  },

  // INTENTS
  async GET_VERSION_INTENTS({ state }, versionId) {
    try {
      // Get Dialogs for bot responses
      let dialogsResponse = await BotMakerService.getDialogs(versionId)
      let intentionsPerVersionResponse = await BotMakerService.getIntentionsPerVersion(
        versionId
      )
      let versionIntentions = intentionsPerVersionResponse.data
      let dialogs = dialogsResponse.data

      // TODO: Sonar: This assign seems to be bad
      return (state.bot.intents = versionIntentions.map(i => {
        let derivesToAgent =
          state.bot.rawIntents &&
          state.bot.rawIntents.includes(i.name.toLowerCase())

        let dialog = Vue._.find(dialogs, d => {
          return d.condition.params.intent === i.name
        })

        const intent = state.bot.intentsByPlatform.find(
          ibp => ibp.intent === i.name
        )

        const intentsByPlatform = intent
          ? intent.platforms.map(ibp => PLATFORMS.find(p => p.value === ibp))
          : []

        return new Intent(
          i.name,
          i.examples,
          derivesToAgent,
          i._id,
          dialog ? dialog._id : undefined,
          intentsByPlatform
        )
      }))
    } catch (error) {
      return await Promise.reject(error)
    }
  },
  async SAVE_INTENTS({ state }, intents) {
    try {
      let intentions = intents ? intents : state.bot.intents
      let versionId = state.bot.activeVersion

      for (let i = 0; i < intentions.length; i++) {
        let intention = intentions[i]

        // If the intent was not saved, create a new one for the current version
        await BotMakerService.createIntent(
          versionId,
          intention.name,
          intention.examples
        )

        if (intention.derivesToAgent) {
          const dialog = state.bot.generateDialogToSave(intention.name)
          dialog.execTakeover = true
          await BotMakerService.createDialog(dialog)
        }
      }
    } catch (e) {
      Vue.$log.error(e)
      return await Promise.reject(e)
    }
  },

  async UPDATE_INTENT(context, intent) {
    try {
      await BotMakerService.updateIntent(intent.id, {
        name: intent.name,
        examples: intent.examples
      })

      //await dispatch('GET_VERSION_INTENTS', state.bot.activeVersion)
    } catch (error) {
      // TODO: Refactor axios error response https://github.com/axios/axios/issues/960#issuecomment-320659373
      throw error.response.data[0]
    }
  },

  async SET_INTENT_DERIVATE({ state }, intent) {
    state.bot.intents.filter((el, index) => {
      if (el.id == intent.id) {
        state.bot.intents[index].name = intent.name
        state.bot.intents[index].derivesToAgent = intent.derivesToAgent
        state.bot.intents[index].examples = intent.examples
      }
      return el
    })
  },

  async SAVE_INTENT({ state, dispatch }, intentPayload) {
    try {
      const activeVersion = intentPayload.version
        ? intentPayload.version
        : state.bot.activeVersion

      await BotMakerService.createIntent(
        activeVersion,
        intentPayload.intent.name,
        intentPayload.intent.examples
      )

      await dispatch('GET_VERSION_INTENTS', activeVersion)
    } catch (error) {
      // TODO: Refactor axios error response https://github.com/axios/axios/issues/960#issuecomment-320659373
      throw error.response.data[0]
    }
  },

  // async DELETE_INTENT({ state, dispatch }, intentId) {
  async DELETE_INTENT({ state, dispatch }, { intentId, versionId }) {
    // eslint-disable-next-line no-useless-catch
    try {
      await BotMakerService.removeIntent(intentId)
      await dispatch(
        'GET_VERSION_INTENTS',
        versionId ? versionId : state.bot.activeVersion
      )
    } catch (error) {
      throw error
    }
  },

  // DIALOGS
  SAVE_DIALOG({ state }, intent) {
    const dialog = state.bot.generateDialogToSave(intent.name)
    return BotMakerService.createDialog(dialog)
      .then(response => {
        intent.dialogId = response.data._id
        return response.data
      })
      .catch(error => {
        return error
      })
  }
}

export default actions
