import Request from './request'
import state from '../store/state'
import axios from 'axios'
import timeZones from "../constants/_TIME_ZONES";
const CancelToken = axios.CancelToken

const PATH = {
  REPORT_DATA: '/report/query'
}

function getTimeZone() {
  const index = timeZones.findIndex(
      item =>
          item.utc &&
          item.utc.indexOf(Intl.DateTimeFormat().resolvedOptions().timeZone) > -1
  )
  if (index > -1 && timeZones[index]) {
    return timeZones[index].text
  } else {
    const offset = (new Date().getTimezoneOffset() / 60) * -1
    const element = timeZones.find(e => e.offset === offset)
    if (element) {
      return element.text
    }
  }
  return undefined
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const _getReportData = (reportId, payload, executor) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'X-Time-Zone': getTimeZone()
    },
    cancelToken: new CancelToken(executor)
  }

  return Request.post(
    `${state.config.konecta}${PATH.REPORT_DATA}/${reportId}`,
    payload,
    config
  )
}

export default {
  getReportData: _getReportData
}
