const getters = {
  // audiences
  audiences: state => {
    return state.audiences
  },
  audience: state => {
    return state.audience
  },
  platform: state => {
    return state.audience.platform
  },

  // push
  hsm: state => {
    return state.push.hsm
  },
  integration: state => {
    return state.push.audiences[0].integration
  },
  schedule: state => {
    return state.push.schedule
  }
}

export default getters
