export default {
  navbar: {
    es: 'Logs Stream',
    en: 'Logs stream',
    pt: 'Logs stream',
  },
  readyMessage: {
    es: 'Listo\nEsperando por eventos entrantes\n.',
    en: 'Ready\nWaiting for incoming events\n.',
    pt: 'Pronto\nEsperando por eventos recebidos\n.',
  },
}