import { MessageType } from '../MessageType'
import BotMakerService from '../../../services/botMaker.service'

export class MediaMessageType extends MessageType {
  constructor(
    name,
    fileData,
    fileUrl,
    fileType,
    platforms = ['all'],
    id = null,
    responseId,
    beforeAPICall
  ) {
    super(name, responseId)
    this.fileData = fileData
    this.fileUrl = fileUrl
    this.fileFormat = fileType
    this.platforms = platforms
    this._id = id
    this.beforeAPICall = beforeAPICall
  }

  getType() {
    return 'media'
  }

  isTextMessage() {
    return false
  }
  isRegexMessage() {
    return false
  }
  isImageMessage() {
    return false
  }

  isMediaMessage() {
    return true
  }

  isIntentMessage() {
    return false
  }

  isButtonsMessage() {
    return false
  }

  isGenericMessage() {
    return false
  }

  isCardMessage() {
    return false
  }

  isSliderMessage() {
    return false
  }

  setMessage(file) {
    this.fileData = new FormData()
    this.fileData.append('file', file)
    this.name = this.fileData.get('file').name
    this.fileFormat = this.fileData
      .get('file')
      .name.split('.')
      .pop()
  }

  generateMessageToSave() {
    return {
      platforms: this.platforms,
      responseFile: {
        name: this.name,
        url: /\/bucket/.test(this.fileUrl)
          ? `/bucket${this.fileUrl.split('/bucket')[1]}`
          : this.fileUrl,
        type: this.fileFormat
      },
      responseId: this.responseId,
      beforeAPICall: this.beforeAPICall
    }
  }

  async generate(botId = undefined) {
    return BotMakerService.fileUpload(this.fileData, botId)
      .then(response => {
        //this.fileUrl = response.data.url
        return response
      })
      .catch(error => {
        return error
      })
  }

  save(botId = undefined) {
    return BotMakerService.fileUpload(this.fileData, botId)
      .then(response => {
        this.fileUrl = response.data.url
        return response
      })
      .catch(error => {
        return error
      })
  }
}
