export default {
  notFound: {
    es: 'Condición no encontrado',
    en: 'Condition not found',
    pt: 'Condição não encontrada'
  },
  new: {
    es: 'Condición',
    en: 'Condition',
    pt: 'Condição'
  },
  secondLevel: {
    es: 'Segundo nivel de atención',
    en: 'Second level attention',
    pt: 'Atendimento de segundo nível'
  },
  replyOutsideBusinessHours: {
    es: 'Responder fuera del horario de atención',
    en: 'Reply outside of business hours',
    pt: 'Responda fora do horário de atendimento'
  },
  name: {
    es: 'Nombre de condición (opcional)',
    en: 'Condition name (optional)',
    pt: 'Nome da condição (opcional)'
  },
  tags: {
    es: 'Etiquetas',
    en: 'Tags',
    pt: 'Etiquetas'
  },
  themes: {
    es: 'Temas',
    en: 'Themes',
    pt: 'Temas'
  },
  placeholder: {
    es: 'Buscar o añadir etiqueta',
    en: 'Search or add tag',
    pt: 'Pesquisar ou adicionar etiqueta'
  },
  placeholderTheme: {
    es: 'Buscar o añadir tema',
    en: 'Search or add theme',
    pt: 'Pesquisar ou adicionar tema'
  },
  tagPlaceholder: {
    es: 'Añadir nueva etiqueta',
    en: 'Add new tag',
    pt: 'Adicione uma nova etiqueta'
  },
  themePlaceholder: {
    es: 'Añadir nueva tema',
    en: 'Add new theme',
    pt: 'Adicione um novo tema'
  },
  noOptions: {
    es: 'Aún no se crearon etiquetas.',
    en: 'No tags created yet.',
    pt: 'Nenhuma etiqueta criada ainda.'
  },
  noThemesOptions: {
    es: 'Aún no se crearon temas.',
    en: 'No themes created yet.',
    pt: 'Nenhum tema criado ainda.'
  },
  noTag: {
    es:
      'El campo Etiquetas es requerido. En caso de no seleccionar ninguna, se asignará por defecto la etiqueta',
    en:
      'The Tags field is required. If you do not select anyone, the default tag will be',
    pt:
      'O campo Tags é obrigatório. Se você não selecionar nenhum, o tag será atribuído por padrão'
  },
  noTheme: {
    es:
      'El campo Temas es requerido. En caso de no seleccionar ninguna, se asignará por defecto el tema',
    en:
      'The Themes field is required. If you do not select anyone, the default theme will be',
    pt:
      'O campo Temas é obrigatório. Se você não selecionar nenhum, o tema será atribuído por padrão'
  },
  unclassified: {
    es: 'Sin clasificar',
    en: 'Unclassified',
    pt: 'Não classificado'
  },
  options: {
    intentions: {
      es: '# Intenciones',
      en: '# Intents',
      pt: 'Intenções'
    },
    entities: {
      es: '@ Entidades',
      en: '@ Entities',
      pt: '@ Entidades'
    },
    regex: {
      es: '(.*) RegEx',
      en: '(.*) RegEx',
      pt: '(.*) RegEx'
    },
    variables: {
      es: 'Variables',
      en: 'Variables',
      pt: 'Variáveis'
    }
  },
  types: {
    intent: {
      es: 'Intención',
      en: 'Intent',
      pt: 'Intenção'
    },
    entity: {
      es: 'Entidad',
      en: 'Entity',
      pt: 'Entidade'
    },
    match: {
      es: 'RegEx',
      en: 'RegEx',
      pt: 'RegEx'
    },
    type: {
      es: 'Función',
      en: 'Function',
      pt: 'Função'
    }
  },
  value: {
    es: 'Valor',
    en: 'Value',
    pt: 'Valor'
  },
  type: {
    es: 'Tipo',
    en: 'Type',
    pt: 'Tipo'
  },
  operator: {
    es: 'Operador',
    en: 'Operator',
    pt: 'Operador'
  },
  dividerText: {
    empty: {
      es: 'Aún no se agregaron condiciones',
      en: 'There are no conditions yet',
      pt: 'Não há condições ainda'
    },
    single: {
      es: 'condición agregada',
      en: 'condition added',
      pt: 'condição adicionada'
    },
    plural: {
      es: 'condiciones agregadas',
      en: 'conditions added',
      pt: 'condições adicionadas'
    }
  },
  buttons: {
    add: {
      es: 'Agregar condición',
      en: 'Add condition',
      pt: 'Adicionar condição'
    },
    remove: {
      es: 'Quitar condición',
      en: 'Remove condition',
      pt: 'Remover condição'
    }
  },
  messages: {
    error: {
      addCondition: {
        title: {
          es: 'Tipo existente',
          en: 'Existing type',
          pt: 'Tipo existente'
        },
        text: {
          es: 'Ya existe un condicional de este tipo',
          en: 'A conditional of this type already exists',
          pt: 'Um condicional desse tipo já existe'
        }
      },
      emptyCustomValue: {
        title: {
          es: 'Ingrese valor',
          en: 'Add value',
          pt: 'Adicione valor'
        },
        text: {
          es: 'Ingrese el valor de la condición',
          en: 'Add condition value',
          pt: 'Adicione o valor da condição'
        }
      }
    }
  },
  search: {
    placeholder: {
      es: 'Agregar una condición',
      en: 'Add condition',
      pt: 'Adicione condição'
    },
    noResult: {
      es: 'No se encontraron resultados para ',
      en: 'No results found for ',
      pt: 'Não foram encontrados resultados para '
    },
    newIntent: {
      es: 'Nueva intención',
      en: 'New intent',
      pt: 'Nova intenção'
    },
    newEntity: {
      es: 'Nueva entidad',
      en: 'New entity',
      pt: 'Nova entidade'
    }
  },
  entities: {
    placeholder: {
      es: 'Seleccione valor de entidad',
      en: 'Select entity value',
      pt: 'Selecione o valor da entidade'
    }
  }
}
