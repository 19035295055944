import { MessageType } from '../MessageType'

export class ButtonsMessageType extends MessageType {
  constructor(
    name,
    responseOptions = [],
    platforms = ['all'],
    id = null,
    responseId,
    beforeAPICall
  ) {
    super(name, responseId)
    this.responseOptions = responseOptions
    this.platforms = platforms
    this._id = id
    this.beforeAPICall = beforeAPICall
  }

  getType() {
    return 'buttons'
  }

  isTextMessage() {
    return false
  }
  isRegexMessage() {
    return false
  }
  isImageMessage() {
    return false
  }

  isMediaMessage() {
    return false
  }

  isCardMessage() {
    return false
  }

  isSliderMessage() {
    return false
  }

  isIntentMessage() {
    return false
  }

  isButtonsMessage() {
    return true
  }

  isGenericMessage() {
    return false
  }

  isValid() {
    super.isNameValid()
  }

  setMessage(buttons) {
    this.responseOptions = buttons
  }

  generateMessageToSave() {
    return {
      platforms: this.platforms,
      responseText: this.name,
      responseOptions: this.responseOptions,
      responseId: this.responseId,
      beforeAPICall: this.beforeAPICall
    }
  }

  save() {
    return Promise.resolve()
  }

  update() {/* update */}
}
