import { USER_ROLES } from './UserRoles'

export class RoleEditionCapability {
  /**
   * Role Edition Capability
   * @param roles {Array<String>}
   */
  constructor(roles) {
    this._generalSettings = false
    this._userSettings = false
    this._engineSettings = false
    this._sessionSettings = false
    this._intentions = false
    this._botAvailability = false
    this._deleteBot = false
    this._testBot = false
    this._serviceUsers = false

    if (roles.length > 0) {
      if (roles.includes(USER_ROLES.SUPER_ADMIN)) {
        this._generalSettings = true
        this._userSettings = true
        this._engineSettings = true
        this._sessionSettings = true
        this._intentions = true
        this._botAvailability = true
        this._deleteBot = true
        this._testBot = true
        this._serviceUsers = true
      }

      if (roles.includes(USER_ROLES.SUPERVISOR)) {
        // this._userSettings = true
        this._botAvailability = true
        this._serviceUsers = true
      }

      if (roles.includes(USER_ROLES.EDITOR)) {
        this._testBot = true
        this._generalSettings = true
        this._engineSettings = true
        this._sessionSettings = true
        this._intentions = true
        this._deleteBot = true
      }

      if (roles.includes(USER_ROLES.ROLES_MANAGER)) {
        this._userSettings = true
        // this._botAvailability = true
      }
    }
  }

  get generalSettings() {
    return this._generalSettings
  }

  get userSettings() {
    return this._userSettings
  }

  get engineSettings() {
    return this._engineSettings
  }

  get sessionSettings() {
    return this._sessionSettings
  }

  get intentions() {
    return this._intentions
  }

  get deleteBot() {
    return this._deleteBot
  }

  get botAvailability() {
    return this._botAvailability
  }

  get testBot() {
    return this._testBot
  }

  get serviceUsers() {
    return this._serviceUsers
  }
}
