<template>
  <ul class="languages-wrapper">
    <li
      @click="updateLanguage(language.code)"
      :key="index"
      v-for="(language, index) in languages"
      class="language"
      :class="{ active: language.title === computedLanguageSelected }"
    >
      <!-- <img
        class="flag"
        :src="require(`@/assets/images/flags/${language.code}.png`)"
      /> -->
      <small class="font-medium block notification-title">
        <span v-if="sidebarItemsMin">
          {{ language.code.charAt(0).toUpperCase() + language.code.slice(1) }}
        </span>
        <span v-else>{{ language.title }}</span>
      </small>
    </li>
  </ul>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import StorageService from '../../../services/storage.service'

export default {
  computed: {
    ...mapState(['lang', 'languageSelected', 'languages', 'sidebarItemsMin']),
    computedLanguageSelected() {
      var laguageSelectedCode
      this.languages.forEach(language => {
        if (language.code == this.languageSelected) {
          laguageSelectedCode = language.title
        }
      })
      return laguageSelectedCode
    }
  },
  methods: {
    ...mapMutations(['UPDATE_LANGUAGE']),
    ...mapActions(['saveCompanyLanguage']),
    async updateLanguage(lang) {
      // moment, store, session, db
      this.$moment.locale(lang)
      this.UPDATE_LANGUAGE(lang)
      StorageService.update('session', 'lang', lang)
      this.saveCompanyLanguage(lang)
    }
  }
}
</script>

<style lang="scss">
.languages-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-evenly;

  .language {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active,
    &:hover {
      color: rgba(var(--vs-primary), 1);
    }

    .flag {
      margin-right: 5px;
      margin-bottom: 2px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }
}

.languages-dropdown {
  cursor: pointer;
  .flag-text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    .flag {
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }

    .notification-title {
      padding: 0 10px;
    }
  }
}
.flag {
  width: 17px;
  height: 12px;
}
.vs-dropdown--menu--after {
  right: 50px;
}
</style>
