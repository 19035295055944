export default {
  title: {
    es: 'Entidades',
    en: 'Entities',
    pt: 'Entidades',
  },
  name: {
    es: 'Nombre',
    en: 'Name',
    pt: 'Nome',
  },
  valuesAndSynonyms: {
    es: 'Valores y Sinónimos',
    en: 'Values and Synonyms',
    pt: 'Valores e Sinônimos',
  },
  downloadTemplate: {
    es: 'Bajar Template',
    en: 'Download Template',
    pt: 'Baixe o Template',
  },
  validationErrors: {
    invalidName: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El nombre de la entidad es requerido.',
        en: 'Entity name is required.',
        pt: 'Nome da entidade é necessário.',
      },
    },
    invalidValue: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El valor de la entidad es requerido.',
        en: 'Entity value is required.',
        pt: 'O valor da entidade é necessário.',
      },
    },
    notValues: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'La entidad debe tener al menos un valor.',
        en: 'Entity value is required.',
        pt: 'O valor da entidade é necessário.',
      },
    },
    duplicateValues: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'La entidad contiene valores duplicados: ',
        en: 'Entity has duplicate values: ',
        pt: 'Entidade tem valores duplicados: ',
      },
    },
    duplicateSynonyms: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'La entidad contiene sinónimos duplicados: ',
        en: 'Entity has duplicate synonyms: ',
        pt: 'Entidade tem sinônimos duplicados: ',
      },
    },
  },
  add: {
    popUpTitle: {
      es: 'Agregar Entidad',
      en: 'Add Entity',
      pt: 'Adicionar Entidade',
    },
    name: {
      es: 'Nombre de entidad',
      en: 'Entity name',
      pt: 'Nome da entidade',
    },
    addValue: {
      es: '+ Agregar valor',
      en: '+ Add value',
      pt: '+ Adicione valor',
    },
    valuesAndSynonyms: {
      es: 'Valores y Sinónimos',
      en: 'Values and Synonyms',
      pt: 'Valores e Sinônimos',
    },
    value: {
      es: 'Valor',
      en: 'Value',
      pt: 'Valor',
    },
    addSynonym: {
      es: 'Agregar sinónimo',
      en: 'Add synonym',
      pt: 'Adicionar sinônimo',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La entidad fue creada correctamente.',
        en: 'The entity was created.',
        pt: 'A entidade foi criada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  edit: {
    popUpTitle: {
      es: 'Editar Entidad',
      en: 'Edit Entity',
      pt: 'Editar Entidade',
    },
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    addValue: {
      es: '+ Agregar valor',
      en: '+ Add value',
      pt: '+ Adicione valor',
    },
    valuesAndSynonyms: {
      es: 'Valores y Sinónimos',
      en: 'Values and Synonyms',
      pt: 'Valores e Sinônimos',
    },
    value: {
      es: 'Valor',
      en: 'Value',
      pt: 'Valor',
    },
    addSynonym: {
      es: 'Agregar sinónimo',
      en: 'Add synonym',
      pt: 'Adicionar sinônimo',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La entidad fue actualizada correctamente.',
        en: 'The entity was updated.',
        pt: 'A entidade foi atualizada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  remove: {
    dialog: {
      title: {
        es: 'Advertencia!',
        en: 'Warning!',
        pt: 'Aviso!',
      },
      text: {
        es: 'Estas por borrar una entidad, esta acción es irreversible. ¿Estás seguro?',
        en: 'You are about to erase an entity, this action is irreversible. Are you sure?',
        pt: 'Você está prestes a apagar uma entidade, esta ação é irreversível',
      },
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La entidad fue eliminada correctamente.',
        en: 'The entity was deleted.',
        pt: 'A entidade foi deletada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
}