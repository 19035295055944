import Helper from '../Helper'
import Vue from 'vue'
import BotMakerService from '../../services/botMaker.service'
import CyberbankService from '../../services/cyberbank.service'
import { generateBot } from '../../models/BotGenerator'
import { Intent } from '../../models/Intent'
import { BotMessage } from '../../models/Messages/BotMessage'
import { TextMessageType } from '../../models/Messages/MessageTypes/TextMessageType'
import { v4 as uuid } from 'uuid'

function setBeforeApiCall(state, message) {
  const dialogId =
    (state.userMessageInEdit && state.userMessageInEdit.dialogId) ||
    (state.botMessageInEdit && state.botMessageInEdit.dialogId)

  if (dialogId) {
    const dialog = state.dialogsMap[dialogId]
    const responsesLength = dialog.responses.length
    if (responsesLength > 0) {
      const lastResponse = dialog.responses[responsesLength - 1]
      const lastBeforeAPICall = lastResponse && lastResponse.beforeAPICall
      message.type.beforeAPICall = lastBeforeAPICall
    } else {
      message.type.beforeAPICall = false
    }
  } else {
    message.type.beforeAPICall = false
  }
  return message
}

const actions = {
  async GET_ENTITIES({ state }, versionId) {
    return BotMakerService.getEntities(versionId)
      .then(response => {
        state.entities = response.data
      })
      .catch(error => error)
  },
  async GET_API_CALLS({ state }, versionId) {
    return BotMakerService.getApiCalls(versionId)
      .then(response => {
        state.apiCalls = response.data
      })
      .catch(error => error)
  },
  async GET_ACTIVE_VERSION({ commit }, [serviceId, queryVersion]) {
    /** Check if already passed version by query */
    if (queryVersion) return
    try {
      const response = await BotMakerService.getActiveVersion(serviceId)
      console.log('Set version active', response.data[0]._id)
      commit('SET_VERSION', {
        versionId: response.data[0]._id,
        versionFork: response.data[0].fork,
        forkTemplate: response.data[0].forkTemplate
      })
    } catch (e) {
      return e
    }
  },
  async GET_DIALOGS({ state, commit }, dialogSavedId) {
    const activeVersion = state.bot.activeVersion
    if (!activeVersion) return
    try {
      const response = await BotMakerService.getDialogs(activeVersion)
      let intentions = state.intentions
      let dialogs = response.data
      const { messages, dialogsMap } = Helper.parseDialogs(dialogs, intentions)
      state.messages = messages
      state.dialogsMap = dialogsMap
      state.dialogs = dialogs
      state.botMakerMessages = Helper.loadMessages(
        state.messages,
        undefined,
        dialogSavedId
      )
      commit('SET_DIALOGS', response.data)
      return response
    } catch (error) {
      return await Promise.reject(error)
    }
  },
  CREATE_DIALOG({ state }, message) {
    return new Promise((resolve, reject) => {
      Vue.$log.info(message)
      const activeVersion = state.bot.activeVersion
      const messages = state.botMakerMessages
      const tmpMessage = message.alternativeMessage
        ? Helper.searchMessage(state.messages, message.alternativeMessage)
        : undefined
  
      const dialogName = Helper.buildDialogName(message, state.conditionName)
      let dialogToSave = {
        version: activeVersion,
        name: dialogName,
        execTakeover: state.secondLevelAttention,
        replyOutsideBusinessHours: state.replyOutsideBusinessHours,
        tags: state.conditionTags,
        type: 'node',
        responses: []
      }
  
      if (
        message.type.isIntentMessage() ||
        message.type.isRegexMessage() ||
        message.type.isGenericMessage()
      ) {
        const condition = Helper.parseDialog(message)
        // dialogToSave = Object.assign(dialogToSave, {
        //   condition: {
        //     params: {
        //       intent: message.type.name
        //     },
        //     tree: {
        //       funcs: ['intent'],
        //       facts: [null],
        //       operator: '...'
        //     }
        //   }
        // })
  
        dialogToSave = Object.assign(dialogToSave, {
          condition: condition
        })
      }
      // else if (message.type.isRegexMessage()) {
      //   dialogToSave = Object.assign(
      //     dialogToSave,
      //     message.type.generateMessageToSave()
      //   )
      // }
  
      if (message.prevMessage || (tmpMessage && tmpMessage.prevMessage)) {
        let prevMessage = messages[messages.length - 2]
        dialogToSave.parent = prevMessage.dialogId
      }
  
      // Create Dialog
      return BotMakerService.createDialog(dialogToSave)
        .then(dialogSaveResponse => {
          const newDialog = dialogSaveResponse.data
  
          message.dialogSavedId = newDialog._id
          message.dialogId = newDialog.dialogId
          message.isSaved = true
          message.type.name = newDialog.name
          message.condition = newDialog.condition
          // TODO: check GET_DIALOGS since next line change
          state.dialogsMap[newDialog.dialogId] = newDialog
          return resolve()
        })
        .catch(error => {
          Vue.$log.error(error)
          // throw error
          return reject()
        })
    });
  },
  async GET_INTENTIONS({ state, commit }) {
    const activeVersion = state.bot.activeVersion
    try {
      const response = await BotMakerService.getIntentionsPerVersion(
        activeVersion
      )
      let intentions = response.data
      Helper.setIntentsScalesSecondLevel(state.bot.rawIntents, intentions)
      commit('SET_INTENTIONS', intentions)
    } catch (error) {
      return await Promise.reject(error)
    }
  },
  CREATE_INTENT({ state }, message) {
    const activeVersion = state.bot.activeVersion

    return message.type
      .save(activeVersion)
      .then(response => {
        state.intentions.push(
          new Intent(
            response.data.name,
            response.data.examples,
            false,
            response.data._id
          )
        )
      })
      .catch(error => {
        throw error
      })
  },
  UPDATE_INTENT({ commit }, message) {
    const intentToSave = {
      name: message.name,
      examples: message.examples
    }
    return BotMakerService.updateIntent(message.intentId, intentToSave)
      .then(response => {
        // Update intention in intentions dialog
        commit('ADD_INTENTION', {
          name: message.name,
          examples: message.examples,
          id: message.intentId
        })
        return response
      })
      .catch(error => {
        Vue.$log.error(error)
        throw error
      })
  },
  async IMPORT_INTENT(context, { bot, file }) {
    try {
      const versionId = bot.activeVersion
      const res = await BotMakerService.importIntent(file, versionId).catch(
        e => {
          return {
            error: e.response && e.response.data ? e.response.data : e.message
          }
        }
      )
      if (res.error) {
        return await Promise.reject(res.error)
      }
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },
  async IMPORT_DIALOG(context, { bot, file }) {
    try {
      const versionId = bot.activeVersion
      const serviceId = bot._botService.id
      await BotMakerService.importDialog(file, versionId, serviceId)
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },
  async IMPORT_ENTITY(context, { bot, file }) {
    try {
      const versionId = bot.activeVersion
      const serviceId = bot._botService.id
      await BotMakerService.importEntity(file, versionId, serviceId)
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },
  GET_BOT({ state }, botId) {
    return BotMakerService.getBot(botId)
      .then(response => {
        state.bot = generateBot(response.data)
      })
      .catch(error => error)
  },
  async SAVE_INTENTION_SECOND_LEVEL({ state }, intentName) {
    Vue.$log.info(intentName)
    const botId = state.bot.id
    const intentions = state.intentions
    const intention = Vue._.find(intentions, i => {
      return i.name.toLowerCase() === intentName.toLowerCase()
    })
    intention.derivesToAgent = true

    const intentionsTriggerSecondLevel = intentions.filter(
      i => i.derivesToAgent
    )
    const intentionsToSave = intentionsTriggerSecondLevel.map(i => i.name)

    return BotMakerService.updateBot(
      {
        intents: intentionsToSave
      },
      botId
    )
      .then(response => {
        return response
      })
      .catch(e => {
        throw e
      })
  },
  async SAVE_BOT_MESSAGE({ state, commit, dispatch }, message) {
    try {
      // new message inherits beforeAPICall value from last dialog message
      message = setBeforeApiCall(state, message)

      if (!message.isSaved) {
        commit('ADD_MESSAGE', message)
      }

      // add bot message to selected user message
      if (state.userMessageInEdit.isEditing) {
        // find editing index
        const editingIndex = state.botMakerMessages.findIndex(m => m.isEditing)
        // move last message up to index
        const lastMessage = state.botMakerMessages.pop()
        state.botMakerMessages.splice(editingIndex + 1, 0, lastMessage)
      }

      // Get previous user message
      const messageIndex = state.botMakerMessages.indexOf(message)
      let previousMessage = state.botMakerMessages[messageIndex - 1]
      if (!previousMessage) {
        previousMessage =
          state.botMakerMessages[state.botMakerMessages.length - 1]
      }

      let { dialogSavedId } = message
      if (
        !dialogSavedId &&
        state.userMessageInEdit &&
        state.userMessageInEdit.dialogSavedId
      ) {
        dialogSavedId = state.userMessageInEdit.dialogSavedId
      } else {
        dialogSavedId = previousMessage.dialogSavedId
      }

      const prevMessageDialogId = previousMessage.dialogSavedId
      Vue.$log.info('Previous message ID', prevMessageDialogId)
      // Look for all the responses for the same dialog ID

      let prevMessages = state.messages.filter(m => {
        return m.dialogSavedId === prevMessageDialogId && m.isBotMessage()
      })

      let responses = prevMessages.map(m => {
        return m.type.generateMessageToSave()
      })

      // if editing message for new platform and clean it
      // state.selectedResponse._id !== state.botMessageInEdit.type._id

      // add message for new platform
      const isEditingBotMsg =
        state.selectedResponse &&
        state.selectedResponse.platforms &&
        state.selectedResponse.platforms[0] &&
        // Chequeo condiciones si pertenece a la plataforma dentro de un array
        state.selectedResponse.platforms.includes(state.activeChannelView)
      // state.selectedResponse.platforms.includes('all')

      if (state.selectedResponse && !isEditingBotMsg) {
        if (!state.selectedResponse.responseId) {
          const responseId = uuid()
          state.selectedResponse.responseId = responseId
        }
        responses.splice(state.responseIndex, 0, state.selectedResponse)
        responses[state.responseIndex + 1].responseId =
          state.selectedResponse.responseId
      }

      Vue.$log.info('Load existing responses:', responses)

      if (!message.isSaved) {
        await message.type.save(state.botId)
        // Allow to save empty responses when an integration is saved without message in edition mode
        if (message.type.name) {
          responses.push(message.type.generateMessageToSave())
        }
      }

      Vue.$log.info('Added new response to the response array: ', responses)

      let copyRes = JSON.parse(JSON.stringify(responses))

      // MAP responses to remove duplicated Observer bug
      // copyRes.map( (item, i) => {
      //   if(item.hasOwnProperty('_id')){
      //     copyRes.splice(i, 1)
      //   }
      // })

      if (responses.length > 1) {
        // Fecth duplicateds
        responses.map((item, i) => {
          let counter = 0
          // Cuento duplicados sin el Observer
          copyRes.map(child => {
            if (
              item.responseId == child.responseId &&
              item.platforms[0] == child.platforms[0]
            ) {
              counter++
            }
          })
          if (counter > 1) {
            responses.splice(i, 1)
          }
        })
      }

      let dataToSave = {
        responses: responses,
        sendValidationSettings: message.sendValidationSettings
      }

      const dialogSavedId2 = state.userMessageInEdit.dialogSavedId

      await BotMakerService.updateDialog(prevMessageDialogId, dataToSave).then(
        async response => {
          // await dispatch('GET_DIALOGS', prevMessageDialogId)
          await dispatch(
            'GET_DIALOGS',
            dialogSavedId ||
              dialogSavedId2 ||
              state.botMakerMessages[0].dialogSavedId
          )
          state.dialogsMap[response.data.dialogId] = response.data
        }
      )
      message.dialogSavedId = prevMessageDialogId
      message.dialogId = previousMessage.dialogId
      message.isSaved = true

      Vue.$log.info('Saved new response!')
      commit('RESET_BOT_MESSAGE')
    } catch (error) {
      Vue.$log.error(error)
      throw error
    }
  },
  async UPDATE_CONDITIONAL_MESSAGE({ state }, message) {
    Vue.$log.info(message)
    // eslint-disable-next-line no-useless-catch
    try {
      // if (message.type.intentId) {
      //   await message.type.update()
      // } else {
      //   await dispatch('CREATE_INTENT', message)
      // }

      // if (message.type.secondLevel) {
      //   await dispatch('SAVE_INTENTION_SECOND_LEVEL', message.type.name)
      // }
      // commit('UPDATE_INTENTS', {
      //   intentId: message.type.intentId,
      //   name: message.type.name,
      //   examples: message.type.examples,
      //   derivesToAgent: message.type.secondLevel,
      //   filters: message.filters
      // })

      // Update dialog with intention content
      // let dialogToUpdate = {
      //   condition: {
      //     params: {
      //       intent: message.type.name
      //     },
      //     tree: {
      //       funcs: ['intent'],
      //       facts: [null],
      //       operator: '...'
      //     }
      //   }
      // }
      const dialogToUpdate = Helper.parseDialog(message)
      message.type.name = Helper.buildDialogName(message, state.conditionName)
      await BotMakerService.updateDialog(message.dialogSavedId, {
        condition: dialogToUpdate,
        execTakeover: state.secondLevelAttentionHasChanged
          ? state.secondLevelAttention
          : undefined,
        replyOutsideBusinessHours: state.replyOutsideBusinessHours,
        name: message.type.name,
        tags: state.conditionTags
      })
    } catch (e) {
      throw e
    }
  },
  async UPDATE_USER_MESSAGE({ commit, dispatch, state }, message) {
    Vue.$log.info(message)
    // eslint-disable-next-line no-useless-catch
    try {
      if (message.type.intentId) {
        await message.type.update()
      } else {
        await dispatch('CREATE_INTENT', message)
      }

      if (message.type.secondLevel) {
        await dispatch('SAVE_INTENTION_SECOND_LEVEL', message.type.name)
      }
      commit('UPDATE_INTENTS', {
        intentId: message.type.intentId,
        name: message.type.name,
        examples: message.type.examples,
        derivesToAgent: message.type.secondLevel,
        filters: message.filters
      })

      // Update dialog with intention content
      // let dialogToUpdate = {
      //   condition: {
      //     params: {
      //       intent: message.type.name
      //     },
      //     tree: {
      //       funcs: ['intent'],
      //       facts: [null],
      //       operator: '...'
      //     }
      //   }
      // }
      const dialogToUpdate = Helper.parseDialog(message)
      await BotMakerService.updateDialog(message.dialogSavedId, {
        condition: dialogToUpdate,
        execTakeover: state.secondLevelAttentionHasChanged
          ? state.secondLevelAttention
          : undefined
      })
    } catch (e) {
      throw e
    }
  },
  async SAVE_CONDITIONAL_MESSAGE({ state, commit, dispatch }, message) {
    // Vue.$log.info(message)
    if (!message.alternativeMessage) {
      commit('ADD_MESSAGE', message)
    }
    try {
      // Check if the intent already exists
      // if (message.type.isIntentMessage() && !message.type.intentId) {
      //   await dispatch('CREATE_INTENT', message)
      // }
      await dispatch('CREATE_DIALOG', message)
      commit('RESET_CONDITIONS')

      // If the message has a reference to a tmp message, then update the tmp message
      let tmpMessage = message.alternativeMessage
        ? Helper.searchMessage(state.messages, message.alternativeMessage)
        : undefined
      if (tmpMessage) {
        tmpMessage.dialogSavedId = message.dialogSavedId
        tmpMessage.dialogId = message.dialogId
        tmpMessage.type.intentId = message.type.intentId
        tmpMessage.isSaved = message.isSaved
        tmpMessage.type.name = message.type.name
        tmpMessage.type.examples = message.type.examples
        tmpMessage.type.secondLevel = message.type.secondLevel
      }

      // Save intent to the bot
      // if (message.type.secondLevel) {
      //   await dispatch('SAVE_INTENTION_SECOND_LEVEL', message.type.name)

      //   // Create default bot response
      //   let defaultResponses = {
      //     es: 'En unos momentos te atenderá un agente.',
      //     en: 'In a few moments an agent will attend you'
      //   }

      //   let noIntentMessage = state.bot.secondLevelMessage
      //     ? state.bot.secondLevelMessage
      //     : defaultResponses[rootState.languageSelected]

      //   let botResponse = new BotMessage(
      //     undefined,
      //     message.dialogSavedId,
      //     new TextMessageType(noIntentMessage)
      //   )
      //   botResponse.dialogSavedId = message.dialogSavedId
      //   botResponse.dialogId = message.dialogId
      //   await dispatch('SAVE_BOT_MESSAGE', botResponse)
      // }

      // if (!message.alternativeMessage) {
      //   commit('RESET_USER_MESSAGE')
      // }
      // if (message.type.isTextMessage()) {
      //   commit('ADD_INTENTION', {
      //     name: message.type.name,
      //     examples: message.type.examples,
      //     id: message.type.intentId
      //   })
      //   commit('UPDATE_INTENTS', {
      //     name: message.type.name,
      //     examples: message.type.examples,
      //     derivesToAgent: message.type.secondLevel
      //   })
      // }
    } catch (error) {
      commit('REMOVE_MESSAGE', message)
      Vue.$log.error(error)
      throw error
    }
  },
  async SAVE_NEW_USER_MESSAGE({ rootState, state, commit, dispatch }, message) {
    Vue.$log.info(message)
    if (!message.alternativeMessage) {
      commit('ADD_MESSAGE', message)
    }
    try {
      // Check if the intent already exists
      if (message.type.isIntentMessage() && !message.type.intentId) {
        await dispatch('CREATE_INTENT', message)
      }
      await dispatch('CREATE_DIALOG', message)
      commit('RESET_CONDITIONS')

      // If the message has a reference to a tmp message, then update the tmp message
      let tmpMessage = message.alternativeMessage
        ? Helper.searchMessage(state.messages, message.alternativeMessage)
        : undefined
      if (tmpMessage) {
        tmpMessage.dialogSavedId = message.dialogSavedId
        tmpMessage.dialogId = message.dialogId
        tmpMessage.type.intentId = message.type.intentId
        tmpMessage.isSaved = message.isSaved
        tmpMessage.type.name = message.type.name
        tmpMessage.type.examples = message.type.examples
        tmpMessage.type.secondLevel = message.type.secondLevel
      }

      // Save intent to the bot
      if (message.type.secondLevel) {
        await dispatch('SAVE_INTENTION_SECOND_LEVEL', message.type.name)

        // Create default bot response
        let defaultResponses = {
          es: 'En unos momentos te atenderá un agente.',
          en: 'In a few moments an agent will attend you'
        }

        let noIntentMessage = state.bot.secondLevelMessage
          ? state.bot.secondLevelMessage
          : defaultResponses[rootState.languageSelected]

        let botResponse = new BotMessage(
          undefined,
          message.dialogSavedId,
          new TextMessageType(noIntentMessage)
        )
        botResponse.dialogSavedId = message.dialogSavedId
        botResponse.dialogId = message.dialogId
        await dispatch('SAVE_BOT_MESSAGE', botResponse)
      }

      if (!message.alternativeMessage) {
        commit('RESET_USER_MESSAGE')
      }
      if (message.type.isTextMessage()) {
        commit('ADD_INTENTION', {
          name: message.type.name,
          examples: message.type.examples,
          id: message.type.intentId
        })
        commit('UPDATE_INTENTS', {
          name: message.type.name,
          examples: message.type.examples,
          derivesToAgent: message.type.secondLevel
        })
      }
    } catch (error) {
      Vue.$log.error(error)
      throw error
    }
  },
  async SAVE_DIALOG({ dispatch }, data) {
    try {
      let message = data.message

      if (message.isUserMessage()) {
        await dispatch('SAVE_USER_MESSAGE', data)
      } else {
        await dispatch('SAVE_BOT_MESSAGE', data)
      }
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },
  async DELETE_BOT_MESSAGE({ state, commit, dispatch }, message) {
    Vue.$log.info(message)

    // if the plafrom is "all" we must delete all responses with the same responseId
    const deleteAllWithSameResponseId = (message.type.platforms[0] === 'all');
    const dialogId = message.dialogSavedId
    let botResponses = []
    let messages = state.botMakerMessages.filter(m => {
      if (deleteAllWithSameResponseId) {
        return (
          m.dialogId === message.dialogId &&
          m.isBotMessage() &&
          m.type.responseId !== message.type.responseId
        )
      }
      return (
        m.dialogId === message.dialogId &&
        m.isBotMessage() &&
        m.id !== message.id
      )
    })

    botResponses = messages.map(m => m.type.generateMessageToSave())

    try {
      await BotMakerService.updateDialog(dialogId, {
        responses: botResponses
      })
      // Get previous user message
      const messageIndex = state.botMakerMessages.indexOf(message)
      let previousMessage = state.botMakerMessages[messageIndex - 1]
      if (!previousMessage) {
        previousMessage =
          state.botMakerMessages[state.botMakerMessages.length - 1]
      }

      let { dialogSavedId } = message
      if (
        !dialogSavedId &&
        state.userMessageInEdit &&
        state.userMessageInEdit.dialogSavedId
      ) {
        dialogSavedId = state.userMessageInEdit.dialogSavedId
      } else {
        dialogSavedId = previousMessage.dialogSavedId
      }
      const dialogSavedId2 = state.userMessageInEdit.dialogSavedId
      await dispatch(
        'GET_DIALOGS',
        dialogSavedId ||
          dialogSavedId2 ||
          state.botMakerMessages[0].dialogSavedId
      )
      commit('RESET_BOT_MESSAGE')
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },

  async UPDATE_DIALOG_ACTIONS({ commit }, { dialogSavedId, actions }) {
    try {
      console.log('actions', actions)
      const response = await BotMakerService.updateDialog(dialogSavedId, {
        actions
      })
      const updatedDialog = response.data
      commit('UPDATE_DIALOG_MAP_BY_DIALOG_ID', updatedDialog)
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },

  async UPDATE_DIALOG_INTEGRATIONS(context, { dialogSavedId, api }) {
    try {
      await BotMakerService.updateDialog(dialogSavedId, {
        apiCall: api
      })
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },

  async UPDATE_DIALOG_SURVEYS(context, { dialogSavedId, sendSurveySettings }) {
    try {
      await BotMakerService.updateDialog(dialogSavedId, {
        sendSurveySettings: sendSurveySettings
      })
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },

  async UPDATE_DIALOG_POSITION(context, { dialogSavedId, next, parent }) {
    try {
      await BotMakerService.updateDialog(dialogSavedId, {
        next: next || null,
        parent: parent || null
      })
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },

  async DELETE_USER_MESSAGE({ state, commit, dispatch }, message) {
    let dialogSavedId = undefined

    // get dialogs of this alternative or the previous one
    if (message.prevAlternative) {
      const prev = state.messages.find(m => m.id === message.prevAlternative)
      dialogSavedId = prev && prev.dialogSavedId
    } else if (message.prevMessage) {
      dialogSavedId = state.botMakerMessages[0].dialogSavedId
    }

    try {
      await BotMakerService.removeDialog(message.dialogSavedId)
      await dispatch('GET_DIALOGS', dialogSavedId)
      commit('RESET_USER_MESSAGE')
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async DELETE_UNSAVED_MESSAGE({ state, dispatch }) {
    state.messages.pop()
    await dispatch('GET_DIALOGS', state.lastAlternativeDialogSavedId)
    state.lastAlternativeDialogSavedId = undefined
    return Promise.resolve()
  },

  async GET_DIALOGS_USING(context, { type, id }) {
    try {
      const response = await BotMakerService.getDialogsUsing(type, id)
      return response
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async GET_RESPONSE_VALIDATIONS({ commit }, botId) {
    try {
      const response = await BotMakerService.getResponseValidations(botId)
      commit('SET_RESPONSE_VALIDATIONS', response.data)
      return response
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async SAVE_BOT_VALIDATION({ state, commit }, validation) {
    try {
      const response = await BotMakerService.saveBotValidation(
        validation,
        state.bot.activeVersion
      )
      commit('SET_RESPONSE_VALIDATION', response.data)
      return response.data._id
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async UPDATE_BOT_VALIDATION({ commit }, { validationId, validation }) {
    try {
      const response = await BotMakerService.updateBotValidation(
        validationId,
        validation
      )
      commit('UPDATE_RESPONSE_VALIDATION', response.data)
      return response.data._id
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async SET_VALIDATION_APPLY_ALL(context, { version, validationId }) {
    try {
      await BotMakerService.setValidationApplyAll(version, validationId)
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async GET_INTENTIONS_BY_VERSION({ commit }, versionId) {
    try {
      const response = await BotMakerService.getIntentionsPerVersion(versionId)
      commit('SET_INTENTIONS_BY_VERSION', {
        versionId,
        intentions: response.data
      })
      return response.data
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async GET_BOT_VERSIONS({ commit, state }, [serviceId, queryVersion]) {
    try {
      const response = await BotMakerService.getBotVersions(serviceId)
      commit('SET_BOT_VERSIONS', response.data)
      if (queryVersion) {
        const version = state.botVersions.find(el => el._id === queryVersion)
        if (version !== undefined) {
          // not working yet due to inconsistencies with other parts of the code
          console.log('Set version queryVersion', queryVersion)
          commit('SET_VERSION', {
            versionId: version._id,
            versionFork: version.fork,
            forkTemplate: version.forkTemplate
          })
        }
      }
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async PROMOTE_BOT_VERSION({ dispatch }, { versionId, changeIntegrations }) {
    try {
      console.log('VERSIONES', versionId, changeIntegrations)
      const response = await BotMakerService.promoteBotVersion(versionId, changeIntegrations)
      await dispatch('GET_BOT_VERSIONS', [response.data.service])
      await dispatch('GET_ACTIVE_VERSION', response.data.service)
      await dispatch('GET_INTENTIONS')
      await dispatch('GET_DIALOGS')
      return response.data
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async PROMOTE_TO_TEST_BOT_VERSION({ dispatch }, versionId) {
    try {
      const response = await BotMakerService.promoteToTestBotVersion(versionId)
      await dispatch('GET_BOT_VERSIONS', [response.data.service])
      await dispatch('GET_ACTIVE_VERSION', response.data.service)
      await dispatch('GET_INTENTIONS')
      await dispatch('GET_DIALOGS')
      return response.data
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async CREATE_BOT_VERSION({ commit }, payload) {
    try {
      const response = await BotMakerService.createBotVersion(payload)
      commit('ADD_BOT_VERSION', response.data)
      return response.data
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async DELETE_BOT_VERSION({ dispatch }, versionId) {
    try {
      const response = await BotMakerService.deleteBotVersion(versionId)
      await dispatch('GET_BOT_VERSIONS', [response.data.service])
      return response.data
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async GET_CYBERBANK_VARIABLES({ commit }) {
    try {
      const response = await CyberbankService.getCyberbankVariables()
      const cyberbankVars = response.data.filter(
        variable => variable.name === 'cyberbank'
      )
      const cyberbankVarsActions = cyberbankVars[0].actions
      commit('SET_CYBERBANK_VARIABLES', cyberbankVarsActions)
      return cyberbankVars
    } catch (error) {
      return await Promise.reject(error)
    }
  },
  async CREATE_TEST_GROUP({ state }, {name, description, service}) {
    try {
      const response = await BotMakerService.createTestGroup({ description, name, service});
      if (!state.testGroups) {
        state.testGroups = [response.data];
      } else {
        state.testGroups.push(response.data);
      }
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },
  async UPDATE_TEST_GROUP({ state }, {name, description, id}) {
    try {
      let groupIndex = state.testGroups.findIndex(tg => tg._id === id);
      const response = await BotMakerService.updateTestGroup({ description, name, id});
      state.testGroups.splice(groupIndex, 1);
      state.testGroups.splice(groupIndex, 0, response.data)
      if (state.selectedTestGroup) {
        let testCases = state.selectedTestGroup.testCases;
        state.selectedTestGroup = response.data;
        state.selectedTestGroup.testCases = testCases;
      }
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },
  async GET_TEST_GROUPS({ state }, service) {
    try {
      const response = await BotMakerService.getTestsGroup(service);
      state.testGroups = response.data;
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },
  async SET_TEST_GROUP ({ state }, testGroupId) {
    const selectedTestGroup = state.testGroups[state.testGroups.findIndex(tg => tg._id === testGroupId)];
    if (selectedTestGroup) {
      try {
        const response = await BotMakerService.getTestGroupCasesById(testGroupId);
        selectedTestGroup.testCases = response.data;
        state.selectedTestGroup = selectedTestGroup;
      } catch (error) {
        Vue.$log.error(error)
        return await Promise.reject(error)
      }
    }
  },
  async SET_TEST_CASE ({ state }, testCaseId) {
    const selectedTestCase = state.selectedTestGroup.testCases[state.selectedTestGroup.testCases.findIndex(tg => tg._id === testCaseId)];
    if (selectedTestCase) {
      try {
        const response = await BotMakerService.getTestCasesStepsById(testCaseId);
        selectedTestCase.testCaseSteps = response.data;
        state.selectedTestCase = selectedTestCase;
      } catch (error) {
        Vue.$log.error(error)
        return await Promise.reject(error)
      }
    }
  },
  UNSET_TEST_GROUP ({ state }) {
    state.selectedTestGroup = undefined;
  },
  UNSET_TEST_CASE ({ state }) {
    state.selectedTestCase = undefined;
  },
  async CREATE_TEST_CASE({ state }, {name, description, testGroup}) {
    try {
      const response = await BotMakerService.createTestCase({ description, name, testGroup});
      if (state.selectedTestGroup) {
        if (state.selectedTestGroup.testCases) {
          state.selectedTestGroup.testCases.push(response.data)
        } else {
          state.selectedTestGroup.testCases = [response.data];
        }
      }
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },
  async DELETE_TEST_GROUP({ state }, testGroupId) {
    try {
      let groupIndex = state.testGroups.findIndex(tg => tg._id === testGroupId);
      await BotMakerService.deleteTestGroup(testGroupId);
      if (groupIndex > -1) {
        state.testGroups.splice(groupIndex, 1);
      }
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },
  async DELETE_TEST_CASE({ state }, testCaseId) {
    try {
      let caseIndex = state.selectedTestGroup.testCases.findIndex(tg => tg._id === testCaseId);
      await BotMakerService.deleteTestCase(testCaseId);
      if (caseIndex > -1) {
        state.selectedTestGroup.testCases.splice(caseIndex, 1);
      }
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },
  async UPDATE_TEST_CASE({ state }, {name, description, id}) {
    try {
      let caseIndex = state.selectedTestGroup.testCases.findIndex(tg => tg._id === id);
      const response = await BotMakerService.updateTestCase({ description, name, id});
      if (caseIndex > -1) {
        state.selectedTestGroup.testCases.splice(caseIndex, 1);
        state.selectedTestGroup.testCases.splice(caseIndex, 0, response.data)
      }
      if (state.selectedTestCase) {
        let testGroupCaseSteps = state.selectedTestCase.testGroupCaseSteps;
        state.selectedTestCase = response.data;
        state.selectedTestCase.testGroupCaseSteps = testGroupCaseSteps;
      }
    } catch (error) {
      Vue.$log.error(error)
      return await Promise.reject(error)
    }
  },
  async UPDATE_TEST_STEPS({ state }, newSteps) {
    let error = false;
    const oldSteps = state.selectedTestCase.testGroupCaseSteps;
    const actualSteps = [];
    const actualStepsIds = [];
    for (let i in newSteps) {
      const step = newSteps[i];
      if (!step._id) {
        let response = null;
        try {
          response = await BotMakerService.createTestStep({ input: step.input, output: step.output, testGroupCase: step.testGroupCase, assertion: step.assertion, disabled: step.disabled });
        } catch (ex) {
          error = true;
        }
        const newStep = response.data;
        actualStepsIds.push(newStep._id);
        actualSteps.push(newStep);
      } else {
        let response = null;
        try {
          response = await BotMakerService.updateTestStep({ input: step.input, output: step.output, assertion: step.assertion, _id:step._id, disabled: step.disabled });
        } catch (ex) {
          error = true;
        }
        const newStep = response.data;
        actualStepsIds.push(newStep._id);
        actualSteps.push(newStep);
      }
    }
    await oldSteps.forEach(async stepId => {
      if (!actualStepsIds.includes(stepId)) {
        await BotMakerService.deleteTestStep(stepId);
      }
    });
    state.selectedTestCase.testGroupCaseSteps = actualStepsIds;
    await BotMakerService.updateTestCaseSteps({ testGroupCaseSteps: state.selectedTestCase.testGroupCaseSteps, _id: state.selectedTestCase._id });
    state.selectedTestCase.testCaseSteps = actualSteps;
    if (error) {
      return await Promise.reject(error);
    }
  }
}

export default actions
