export default {
  title: {
    es: 'Vista de árbol',
    en: 'Tree view',
    pt: 'Vista da árvore'
  },
  navbar: {
    es: 'Vista de árbol',
    en: 'Tree view',
    pt: 'Vista da árvore'
  },
  tree: {
    search: {
      placeholder: {
        es: 'Condición, respuesta, validación, etc...',
        en: 'Condition, response, validation, etc...',
        pt: 'Condição, resposta, validação, etc...'
      },
      button: {
        es: 'Buscar',
        en: 'Search',
        pt: 'Pesquisar'
      }
    },
    loading: {
      es: 'Cargando vista de árbol',
      en: 'Loading tree view',
      pt: 'Carregando a vista da árvore'
    },
    moveAbove: {
      es: 'Mover arriba',
      en: 'Move above',
      pt: 'Mova-se para cima'
    },
    moveBelow: {
      es: 'Mover abajo',
      en: 'Move below',
      pt: 'Mova-se para baixo'
    },
    moveAsASon: {
      es: 'Mover como hijo',
      en: 'Move as a son',
      pt: 'Mexam-se como um filho'
    },
    cancel: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar'
    },
    alert: {
      es: 'Selecciona el lugar donde quieres mover el diálogo',
      en: 'Select the place where you want to move the dialog',
      pt: 'Selecione o lugar onde você quer mover o diálogo'
    },
    confirmation: {
      title: {
        es: 'Confirmación',
        en: 'Confirmation',
        pt: 'Confirmação'
      },
      text: {
        es: '¿Estás seguro de que quieres mover el diálogo?',
        en: 'Are you sure that you want to move the dialog?',
        pt: 'Tem certeza que quer mover o diálogo?'
      },
      acceptText: {
        es: 'Sí',
        en: 'Yes',
        pt: 'Sim'
      },
      cancelText: {
        es: 'No',
        en: 'No',
        pt: 'Não'
      }
    }
  }
}
