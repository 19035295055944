export default {
  validationErrors: {
    url: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'La Url ingresada no es válida.',
        en: 'The Url is not valid.',
        pt: 'O Url não é válido.',
      },
    },
    https: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El protocolo debe ser https.',
        en: 'The protocol must be https.',
        pt: 'O protocolo deve ser https.',
      },
    },
  },
  list: {
    title: {
      es: 'Integraciones',
      en: 'Integrations',
      pt: 'Integrações',
    },
    url: {
      es: 'Url',
      en: 'Url',
      pt: 'Url',
    },
    headers: {
      title: {
        es: 'Headers',
        en: 'Headers',
        pt: 'Headers',
      },
      header: {
        es: 'Header',
        en: 'Header',
        pt: 'Header',
      },
      value: {
        es: 'Value',
        en: 'Value',
        pt: 'Value',
      },
      empty: {
        es: 'No hay headers',
        en: 'No headers available',
        pt: 'Não há headers disponíveis',
      },
    },
  },
  add: {
    button: {
      es: 'Crear integración',
      en: 'Create integration',
      pt: 'Criar integração',
    },
    popUpTitle: {
      es: 'Agregar Integración',
      en: 'Add Integration',
      pt: 'Adicionar integração',
    },
    url: {
      es: 'Url',
      en: 'Url',
      pt: 'Url',
    },
    headers: {
      title: {
        es: 'Headers',
        en: 'Headers',
        pt: 'Headers',
      },
      header: {
        es: 'Header',
        en: 'Header',
        pt: 'Header',
      },
      value: {
        es: 'Value',
        en: 'Value',
        pt: 'Value',
      },
      empty: {
        es: 'No hay headers',
        en: 'No headers available',
        pt: 'Não há headers disponíveis',
      },
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La integración fue creada correctamente.',
        en: 'The integration was created.',
        pt: 'A integração foi criada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  edit: {
    popUpTitle: {
      es: 'Editar Integración',
      en: 'Edit Integration',
      pt: 'Editar Integração',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La integración fue actualizada correctamente.',
        en: 'The integration was updated.',
        pt: 'A integração foi atualizada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  remove: {
    dialog: {
      title: {
        es: 'Confirmación',
        en: 'Confirmation',
        pt: 'Confirmação',
      },
      text: {
        es: '¿Estás seguro de querer eliminar ésta integración?',
        en: 'Are you sure you want to delete the integration?',
        pt: 'Tem certeza que quer apagar a integração?',
      },
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La integración fue eliminada correctamente.',
        en: 'The integration was deleted.',
        pt: 'A integração foi deletada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  baseUrl: {
    es: 'URL Base',
    en: 'Base URL',
    pt: 'URL Base',
  },
}