import Vue from 'vue'

const mutations = {
  START_STORE(state) {
    state.ipRanges = []
    Vue.$log.info()
  },

  CREATE_IP_RANGE(state, ipRange) {
    state.ipRanges = [ipRange].concat(state.ipRanges)
  },

  UPDATE_IP_RANGE(state, ipRange) {
    let index = null
    state.ipRanges.some((element, i) => {
      if (element._id === ipRange._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.ipRanges[index] = ipRange
      const aux = JSON.parse(JSON.stringify(state.ipRanges))
      state.ipRanges = aux
    }
  },

  DELETE_IP_RANGE(state, ipRange) {
    let index = null
    state.ipRanges.some((element, i) => {
      if (element._id === ipRange._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.ipRanges.splice(index, 1)
    }
  }
}

export default mutations
