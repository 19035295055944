import Vue from 'vue'
import { BotService } from '../../models/bot/BotService'
import { NLU } from '../../models/bot/Nlu'
import { Availability } from '../../models/bot/Availability'
import { Bot } from '../../models/Bot'
import { USERS_PLATFORMS } from '@/constants/constants'
import botMaker from '../../languages/botMaker'
import { botTimeoutMessageValidationFactory, negativeSentimentOptionFactory } from '../factories'
function getUsersByChannel(usersByChannel) {
  let ubc = {}
  usersByChannel.forEach(
    u =>
      (ubc[u.user] = u.platforms.map(ubcp =>
        USERS_PLATFORMS.find(p => ubcp === p.value)
      ))
  )
  return ubc
}

const mutations = {
  // Bots views
  SET_BOTS: (state, bots) => {
    state.bots = bots
  },
  SET_OPTION: (state, option) => {
    state.botTimeoutMessageValidation = option
  },
  SET_DISAMBIGUATION: (state, disambiguationAction) => {
    state.bot.disambiguationAction = disambiguationAction
  },
  SET_NOTDIALOGDETECTED: (state, notDialogDetected) => {
    state.bot.notDialogDetected = notDialogDetected
  },
  UPDATE_SEARCH_FILTER: (state, value) => {
    state.filters.search = value
  },

  // Users administration
  SET_USER_TO_EDIT: (state, userId) => {
    state.userToEdit = userId
  },
  ADD_USER: (state, user) => {
    state.bot.service.users.push(user)
  },
  ADD_USER_BY_ID: (state, userId) => {
    const user = Vue._.find(state.users, u => {
      return u.id === userId
    })

    state.bot.service.users.push(user)
  },
  ADD_USERS: (state, users) => {
    state.bot.service.users = users
  },
  REMOVE_USER: (state, userEmail) => {
    state.bot.service.users = state.bot.service.users.filter(user => {
      return user.email !== userEmail
    })
  },
  SET_USER_MODEL: (state, user) => {
    Object.assign(state.user, user)
  },
  UPDATE_ADD_USER_PROMPT: (state, promptStatus) => {
    state.isAddUserPromptOpened = promptStatus
  },
  UPDATE_SELECT_USER_PROMPT: (state, promptStatus) => {
    state.isSelectUsersPromptOpened = promptStatus
  },
  RESET_ADD_BOT: state => {
    Vue.$log.info()
    let botService = new BotService()
    let nlu = new NLU()
    let availability = new Availability()
    state.bot = new Bot(botService, nlu, '', null, 0.6, availability, '')
  },
  RESET_SECTIONS_READY: state => {
    state.sectionReady = {
      generalSetting: false,
      usersAdministration: false,
      engineSettings: false,
      intentions: false,
      botAvailability: false
    }
  },
  SET_SECTION_READY_BASED_ON_ROL: (state, role) => {
    state.sectionReady = {
      generalSetting: !role.canEditBot.generalSettings,
      usersAdministration: !role.canEditBot.userSettings,
      engineSettings: !role.canEditBot.engineSettings,
      intentions: !role.canEditBot.intentions,
      botAvailability: !role.canEditBot.botAvailability
    }
  },
  SET_USERS: (state, users) => {
    state.users = users
  },

  SET_BOT_USERS: (state, users) => {
    let allUsers = users ? users : state.users

    if (state.bot.service.users.length > 0) {
      state.bot.service.users = allUsers.filter(u => {
        return state.bot.service.users.includes(u.id)
      })
    }
  },

  // Intentions
  SET_ADD_INTENT_PROMPT: (state, value) => {
    state.isAddIntentOpen = value
  },
  SET_INTENTION_ID_TO_EDIT: (state, value) => {
    state.editIntentionInternalId = value
  },

  SET_FORK(state, fork) {
    state.bot.fork = fork
    Vue.$log.info(fork)
  },

  SET_TIME_OFFSET(state, timeOffset) {
    state.bot.timeOffset = timeOffset
  },

  // BOT
  SET_BOT: (state, bot) => {
    state.bot = bot
    state.usersByChannelMap = getUsersByChannel(bot.service.usersByChannel)
  },
  // SET_BOT_COPY: (state, bot) => {
  //   state.botCopy = bot
  // },

  UPDATE_BOT_NO_INTENT_MESSAGE(state, newBotLanguage) {
    state.bot._noIntentMessage2 = state.bot._setNoIntentMessage2()
    state.bot._noIntentMessage = state.bot._setNoIntentMessage()
    if (state.bot._noIntentAction.key == 'responseText') {
      state.bot._noIntentAction = { name: botMaker.botMessageValidation.form.answers.table.text[
        newBotLanguage
      ], key: 'responseText', args: [state.bot._setNoIntentMessage()] }
    }
    state.negativeSentimentOption.positive.actions[0].args[0] = botMaker.addBot.engineSetting.negativeSentimentAction.firstMessage[
      newBotLanguage
    ]
  },

  UPDATE_BOT_CONNECTION_ERROR_MESSAGE(state) {
    state.bot._connectionErrorMessage = state.bot._setConnectionErrorMessage()
  },

  UPDATE_DISAMBIGUATION_MESSAGES(state){
    state.bot.disambiguationAction.message = state.bot._setDisambiguationMessage()
    state.bot.disambiguationAction.anythingElseActions.text = state.bot._setAnythingElseMessage()
    state.bot.disambiguationAction.anythingElseActions.actions[0].args[0] = state.bot._setAnythingElseResponseMessage()
  },

  UPDATE_AVAILABILITY_MESSAGE(state) {
    state.bot.availability.message = state.bot.availability._setUpMessage()
  },
  SET_TIMEOUT_VALIDATION(state, value) {
    state.hasTimeoutValidation = value
  },
  RESET_TIMEOUT_VALIDATION(state) {
    state.hasTimeoutValidation = false
    state.botTimeoutMessageValidation = botTimeoutMessageValidationFactory()
  },
  RESET_NEGATIVE_OPTION(state) {
    state.hasNegativeSentimentOption = false
    state.negativeSentimentOption = negativeSentimentOptionFactory()
  },
  SET_NEGATIVE_SENTIMENT_TAKEOVER(state, value) {
    state.hasNegativeSentimentOption = value
  },
  SET_WIDGET_SETTINGS_OPEN(state, value) {
    state.isWidgetSettingsOpen = value
  }
}

export default mutations
