import { conditionsFactory, botMessageValidationFactory } from '../factories'

const state = {
  sliderChange: {
    forward: null,
    msg_id: null
  },
  apiCalls: [],
  entities: [],
  bot: undefined,
  botId: undefined,
  userMessageInEdit: {},
  botMessageInEdit: {},
  botMakerMessages: [],

  // For storing all the messages available
  messages: [],

  dialogs: null,
  dialogMsgId: null,
  // map dialogId - dialogs data
  dialogsMap: {},

  // For handling message ids
  // prevMessage: undefined,

  // For animating buttons when clicking on add new message
  animateButtons: false,

  // For displaying alternative paths in the UI
  prevAlternativeMessages: [],
  nextAlternativeMessages: [],

  // For storing all intentions
  intentions: [],
  selectedIntention: {},
  changedMessageInEdit: false,
  // Handle user says tabs
  activeUserTab: 0,
  activeBotTab: 0,
  currentDialogHasJumps: false,
  currentDialogHasSurveys: false,
  currentDialogHasValidation: false,
  lastAlternativeDialogSavedId: undefined,
  conditionsGroups: {},
  conditionsFilters: conditionsFactory(),
  conditionName: null,
  secondLevelAttention: false,
  replyOutsideBusinessHours: false,
  secondLevelAttentionHasChanged: false,
  conditionTags: [],

  selectedEntity: {},
  newEntityName: null,

  activeIntention: null,
  activeEntity: null,
  // uploadProgress: 0,
  activeChannelView: 'all',
  responseDialogId: null,
  selectedResponse: null,
  responseIndex: null,
  responseValidations: null,
  botValidationEnabled: false,
  botMessageValidation: botMessageValidationFactory(),
  botMessageValidationApplyAll: false,
  validationUpdateMode: 'create',
  intentionsMap: {}, // version: itentions,
  cancelRequest: undefined,

  testGroups: undefined,
  selectedTestGroup: undefined,
  selectedTestCase: undefined,
  testCases: [],
  botVersions: [],
  activeVersion: undefined,
  fork: null,
  cyberbankVariables: null
}

export default state
