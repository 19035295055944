export default {
  view: {
    title: {
      es: 'Difusión',
      en: 'Diffusion',
      pt: 'Difusão'
    },
    addAudience: {
      es: 'Crear audiencia',
      en: 'Create Audience',
      pt: 'Criar Audiência'
    },
    table: {
      noDataText: {
        es: 'No se encontraron difusiones',
        en: 'No broadcasts found',
        pt: 'Nenhuma transmissão encontrada'
      },
      name: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome'
      },
      platform: {
        es: 'Plataforma',
        en: 'Platform',
        pt: 'Plataforma'
      },
      created: {
        es: 'Fecha de creación',
        en: 'Creation date',
        pt: 'Data de criação'
      },
      scheduledSend: {
        es: 'Envío programado',
        en: 'Scheduled delivery',
        pt: 'Entrega programada'
      },
      parameters: {
        es: 'Parámetros',
        en: 'Parameters',
        pt: 'Parametros'
      },
      count: {
        es: 'Cantidad',
        en: 'Quantity',
        pt: 'Quantidade'
      },
      empty: {
        es: 'Todavía no hay audiencias',
        en: 'There are no audiences yet',
        pt: 'Ainda não há audiência'
      },
      remove: {
        es: 'Eliminar',
        en: 'Remove',
        pt: 'Remover'
      },
      downloadAudience: {
        es: 'Descargar audiencia',
        en: 'Download audience',
        pt: 'Baixar audiência'
      },
      downloadAudienceError: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!'
        },
        text: {
          es: 'Error al descargar la audiencia.',
          en: 'Error downloading audience.',
          pt: 'Falha ao baixar o audiência.'
        }
      }
    }
  },
  add: {
    title: {
      es: 'Crear audiencia',
      en: 'Create audience',
      pt: 'Criar audiência'
    },
    wizard: {
      step: {
        es: 'Paso',
        en: 'Step',
        pt: 'Passo'
      },
      prev: {
        es: 'Anterior',
        en: 'Previous',
        pt: 'Anterior'
      },
      next: {
        es: 'Siguiente',
        en: 'Next',
        pt: 'Próxima'
      },
      finish: {
        es: 'Finalizar',
        en: 'Finish',
        pt: 'Finalizar'
      },
      cancel: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      },
      stepTitles: {
        step1: {
          es: 'Crear audiencia',
          en: 'Create audience',
          pt: 'Criar audiência'
        },
        step2: {
          es: 'Bot saliente',
          en: 'Outgoing bot',
          pt: 'Bot de saída'
        },
        step3: {
          es: 'Importar audiencia',
          en: 'Import audience',
          pt: 'Importar audiência'
        },
        step4: {
          es: 'Mensaje',
          en: 'Message',
          pt: 'Mensagem'
        },
        step5: {
          es: 'Envío',
          en: 'Delivery',
          pt: 'Envio'
        }
      },
      steps: {
        step1: {
          es: 'crear audiencia',
          en: 'create audience',
          pt: 'criar audiência'
        },
        step2: {
          es: 'seleccionar bot saliente',
          en: 'select outgoing bot',
          pt: 'selecione o bot de saída'
        },
        step3: {
          es: 'importar audiencia',
          en: 'import audience',
          pt: 'importar audiência'
        },
        step4: {
          es: 'Mensaje',
          en: 'Message',
          pt: 'Mensagem'
        },
        step5: {
          es: 'enviar',
          en: 'send',
          pt: 'enviar'
        }
      },
      audienceOperator: {
        new: {
          es: 'Nueva',
          en: 'New',
          pt: 'Novo'
        },
        import: {
          es: 'Importar',
          en: 'Import',
          pt: 'Importar'
        }
      },
      audienceName: {
        es: 'Nombre de audiencia *',
        en: 'Audience name *',
        pt: 'Nome da audiência *'
      },
      audienceSelector: {
        from: {
          es: 'Desde',
          en: 'From',
          pt: 'De'
        },
        selectBot: {
          es: 'Seleccione el bot',
          en: 'Select bot',
          pt: 'Selecione o bot'
        },
        downloadTemplate: {
          es: 'Descargar plantilla',
          en: 'Download template',
          pt: 'Descarregar modelo'
        },
        selectItems: {
          es: 'Seleccione items a importar',
          en: 'Select items to import',
          pt: 'Selecione itens para importar'
        },
        items: {
          name: {
            es: 'Nombre',
            en: 'Name',
            pt: 'Nome'
          },
          number: {
            es: 'Número',
            en: 'Number',
            pt: 'Número'
          }
        }
      },
      audienceChannel: {
        es: 'Seleccione un canal *',
        en: 'Select channel *',
        pt: 'Selecione o canal *'
      },
      audienceMessage: {
        new: {
          es: 'Agregar nuevo mensaje',
          en: 'Add new message',
          pt: 'Adicionar uma nova mensagem'
        }
      },
      audienceSend: {
        send: {
          es: 'Enviar',
          en: 'Send',
          pt: 'Enviar'
        },
        quickSend: {
          es: 'Envío rápido',
          en: 'Quick delivery',
          pt: 'Envio rápido'
        },
        scheduledSend: {
          title: {
            es: 'Programar envío',
            en: 'Schedule delivery',
            pt: 'Agendar envio'
          },
          datetime: {
            es: 'Fecha y hora',
            en: 'Datetime',
            pt: 'Data e hora'
          }
        }
      },
      validations: {
        emptyName: {
          es: 'Por favor ingrese nombre de audiencia',
          en: 'Please insert audience name',
          pt: 'Por favor, insira o nome da audiência'
        },
        existingName: {
          es: 'Ya existe una audiencia con ese nombre',
          en: 'Already exists an audience with that name',
          pt: 'Já existe uma audiência com esse nome'
        },
        emptyPlatform: {
          es: 'Por favor seleccione un canal',
          en: 'Please select channel',
          pt: 'Por favor, selecione o canal'
        },
        emptyIntegration: {
          es: 'Por favor seleccione Bot saliente ',
          en: 'Please select outgoing Bot',
          pt: 'Por favor, selecione o Bot que sai'
        },
        emptyHsmName: {
          es: 'Por favor ingrese nombre de elemento',
          en: 'Please insert element name',
          pt: 'Por favor, insira o nome do elemento'
        },
        emptyHsmLanguageCode: {
          es: 'Por favor ingrese código de lenguage',
          en: 'Please insert language code',
          pt: 'Por favor, insira o código do idioma'
        },
        hsmElementVariables: {
          es: 'El HSM "chatclub_southcone_welcome_v1" requiere 2 variables',
          en: 'HSM "chatclub_southcone_welcome_v1" requires 2 variables',
          pt: 'HSM "chatclub_sudcone_welcome_v1" requer 2 variáveis'
        },
        emptyImportValues: {
          es: 'Por favor seleccione los contactos a importar',
          en: 'Please select contacts to be imported',
          pt: 'Por favor, selecione contatos a serem importados'
        }
      },
      messages: {
        save: {
          success: {
            title: {
              es: 'Éxito!',
              en: 'Success!',
              pt: 'Sucesso!'
            },
            text: {
              es: 'Audiencia guardada correctamente.',
              en: 'Audience saved sucessfully.',
              pt: 'Audiência salva com sucesso.'
            }
          },
          error: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              es: 'Error al guardar audiencia.',
              en: 'Error saving audience.',
              pt: 'Erro em salvar a audiência.'
            }
          }
        }
      }
    }
  },
  edit: {
    title: {
      es: 'Editar audiencia',
      en: 'Edit audience',
      pt: 'Editar audiência'
    }
  },
  delete: {
    confirm: {
      title: {
        es: 'Confirmar',
        en: 'Confirm',
        pt: 'Confirme'
      },
      text: {
        es: '¿Está seguro que desea eliminar la audiencia?',
        en: 'Confirm delete audience?',
        pt: 'Confirmar a audiência deletar?'
      }
    }
  },
  quickMessage: {
    title: {
      es: 'Mensaje rápido',
      en: 'Quick message',
      pt: 'Mensagem rápida'
    }
  }
}
