export default {
  tabs: {
    general: {
      es: 'Generales',
      en: 'General',
      pt: 'Geral'
    },
    transitions: {
      es: 'Transiciones de chats',
      en: 'Chat transitions',
      pt: 'Transições de chats'
    },
    survey: {
      es: 'Encuesta',
      en: 'Survey',
      pt: 'Pesquisa'
    }
  },
  tabsTitle: {
    es: 'Configuraciones',
    en: 'Settings',
    pt: 'Configuração'
  },
  title: {
    es: 'Configuración de transiciones de Chats',
    en: 'Chat transitions settings',
    pt: 'Configuração das transições de Chats'
  },
  finish: {
    es: 'Finalización',
    en: 'Finish',
    pt: 'Finalização'
  },
  transfer: {
    es: 'Liberación',
    en: 'Transfer',
    pt: 'Transferência'
  },
  save: {
    es: 'Guardar',
    en: 'Save',
    pt: 'Salvar'
  },
  cancel: {
    es: 'Cancelar',
    en: 'Cancel',
    pt: 'Cancelar'
  },
  settings: {
    alertNoPd: {
      es: 'La encuesta no será enviada por no tener una versión EN VIVO',
      en: 'The survey will not be sent for not having a LIVE version',
      pt: 'A pesquisa não será enviada por não ter uma versão ao VIVO'
    },
    test: {
      es: 'Probar ahora',
      en: 'Test now',
      pt: 'Teste agora'
    },
    radios: {
      disabled: {
        es: 'Deshabilitado',
        en: 'Disabled',
        pt: 'Desabilitado'
      },
      optional: {
        es: 'Opcional',
        en: 'Optional',
        pt: 'Opcional'
      },
      required: {
        es: 'Requerido',
        en: 'Required',
        pt: 'Obrigatório'
      },
      single: {
        es: 'Único',
        en: 'Single',
        pt: 'Único'
      },
      multiple: {
        es: 'Múltiple',
        en: 'Multiple',
        pt: 'Múltiplos'
      }
    },
    tagging: {
      es: 'Etiquetado',
      en: 'Tagging',
      pt: 'Marcação'
    },
    noSurveyFail: {
      es: 'Debe elegir una encuesta',
      en: 'You must select a survey',
      pt: 'Você deve selecionar uma pesquisa'
    },
    confirmationFail: {
      es:
        'Para utilizar la confirmación de encuesta debes completar todos los campos requeridos',
      en:
        'To use the survey confirmation you must complete all required fields',
      pt:
        'Para usar a confirmação da pesquisa, você deve completar todos os campos necessários'
    },
    taggingPlaceholder: {
      es: 'Crear nuevo tag',
      en: 'Add new tag',
      pt: 'Adicione uma nova etiqueta'
    },
    comment: {
      es: 'Comentario',
      en: 'Comment',
      pt: 'Comentário'
    },
    defaultTransitionMessage: {
      es: 'Mensaje de cierre',
      en: 'Closing message',
      pt: 'Mensagem de encerramento'
    },
    defaultTransitionMessagePlaceholder: {
      es: 'Escribe un mensaje de cierre genérico.',
      en: 'Write a generic closing message.',
      pt: 'Escreva uma mensagem genérica de encerramento.'
    },
    surveyTitle: {
      es: 'Encuesta',
      en: 'Survey',
      pt: 'Pesquisa'
    },
    surveyEnabledIntegration: {
      es: 'Enviar encuesta',
      en: 'Send survey',
      pt: 'Enviar pesquisa'
    },
    noResult: {
      es: 'No se encontraron encuestas.',
      en: 'No surveys found.',
      pt: 'Nenhuma pesquisa encontrada.'
    },
    surveyEnabled: {
      es: 'Enviar encuesta al finalizar la atención',
      en: 'Send survey at the end of the service',
      pt: 'Envie uma pesquisa no final do serviço'
    },
    allOptionsAreDisabled: {
      es:
        'Todas las opciones están desactivadas. El agente no verá esta ventana flotante.',
      en:
        'All options are disabled. The agent will not see this floating window.',
      pt:
        'Todas as opções estão desativadas. O agente não verá esta janela flutuante.'
    },
    xThreshold: {
      es: 'Pendientes Leadtime 1 (minutos)',
      en: 'Pending Leadtime 1 (minutes)',
      pt: 'Tempo de entrega pendente 1 (minutos)'
    },
    yThreshold: {
      es: 'Pendientes Leadtime 2 (minutos)',
      en: 'Pending Leadtime 2 (minutes)',
      pt: 'Tempo de entrega pendente 2 (minutos)'
    }
  },
  notify: {
    success: {
      title: {
        es: 'Éxito!',
        en: 'Success!',
        pt: 'Sucesso!'
      },
      text: {
        es: 'Configuración actualizada exitosamente.',
        en: 'Setting updated successfully.',
        pt: 'Configuração atualizada com sucesso.'
      }
    },
    error: {
      title: {
        es: 'Error',
        en: 'Error',
        pt: 'Erro'
      },
      text: {
        es: 'Error actualizando configuración. Por favor, intente nuevamente.',
        en: 'Error updating setting. Please, try again.',
        pt: 'Erro em atualizar a configuração.'
      }
    }
  },
  errorThresholdsLimits: {
    title: {
      es: 'Error',
      en: 'Error',
      pt: 'Erro'
    },
    text: {
      es: 'Pendientes Leadtime 2 debe ser mayor que Pendientes Leadtime 1.',
      en: 'Pending Leadtime 2 must be greater than Pending Leadtime 1.',
      pt: 'O tempo de espera 2 deve ser maior que o tempo de espera 1.'
    }
  },
  transitions: {
    tagging: {
      placeholder: {
        es: 'Selecciona una etiqueta',
        en: 'Select a tag',
        pt: 'Selecione uma etiqueta'
      }
    },
    errors: {
      taggingRequired: {
        es: 'Etiquetado requerido.',
        en: 'Tagging required.',
        pt: 'A marcação é necessária.'
      },
      commentRequired: {
        es: 'Comentario requerido.',
        en: 'Comment required.',
        pt: 'Comentário necessário.'
      },
      rejectAccept: {
        es: 'Existen campos requeridos sin completar.',
        en: 'There are required fields not completed.',
        pt: 'Há campos obrigatórios não completados.'
      }
    },
    surveyDisclaimer: {
      es:
        'El usuario recibirá una encuesta de satisfacción luego de finalizada la interacción.',
      en:
        'The user will receive a satisfaction survey after the interaction ends.',
      pt:
        'O usuário receberá uma pesquisa de satisfação após a interação terminar.'
    }
  }
}
