const getters = {
  integrations: state => {
    return state.integrations
  },
  botChannels: state => {
    return state.botChannels
  },
  selectedChannel: state => {
    return state.selectedChannel
  }
}

export default getters
