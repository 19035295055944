import Request from './request'
import state from '../store/state'

const PATH = {
  CYBERBANK_APIS: '/apiCall/store',
  CYBERBANK_VARIABLES: '/dialog/defaultVariables'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const _getCyberbankApis = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${state.config.konecta}${PATH.CYBERBANK_APIS}`, config)
}

const _getCyberbankVariables = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.CYBERBANK_VARIABLES}`,
    config
  )
}

export default {
  getCyberbankApis: _getCyberbankApis,
  getCyberbankVariables: _getCyberbankVariables
}
