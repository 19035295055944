export default {
  condition: {
    es: 'Condición',
    en: 'Condition',
    pt: 'Condicional',
  },
  condition_arg: {
    es: 'Argumento',
    en: 'Argument',
    pt: 'Argumento'
  },
  title: {
    es: 'Acciones de Contexto',
    en: 'Context Actions',
    pt: 'Ações de Contexto',
  },
  addContextAction: {
    es: '+ Agregar acción de contexto',
    en: '+ Add context action',
    pt: '+ Adicionar ação de contexto',
  },
  other: {
    es: 'Personalizadas',
    en: 'Custom',
    pt: 'Personalizado',
  },
  noActions: {
    es: 'No hay acciones de contexto',
    en: 'There are not context actions',
    pt: 'Não há ações de contexto',
  },
  variable: {
    es: 'Variable',
    en: 'Variable',
    pt: 'Variável',
  },
  overwrite: {
    es: 'Guardar',
    en: 'Overwrite',
    pt: 'Sobreescrever',
  },
  append: {
    es: 'Concatenar',
    en: 'Append',
    pt: 'Concatenar',
  },
  drop: {
    es: 'Eliminar',
    en: 'Drop',
    pt: 'Excluir',
  },
  operation: {
    es: 'Operación',
    en: 'Operation',
    pt: 'Operação',
  },
  allText: {
    es: 'Todo el texto',
    en: 'All text',
    pt: 'Todo o texto',
  },
  markAsSensible: {
    es: 'Marcar como sensible',
    en: 'Set as sensible',
    pt: 'Marcar como sensível',
  },
  numbersOnly: {
    es: 'Sólo números',
    en: 'Numbers only',
    pt: 'Apenas números',
  },
  matchRexExp: {
    es: 'Match de RegExp',
    en: 'RegExp match',
    pt: 'Match de RegExp',
  },
  literal: {
    es: 'Valor',
    en: 'Value',
    pt: 'Valor',
  },
  copyVariable: {
    es: 'Copiar variable',
    en: 'Copy variable',
    pt: 'Copiar variável',
  },
  dropVariable: {
    es: 'Eliminar variable',
    en: 'Drop variable',
    pt: 'Variável de queda',
  },
  type: {
    es: 'Tipo',
    en: 'Type',
    pt: 'Tipo',
  },
  types: {
    all: {
      es: 'todo',
      en: 'all',
      pt: 'todo',
    },
    numbers: {
      es: 'números',
      en: 'numbers',
      pt: 'números',
    },
    match: {
      es: 'match',
      en: 'match',
      pt: 'match',
    },
    literal: {
      es: 'literal',
      en: 'literal',
      pt: 'literal',
    },
    copy: {
      es: 'copia',
      en: 'copy',
      pt: 'cópia',
    },
  },
  option: {
    es: 'Opción',
    en: 'Option',
    pt: 'Opção',
  },
  value: {
    es: 'Valor *',
    en: 'Value *',
    pt: 'Valor *',
  },
  variableToCopy: {
    es: 'Variable a copiar *',
    en: 'Variable to copy *',
    pt: 'Variável para copiar *',
  },
  modifiers: {
    es: 'Modificadores',
    en: 'Modifiers',
    pt: 'Modificadores',
  },
  cancelText: {
    es: 'Cancelar',
    en: 'Cancel',
    pt: 'Cancelar',
  },
  saveText: {
    es: 'Guardar',
    en: 'Save',
    pt: 'Salvar',
  },
  first: {
    es: 'primero',
    en: 'first',
    pt: 'Primeiro',
  },
  all: {
    es: 'todos',
    en: 'all',
    pt: 'todos',
  },
  last: {
    es: 'último',
    en: 'last',
    pt: 'a última',
  },
  copy: {
    es: 'copia',
    en: 'copy',
    pt: 'cópia',
  },
  reverse: {
    es: 'inverso',
    en: 'reverse',
    pt: 'inverso',
  },
  sort: {
    es: 'ordenado',
    en: 'sort',
    pt: 'ordenar',
  },
  success: {
    title: {
      es: 'Éxito',
      en: 'Success',
      pt: 'Sucesso',
    },
    text: {
      es: 'Las acciones de contexto fueron actualizadas correctamente.',
      en: 'The context actions were created.',
      pt: 'As ações de contexto foram criadas.',
    },
  },
  error: {
    title: {
      es: 'Oops!',
      en: 'Oops!',
      pt: 'Oops!',
    },
    text: {
      es: 'Parece que ocurrió un error.',
      en: 'Looks like something went wrong.',
      pt: 'Parece que algo deu errado.',
    },
  },
  validationError: {
    es: 'Oops!',
    en: 'Oops!',
    pt: 'Oops!',
  },
  validationMessage: {
    operator: {
      es: 'Operación inválida.',
      en: 'Invalid operator.',
      pt: 'Operador inválido.',
    },
    as_length: {
      es: 'Nombre de variable inválido.',
      en: 'Invalid variable name.',
      pt: 'Nome de variável inválido.',
    },
    as_alpha: {
      es: 'Nombre de variable inválido. Debe ser alfanumérico.',
      en: 'Invalid variable name. Must be alphanumeric.',
      pt: 'Nome de variável inválido, deve ser alfanumérico.',
    },
    option: {
      es: 'Opción inválida.',
      en: 'Invalid option.',
      pt: 'Opção inválida.',
    },
    regex: {
      es: 'RegExp inválida.',
      en: 'Invalid RegExp.',
      pt: 'RegExp inválido.',
    },
    modifiers: {
      es: 'Modificadores de RegExp inválidos.',
      en: 'Invalid RegExp modifiers.',
      pt: 'Modificadores RegExp inválidos.',
    },
    key: {
      es: 'Nombre de variable a copiar inválido.',
      en: 'Invalid variable to copy name.',
      pt: 'Variável inválida para copiar o nome.',
    },
  },
  chat: {
    userDataPopup: {
      title: {
        es: 'Tomas de control',
        en: 'Takeovers',
        pt: 'Assumir',
      },
      emptyTakeovers: {
        es: 'No hay tomas de control',
        en: 'There are no takeovers',
        pt: 'Não há tomadas de posses',
      },
    },
  },
}