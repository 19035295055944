export default {
  title: {
    es: 'Flujo de sesiones',
    en: 'Sessions Flow',
    pt: 'Fluxo de sessões',
  },
  text: {
    es: 'Seleccione un bot en el filtro para ver su flujo de sesiones',
    en: 'Select a bot in the filter to see its flow of sessions',
    pt: 'Selecione um bot no filtro para ver seu fluxo de sessões',
  },
}