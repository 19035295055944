export default {
  list: {
    title: {
      es: 'Organizaciones',
      en: 'Tenants',
      pt: 'Organizações'
    },
    add: {
      es: 'Crear Organización',
      en: 'Create Tenant',
      pt: 'Criar Organização'
    },
    table: {
      header: {
        name: {
          es: 'Nombre',
          en: 'Name',
          pt: 'Nome'
        },
        created: {
          es: 'Fecha de Creación',
          en: 'Creation Date',
          pt: 'Data de criação'
        },
        status: {
          es: 'Estado',
          en: 'Status',
          pt: 'Status'
        },
        enableDisable: {
          es: 'Habilitar / Deshabilitar',
          en: 'Enable / Disable',
          pt: 'Habilitar / Desativar'
        }
      },
      body: {
        statuses: {
          true: {
            es: 'Habilitado',
            en: 'Enabled',
            pt: 'Activado'
          },
          false: {
            es: 'Deshabilitado',
            en: 'Disabled',
            pt: 'Desativado'
          },
          all: {
            es: 'Todos',
            en: 'All',
            pt: 'Todos'
          }
        }
      },
      actions: {
        updateStatus: {
          title: {
            es: 'Cambiar estado',
            en: 'Change status',
            pt: 'Mude de status'
          },
          text: {
            es: "¿Está seguro que desea XXX la Organización 'YYY'?",
            en: "Are you sure you want XXX Tenant 'YYY'?",
            pt: "Tem certeza que quer XXX a Organização 'YYY'?"
          }
        },
        statuses: {
          enable: {
            es: 'habilitar',
            en: 'enable',
            pt: 'ativar'
          },
          disable: {
            es: 'deshabilitar',
            en: 'disable',
            pt: 'Desativar'
          }
        }
      }
    }
  },
  create: {
    title: {
      es: 'Nueva Organización',
      en: 'New Tenant',
      pt: 'Nova Organização'
    },
    form: {
      companyName: {
        label: {
          es: 'Nombre de la organización',
          en: 'Tenant name',
          pt: 'Nome da organização'
        },
        placeholder: {
          es: 'Mi Organización',
          en: 'My Tenant',
          pt: 'Minha Organização'
        },
        error: {
          es: 'Por favor, ingrese el nombre de la Organización',
          en: 'Please, enter Tenant name',
          pt: 'Por favor, digite o nome da Organização'
        }
      },
      useStaffManagement: {
        es: 'Usar Staff Management',
        en: 'Use Staff Management',
        pt: 'Usar Staff Management',
      },
      username: {
        label: {
          es: 'Usuario administrador (email)',
          en: 'Admin user (email)',
          pt: 'Usuário de administração (e-mail)'
        },
        placeholder: {
          es: 'admin@miOrganizacion.com',
          en: 'admin@myTenant.com',
          pt: 'admin@myTenant.com'
        },
        error: {
          es: 'Por favor, ingrese email del administrador',
          en: 'Please, enter admin email',
          pt: 'Por favor, digite o e-mail do administrador'
        }
      },
      password: {
        label: {
          es: 'Contraseña',
          en: 'Password',
          pt: 'Senha'
        },
        error: {
          required: {
            es: 'Por favor, ingrese contraseña',
            en: 'Please, enter password',
            pt: 'Por favor, digite a senha'
          },
          strength: {
            es: 'Por favor, verifique las reglas de la contraseña',
            en: 'Please, check password rules',
            pt: 'Por favor, verifique as regras da senha'
          }
        },
        show: {
          es: 'Mostrar contraseña',
          en: 'Show password',
          pt: 'Mostrar senha'
        },
        validations: {
          strength: {
            es: 'Seguridad de la contraseña',
            en: 'Password strength',
            pt: 'Força da senha'
          },
          containsEightCharacters: {
            es: 'Al menos 8 caracteres',
            en: 'At least 8 characters',
            pt: 'Pelo menos 8 caracteres'
          },
          containsNumber: {
            es: 'Al menos 1 dígito',
            en: 'At least 1 digit',
            pt: 'Pelo menos 1 dígito'
          },
          containsUppercase: {
            es: 'Al menos 1 mayúscula',
            en: 'At least 1 capital letter',
            pt: 'Pelo menos uma letra maiúscula'
          },
          containsSpecialCharacter: {
            es: 'Al menos 1 caractér especial',
            en: 'At least 1 special character',
            pt: 'Pelo menos 1 caracter especial'
          }
        }
      },
      confirmPassword: {
        label: {
          es: 'Confirmar contraseña',
          en: 'Confirm password',
          pt: 'Confirme a senha'
        },
        error: {
          required: {
            es: 'Por favor, reingrese contraseña',
            en: 'Please, re-enter password',
            pt: 'Por favor, entre novamente a senha'
          },
          mismatch: {
            es: 'Las contraseñas deben coincidir',
            en: 'Passwords must match',
            pt: 'As senhas devem coincidir'
          }
        }
      }
    },
    success: {
      es: 'Nueva Organización creada exitosamente!',
      en: 'New Tenant created successfully!',
      pt: 'Nova organização criada com sucesso!'
    },
    error: {
      es: 'No se pudo crear la Organización. Por favor, intente nuevamente.',
      en: 'There was an error creating the Tenant. Please, try again.',
      pt: 'Houve um erro na criação da Organização. Por favor, tente novamente.'
    }
  },
  messages: {
    list: {
      error: {
        es: 'Ha ocurrido un error al obtener las Organizaciones.',
        en: 'An error occurred while obtaining Tenants.',
        pt: 'Ocorreu um erro na obtenção das Organizações.'
      }
    },
    updateStatus: {
      error: {
        es:
          'Ha ocurrido un error al actualizar el estado de la Organización. Por favor, intente nuevamente.',
        en:
          "An error occurred while updating Tenant's status. Please, try again.",
        pt: 'Ocorreu um erro ao atualizar o status da Organização.'
      }
    }
  }
}
