<template>
  <div class="kona-alert" :class="size" :style="style">
    <feather-icon :icon="icon" v-if="icon" class="alert-icon"></feather-icon>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'kona-alert',
  props: {
    icon: String,
    bgColor: {
      type: String,
      default: 'rgba(var(--vs-danger), 0.15);'
    },
    color: {
      type: String,
      default: 'rgb(255, 71, 87);'
    },
    size: {
      type: String,
      validator: prop => ['small', 'default', 'large'].includes(prop),
      default: 'default'
    }
  },
  computed: {
    style() {
      return {
        background: this.bgColor,
        color: this.color
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.kona-alert {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;

  &.small {
    padding: 8px;
    font-size: 0.8rem;
  }

  .alert-icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
}
</style>
