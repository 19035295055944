import Vue from 'vue'
import { channelsFactory } from '../factories'

const mutations = {
  START_STORE(state) {
    state.integrations = []
    // state.botChannels = channelsFactory()
    Vue.$log.info()
  },

  CREATE_INTEGRATION(state, integration) {
    state.integrations = [integration].concat(state.integrations)
  },

  UPDATE_INTEGRATION(state, integration) {
    let index = null
    state.integrations.some((element, i) => {
      if (element._id === integration._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.integrations[index] = integration
      const aux = JSON.parse(JSON.stringify(state.integrations))
      state.integrations = aux
    }
  },

  UPDATE_BOT_CHANNEL(state, channel) {
    const botChannelIndex = state.botChannels.findIndex(
      b => b.type === channel.type
    )

    let botChannel = Object.assign({}, state.botChannels[botChannelIndex])
    botChannel.active = channel.active
    botChannel._id = channel._id
    Vue.set(state.botChannels, botChannelIndex, botChannel)
  },

  DELETE_INTEGRATION(state, integration) {
    let index = null
    state.integrations.some((element, i) => {
      if (element._id === integration._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.integrations.splice(index, 1)
    }
  },

  INIT_BOT_CHANNELS(state, botChannels) {
    botChannels.forEach(botChannel => {
      const botChannelIndex = state.botChannels.findIndex(
        b => b && b.type === botChannel.knownType
      )

      if (botChannelIndex > -1) {
        state.botChannels[botChannelIndex].active = botChannel.active
        state.botChannels[botChannelIndex]._id = botChannel._id
        state.botChannels[botChannelIndex].config = botChannel.config
        state.botChannels[botChannelIndex].meta = botChannel.meta
      }
    })
  },

  RESET_BOT_CHANNELS(state) {
    state.botChannels = channelsFactory()
  }
}

export default mutations
