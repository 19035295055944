export default {
  actionIcon: 'StarIcon',
  highlightColor: 'warning',
  data: [
    {
      index: 0,
      label: 'Home',
      url: '/',
      labelIcon: 'HomeIcon',
      highlightAction: false
    },
    {
      index: 1,
      label: 'Page 2',
      url: '/page2',
      labelIcon: 'CalendarIcon',
      highlightAction: false
    }
  ]
}
