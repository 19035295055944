const getters = {
  clusters: state => {
    return state.clusters
  },
  centers: state => clusterId => {
    return state.centers[clusterId]
  },
  questions: state => {
    return state.questions
  },
  questionsLength: state => {
    return state.questionsLength
  },
  questionsBotVersions: state => serviceId => {
    return state.questionsBotVersions[`${serviceId}`] || null
  },
  versionsById: state => {
    return state.versionsById
  }
}

export default getters
