const mutations = {
  SET_CHARTS: (state, charts) => {
    state.charts = state.charts.concat(charts)
  },

  RESET_CHARTS: state => {
    state.charts = []
    state.chartsData = {}
  },

  SET_CHARTS_DATA: (state, payload) => {
    state.chartsData[payload.chartId] = payload.data
  },

  SET_SURVEY_OPTIONS: (state, payload) => {
    state.surveyOptions[payload.surveyId] = payload.options
  },

  SET_SURVEY_EXTRA_OPTIONS: (state, payload) => {
    state.surveyExtraOptions[payload.surveyId] = payload.options
  }
}

export default mutations
