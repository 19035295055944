export default [
  {
    es: {
      name: 'Hablar con humano',
      examples: [
        'hablar con humano',
        'pasame con alguien',
        'pasame con alguna persona',
        'quiero hablar con alguien',
        'dame con un compañero',
        'pasame con una compañera',
        'quiero hablar con una persona',
        'dame con alguien'
      ],
      derivesToAgent: true
    },
    en: {
      name: 'Talk to human',
      examples: [
        'talk to human',
        'pass me someone',
        'pass me somebody',
        'I want to speak with someone',
        'give me with a partner',
        'pass me with a partner',
        'I want to talk to a person',
        'give me someone'
      ],
      derivesToAgent: true
    },
    pt: {
      name: 'Fale com um humano',
      examples: [
        'falar com humanos',
        'me passe para alguém',
        'eu quero falar com alguém',
        'me dê um parceiro',
        'me passe com um parceiro',
        'eu quero falar com uma pessoa',
        'eu gostaria de falar com alguém',
        'me transfira'
      ],
      derivesToAgent: true
    }
  },
  {
    es: {
      name: 'Saludo',
      examples: [
        'Hola',
        'Buenas',
        'Buenos días',
        'Buen día',
        'Buenas tardes',
        'Buenas noches',
        'Alo'
      ],
      derivesToAgent: false
    },
    en: {
      name: 'Greetings',
      examples: [
        'Hello',
        'Good',
        'Good Morning',
        'Good day',
        'Good afternoon',
        'Goodnight'
      ],
      derivesToAgent: false
    },
    pt: {
      name: 'Saudação',
      examples: ['Olá', 'Bom dia', 'Boa tarde', 'Boa noite', 'Oi'],
      derivesToAgent: false
    }
  }
]
