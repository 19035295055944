export default {
  title: {
    es: 'Intenciones',
    en: 'Intentions',
    pt: 'Intenções',
  },
  navbar: {
    es: 'Intenciones',
    en: 'Intentions',
    pt: 'Intenções',
  },
}