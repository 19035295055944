import { TextMessageType } from './TextMessageType'
import Validations from '../../../store/Validations'
import BotMakerService from '../../../services/botMaker.service'

export class IntentMessageType extends TextMessageType {
  constructor(intentId, name = undefined, examples = [], secondLevel = false) {
    super(name)
    this.intentId = intentId ? intentId : undefined
    this.examples = examples
    this.secondLevel = secondLevel
    this.exampleInput = ''
  }

  isIntentMessage() {
    return true
  }

  /**
   * Is form valid
   * @returns {*|boolean}
   */
  isValid() {
    return this.isNameValid() && this.hasExamples()
  }

  /**
   * Set intent selected
   * @param intent
   */
  setIntent(intent) {
    this.name = intent.name
    this.examples = intent.examples
    this.intentId = intent.id
    this.secondLevel = intent.derivesToAgent
  }

  /**
   * Has examples
   * @returns {boolean}
   */
  hasExamples() {
    return !Validations.isEmpty(this.examples)
  }

  /**
   * Has the example entered in the example input
   * @returns {boolean}
   */
  hasExampleInExampleInput() {
    return !!this.examples.find(example => {
      return example.toLowerCase() === this.exampleInput.toLowerCase()
    })
  }

  /**
   * Has example
   * @param name {String}
   * @returns {boolean}
   */
  hasExample(name) {
    return !!this.examples.find(example => {
      return example.toLowerCase() === name.toLowerCase()
    })
  }

  /**
   * Add example
   * @param anExample {String}
   */
  addExample() {
    this.examples.push(this.exampleInput)
    this.exampleInput = ''
  }

  /**
   * Remove example
   * @param exampleIndex {Number}
   */
  removeExample(exampleIndex) {
    this.examples.splice(exampleIndex, 1)
  }

  // ASYNC

  /**
   * Save intent
   * @param version {String}
   * @returns {Promise<Object>}
   */
  save(version) {
    return BotMakerService.createIntent(version, this.name, this.examples)
      .then(response => {
        this.intentId = response.data._id
        this.name = response.data.name
        return response
      })
      .catch(error => {
        return Promise.reject(error.response.data[0])
      })
  }

  /**
   * Update intent
   * @returns {Promise<Intent>}
   */
  update() {
    return BotMakerService.updateIntent(this.intentId, {
      name: this.name,
      examples: this.examples
    }).catch(error => {
      return Promise.reject(error.response.data[0])
    })
  }
}
