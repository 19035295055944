/**
 * Is String
 * @param value {Object} the value to be detected
 * @returns {boolean}
 * @private
 */
function _isString(value) {
  return typeof value === 'string' || value instanceof String
}

/**
 * Is an array
 * @returns {*|boolean}
 * @private
 * @param value {Object}
 */
function _isArray(value) {
  return value && typeof value === 'object' && value.constructor === Array
}

/**
 * Has special characters
 * @param {String} value validate if there are special characters
 * @returns {Boolean}
 */
const _hasSpecialCharacters = function(value) {
  if (_isString(value)) {
    return /[@,#,',",\\\\,/]/.test(value)
  }

  return true
}

/**
 * Validate if the object is empty
 * @param {Object} value the object to be evaluated
 * @returns {Boolean}
 */
const _isEmpty = function(value) {
  if (_isString(value)) {
    return value.length === 0 && value.replace(/\s/g, '').length === 0
  }

  if (_isArray(value)) {
    return value === undefined || value.length === 0
  }
}

/**
 * Validate email address
 * @param {String} value the email address to be validated
 * @returns {Boolean}
 */
const _isValidEmail = function(value) {
  if (_isString(value)) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(value).toLowerCase())
  }

  return false
}

/**
 * Validate date
 * @param {String} value the date to be validated
 * @returns {Boolean}
 */
const _isValidDate = function(value) {
  const moment = require('moment')
  const date = moment(value, 'YYYY-MM-DD HH:mm', true)
  const isValid = date.isValid()
  return isValid
}

/**
 * Validate date after today
 * @param {String} value the date to be validated
 * @returns {Boolean}
 */
const _isDateAfterNow = function(value) {
  const moment = require('moment')
  const date = moment(value)
  const now = moment()
  const isAfterNow = date.isAfter(now)
  return isAfterNow
}

/**
 * Validate date after today
 * @param {String} schedule the date to be schedule
 * * @param {String} expiration the date to expire
 * @returns {Boolean}
 */
const _isDateValidExpiration = function(schedule, expiration) {
  const moment = require('moment')
  const scheduleM = moment(schedule)
  const expirationM = moment(expiration)
  var duration = moment.duration(expirationM.diff(scheduleM))
  var minutes = duration.asMinutes() >= 10
  return minutes
}

const _isRegexValid = function(value) {
  if (value) {
    try {
      new RegExp(value)
      return true
    } catch {
      return false
    }
  }
}

export default {
  isValidDate: _isValidDate,
  isDateAfterNow: _isDateAfterNow,
  isDateValidExpiration: _isDateValidExpiration,
  isValidEmail: _isValidEmail,
  isEmpty: _isEmpty,
  hasSpecialCharacters: _hasSpecialCharacters,
  isRegexValid: _isRegexValid
}
