import ClustersService from '@/services/clusters.services'
import BotMakerService from '@/services/botMaker.service'

const actions = {
  getClusters({ commit }) {
    return ClustersService.getClusters().then(response => {
      commit('SET_CLUSTERS', response.data)
    })
  },

  getCenters({ commit }, clusterId) {
    return ClustersService.getCenters(clusterId).then(response => {
      commit('SET_CENTERS', { clusterId, centers: response.data })
    })
  },

  createCluster(context, payload) {
    return ClustersService.createCluster(payload)
  },

  getBotVersions({ commit }, serviceId) {
    return BotMakerService.getBotVersions(serviceId).then(response => {
      if (response.data && response.data[0]) {
        commit('SET_VERSIONS_BY_ID', response.data)
        commit('SET_QUESTIONS_BOT_VERSIONS', {
          service: serviceId,
          versions: response.data
        })
      }
    })
  },

  getQuestions({ state, commit }, queryParams = null) {
    if (state.cancelRequest) {
      state.cancelRequest('cancelled')
    }
    const executor = c => {
      commit('SET_CANCEL_REQUEST', c)
    }
    return ClustersService.getQuestions(queryParams, executor).then(
      response => {
        // filter empty questions
        // const questions = response.data.result.filter(
        //   q => q.input && q.input[0] && q.input[0] !== ''
        // )
        commit('SET_QUESTIONS', response.data.result)
        commit('SET_QUESTIONS_LENGTH', response.data.length)
      }
    )
  },

  setTrain(context, payload) {
    return ClustersService.setTrain(payload)
  }
}

export default actions
