import Validations from '../../store/Validations'
import { v4 as uuid } from 'uuid'

export class MessageType {
  constructor(name = '', responseId = null) {
    this.name = name
    this.responseId = responseId || uuid()
  }

  isNameValid() {
    return !Validations.isEmpty(this.name)
  }

  isValid() {
    throw new TypeError('Abstract class. Missing implementation')
  }

  getType() {
    return 'messageType'
  }

  isCardMessage() {
    throw new TypeError('Abstract class. Missing implementation')
  }

  isSliderMessage() {
    throw new TypeError('Abstract class. Missing implementation')
  }

  isTextMessage() {
    throw new TypeError('Abstract class. Missing implementation')
  }
  isRegexMessage() {
    throw new TypeError('Abstract class. Missing implementation')
  }
  isImageMessage() {
    throw new TypeError('Abstract class. Missing implementation')
  }
  isMediaMessage() {
    throw new TypeError('Abstract class. Missing implementation')
  }
  isIntentMessage() {
    throw new TypeError('Abstract class. Missing implementation')
  }
  generateMessageToSave() {
    throw new TypeError('Abstract class. Missing implementation')
  }
  save() {
    throw new TypeError('Abstract class. Missing implementation')
  }
  update() {
    throw new TypeError('Abstract class. Missing implementation')
  }
}
