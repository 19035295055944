export default {
  description: {
    es: 'Ingrese la información de la consulta del cliente para que sea contestada por personal de la empresa.',
    en: 'Please enter the information that you want to get an answer for.',
    pt: 'Por favor, digite a informação para a qual você quer obter uma resposta.',
  },
  inputs: {
    process: {
      es: 'Proceso/Producto',
      en: 'Process/Product',
      pt: 'Processo/Produto',
    },
    topic: {
      es: 'Proceso/Producto',
      en: 'Process/Product',
      pt: 'Processo/Produto',
    },
    subtopic: {
      es: 'Proceso/Producto',
      en: 'Process/Product',
      pt: 'Processo/Produto',
    },
    details: {
      title: {
        es: 'Detalle de consulta',
        en: 'Detail your question',
        pt: 'Detalhe sua pergunta',
      },
      description: {
        es: 'Te pedimos que nos brindes el mayor detalle posible así podemos ingresar una mejor respuesta',
        en: 'Please give us the maximun level of detail possible to be able to provide you with the best answer.',
        pt: 'Por favor, nos dê o nível máximo de detalhe possível para poder lhe dar a melhor resposta.',
      },
    },
  },
}