export default {
  errorJump: {
    es: 'No es posible agregar saltos si hay una encuesta activa',
    en: 'Not possible to add jumps if there is a survey active',
    pt: 'Não é possível adicionar saltos se houver uma pesquisa ativa'
  },
  import: {
    success: {
      es: 'Archivo importado con éxito',
      en: 'File imported successfully',
      pt: 'Arquivo importado com sucesso'
    },
    intent: {
      es: 'Importar intenciones',
      en: 'Import intentions',
      pt: 'Intenções de importação'
    },
    confirmation: {
      intent: {
        es: 'las intenciones',
        en: 'the intents',
        pt: 'as intenções'
      },
      dialog: {
        es: 'los diálogos',
        en: 'the dialogs',
        pt: 'os diálogos'
      },
      entity: {
        es: 'las entidades',
        en: 'the entities',
        pt: 'as entidades'
      },
      text: {
        es: '¿Está seguro que desea importar ${item} del archivo ${file}',
        en: 'Are you sure you want to import ${item} from the file ${file}',
        pt: 'Tem certeza que quer importar ${item} do arquivo ${file}'
      }
    },
    export: {
      es: 'Exportar bot',
      en: 'Export bot',
      pt: 'Exportar bot'
    },
    intent_import: {
      es: 'Importar intenciones',
      en: 'Import intentions',
      pt: 'Intenções de importação'
    },
    import_dialog_noFile: {
      es: 'Falta cargar achivo de diálogos',
      en: 'Missing dialogs file',
      pt: 'Falta o carregamento do arquivo de diálogos'
    },
    download_template: {
      es: 'Descargar template',
      en: 'Download template',
      pt: 'Descarregar template'
    },
    import_intent_noFile: {
      es: 'Falta cargar achivo de intenciones',
      en: 'Missing intent file',
      pt: 'Falta o carregamento do arquivo de intenções'
    },
    dialog_import: {
      es: 'Importar diálogos',
      en: 'Import dialogs',
      pt: 'Importar diálogos'
    },
    entity_import: {
      es: 'Importar entidades',
      en: 'Import entities',
      pt: 'Entidades importadoras'
    },
    import_entity_noFile: {
      es: 'Falta cargar achivo de entidades',
      en: 'Missing entity file',
      pt: 'Falta o carregamento do arquivo de entidades'
    },
    entity: {
      es: 'Importar entidades',
      en: 'Import entities',
      pt: 'Importar entidades'
    },
    dialog: {
      es: 'Importar diálogos',
      en: 'Import dialogs',
      pt: 'Importar diálogos'
    }
  },
  hasChildren: {
    es: 'No es posible agregar un salto en mitad de un flujo',
    en: 'Not possible to add a jump in the middle of a flow',
    pt: 'Não é possível adicionar um salto no meio de um fluxo'
  },
  hasChildrenSurvey: {
    es: 'No es posible agregar una encuesta en mitad de un flujo',
    en: 'Not possible to add a survey in the middle of a flow',
    pt: 'Não é possível adicionar uma pesquisa no meio de um fluxo'
  },
  errorSurvey: {
    es: 'No es posible agregar una encuesta si hay un salto activo',
    en: 'Not possible to add surveys if there is an active jump',
    pt: 'Não é possível adicionar pesquisas se houver um salto ativo'
  },
  loading: {
    es: 'Cargando',
    en: 'Loading',
    pt: 'Carregando'
  },
  title: {
    es: 'Bot maker',
    en: 'Bot maker',
    pt: 'Bot maker'
  },
  accept: {
    es: 'Aceptar',
    en: 'Accept',
    pt: 'Aceitar'
  },
  cancel: {
    es: 'Cancelar',
    en: 'Cancel',
    pt: 'Cancelar'
  },
  errorTitle: {
    es: 'Oops',
    en: 'Oops',
    pt: 'Oops'
  },
  formWithError: {
    es: 'El formulario tiene errores',
    en: 'The form has errors',
    pt: 'O formulário tem erros'
  },
  errorName: {
    es: 'Ya existe un bot con el mismo nombre.',
    en: 'A bot with the same name already exists.',
    pt: 'Já existe um bot com o mesmo nome.'
  },
  error: {
    es: 'Algo salio mal. Por favor intenta de vuelta mas tarde',
    en: 'Something went wrong. Please try again later',
    pt: 'Algo deu errado. Por favor, tente de novo mais tarde'
  },
  botsView: {
    title: {
      es: 'Chatbots',
      en: 'Chatbots',
      pt: 'Chatbots'
    },
    description: {
      es:
        'Aquí se muestran todos los bots de su cuenta. Para crear uno nuevo, haga click en "Nuevo bot" y siga los pasos.',
      en:
        'Here you can see all your bots. To create a new one click on "New bot" and follow the steps.',
      pt:
        'Aqui você pode ver todos os seus bots. Para criar um novo clique em "Novo bot" e seguir os passos.'
    },
    botList: {
      add: {
        es: 'Nuevo bot',
        en: 'New bot',
        pt: 'Novo bot'
      }
    },
    categories: {
      es: 'Categorías',
      en: 'Categories',
      pt: 'Categorias'
    },
    chanels: {
      es: 'Canales',
      en: 'Channels',
      pt: 'Canais'
    },
    category: {
      es: 'Categoría',
      en: 'Category',
      pt: 'Categoria'
    },
    search: {
      es: 'Buscar',
      en: 'Search',
      pt: 'Pesquisar'
    },
    filters: {
      es: 'Filtros',
      en: 'Filter',
      pt: 'Filtro'
    },
    edit: {
      es: 'Editar',
      en: 'Edit',
      pt: 'Editar'
    },
    notifications: {
      deleteBotSuccess: {
        es: 'Tu bot fue borrado exitosamente',
        en: 'Your bot has been deleted successfully.',
        pt: 'Seu bot foi deletado com sucesso.'
      },
      deleteBotError: {
        es: 'No hemos podido borrar tu bot, intenta mas tarde.',
        en: "We couldn't delete your bot. Try again later.",
        pt: 'Não foi possível deletar seu bot, tente novamente mais tarde.'
      }
    },
    tooltip: {
      whatsApp: {
        es: 'Probar bot con WhatsApp',
        en: 'Test bot with WhatsApp',
        pt: 'Teste de bot com WhatsApp'
      },
      test: {
        es: 'Probar bot. Necesitas al menos 2 condiciones.',
        en: 'Test bot. You will need at least 2 conditions',
        pt: 'Você precisará de pelo menos 2 condições'
      },
      edit: {
        es: 'Editar bot',
        en: 'Edit bot',
        pt: 'Editar bot'
      },
      delete: {
        es: 'Borrar bot',
        en: 'Delete bot',
        pt: 'Apagar bot'
      }
    },
    deleteBotWarning: {
      title: {
        es: 'Atención!',
        en: 'Warning!',
        pt: 'Aviso!'
      },
      text: {
        es:
          'Estas por borrar tu bot, esta acción es irreversible. ¿Estás seguro?',
        en:
          'You are about to delete your bot, this action is irreversible. Are you sure?',
        pt:
          'Você está prestes a apagar seu bot, esta ação é irreversível. Tem certeza?'
      }
    },
    whatsApp: {
      popup: {
        title: {
          es: 'Prueba con WhatsApp 📲',
          en: 'Test with WhatsApp 📲',
          pt: 'Teste com WhatsApp 📲'
        },
        text: {
          es: 'Para probar el bot ingresa tu número de WhatsApp',
          en: 'For bot testing, please insert your WhatsApp number',
          pt: 'Para testar o bot, digite seu número do WhatsApp'
        },
        placeholder: {
          es: 'Número',
          en: 'Number',
          pt: 'Número'
        },
        button: {
          accept: {
            es: 'Aceptar',
            en: 'Accept',
            pt: 'Aceitar'
          }
        }
      },
      title: {
        es: 'Exito!',
        en: 'Success!',
        pt: 'Sucesso!'
      },
      text: {
        es: 'Un nuevo mensaje llegará a tu teléfono',
        en: 'A new message will arrive on your phone',
        pt: 'Uma nova mensagem chegará no seu telefone'
      }
    }
  },
  editBot: {
    copyScript: {
      es: 'Copiar',
      en: 'Copy',
      pt: 'Copiar'
    },
    previewBot: {
      es: 'Previsualizar',
      en: 'Preview',
      pt: 'Visualização'
    },
    title: {
      es: 'Editar bot',
      en: 'Edit bot',
      pt: 'Editar bot'
    },
    legacyBot: {
      title: {
        es: 'El bot no puede ser editado.',
        en: 'The bot cannot be edited.',
        pt: 'O bot não pode ser editado.'
      }
    },
    saveBot: {
      es: 'Guardar cambios',
      en: 'Save changes',
      pt: 'Salvar as mudanças'
    },
    verifyToken: {
      es: 'Token de verificación',
      en: 'Verify token',
      pt: 'Token de verificação'
    },
    accessToken: {
      es: 'Token de acceso',
      en: 'Access token',
      pt: 'Token de acesso'
    },
    saveApiKey: {
      es: 'Enviar',
      en: 'Send',
      pt: 'Enviar'
    },
    doneApiKey: {
      es: 'APIKey enviada exitosamente!',
      en: 'APIKey successfully sent!',
      pt: 'APIKey foi enviada com sucesso!'
    },
    notDoneApiKey: {
      es:
        'Ha ocurrido un error al enviar la APIKey. Por favor, intente nuevamente.',
      en: 'An error has ocurred while sending APIKey. Pleas, try again.',
      pt: 'Um erro ocorreu ao enviar APIKey.'
    },
    notApiKey: {
      es: 'Ingrese APIKey',
      en: 'Enter APIKey',
      pt: 'Digite APIKey'
    },
    deleteDialog: {
      title: {
        es: 'Confirmar',
        en: 'Confirm',
        pt: 'Confirme'
      },
      text: {
        es:
          'Si borras este mensaje se borrarán todos los mensajes asociados. Esta acción es irreversible.',
        en:
          'If you delete this message, all associated messages will be deleted. This action is irreversible.',
        pt:
          'Se você apagar esta mensagem, todas as mensagens associadas serão excluídas. Esta ação é irreversível.'
      },
      textWithJump: {
        es:
          'Si borras este mensaje se borrarán todos los mensajes asociados. Esta acción es irreversible. \nEstá por eliminar el Diálogo al que salta la conversación cuando el Bot no identifica intención. Será necesario actualizar la sección de Configuración general del Chatbot. ¿Desea continuar?',
        en:
          'If you delete this message, all associated messages will be deleted. This action is irreversible. \nYou are about to delete the Dialog to which the conversation jumps to when the Bot is unable to identify an intention. It will be necessary to update the General Settings section. Do you wish to continue?',
        pt:
          'Se você apagar esta mensagem, todas as mensagens associadas serão excluídas. Esta ação é irreversível. \nVocê está prestes a apagar o Diálogo para a qual a conversa pula quando o Bot é incapaz de identificar uma intenção. Será necessário atualizar a seção Configurações Gerais. Você deseja continuar?'
      }
    },
    deleteMessage: {
      confirm: {
        text: {
          es:
            'Esta respuesta está siendo utilizada por otros canales. Eliminarla hará que desaparezca de todos los canales configurados. ¿Estás seguro que deseas continuar?',
          en:
            'This answer is being used by other channels. Deleting it will make it disappear from all configured channels. Are you sure you want to continue?',
          pt:
            'Esta resposta está sendo usada por outros canais. Eliminando-a fará desaparecer de todos os canais configurados. Tem certeza que quer continuar?'
        },
        accept: {
          es: 'Sí',
          en: 'Yes',
          pt: 'Sim'
        },
        cancel: {
          es: 'No',
          en: 'No',
          pt: 'Não'
        }
      },
      success: {
        title: {
          es: 'Exito!',
          en: 'Success!',
          pt: 'Sucesso!'
        },
        text: {
          es: 'El mensaje ha sido borrado con exito!',
          en: 'The message has been deleted successfully!',
          pt: 'A mensagem foi apagada com sucesso!'
        }
      }
    },
    userSays: {
      error: {
        missingIntentName: {
          es: 'Por favor ingresa el nombre de la intención.',
          en: 'Please enter the name of the intention.',
          pt: 'Por favor, digite o nome da intenção.'
        },
        missingEntityName: {
          es: 'Por favor ingresa el nombre de la entidad.',
          en: 'Please enter the name of the entity.',
          pt: 'Por favor, digite o nome da entidade.'
        },
        missingExamples: {
          es: 'Por favor ingresa al menos un ejemplo.',
          en: 'Please enter at least one example.',
          pt: 'Por favor, digite pelo menos um exemplo.'
        },
        duplicatedExample: {
          es: 'Ejemplo duplicado.',
          en: 'Duplicated example',
          pt: 'Exemplo duplicado'
        }
      }
    },
    botSays: {
      error: {
        es: 'Por favor ingresa una respuesta del bot',
        en: 'Please enter a bot response',
        pt: 'Por favor, digite uma resposta de bot'
      },
      errorImage: {
        es: 'Por favor sube una imagen',
        en: 'Please upload an image',
        pt: 'Por favor, carregue uma imagem'
      }
    }
  },
  addBot: {
    copySuccess: {
      text: {
        es: 'El código está disponible en el portapapeles',
        en: 'The code is available in clipboard',
        pt: 'O código está disponível no clipboard'
      }
    },
    widgetDefault: {
      chat: {
        message_placeholder: {
          es: 'Escriba su pregunta...',
          en: 'Write your question...',
          pt: 'Escreva sua pergunta...'
        },
        datemessage_placeholder: {
          es: 'Seleccione fecha',
          en: 'Select date',
          pt: 'Seleccione fecha'
        },
        noLocationPermissionMessage: {
          es: 'No fue posible obtener su ubicación, ingresela manualmente.',
          en: 'Unable to get your location, please enter it manually.',
          pt: 'Não foi possível obter sua localização, insira-o manualmente.'
        },
      },
      header: {
        title: {
          es: 'Asistente Virtual',
          en: 'Virtual Assistant',
          pt: 'Assistente Virtual'
        }
      },
      presentation: {
        text: {
          es: '¿Necesitas ayuda?',
          en: 'Do you need help?',
          pt: 'Precisa de ajuda?'
        }
      },
      welcome_message: {
        text: {
          es: 'Hola, soy tu asistente virtual. ¿Como puedo ayudarte?',
          en: "Hi, I'm your virtual assistant. How can I help you?",
          pt: 'Olá, sou seu assistente virtual. Como posso ajudar?'
        }
      }
    },
    facebookConfig: {
      title: {
        es: 'Configuración de Facebook Messenger',
        en: 'Facebook Messenger setup',
        pt: 'Configuração do Facebook Messenger'
      }
    },
    channelConfig: {
      es: 'Activo',
      en: 'Active',
      pt: 'Ativo'
    },
    fieldShowWhenActive: {
      es: 'Este campo estará disponible cuando el canal este activo.',
      en: 'This field will be available when the channel is active.',
      pt: 'Este campo estará disponível quando o canal estiver ativo.'
    },
    active: {
      es: 'Activo',
      en: 'Active',
      pt: 'Ativo'
    },
    fieldError:{
      es: 'Por favor ingrese un valor para este campo.',
      en: 'Please enter a value for this field.',
      pt: 'Por favor, insira um valor para este campo.'
    },
    version: {
      es: 'Version',
      en: 'Versión',
      pt: 'Versão'
    },
    widget: {
      colors: {
        colors: {
          es: 'Colores',
          en: 'Colors',
          pt: 'Cores'
        },
        text: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        },
        background: {
          es: 'Fondo',
          en: 'Background',
          pt: 'Fundo'
        },
        links: {
          es: 'Enlaces',
          en: 'Links',
          pt: 'Links'
        },
        border: {
          es: 'Bordes',
          en: 'Borders',
          pt: 'Borda'
        },
        header: {
          es: 'Encabezado',
          en: 'Header',
          pt: 'Cabeçalho'
        },
        botMessages: {
          es: 'Colores de mensajes del bot',
          en: 'Bot messages colors',
          pt: 'Cores das mensagens do bot'
        },
        userMessages: {
          es: 'Colores de mensajes de ususarios',
          en: 'User messages colors',
          pt: 'Cores da mensagem do usuário'
        },
        buttons: {
          es: 'Color de los botones',
          en: 'Buttons colors',
          pt: 'Cores dos botões'
        }
      },
      desktopMobile: {
        es: 'Especificaciones para burbuja de tamaño grande',
        en: 'Specifications for the big bubble',
        pt: 'Especificações para bolha de tamanho grande'
      } ,
      presentation: {
        es: '¿Cómo se presentará el widget?',
        en: 'How will the widget be presented?',
        pt: 'Como o widget será apresentado?'
      },
      scriptBot: {
        es: 'Script',
        en: 'Script',
        pt: 'Script'
      },
      scriptVersion: {
        es: 'Versión',
        en: 'Version',
        pt: 'Versão'
      },
      copy:{
        script: {
          es: 'Copiar Script',
          en: 'Copy Script',
          pt: 'Copiar Script'
        },
        link: {
          es: 'Copiar Link',
          en: 'Copy Link',
          pt: 'Copiar Link'
        }
      },
      messageSize: {
        es: 'Ancho mensaje del bot',
        en: 'Bot message width',
        pt: 'Largura da mensagem de bot'
      },
      fontSizes: {
        containerSubtitleText: {
          es: 'Tamaño texto del Chat (px)',
          en: 'Chat font size(px)',
          pt: 'Tamanho texto do Chat (px)'
        },
        containerSubtitleButton: {
          es: 'Tamaño texto botón (px)',
          en: 'Button font size (px)',
          pt: 'Tamanho fonte do botão (px)'
        },
        containerTitle: {
          es: 'Ajustes de fuente',
          en: 'Font settings',
          pt: 'Configuração do tipo de letra'
        },
        containerSubtitle: {
          es: 'Tamaño (px)',
          en: 'Font size (px)',
          pt: 'Tamanho (px)'
        },
        presentation: {
          es: 'Presentación',
          en: 'Presentation',
          pt: 'Apresentação'
        },
        header: {
          title: {
            es: 'Título Header',
            en: 'Header Title',
            pt: 'Título do cabeçalho'
          },
          subtitle: {
            es: 'Subtítulo Header',
            en: 'Header Subtitle',
            pt: 'Legenda do cabeçalho'
          }
        },
        chat: {
          es: 'Chat',
          en: 'Chat',
          pt: 'Chat'
        },
        placeholder: {
          es: 'Placeholder',
          en: 'Placeholder',
          pt: 'Placeholder'
        },
        button: {
          es: 'Botones',
          en: 'Buttons',
          pt: 'Botões'
        }
        // Will have an single implementation (Per card)
        // card: {
        //   title: '0.85rem',
        //   subtitle: '0.85rem',
        //   button: '14px'
        // }
      },
      disable: {
        es: 'Deshabilitar',
        en: 'Disable',
        pt: 'Desativar'
      },
      enable: {
        es: 'Habilitar',
        en: 'Enable',
        pt: 'Habilitar'
      },
      medium: {
        es: 'Medio',
        en: 'Medium',
        pt: 'Médio'
      },
      large_width: {
        es: 'Largo',
        en: 'Large',
        pt: 'Grande'
      },
      large: {
        es: 'Grande',
        en: 'Large',
        pt: 'Grande'
      },
      small: {
        es: 'Pequeño',
        en: 'Small',
        pt: 'Pequeno'
      },
      restoreDefault: {
        es: 'Restaurar',
        en: 'Restore Default',
        pt: 'Restaurar o padrão'
      },
      position: {
        es: 'Posición',
        en: 'Position',
        pt: 'Posição',
        bottom:{
          es: 'Inferior',
          en: 'Bottom',
          pt: 'Inferior',
        },
        right:{
          es: 'Derecha',
          en: 'Right',
          pt: 'Dereita'
        }
      },
      width: {
        es: 'Ancho',
        en: 'Width',
        pt: 'Largura'
      },
      height: {
        es: 'Altura',
        en: 'Height',
        pt: 'Altura'
      },
      borderRadius: {
        es: 'Radio del borde',
        en: 'Border Radius',
        pt: 'Rádio de borda',
        corner:{
          bottomLeft:{
            es: 'Inferior izquierdo',
            en: 'Bottom Left',
            pt: 'Inferior esquerdo',
          },
          bottomRight:{
            es: 'Inferior derecho',
            en: 'Bottom Right',
            pt: 'Inferior direito',
          },
          topLeft:{
            es: 'Superior izquierdo',
            en: 'Top Left',
            pt: 'Superior esquerdo',
          },
          topRight:{
            es: 'Superior derecho',
            en: 'Top Right',
            pt: 'Superior dereito',
          }
        }
      },
      imageSize: {
        es: 'Medidas de la burbuja (px)',
        en: 'Bubble measures (px)',
        pt: 'Medidas de bolha'
      },
      desktop: {
        es: 'Escritorio',
        en: 'Desktop',
        pt: 'Desktop'
      },
      mobile: {
        es: 'Móvil',
        en: 'Mobile',
        pt: 'Móvel'
      },
      tooltip: {
        script: {
          es: 'Copia este código en tu página para agregar el widget del bot',
          en: 'Copy this code into your page to add the bot widget',
          pt: 'Copiar este código em sua página para adicionar o widget bot'
        },
        scriptVersion: {
          es:
            'Puedes seleccionar una versión específica para probar en un ambiente de pre-producción. Recuerda que en este caso el bot siempre utilizará la versión pre fijada, independientemente de qué versión está en vivo',
          en:
            'You can select a specific version to test in a pre-production environment. Remember that in this case the bot will always use the pre-set version, regardless of which version is live',
          pt:
            'Você pode selecionar uma versão específica para testar em um ambiente de pré-produção. Lembre-se que neste caso o bot sempre usará a versão pré-configurada, independente de qual versão está ativa'
        },
        padding: {
          es: 'Espacio entre la imagen y el borde de la burbuja (px)',
          en: 'Space between the image and the bubble border (px)',
          pt: 'Espaço entre a imagem e a borda da bolha (px)'
        },
        reload: {
          es:
            'Habilitar el botón de reload ↻ en el widget para reiniciar el chat con un nuevo mensaje',
          en:
            'Enable reload button ↻ on the widget to restart chat with a new message',
          pt:
            'Ativar o botão de recarga no widget para reiniciar o chat com uma nova mensagem'
        }
      },
      padding: {
        title: {
          es: 'Padding(px)',
          en: 'Padding (px)',
          pt: 'Padding (px)'
        },
        top: {
          es: 'Arriba',
          en: 'Top',
          pt: 'Acima',
         },
         right: {
          es: 'Derecha',
          en: 'Right',
          pt: 'Direito',
         },
         left: {
          es: 'Izquierda',
          en: 'Left',
          pt: 'Esquerda',
         },
         bottom: {
          es: 'Abajo',
          en: 'Bottom',
          pt: 'Abaixo',
         }
      },
      bubbleTitle: {
        es: 'Burbuja del Widget',
        en: 'Widget bubble',
        pt: 'Bolha de widget',
      },
      webPanelTitle: {
        es: 'Personaliza la apariencia de Konecta',
        en: 'Customize appearance for Konecta',
        pt: 'Personalize a aparência do Konecta',
      },
      headerTitle: {
        es: 'Encabezado',
        en: 'Header',
        pt: 'Cabeçalho'
      },
      chatAreaTitle: {
        es: 'Area de chat',
        en: 'Chat area',
        pt: 'Área de chat'
      },
      actionTitle: {
        es: 'Acciones',
        en: 'Actions',
        pt: 'Ações'
      },
      footer: {
        es: 'Footer',
        en: 'Footer',
        pt: 'Footer'
      },
      chatLink: {
        show: {
          es: 'Mostrar footer',
          en: 'Show footer',
          pt: 'Mostrar footer'
        },
        description: {
          es: 'Se mostrará abajo de la caja de texto el mensaje: ',
          en: 'The message will be displayed below the text box: ',
          pt: 'A mensagem será exibida abaixo da caixa de texto: '
        }
      },
      versionScriptsTitle: {
        es: 'Versión y scripts',
        en: 'Version & Scripts',
        pt: 'Versão e scripts'
      },
      chatWindowTitle: {
        es: 'Ventana de chat',
        en: 'Chat window',
        pt: 'Chat window'
      },
      bubbleSize: {
        es: 'Tamaño de la burbuja',
        en: 'Size',
        pt: 'Tamanho da bolha'
      },
      titleError: {
        es: 'Atención!',
        en: 'Warning!',
        pt: 'Aviso!'
      },
      acceptError: {
        es: 'Aceptar',
        en: 'Accept',
        pt: 'Aceitar'
      },
      openError: {
        es:
          'Este widget aún no ha sido habilitado. Para habilitarlo por la primera vez, por favor contáctenos en',
        en:
          'This widget has not been enabled yet. To enable it for the first time, please contact us at ',
        pt:
          'Este widget ainda não foi ativado. Para habilitá-lo pela primeira vez, entre em contato conosco em'
      },
      resetUserLogo: {
        es: 'Restaurar logo por defecto',
        en: 'Restore default logo',
        pt: 'Restaurar o logotipo padrão'
      },
      eraseBg: {
        es: 'Borrar imagen de fondo',
        en: 'Erase background image',
        pt: 'Apagar a imagem de fundo'
      },
      background: {
        es: 'Imagen de fondo',
        en: 'Background image',
        pt: 'Imagem de fundo'
      },
      disableAvatarUser: {
        es: 'Ocultar Avatar de Usuario',
        en: 'Disable Avatar User',
        pt: 'Ocultar Avatar do Usuário'
      },
      placeholder: {
        es: 'Input Placeholder',
        en: 'Input Placeholder',
        pt: 'Input Placeholder'
      },
      dateplaceholder: {
        es: 'DatePicker Placeholder',
        en: 'DatePicker Placeholder',
        pt: 'DatePicker Placeholder'
      },
      noLocationPermissionMessage: {
        es: 'Mensaje para obtener ubicación manualmente.',
        en: 'Message to get location manually.',
        pt: 'Mensagem para obter a localização manualmente.'
      },
      defaultConfig: {
        restore: {
          es: 'Restaurar configuración por defecto',
          en: 'Restore default settings',
          pt: 'Restaurar as configurações padrão'
        },
        textRestore: {
          es:
            '¿Está seguro que desea restablecer a la última configuración por defecto guardada?',
          en: 'Are you sure you want to restore default settings?',
          pt: 'Tem certeza que quer restaurar as configurações padrão?'
        },
        text: {
          es: '¿Deseas establecer esta configuración como default?',
          en: 'Are you sure you want to set these settings as default?',
          pt: 'Tem certeza que quer definir essas configurações como padrão?'
        },
        textConfirm: {
          es: 'Confirmar cambios',
          en: 'Confirm changes',
          pt: 'Confirme as mudanças'
        }
      },
      botMessage: {
        text: {
          es: 'Color Texto Bot',
          en: 'Bot Text Color',
          pt: 'Cor do Texto Bot'
        },
        bg: {
          es: 'Color Fondo Bot',
          en: 'Bot Background color',
          pt: 'Cor de fundo do bot'
        },
        link: {
          es: 'Color Enlaces Bot',
          en: 'Bot Links color',
          pt: 'Bot Links cor'
        }
      },
      buttons: {
        text: {
          es: 'Color Texto Botones',
          en: 'Buttons Text Color',
          pt: 'Botões Cor do texto'
        },
        bg: {
          es: 'Color Fondo botones',
          en: 'Buttons Background color',
          pt: 'Botões Cor de fundo'
        },
        border: {
          es: 'Color Borde Botones',
          en: 'Buttons border color',
          pt: 'Botões de cor borda'
        }
      },
      userMessage: {
        text: {
          es: 'Color Texto Usuario',
          en: 'User Text Color',
          pt: 'Cor do texto do usuário'
        },
        bg: {
          es: 'Color fondo Usuario',
          en: 'User Background color',
          pt: 'Cor do fundo do usuário'
        },
        link: {
          es: 'Color Enlaces Usuario',
          en: 'User Links color',
          pt: 'Cor do Links de usuário'
        }
      },
      reaction: {
        es: 'Habilitar reacción de validación',
        en: 'Enable reaction from validation',
        pt: 'Habilitar a reação da validação'
      },
      bubble: {
        text: {
          es: 'Texto Burbuja',
          en: 'Bubble Text',
          pt: 'Texto de bolha'
        },
        image: {
          es: 'Imagen Burbuja',
          en: 'Bubble Image',
          pt: 'Imagem da bolha'
        },
        color: {
          es: 'Color Burbuja',
          en: 'Bubble Color',
          pt: 'Cor da bolha'
        }
      },
      header: {
        color: {
          text: {
            es: 'Color Texto Header',
            en: 'Header Text Color',
            pt: 'Cor do texto do cabeçalho'
          },
          bg: {
            es: 'Color fondo Header',
            en: 'Header Background color',
            pt: 'Cor do fundo do cabeçalho'
          }
        },
        title: {
          es: 'Título',
          en: 'Title',
          pt: 'Título'
        },
        subtitle: {
          es: 'Subtítulo',
          en: 'Subtitle',
          pt: 'Legenda'
        },
        image: {
          es: 'Imagen',
          en: 'Image',
          pt: 'Imagem'
        }
      },
      domain: {
        es: 'Dominio',
        en: 'Domain',
        pt: 'Domínio'
      },
      title: {
        es: 'Widget',
        en: 'Widget',
        pt: 'Widget'
      },
      history: {
        es: 'Histórico',
        en: 'History',
        pt: 'Histórico'
      },
      reload: {
        es: 'Reload',
        en: 'Reload',
        pt: 'Recarregar'
      },
      text: {
        es: 'Texto',
        en: 'Text',
        pt: 'Texto de apresentação'
      },
      onlyImage: {
        es: 'Selecciona una imagen',
        en: 'Select an image',
        pt: 'Selecione uma imagem'
      },
      image: {
        es: 'Imagen',
        en: 'Image',
        pt: 'Imagem'
      },
      dataState: {
        es: 'Mensaje de inicio (DataState)',
        en: 'Start message (DataState)',
        pt: 'Início da mensagem (DataState)'
      },
      success: {
        title: {
          es: 'Éxito!',
          en: 'Success!',
          pt: 'Sucesso!'
        },
        text: {
          es: 'El widget fue actualizada con éxito',
          en: 'The widget was updated successfully.',
          pt: 'O widget foi atualizado com sucesso.'
        }
      },
      startMinimized: {
        es: 'Comenzar minimizado',
        en: 'Start minimized',
        pt: 'Iniciar minimizado'
      },
      enableAudioMessages: {
        es: 'Habilitar mensajes de audio',
        en: 'Enable audio messages',
        pt: 'Habilitar mensagens de áudio'
      },
      disableEmojis: {
        es: 'Deshabilitar emojis',
        en: 'Disable emojis',
        pt: 'Desativar emojis'
      },
      enableFiles: {
        es: 'Habilitar mensajes con archivos',
        en: 'Enable file messages',
        pt: 'Habilitar mensagens com arquivos'
      },
      mobileVersion: {
        es: 'Link para versión móvil',
        en: 'Link for Mobile version',
        pt: 'Link para versão mobile'
      },
      color: {
        es: 'Color de fondo',
        en: 'Background Color',
        pt: 'Cor da fundo'
      },
      textColorPresentation: {
        es: 'Color del texto',
        en: 'Text color',
        pt: 'Cor do texto'
      },
      logoBot: {
        es: 'Avatar para el bot',
        en: 'Avatar bot for chat',
        pt: 'Avatar para o bot'
      },
      logoUser: {
        es: 'Avatar para el usuario',
        en: 'Avatar user for chat',
        pt: 'Avatar para o usuário'
      },
      chatColor: {
        es: 'Color de Chat',
        en: 'Chat Color',
        pt: 'Cor do Chat'
      },
      chatFont: {
        es: 'Fuente del Chat',
        en: 'Chat Font Family',
        pt: 'Fonte do Chat'
      },
      textColor: {
        es: 'Color Texto',
        en: 'Text Color',
        pt: 'Cor do texto'
      },
      welcomeMessage: {
        es: 'Saludo Inicial',
        en: 'Welcome Message',
        pt: 'Mensagem de boas-vindas'
      },
      display: {
        es: 'Presentación',
        en: 'Presentation',
        pt: 'Apresentação'
      },
      chat: {
        es: 'Chat',
        en: 'Chat',
        pt: 'Conversa'
      },
      error: {
        text: {
          empty: {
            es: 'Texto de presentación no puede estar vacío',
            en: 'Presentation text cannot be empty',
            pt: 'Texto de apresentação não pode estar vazio'
          }
        },
        welcomeMessage: {
          empty: {
            es: 'Mensaje de Bienvenida no puede estar vacío',
            en: 'Welcome Message cannot be empty',
            pt: 'Mensagem de boas-vindas não pode estar vazia'
          }
        },
        domain: {
          empty: {
            es: 'Dominio no puede estar vacío',
            en: 'Domain cannot be empty',
            pt: 'O domínio não pode estar vazio'
          }
        },
        image: {
          empty: {
            es: 'La imagen no puede estar vacío',
            en: 'Image cannot be empty',
            pt: 'A imagem não pode estar vazia'
          }
        }
      }
    },
    title: {
      es: 'Nuevo Bot',
      en: 'New bot',
      pt: 'Novo Bot'
    },
    deleteBotPrompt: {
      title: {
        es: 'Borrar bot',
        en: 'Delete bot',
        pt: 'Apagar bot'
      },
      message: {
        es:
          '¿Estás seguro que quieres borrar el bot? Ten en cuenta que esta acción es permanente.',
        en:
          'Are you sure you want to delete the bot? Keep in mind that this action is permanent.',
        pt:
          'Tem certeza que quer apagar o bot? Observe que esta ação é permanente.'
      }
    },
    basicSettings: {
      title: {
        es: 'Configuración básica',
        en: 'Basic settings',
        pt: 'Configurações básicas'
      }
    },
    generalSettings: {
      title: {
        es: 'Configuración general',
        en: 'General settings',
        pt: 'Configurações gerais'
      },
      name: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome'
      },
      nameValidation: {
        es: 'Por favor ingrese un nombre para su bot',
        en: 'Please enter a name for your bot',
        pt: 'Por favor, digite um nome para o seu bot'
      },
      description: {
        es: 'Descripción',
        en: 'Description',
        pt: 'Descrição'
      },
      descriptionPlaceholder: {
        es: 'Ingrese la descripción de su bot',
        en: 'Enter your bot description',
        pt: 'Digite a descrição de seu bot'
      },
      channels: {
        es: 'Canales:',
        en: 'Channels:',
        pt: 'Canais:'
      },
      error: {
        name: {
          empty: {
            es: 'Por favor ingrese un nombre válido para su bot.',
            en: 'Please enter a valid bot name.',
            pt: 'Por favor, digite um nome de bot válido.'
          },
          invalid: {
            es: 'Por favor no utilice caracteres especiales.',
            en: 'Please do not use special characters.',
            pt: 'Por favor, não use caracteres especiais.'
          }
        },
        channel: {
          es: 'Por favor seleccione un canal',
          en: 'Please select a channel.',
          pt: 'Por favor, selecione um canal.'
        },
        description: {
          empty: {
            es: 'Por favor ingrese una descripción.',
            en: 'Please enter a description.',
            pt: 'Por favor, digite uma descrição.'
          },
          invalid: {
            es: 'Por favor no utilice caracteres especiales.',
            en: 'Please do not use special characters.',
            pt: 'Por favor, não use caracteres especiais.'
          }
        },
        notDialogDetected: {
          empty: {
            es: 'Por favor ingrese la URL del webhook',
            en: 'Please enter the URL of the webhook',
            pt: 'Por favor, digite uma URL do webhook',
          },
        },
      },
      form: {
        noIntentMessage: {
          es:
            'Lo siento, no entendí. ¿Podría reformular la pregunta, por favor?',
          en:
            'Sorry I did not understand. Could you rephrase the question, please?',
          pt:
            'Desculpe, não entendi. Você poderia reformular a pergunta, por favor?'
        },
        connectionErrorMessage: {
          es:
            'Lo siento, pero he perdido momentáneamente la comunicación. ¿Podría por favor escribir nuevamente?',
          en:
            'I am sorry but I temporarily lost communication. Would you please repeat it for me?',
          pt:
            'Desculpe, mas perdi temporariamente a comunicação. Você poderia escrever novamente?'
        },
        inactivityMessage: {
          es: '',
          en: '',
          pt: ''
        },
        noIntentMessage2: {
          es:
            'No estoy logrando entender; por favor, espera en línea y un agente te asistirá',
          en:
            'I am not understanding you, I leave you in contact with an agent so that he can help you better.',
          pt:
            'Não estou conseguindo entender, deixo você em contato com um agente para que ele possa te ajudar melhor.'
        },
        disambiguationMessage: {
          es:
            'Lo lamento, no entendí tu pregunta. Tal vez quisiste preguntar por:',
          en:
            "I'm sorry, I didn't understand your question. Maybe you wanted to ask about:",
          pt:
            'Desculpe, não entendi sua pergunta. Talvez você quisesse perguntar sobre:'
        },
        anythingElseMessage: {
          es: 'Ninguna de las anteriores',
          en: 'None of the above',
          pt: 'Nenhum dos acima'
        },
        anythingElseResponseMessage: {
          es: 'Entiendo, puedo ayudarte con algo más?',
          en: 'I understand, can I help you with something else?',
          pt: 'Entendo, posso ajudá-lo com outra coisa?'
        }
      }
    },
    empathicEngine: {
      title: {
        es: 'Motor de empatía',
        en: 'Empathic engine',
        pt: 'Motor de empatia'
      }
    },
    priorities: {
      title: {
        es: 'Prioridades',
        en: 'Priorities',
        pt: 'Prioridades'
      },
      tableHeaders: {
        priority: {
          es: 'Prioridad',
          en: 'Priority',
          pt: 'Prioridade'
        },
        tags: {
          es: 'Etiquetas',
          en: 'Tags',
          pt: 'Etiquetas'
        },
        intents: {
          es: 'Intenciones',
          en: 'Intents',
          pt: 'Intenções'
        },
        updated: {
          es: 'Actualizado',
          en: 'Updated',
          pt: 'Atualizado'
        }
      },
      description: {
        es: `Define prioridades para los chats pendientes de atención. Las prioridades pueden ser asignadas tanto a tags como a deteción de intenciones.
        La mayor prioridad posible es 1, la menor prioridad posible es 1000.`,
        en: `Define priorities for pending chats. Priorities can be assigned to both tags and intent detection.
        The highest possible priority is 1, the lowest possible priority is 1000.`,
        pt: `Defina prioridades para bate-papos pendentes. As prioridades podem ser atribuídas a tags e detecção de intent.
        A prioridade mais alta possível é 1, a prioridade mais baixa possível é 1000.`
      },
      disclaimer: {
        es:
          'Para configurar prioridades, es necesario guardar el chatbot anteriormente.',
        en:
          'To set up priorities, it is necessary to save the chatbot previously.',
        pt: 'Para configurar prioridades, é necessário salvar o chatbot antes.'
      },
      deleteConfirmation: {
        title: {
          es: 'Atención!',
          en: 'Warning!',
          pt: 'Aviso!'
        },
        content: {
          es:
            'Estas por borrar una prioridad, esta acción es irreversible. ¿Estás seguro?',
          en:
            'You are about to erase a priority, this action is irreversible. Are you sure?',
          pt:
            'Você está prestes a apagar uma prioridade, esta ação é irreversível. Tem certeza?'
        }
      },
      prompt: {
        accept: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        },
        cancel: {
          es: 'Cancelar',
          en: 'Cancel',
          pt: 'Cancelar'
        },
        priorityValueLabel: {
          es: 'Prioridad (1 a 1000)',
          en: 'Priority (1 to 1000)',
          pt: 'Prioridade (1 a 1000)'
        },
        intentsLabel: {
          es: 'Intenciones',
          en: 'Intents',
          pt: 'Intenções'
        },
        intentsPlaceholder: {
          es:
            'Escribe el nombre de una intención y presiona Enter para agregarla',
          en: 'Write the intent name and press Enter to add it',
          pt: 'Digite o nome de uma intenção e pressione Enter para adicioná-la'
        },
        tagsLabel: {
          es: 'Etiquetas',
          en: 'Tags',
          pt: 'Etiquetas'
        },
        tagsPlaceholder: {
          es: 'Escribe una etiqueta y presiona Enter para agregarla',
          en: 'Write a tag and press Enter to add it',
          pt: 'Digite uma etiqueta e pressione Enter para adicioná-la'
        },
        preview: {
          es: 'Previsualizar',
          en: 'Preview',
          pt: 'Visualização'
        },
      },
      addPriority: {
        button: {
          es: 'Crear Prioridad',
          en: 'Create Priority',
          pt: 'Criar Prioridade'
        },
        prompt: {
          title: {
            es: 'Crear Prioridad',
            en: 'Create Priority',
            pt: 'Criar Prioridade'
          }
        }
      },
      editPriority: {
        prompt: {
          title: {
            es: 'Editar Prioridad',
            en: 'Edit Priority',
            pt: 'Editar Prioridade'
          }
        }
      },
      genericError: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!'
        },
        text: {
          es: 'Parece que ocurrió un error.',
          en: 'It seems that an error occurred.',
          pt: 'Parece que ocorreu um erro.'
        }
      },
      genericSuccess: {
        title: {
          es: 'Éxito!',
          en: 'Success!',
          pt: 'Sucesso!'
        },
        text: {
          es: 'Cambios guardados con éxito',
          en: 'Changes saved successfully',
          pt: 'Alterações salvas com sucesso'
        }
      },
      invalidPriorityValue: {
        title: {
          es: 'Prioridad inválida',
          en: 'Invalid priority',
          pt: 'Prioridade inválida'
        },
        text: {
          es: 'La prioridad debe ser un número entero entre 1 y 1000',
          en: 'The priority must be an integer between 1 and 1000',
          pt: 'A prioridade deve ser um número inteiro entre 1 e 1000'
        }
      }
    },
    manageUsers: {
      title: {
        es: 'Administrar usuarios',
        en: 'Manage users',
        pt: 'Gerenciar usuários'
      },
      button: {
        es: 'Seleccionar usuarios',
        en: 'Select users',
        pt: 'Selecione usuários'
      },
      table: {
        name: {
          es: 'Nombre',
          en: 'Name',
          pt: 'Nome'
        },
        email: {
          es: 'Correo electrónico',
          en: 'Email',
          pt: 'E-mail'
        },
        username: {
          es: 'Nombre de Usuario',
          en: 'Username',
          pt: 'Nome de usuário'
        },
        password: {
          es: 'Contraseña',
          en: 'Password',
          pt: 'Senha'
        },
        role: {
          es: 'Roles',
          en: 'Roles',
          pt: 'Roles'
        },
        channels: {
          es: 'Canales habilitados',
          en: 'Enabled channels',
          pt: 'Canais habilitados'
        },
        download: {
          es: 'Descargar',
          en: 'Download',
          pt: 'Baixe',
        },
      },
      dialog: {
        title: {
          es: 'Crear un usuario',
          en: 'Add user',
          pt: 'Adicionar usuário'
        },
        description: {
          es: 'Complete el formulario para crear un nuevo usuario',
          en: 'Complete the form to create a new user.',
          pt: 'Complete o formulário para criar um novo usuário.'
        },
        addBtn: {
          es: 'Crear usuario',
          en: 'Create user',
          pt: 'Criar usuário'
        },
        role: {
          admin: {
            es: 'Administrador',
            en: 'Admin',
            pt: 'Administração'
          },
          agent: {
            es: 'Agente',
            en: 'Agent',
            pt: 'Agente'
          }
        },
        errorMessage: {
          es: 'Por favor completa todos los campos para continuar',
          en: 'Please complete all the fields in order to continue',
          pt: 'Por favor, complete todos os campos para continuar'
        },
        errorsAPI: {
          existingEmail: {
            es: 'Ya existe un usuario con ese correo electrónico.',
            en: 'Email already in use.',
            pt: 'E-mail já está em uso.'
          }
        }
      },
      dialogSelectUsers: {
        title: {
          es: 'Usuarios',
          en: 'users',
          pt: 'Usuários'
        },
        alert: {
          es: 'Por favor selecciona uno o mas usuarios',
          en: 'Please select one or more users',
          pt: 'Por favor, selecione um ou mais usuários'
        },
        selectUsers: {
          es: 'Seleccionar usuarios',
          en: 'Select users',
          pt: 'Selecione usuários'
        }
      }
    },
    engineSetting: {
      title: {
        es: 'Configuración del motor de procesamiento de lenguaje natural',
        en: 'Natural Language Processing Engine Settings',
        pt: 'Configuração do motor de processamento de linguagem natural'
      },
      platform: {
        es: 'Plataforma',
        en: 'Platform',
        pt: 'Plataforma'
      },
      deprecated: {
        es: 'Deprecado',
        en: 'Deprecated',
        pt: 'Depreciada'
      },
      platformError: {
        es: 'Por favor seleccione una plataforma.',
        en: 'Please select a platform',
        pt: 'Por favor, selecione uma plataforma'
      },
      culture: {
        es: 'Idioma',
        en: 'Language',
        pt: 'Idioma'
      },
      cultureOptions: {
        spanish: {
          es: 'Español',
          en: 'Spanish',
          pt: 'Espanhol'
        },
        english: {
          es: 'Inglés',
          en: 'English',
          pt: 'Inglês'
        },
        portuguese: {
          es: 'Portugués',
          en: 'Portuguese',
          pt: 'Português'
        }
      },
      cultureError: {
        es: 'Por favor seleccione un idioma.',
        en: 'Please select a language.',
        pt: 'Por favor, selecione um idioma.'
      },
      intentConfidence: {
        es: 'Confianza de la intención',
        en: 'Intent confidence',
        pt: 'Confiança da intenção'
      },
      intentConfidenceOptions: {
        high: {
          es: 'Alto',
          en: 'High',
          pt: 'Alto'
        },
        medium: {
          es: 'Medio',
          en: 'Medium',
          pt: 'Médio'
        },
        low: {
          es: 'Bajo',
          en: 'Low',
          pt: 'Baixo'
        }
      },
      intentConfidenceError: {
        es: 'Por favor seleccione la confianza de la intención',
        en: 'Please select the intent confidence.',
        pt: 'Por favor, selecione a confiança da intenção.'
      },
      noIntentMessage: {
        es: 'Acción cuando se desconoce la intención',
        en: 'No intent action',
        pt: 'Ação quando a intenção é desconhecida'
      },
      connectionErrorMessage: {
        es: 'Mensaje cuando ocurre un error de conexión con el NLU',
        en: 'NLU connection error message',
        pt: 'Mensagem de erro de conexão NLU'
      },
      timeToTimeoutAction: {
        es: 'Tiempo máximo de espera a agente (minutos)',
        en: 'No intent action (minutes)',
        pt: 'Tempo máximo de espera para agente (minutos)'
      },
      timeToTimeoutActionTooltip: {
        es: 'Tiempo máximo de espera a agente tooltip',
        en: 'No intent action',
        pt: 'Tempo máximo de espera a agente tooltip'
      },
      timeoutActionExecute: {
        es: 'Configurar y ejecutar acción',
        en: 'Set and execute action',
        pt: 'Definir e executar ação'
      },
      timeoutAction: {
        es: 'Enviar pregunta a Usuarios en espera en Pedidos de Atención',
        en: 'Send question to Users at Pending Chats',
        pt: 'Envie uma pergunta para Usuários em Chats Pendentes'
      },
      negativeSentimentAction: {
        text: {
          es:
            'Acción de derivar luego de determinada cantidad de mensajes con sentimiento negativo',
          en:
            'Action of deriving after a certain amount of messages in a row with negative sentiment',
          pt:
            'Ação de derivar após uma certa quantidade de mensagens em uma fila com sentimento negativo'
        },
        tooltip: {
          es:
            'Luego de la cantidad determinada de mensajes con sentimiento menor al definido continuos, el bot derivara la conversación con un agente y enviara el mensaje predeterminado',
          en:
            'After the determined amount messages in a row with feeling less than the defined one, the bot will derive the conversation with an agent and send the predetermined message',
          pt:
            'Após a quantidade determinada mensagens em uma linha com sentimento menor que o definido, o bot vai derivar a conversa com um agente e enviar a mensagem pré-determinada'
        },
        repeat: {
          es: 'Mensajes seguidos',
          en: 'Times in a row',
          pt: 'Mensagens seguidas'
        },
        lessThan: {
          es: 'Sentimiento menor a',
          en: 'Sentiment less than',
          pt: 'Sentimento menos do que'
        },
        lessThanError: {
          es: 'Ingrese un valor entre 1 y 5',
          en: 'Insert a value beetwen 1 and 5',
          pt: 'Insira um valor entre 1 e 5'
        },
        lessThanValue: {
          es: 'Valor entre 1 y 5',
          en: 'Value beetwen 1 and 5',
          pt: 'Valor entre 1 e 5'
        },
        cantError: {
          es: 'Ingrese un valor mayor a 0',
          en: 'Insert a value greater than 0',
          pt: 'Inserir um valor maior que 0'
        },
        message: {
          es: 'Mensaje',
          en: 'Message',
          pt: 'Mensagem'
        },
        messageError: {
          es: 'Por favor ingrese un mensaje',
          en: 'Please insert a message',
          pt: 'Por favor, insera uma mensagem'
        },
        firstMessage: {
          es:
            'Hemos detectado que no estas conforme con las respuestas brindadas, le derivaremos con un agente.',
          en:
            'We have detected that you are not satisfied with the answers provided, we will refer you to an agent.',
          pt:
            'Nós detectamos que você não está satisfeito com as respostas fornecidas, nós vamos encaminhar você para um agente.'
        }
      },
      notDialogDetected :{
        text: {
          es: 'Diálogo no detectado',
          en: 'Dialog not detected',
          pt: 'Diálogo não detectada'
        },
        tooltip: {
          es:
            'Permite configurar un webhook para re-dirigir el mensaje del usuario, cuando el bot no entienda la intención, y obtener una respuesta externa.',
          en:
            'It allows configuring a webhook to redirect the user message, when the bot does not understand the intention, and obtain an external response.',
          pt:
            'Permite configurar um webhook para re-dirigir a mensagem do usuário, cuando o bot sem entienda a intenção, e obtener uma resposta externa.'
          
        }
      },
      disambiguation: {
        text: {
          es: 'Desambiguación',
          en: 'Disambiguation',
          pt: 'Desambiguação'
        },
        tooltip: {
          es:
            'Permite configurar si el Bot puede desambiguar entre opciones cercanas para el texto ingresado por el usuario.',
          en:
            'It allows configuring if the Bot can disambiguate between nearby options for the text entered by the user.',
          pt:
            'Permite configurar se o Bot pode desambiguar entre as opções próximas para o texto digitado pelo usuário.'
        },
        message: {
          es: 'Mensaje de desambiguación',
          en: 'Disambiguation message',
          pt: 'Mensagem de desambiguação'
        },
        maxIntents: {
          es: 'Max. intenciones sugeridas',
          en: 'Intents max suggestion',
          pt: 'Máx. intenções sugeridas'
        },
        maxIntentsError: {
          es: 'Ingrese un valor entre 1 y 5',
          en: 'Insert a value between 1 and 5',
          pt: 'Insira um valor entre 1 e 5'
        },
        anythingElseMessage: {
          title: {
            es: 'Configurar mensaje para ninguna opción',
            en: 'Set message for no option',
            pt: 'Definir mensagem para nenhuma opção'
          },
          message: {
            es: 'Mensaje ninguna opción',
            en: 'Message no option',
            pt: 'Mensagem sem opção'
          }
        }
      },
      timeoutActionPopup: {
        es: 'Preguntar a Usuarios en espera',
        en: 'Send question to Users at Pending Chats',
        pt: 'Envie uma pergunta para usuários em Chats Pendentes'
      },
      timeoutActionName: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome'
      },
      timeoutActionTooltip: {
        es:
          'Permite configurar el envío de una pregunta (así como las distintas acciones según la respuesta del usuario) a aquellos usuarios que han sido derivados para asistencia humana y están en espera en Pedidos de Atención',
        en:
          'This feature allows the set up of a question (as well as its resulting actions) to those users that have been transferred to human attention, and are currently waiting at Pending Chats',
        pt:
          'Esta funcionalidade permite a configuração de uma pergunta ( bem como suas ações resultantes) para os usuários que foram transferidos para a atendimento humana, e estão atualmente esperando em Chats Pendentes'
      },
      noIntentMessageError: {
        es: 'Por favor ingrese un mensaje.',
        en: 'Please enter a message.',
        pt: 'Por favor, digite uma mensagem.'
      },
      connectionErrorMessageError: {
        es: 'Por favor ingrese un mensaje.',
        en: 'Please enter a message.',
        pt: 'Por favor, digite uma mensagem.'
      },
      intents: {
        es: 'Intención para derivar a humano',
        en: 'Intention to derive a human',
        pt: 'Intenção de derivar a humano'
      },
      intentions: {
        name: {
          es: 'Nombre',
          en: 'Name',
          pt: 'Nome'
        },
        examples: {
          es: 'Ejemplos',
          en: 'Examples',
          pt: 'Exemplos'
        }
      },
      showSuggestions: {
        es: 'Mostrar sugerencias',
        en: 'Show suggestions',
        pt: 'Mostrar sugestões'
      },
      inactivityTime: {
        es: 'Tiempo máximo de inactividad (minutos)',
        en: 'Maximum inactivity time (minutes)',
        pt: 'Tempo máximo de inatividade (minutos)'
      },
      inactivityTimeError: {
        es: 'Por favor ingrese un valor.',
        en: 'Please enter a value.',
        pt: 'Por favor, digite um valor.'
      },
      inactivityMessage: {
        es: 'Mensaje a enviar pasado el tiempo de inactividad',
        en: 'Message to send after inactivity time',
        pt: 'Mensagem para enviar depois do tempo de inatividade'
      },
      notDialogDetectedWebhook: {
        es: 'URL a enviar la consulta del usuario',
        en: 'URL to send the user query',
        pt: 'URL para enviar a consulta do usuário'
      },
      entityExtractor: {
        text: {
          es: 'Extractor de entidades',
          en: 'Entity extractor',
          pt: 'Extrator de entidade'
        },
        tooltip: {
          es:
            'Convierte números de texto en números literales (primero -> 1). Recomendado para canales de voz.',
          en:
            'Converts text numbers into literal numbers (first -> 1). Recommended for voice channels.',
          pt:
            'Converte números de texto em números literais (primeiro -> 1). Recomendado para canais de voz.'
        }
      },
      useAsTemplate: {
        text: {
          es: 'Usar como template',
          en: 'Use as template',
          pt: 'Usar como template'
        },
        tooltip: {
          es:
            'Permite usar el bot como template.',
          en:
            'Allows to use the bot as a template.',
          pt:
            'Permite usar o bot como template.'
        }
      },
      inactivityMessageError: {
        es: 'Por favor ingrese un mensaje.',
        en: 'Please enter a message.',
        pt: 'Por favor, digite uma mensagem.'
      },
      noIntentTimesToTakeOver: {
        es: 'Cantidad de errores antes de derivar a un agente',
        en: 'Number of errors before moving on to an agent',
        pt: 'Número de erros antes de passar a um agente'
      },
      noIntentTimesToTakeOverTooltip: {
        es:
          'Derivará a atención humana luego de la cantidad de veces que se determine. Ingresar 0 si no debe derivar en este caso.',
        en:
          'It will refer to human attention after the number of times determined. Enter 0 if you should not bypass in this case.',
        pt:
          'Vai se referir à atendimento humana depois do número de vezes determinado. Insira 0 se você não deve derivar neste caso.'
      },
      noIntentTimesToTakeOverError: {
        es: 'Por favor ingrese un valor. Ingrese 0 si nunca debe derivar',
        en: 'Please enter a value. Enter 0 if you should never derive',
        pt: 'Por favor, digite um valor. Insira 0 se você nunca deve derivar'
      },
      platformsNoIntentTimesToTakeOver: {
        es: 'Canales que deriva a un agente cuando el bot no entiende',
        en: "Channels that move to an agent when the bot doesn't understand",
        pt: 'Canais que se movem para um agente quando o bot não entende'
      },
      platformsNoIntentTimesToTakeOverTooltip: {
        es:
          'Selecciona las plataformas dónde se derivará a atención humana cuando el bot no entiende luego de la cantidad configurada de veces (arriba)',
        en:
          'Select the platforms where human attention will be moved when the bot does not understand after the configured number of times (above)',
        pt:
          'Selecione as plataformas onde a atendimento humana será movida quando o bot não entender depois do número configurado de vezes (acima)'
      },
      noIntentMessage2: {
        es: 'Mensaje al derivar a agente',
        en: 'Message when referring to agent',
        pt: 'Mensagem quando se refere a um agente'
      },
      noIntentMessage2Tooltip: {
        es: 'Aplica únicamente luego de la cantidad de veces definida arriba.',
        en: 'Applies only after the number of times defined above.',
        pt: 'Só se aplica após o número de vezes definido acima.'
      },
      noIntentMessage2Error: {
        es: 'Por favor ingrese un mensaje.',
        en: 'Please enter a message.',
        pt: 'Por favor, digite uma mensagem.'
      },
      showEmotions: {
        es: 'Mostrar sentimientos en los mensajes',
        en: 'Show sentiments in messages',
        pt: 'Mostre sentimentos nas mensagens'
      }
    },
    sessionSettings: {
      title: {
        es: 'Configuración de expiración de sesión',
        en: 'Sessions expiration settings',
        pt: 'Configurações de expiração das sessões'
      },
      sessionTimeoutStatus0: {
        es: 'Bot',
        en: 'Bot',
        pt: 'Bot'
      },
      sessionTimeoutStatus0Tooltip: {
        es:
          'Para chats en status “Bot”: tiempo en horas que pasará desde que el bot envió el último mensaje, para que se considere el comienzo de una nueva sesión.',
        en:
          'For chats in “Bot” status: time in hours that will pass since the bot sent the last message, to be considered the beginning of a new session.',
        pt:
          'Para chats em status “Bot”: tempo em horas que passará desde que o bot enviou a última mensagem, para ser considerado o início de uma nova sessão.'
      },
      sessionTimeoutStatus1: {
        es: 'Pendiente',
        en: 'Pending',
        pt: 'Pendente'
      },
      sessionTimeoutStatus1Tooltip: {
        es:
          'Estando en la cola de pedidos de atención. Es el tiempo que debe pasar desde el último mensaje del usuario y el momento en el que un agente toma el control, para considerar que esta toma de control es una sesión nueva.',
        en:
          'Being in the queue for attention requests. It is the time that must pass from the last message of the user and the moment in which an agent takes control, to consider that this takeover is a new session.',
        pt:
          'Estar na fila de pedidos de atendimento. É o tempo que deve transcorrer da última mensagem do usuário e o momento em que um agente toma o controle, para considerar que essa tomada é uma nova sessão.'
      },
      sessionTimeoutStatus2: {
        es: 'En atención',
        en: 'Active',
        pt: 'Em atendimento'
      },
      sessionTimeoutStatus2Tooltip: {
        es:
          'Para chats en status “En Atención”: tiempo en horas que pasará desde el último mensaje, para que cualquier acción posterior (como continuar respondiendo, o finalizar el chat) se considere el comienzo de una nueva sesión.',
        en:
          'For chats in “Active” status: time in hours that will pass from the last message, so that any subsequent action (such as continuing to reply, or ending the chat) is considered the beginning of a new session.',
        pt:
          'Para chats em status “Em atendimento”: tempo em horas que passará da última mensagem, de modo que qualquer ação subsequente (como continuar a responder, ou terminar o chat) é considerado o início de uma nova sessão.'
      },
      sessionTimeoutStatus3: {
        es: 'Liberado',
        en: 'Released',
        pt: 'Liberado'
      },
      sessionTimeoutStatus3Tooltip: {
        es:
          'Para chats en status “Liberado”: tiempo en horas que pasará desde el último mensaje, para que cualquier acción posterior (como continuar respondiendo, o un agente tomando nuevamente el chat) se considere el comienzo de una nueva sesión.',
        en:
          'For chats in “Released” status: time in hours that will pass from the last message, so that any subsequent action (such as continuing to reply, or an agent taking the chat again) is considered the beginning of a new session.',
        pt:
          'Para chat em estado “Liberado”: tempo em horas que passarão da última mensagem, de modo que qualquer ação subsequente (como continuar a responder, ou um agente fazendo o chat novamente) é considerado o início de uma nova sessão.'
      }
    },
    intentionTriggerSecondLevel: {
      title: {
        es: 'Configurar intenciones',
        en: 'Set intentions',
        pt: 'Definir intenções'
      },
      table: {
        name: {
          es: 'Nombre',
          en: 'Name',
          pt: 'Nome'
        },
        examples: {
          es: 'Ejemplos',
          en: 'Examples',
          pt: 'Exemplos'
        },
        botResponses: {
          es: 'Respuestas del bot',
          en: 'Bot responses',
          pt: 'Respostas do bot'
        },
        derivesToAgent: {
          es: 'Deriva a un agente',
          en: 'Transfers to agent',
          pt: 'Transferências para o agente'
        },
        selectChannels: {
          es: 'Seleccionar canales',
          en: 'Select channels',
          pt: 'Selecione canais'
        }
      },
      form: {
        name: {
          es: 'Nombre de la intención',
          en: 'Intention name',
          pt: 'Nome da intenção'
        },
        examples: {
          es: 'Ejemplo',
          en: 'Example',
          pt: 'Exemplo'
        },
        addExample: {
          es: 'Agregar',
          en: 'Add',
          pt: 'Adicionar'
        },
        genExample: {
          es: 'Generar',
          en: 'Generate',
          pt: 'Gerar'
        },
        genExampleError: {
          es: 'Ha ocurrido un error al generar los ejemplos.',
          en: 'An error has ocurred while generating examples.',
          pt: 'Um erro ocorreu ao gerar exemplos.'
        },
        nameError: {
          es: 'Por favor ingrese un nombre válido',
          en: 'Please enter a valid name',
          pt: 'Por favor, digite um nome válido'
        },
        examplesError: {
          es: 'Por favor ingrese un ejemplo válido',
          en: 'Please enter a valid intention example',
          pt: 'Por favor, digite um exemplo de intenção válido'
        },
        emptyIntention: {
          es: 'Por favor ingresa una intención.',
          en: 'Please enter an intention.',
          pt: 'Por favor, insira uma intenção.'
        },
        existingIntent: {
          es: 'La intención que intentas agregar ya existe.',
          en: 'The intent already exists',
          pt: 'A intenção já existe'
        },
        botResponse: {
          error: {
            emptyBotResponse: {
              es: 'Por favor ingrese una respuesta del bot',
              en: 'Please enter a bot response',
              pt: 'Por favor, digite uma resposta de bot'
            }
          }
        },
        derivesToAgent: {
          es: 'Deriva a un agente',
          en: 'Transfers to agent',
          pt: 'Transferência para o agente'
        }
      },
      prompt: {
        title: {
          es: 'Intenciones',
          en: 'Intents',
          pt: 'Intenções'
        },
        description: {
          es: 'Por favor selecciona una o mas intenciones.',
          en: 'Please select one or more intentions.',
          pt: 'Por favor, selecione uma ou mais intenções.'
        },
        acceptBtn: {
          es: 'Seleccionar',
          en: 'Select',
          pt: 'Selecione'
        },
        error: {
          intent: {
            empty: {
              es: 'Por favor ingresa un nombre para la intención.',
              en: 'Please enter a name for the intention.',
              pt: 'Por favor, digite um nome para a intenção.'
            },
            duplicate: {
              es: 'Ya existe una intención con ese nombre.',
              en: 'There is already an intention with that name.',
              pt: 'Já há uma intenção com esse nome.'
            }
          },
          examples: {
            empty: {
              es: 'Por favor ingresa un ejemplo para la intención.',
              en: 'Please enter an example for the intention.',
              pt: 'Por favor, digite um exemplo para a intenção.'
            },
            duplicate: {
              es: 'Ya existe un ejemplo con el mismo nombre',
              en: 'That example already exists with the same name',
              pt: 'Esse exemplo já existe com o mesmo nome'
            },
            intentionInfo: {
              es: 'en la intención',
              en: 'in intention',
              pt: 'em intenção'
            }
          }
        },
        success: {
          title: {
            es: 'Éxito!',
            en: 'Success!',
            pt: 'Sucesso!'
          },
          text: {
            es: 'La intención fue guardada',
            en: 'The intention was saved',
            pt: 'A intenção foi salva'
          }
        },
        successUpdate: {
          es: 'La intención fue actualizada con éxito',
          en: 'The intention was updated successfully.',
          pt: 'A intenção foi atualizada com sucesso.'
        }
      },
      secondLevelDerivation: {
        message: {
          es: 'Un agente se comunicará a la brevedad.',
          en: 'An agent will contact you shortly.',
          pt: 'Um agente contatará você em breve.'
        },
        description: {
          es:
            'Ingrese el mensaje a mostrar cuando el usuario es derivado a un agente. (Este mensaje será aplicado a todas las intenciones)',
          en:
            'Enter the message to display when the user is referred to an agent. (This message will be used for all intentions)',
          pt:
            'Digite a mensagem para exibir quando o usuário for encaminhado para um agente. (Esta mensagem será usada para todas as intenções)'
        },
        errorMessage: {
          es: 'Por favor ingrese un mensaje',
          en: 'Please enter a message',
          pt: 'Por favor, digite uma mensagem'
        }
      },
      deleteIntentConfirmation: {
        title: {
          es: 'Atención!',
          en: 'Warning!',
          pt: 'Aviso!'
        },
        content: {
          es:
            'Estas por borrar una intención, esta acción es irreversible. ¿Estás seguro?',
          en:
            'You are about to erase an intention, this action is irreversible. Are you sure?',
          pt:
            'Você está prestes a apagar uma intenção, esta ação é irreversível. Tem certeza?'
        }
      },
      deleteSuccess: {
        title: {
          es: 'Intención borrada con éxito',
          en: 'Intent deleted successfully',
          pt: 'Intenções excluídas com sucesso'
        }
      }
    },
    timeZone: {
      title: {
        es: 'Zona horaria',
        en: 'Time zone',
        pt: 'Fuso horário'
      }
    },
    botAvailability: {
      title: {
        es: 'Disponibilidad del agente',
        en: 'Agent availability',
        pt: 'Disponibilidade do agente'
      },
      daysAvailability: {
        es: 'Dias disponibles de los agentes:',
        en: 'Days available for agents:',
        pt: 'Dias disponíveis para agentes:'
      },
      selectWeekDays: {
        es: 'Días y horarios de atención',
        en: 'Available days and business hours',
        pt: 'Dias e horas de atendimento disponíveis'
      },
      selectTime: {
        es: 'Horario de atención',
        en: 'Business hours',
        pt: 'Horário de atendimento'
      },
      checkAvailability: {
        es: 'No realizar takeover fuera de horario de atención',
        en: 'Dont do takeovers after business hours',
        pt: 'Verifique a disponibilidade'
      },
      dayFrom: {
        es: 'De',
        en: 'From',
        pt: 'De'
      },
      weekDaysOptions: {
        0: {
          es: 'Domingo',
          en: 'Sunday',
          pt: 'Domingo'
        },
        1: {
          es: 'Lunes',
          en: 'Monday',
          pt: 'Segunda-feira'
        },
        2: {
          es: 'Martes',
          en: 'Tuesday',
          pt: 'Terça-feira'
        },
        3: {
          es: 'Miércoles',
          en: 'Wednesday',
          pt: 'Quarta-feira'
        },
        4: {
          es: 'Jueves',
          en: 'Thursday',
          pt: 'Quinta-feira'
        },
        5: {
          es: 'Viernes',
          en: 'Friday',
          pt: 'Sexta-feira'
        },
        6: {
          es: 'Sábado',
          en: 'Saturday',
          pt: 'Sábado'
        }
      },
      weekDaysAbbr: {
        monday: {
          es: 'Lun',
          en: 'Mon',
          pt: 'Seg'
        },
        tuesday: {
          es: 'Mar',
          en: 'Tue',
          pt: 'Terç'
        },
        wednesday: {
          es: 'Mié',
          en: 'Wed',
          pt: 'Qua'
        },
        thursday: {
          es: 'Jue',
          en: 'Thu',
          pt: 'Qui'
        },
        friday: {
          es: 'Vie',
          en: 'Fri',
          pt: 'Sex'
        },
        saturday: {
          es: 'Sáb',
          en: 'Sat',
          pt: 'Sáb'
        },
        sunday: {
          es: 'Dom',
          en: 'Sun',
          pt: 'Dom'
        }
      },
      dayTo: {
        es: 'A',
        en: 'To',
        pt: 'Para'
      },
      daysAvailabilityError: {
        noSelection: {
          es:
            'Por favor seleccione los días en los cuales su agente va a operar.',
          en: 'Please select the days on which your agent will operate.',
          pt: 'Por favor, selecione os dias em que seu agente irá operar.'
        },
        invalidTime: {
          es:
            'Por favor seleccione correctamente los horarios en los cuales su agente va a operar.',
          en:
            'Please correctly select the times in which your agente will operate.',
          pt:
            'Por favor, selecione corretamente os horários em que seu agente irá operar.'
        },
        incorrectTime: {
          es: 'Los horarios seleccionados no son válidos.',
          en: 'The timeframe is not valid',
          pt: 'Os horários selecionados não são válidos.'
        },
        messageError: {
          es:
            'Por favor ingrese el mensaje a monstrar cuando el agente no se encuentre disponible',
          en:
            'Please enter the message to show when the agent is not available',
          pt:
            'Por favor, digite a mensage para mostrar quando o agente não estiver disponível'
        },
        existingDate: {
          es: 'La fecha seleccionada ya fue agregada',
          en: 'The selected date has already been added',
          pt: 'A data selecionada já foi adicionada'
        }
      },
      exceptions: {
        es: 'Excepciones',
        en: 'Exceptions',
        pt: 'Exceções'
      },
      datesSelected: {
        es: 'Fechas seleccionadas',
        en: 'Dates selected',
        pt: 'Datas selecionadas'
      },
      addException: {
        es: 'Agregar excepción',
        en: 'Add exception',
        pt: 'Adicionar exceção'
      },
      message: {
        es: 'Mensaje a mostrar fuera del horario de atención',
        en: 'Message to display outside of business hours',
        pt: 'Mensagem para exibir fora do horário de atendimento'
      },
      messageError: {
        es:
          'Por favor ingrese el mensaje que se mostrará cuando su agente no se encuentro disponible.',
        en:
          'Please enter the message that will be displayed when your agent is not available.',
        pt:
          'Por favor, digite a mensagem que será exibida quando seu agente não estiver disponível.'
      },
      dates: {
        es: 'Fechas',
        en: 'Dates',
        pt: 'Datas'
      }
    },
    description: {
      name: {
        es: 'Escribe un nombre para tu bot',
        en: 'Type a name for your bot',
        pt: 'Escreva um nome para o seu bot'
      },
      platform: {
        es: 'Selecciona los canales',
        en: 'Select the channels',
        pt: 'Selecione os canais'
      },
      botDescription: {
        es: 'Descripción',
        en: 'Description',
        pt: 'Descrição'
      }
    },
    startDevelop: {
      es: 'Crear bot',
      en: 'Create bot',
      pt: 'Criar bot'
    },
    notifications: {
      noPlatforms: {
        title: {
          es: 'Falta algo',
          en: 'Something is missing',
          pt: 'Algo está faltando'
        },
        text: {
          es: 'Selecciona por lo menos un canal',
          en: 'You must select at least one channel',
          pt: 'Você deve selecionar pelo menos um canal'
        }
      },
      couldNotSaveWidget: {
        title: {
          es: 'Algo salio mal',
          en: 'Oops something went wrong',
          pt: 'Oops algo deu errado'
        },
        text: {
          es: 'No pudimos guardar el widget',
          en: "We couldn't save your widget",
          pt: 'Não conseguimos salvar seu widget'
        }
      },
      couldNotSaveBot: {
        title: {
          es: 'Algo salio mal',
          en: 'Oops something went wrong',
          pt: 'Oops algo deu errado'
        },
        text: {
          es: 'No pudimos guardar tu bot. Intenta mas tarde',
          en: "We couldn't save your bot. Please try again later",
          pt:
            'Não foi possível salvar seu bot. Por favor, tente de novo mais tarde'
        }
      }
    },
    noIntentMessage: {
      es: 'No entendi lo que me quisiste decir.',
      en: "I didn't understand what you are trying to say",
      pt: 'Eu não entendi o que você está tentando dizer'
    },
    connectionErrorMessage: {
      es: 'Ocurrio un error de conexion con el NLU',
      en: 'NLU error connection',
      pt: 'Ocorreu um erro de conexão com o NLU'
    },
    botChannels: {
      title: {
        es: 'Canales',
        en: 'Channels',
        pt: 'Canais'
      },
      description: {
        es: 'Activar el/los canal/es a utilizar',
        en: 'Activate the channels to be used',
        pt: 'Ativar os canais a serem usados'
      },
      otherActive: {
        es: 'Ya existe una integración activa para este canal y version.',
        en: 'An active integration already exists for this channel and versión.',
        pt: 'Já existe uma integração ativa para este canal e versão.'
      },
      disclaimer: {
        es:
          'Para configurar los canales, es necesario guardar el chatbot anteriormente.',
        en:
          'To set up channels, it is necessary to save the chatbot previously.',
        pt: 'Para configurar canais, é necessário salvar o chatbot antes.'
      },
      confirm: {
        title: {
          es: 'Atención!',
          en: 'Warning!',
          pt: 'Aviso!'
        },
        textPre: {
          es: 'Para integrarse con',
          en: 'In order to integrate with',
          pt: 'A fim de se integrar com'
        },
        textPost: {
          es: 'por favor contacte al equipo de soporte.',
          en: 'please contact the support team.',
          pt: 'Por favor, entre em contato com o equipe de suporte.'
        },
        instructive: {
          textPre: {
            es: 'Para integrar el chatbot a ',
            en: 'To integrate your chatbot to ',
            pt: 'Para integrar seu chatbot para '
          },
          textPost: {
            es: 'por favor guárdelo primero.',
            en: 'please save it first.',
            pt: 'Por favor, salve-o primeiro.'
          }
        }
      },
      messages: {
        errors: {
          createIntegration: {
            es:
              'No se pudo actualizar el estado del canal. Por favor, intente nuevamente.',
            en: 'The channel status could not be updated. Please try again.',
            pt:
              'O status do canal não pôde ser atualizado. Por favor, tente novamente.'
          }
        },
        createIntegration: {
          es:
            'Integración creada correctamente.',
          en: 'Integration created successfully.',
          pt:
            'Integração criada com sucesso.'
        },
        updateIntegration: {
          es:
            'Integración actualizada correctamente.',
          en: 'Integration successfully updated.',
          pt:
            'Integração atualizada corretamente.'
        }
      }
    },
    tabs: {
      widget: {
        es: 'Widget',
        en: 'Widget',
        pt: 'Widget'
      },
      general: {
        es: 'General',
        en: 'General',
        pt: 'Geral'
      },
      users: {
        es: 'Usuarios',
        en: 'Users',
        pt: 'Usuários'
      },
      availability: {
        es: 'Disponibilidad',
        en: 'Availability',
        pt: 'Disponibilidade'
      },
      channels: {
        es: 'Canales',
        en: 'Channels',
        pt: 'Canais'
      },
      empathic: {
        es: 'Motor de empatía',
        en: 'Empathic engine',
        pt: 'Motor de empatia'
      },
      priorities: {
        es: 'Prioridades',
        en: 'Priorities',
        pt: 'Prioridades'
      }
    }
  },
  addEntity: {
    title: {
      es: 'Crear entidad',
      en: 'Create entity',
      pt: 'Criar entidade'
    },
    error: {
      duplicate: {
        es: 'Ya existe una entidad con ese nombre.',
        en: 'There is already an entity with that name.',
        pt: 'Já há uma entidade com esse nome.'
      }
    }
  },
  botEditor: {
    errorButtons: {
      es: 'Atención: no es posible poner texto abajo de un Menú con botones',
      en:
        'Attention: it is not possible to put text below a Menu with buttons.',
      pt:
        'Atendimento: não é possível colocar texto abaixo de um Menu com botões.'
    },
    navBar: {
      title: {
        es: 'Cantidad de condiciones insuficiente',
        en: 'Insufficient amount of conditions',
        pt: 'Insuficiente quantidade de condições'
      },
      text: {
        es:
          'Debes agregar por lo menos dos condiciones para que el motor de IA funcione.',
        en: 'You must add at least two conditions for the AI ​​engine to work.',
        pt:
          'Você deve adicionar pelo menos duas condições para que o motor IA funcione.'
      },
      tooltips: {
        mobile: {
          es: 'Vista de móvil',
          en: 'Mobile view',
          pt: 'Vista móvel'
        },
        fork: {
          es: 'Versiones del bot',
          en: 'Bot versions',
          pt: 'Versões do Bot'
        },
        tree: {
          es: 'Vista de árbol',
          en: 'Tree view',
          pt: 'Vista da árvore'
        },
        play: {
          es: 'Probar bot',
          en: 'Try it',
          pt: 'Testar bot'
        },
        settings: {
          es: 'Configuración',
          en: 'Settings',
          pt: 'Configuração'
        },
        integrations: {
          es: 'Integraciones',
          en: 'Integrations',
          pt: 'Integrações'
        },
        entities: {
          es: 'Entidades',
          en: 'Entities',
          pt: 'Entidades'
        },
        intentions: {
          es: 'Intenciones',
          en: 'Intents',
          pt: 'Intenções'
        },
        catalogs: {
          es: 'Catálogos',
          en: 'Catalogs',
          pt: 'Catálogos'
        },
        terminal: {
          es: 'Terminal',
          en: 'Terminal',
          pt: 'Terminal'
        },
        test: {
          es: 'Tests',
          en: 'Tests',
          pt: 'Tests'
        }
      },
      versions: {
        title: {
          es: 'Versiones',
          en: 'Versions',
          pt: 'Versões'
        },
        fromVersion: {
          label: {
            es: 'Desde versión (opcional)',
            en: 'From version (optional)',
            pt: 'Da versão (opcional)'
          },
          placeholder: {
            es: 'Buscar versión',
            en: 'Search version',
            pt: 'Versão de pesquisa'
          },
          error: {
            es: 'Por favor, seleccione la versión de origen.',
            en: 'Please, select the source version.',
            pt: 'Por favor, selecione a versão fonte.'
          }
        },
        selectVersion: {
          es: 'Cargando versión',
          en: 'Loading version',
          pt: 'Carregando a versão'
        },
        selectVersionFail: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!'
          },
          text: {
            es: 'Parece que ocurrió un error.',
            en: 'It seems that an error occurred.',
            pt: 'Parece que ocorreu um erro.'
          }
        },
        forkInProgress: {
          title: {
            es: 'Creando versión',
            en: 'Creating version',
            pt: 'Criando a versão'
          },
          text: {
            es: 'Por favor aguarda, esto puede tardar unos minutos. Progreso ',
            en: 'Please wait, this may take a few minutes. Progress ',
            pt: 'Por favor, espere, isso pode levar alguns minutos. Progresso '
          }
        },
        newVersion: {
          labelRequired: {
            es: 'Etiqueta requerida',
            en: 'Tag required',
            pt: 'Etiqueta necessária'
          },
          title: {
            es: 'Crear versión',
            en: 'Create version',
            pt: 'Criar versão'
          },
          label: {
            es: 'Nueva versión',
            en: 'New version',
            pt: 'Nova versão'
          },
          placeholder: {
            es: 'Nueva versión',
            en: 'New version',
            pt: 'Nova versão'
          },
          error: {
            es: 'Por favor, ingrese el nombre de la nueva versión.',
            en: 'Please, insert new version name',
            pt: 'Por favor, insira o nome da nova versão'
          },
          loading: {
            es: 'Creando nueva versión',
            en: 'Creating new version',
            pt: 'Criando uma nova versão'
          }
        },
        messages: {
          createVersion: {
            success: {
              es: 'Versión creada exitosamente.',
              en: 'Version created successfully.',
              pt: 'Versão criada com sucesso.'
            },
            error: {
              es: 'Ha ocurrido un error al crear nueva versión.',
              en: 'An error has ocurred while creating new version.',
              pt: 'Um erro ocorreu ao criar uma nova versão.'
            }
          },
          promoteVersion: {
            confirm: {
              title: {
                es: 'Promover a en vivo',
                en: 'Promote to live',
                pt: 'Promover para ao-vivo'
              },
              text: {
                es:
                  '¿Estás seguro de querer promocionar la versión XXX a en vivo?',
                en: '️Do you want to promote the version XXX to live?',
                pt: 'Você quer promover a versão XXX para ao-vivo?'
              },
              textWithJump: {
                es:
                  '¿Estás seguro de querer promocionar la versión XXX a en vivo? \nEl Salto a un diálogo cuando el Bot no identifica intención, en caso de no encontrar el diálogo del salto en la nueva versión, quedará sin efecto y tendrá que ser actualizado. ¿Desea continuar?',
                en:
                  '️Do you want to promote the version XXX to live? \nThe Jump to a dialog when the Bot is unable to identify an intention, in case of not finding the jump dialog in the new version, will be disabled and will have to be updated. Do you wish to continue?',
                pt:
                  'Você quer promover a versão XXX para ao-vivo? \nO Salto para um diálogo quando o Bot é incapaz de identificar uma intenção, no caso de não encontrar o diálogo de salto na nova versão, será desativado e terá que ser atualizado. Você deseja continuar?'
              }
            },
            success: {
              es: 'Versión promocionada exitosamente.',
              en: 'Version promoted successfully.',
              pt: 'A versão foi promovida com sucesso.'
            },
            error: {
              es: 'Ha ocurrido un error al promocionar la versión.',
              en: 'An error has ocurred while promoting version.',
              pt: 'Um erro ocorreu durante a promoção da versão.'
            },
            changeIntegrations: {
              es: '¿Desea intercambiar las integraciones entre la version actual y la que se va a promover?',
              en: 'Do you want to swap the integrations between the current version and the one to be promoted?',
              pt: 'Deseja trocar as integrações entre a versão atual e a que será promovida?'
            }
          },
          promoteToTestVersion: {
            confirm: {
              title: {
                es: 'Promover a versión de test',
                en: 'Promote to test version',
                pt: 'Promover para a versão de teste'
              },
              text: {
                es:
                  '¿Estás seguro de querer promocionar la versión XXX a test?',
                en: '️Do you want to promote the version XXX to test?',
                pt: 'Você quer promover a versão XXX para ao teste?'
              },
              textWithJump: {
                es:
                  '¿Estás seguro de querer promocionar la versión XXX a test?',
                en: '️Do you want to promote the version XXX to test?',
                pt: 'Você quer promover a versão XXX para ao teste?'
              }
            },
            success: {
              es: 'La versión fue promovida a test.',
              en: 'This version was promoted to test.',
              pt: 'Esta versão foi promovida para ao test.'
            },
            error: {
              es: 'Parece que ocurrió un error.',
              en: 'Looks like something went wrong.',
              pt: 'Parece que algo deu errado.'
            }
          },
          deleteVersion: {
            errorActive: {
              es: 'No se puede eliminar la versión activa.',
              en: 'The active version cannot be deleted.',
              pt: 'A versão ativa não pode ser excluída.'
            },
            confirm: {
              title: {
                es: 'Eliminar versión',
                en: 'Delete version',
                pt: 'Apagar a versão'
              },
              text: {
                es: '¿Estás seguro de querer eliminar la versión XXX?',
                en: '️Do you want to delete the version XXX?',
                pt: 'Quer apagar a versão XXX?'
              }
            },
            success: {
              es: 'Versión eliminada exitosamente.',
              en: 'Version deleted successfully.',
              pt: 'Versão excluída com sucesso.'
            },
            error: {
              es: 'Ha ocurrido un error al eliminar la versión.',
              en: 'An error has ocurred while deleting version.',
              pt: 'Um erro ocorreu enquanto apagava a versão.'
            }
          }
        },
        noTag: {
          es: '<sin nombre>',
          en: '<no name>',
          pt: '<Sem nome>'
        }
      },
      templates: {
        table: {
          tag: {
            es: 'Versión:',
            en: 'Version:',
            pt: 'Versão:'
          }
        }
      }
    },
    addMessage: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar'
    },
    linkMessage: {
      es: 'Encadenar Mensaje',
      en: 'Link message',
      pt: 'Link mensagem'
    },
    modifyMessage: {
      es: 'Modificar',
      en: 'Modify',
      pt: 'Modificar'
    },
    cancelMessage: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar'
    },
    missingLink: {
      es: 'Error: Todos los botones deben tener un enlace',
      en: 'Error: All buttons must have a link',
      pt: 'Erro: Todos os botões devem ter um link'
    },
    missingField: {
      es: 'Por favor ingresá',
      en: 'Please enter',
      pt: 'Por favor, digite'
    },
    addingMessage: {
      es: 'Añadiendo Mensaje',
      en: 'Adding message',
      pt: 'Adicionando mensagem'
    },
    messageType: {
      intention: {
        title: {
          es: 'Intenciones',
          en: 'Intents',
          pt: 'Intenções'
        }
      },
      text: {
        title: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        },
        description: {
          es: 'Respuesta del bot',
          en: 'Bot response',
          pt: 'Resposta do Bot'
        },
        descriptionError: {
          es: 'Por favor ingrese un nombre',
          en: 'Please enter a name',
          pt: 'Por favor, digite um nome'
        },
        examples: {
          es: 'Ejemplos',
          en: 'Examples',
          pt: 'Exemplos'
        },
        example: {
          es: 'Agregar una pregunta',
          en: 'Add question',
          pt: 'Adicionar uma pergunta'
        },
        addExample: {
          es: 'Añadir',
          en: 'Add',
          pt: 'Adicionar'
        }
      },
      image: {
        title: {
          es: 'Imagen',
          en: 'Image',
          pt: 'Imagem'
        },
        description: {
          es: 'Selecciona una imagen',
          en: 'Select an image',
          pt: 'Selecione uma imagem'
        },
        error: {
          es: 'Por favor ingresa una imagen',
          en: 'Please upload an image',
          pt: 'Por favor, carregue uma imagem'
        }
      },
      media: {
        title: {
          es: 'Media',
          en: 'Media',
          pt: 'Mídia'
        },
        description: {
          es: 'Selecciona una imagen, video o documento',
          en: 'Select an image, video or document',
          pt: 'Selecione uma imagem, vídeo ou documento'
        },
        error: {
          es: 'Por favor ingresa una imagen. video o documento',
          en: 'Please upload an image, video or document',
          pt: 'Por favor, envie uma imagem, vídeo ou documento'
        },
        errorMaxSize: {
          es: 'El tamaño del archivo no debe ser superior a ',
          en: 'The file size must be no more than ',
          pt: 'O tamanho do arquivo não deve ser mais do que '
        }
      },
      buttons: {
        check: {
          es: 'Enlace o texto a enviar',
          en: 'Link or text to be sent',
          pt: 'Link ou texto a ser enviado'
        },
        isLink: {
          es: 'Es enlace?',
          en: 'Is link?',
          pt: 'É um Link?'
        },
        link: {
          es: 'Enlace',
          en: 'Link',
          pt: 'Link'
        },
        intent: {
          es: 'Usar texto',
          en: 'Use text',
          pt: 'Usar texto'
        },
        title: {
          es: 'Botones',
          en: 'Buttons',
          pt: 'Botões'
        },
        textarea: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        },
        button: {
          es: 'Botón',
          en: 'Button',
          pt: 'Botão'
        },
        addButton: {
          es: 'Agregar botón',
          en: 'Add button',
          pt: 'Adicionar botão'
        }
      },
      RcsSlider: {
        title: {
          es: 'Carousel',
          en: 'Carousel',
          pt: 'Carrousel'
        },
        textarea: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        },
        button: {
          es: 'Botón',
          en: 'Button',
          pt: 'Botão'
        },
        addButton: {
          es: 'Agregar botón',
          en: 'Add button',
          pt: 'Adicione o botão'
        }
      },
      RcsCard: {
        mediaError: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!'
          },
          text: {
            es: 'Error subiendo imagen',
            en: 'Error uploading image',
            pt: 'Erro ao carregar a imagem'
          }
        },
        errors: {
          text_field: {
            es: 'el texto',
            en: 'the text',
            pt: 'o texto'
          },
          buttons: {
            es: ' al menos una opción',
            en: ' at least one option',
            pt: ' pelo menos uma opção'
          },
          picture: {
            es: 'la imagen',
            en: 'the Media File',
            pt: 'o arquivo da mídia'
          },
          height: {
            es: 'la altura',
            en: 'the height',
            pt: 'a altura'
          },
          text_title: {
            es: 'el título',
            en: 'the title',
            pt: 'o título'
          },
          text_subtitle: {
            es: 'el subtítulo',
            en: 'the subtitle',
            pt: 'a legenda'
          }
        },
        title: {
          es: 'Card',
          en: 'Card',
          pt: 'Card'
        },
        picture: {
          es: 'Imagen',
          en: 'Media File',
          pt: 'Arquivo de mídia'
        },
        height: {
          es: 'Altura',
          en: 'Height',
          pt: 'Altura'
        },
        text_title: {
          es: 'Título',
          en: 'Title',
          pt: 'Título'
        },
        sizes_large: {
          es: 'Largo',
          en: 'Large',
          pt: 'Longo'
        },
        size_height: {
          es: 'Altura',
          en: 'Height',
          pt: 'Altura'
        },
        size_width: {
          es: 'Ancho',
          en: 'Width',
          pt: 'Largura'
        },
        sizes_medium: {
          es: 'Medio',
          en: 'Medium',
          pt: 'Médio'
        },
        sizes_short: {
          es: 'Corto',
          en: 'Short',
          pt: 'Curto'
        },
        text_button: {
          es: 'Botón',
          en: 'Button',
          pt: 'Botão'
        },
        text_subtitle: {
          es: 'Subtítulo',
          en: 'Subtitle',
          pt: 'Subtítulo'
        },
        addImage: {
          es: 'Agregar imagen',
          en: 'Add image',
          pt: 'Adicionar imagem'
        },
        textarea: {
          es: 'Texto',
          en: 'Text',
          pt: 'Texto'
        },
        button: {
          es: 'Botón',
          en: 'Button',
          pt: 'Botão'
        },
        addButton: {
          es: 'Agregar botón',
          en: 'Add button',
          pt: 'Adicione o botão'
        }
      },
      quickReply: {
        title: {
          es: 'Respuesta rápida',
          en: 'Quick reply',
          pt: 'Resposta rápida'
        },
        placeholder: {
          es: 'Respuesta rápida',
          en: 'Quick reply',
          pt: 'Resposta rápida'
        }
      },
      place: {
        title: {
          es: 'Ubicación',
          en: 'Location',
          pt: 'Localização'
        },
        description: {
          es: 'Enviar una ubicación',
          en: 'Send a location',
          pt: 'Envie uma localização'
        }
      },
      audio: {
        title: {
          es: 'Audio',
          en: 'Audio message',
          pt: 'Mensagem de áudio'
        },
        description: {
          es: 'Selecciona un audio',
          en: 'Select an audio message',
          pt: 'Selecione uma mensagem de áudio'
        }
      },
      regExp: {
        title: {
          es: 'Exp. Regulares',
          en: 'Reg. Expressions',
          pt: 'Reg. Expressões'
        },
        match: {
          description: {
            es: 'Coincidir texto',
            en: 'Match text',
            pt: 'Corresponde ao texto'
          }
        },
        regex: {
          description: {
            es: 'Expresión regular',
            en: 'Regular expression',
            pt: 'Expressão regular'
          },
          placeholder: {
            es: 'Ingrese una expresión regular',
            en: 'Enter a regular expression',
            pt: 'Digite uma expressão regular'
          },
          error: {
            es: 'Por favor ingrese una expresión regular válida',
            en: 'Please enter a valid regular expression',
            pt: 'Por favor, digite uma expressão regular válida'
          }
        }
      },
      entity: {
        title: {
          es: 'Entidades',
          en: 'Entities',
          pt: 'Entidades'
        }
      }
    },
    userSays: {
      title: {
        es: 'El Bot detecta',
        en: 'Bot detects',
        pt: 'Bot detecta'
      },
      scalesSecondLevel: {
        es: 'Escala a segundo nivel',
        en: 'Second level scale',
        pt: 'Escala de segundo nível'
      },
      secondLevelWarning: {
        es:
          'Se agregará una respuesta del bot por defecto si no hay una configurada.',
        en: 'A default bot response will be added if one is not configured.',
        pt:
          'Uma resposta de bot padrão será adicionada se uma não estiver configurada.'
      },
      intentPlaceholder: {
        es: 'Buscar o crear nueva intención',
        en: 'Search or create new intent',
        pt: 'Procure ou crie novas intenções'
      },
      entityPlaceholder: {
        es: 'Buscar o crear nueva entidad',
        en: 'Search or create new entity',
        pt: 'Procure ou crie uma nova entidade'
      }
    },
    botSays: {
      title: {
        es: 'El Bot responde',
        en: 'Bot replies',
        pt: 'Bot responde'
      },
      emptyBotMakerMessages: {
        title: {
          es: 'Ups!',
          en: 'Oops!',
          pt: 'Oops!'
        },
        content: {
          es: 'El primer mensaje debe de pertencer al usuario.',
          en: 'The first message must belong to the user.',
          pt: 'A primeira mensagem deve pertencer ao usuário.'
        }
      }
    },
    alternativePath: {
      previous: {
        es: 'Flujo previo',
        en: 'Previous flow',
        pt: 'Fluxo anterior'
      },
      next: {
        es: 'Flujo siguiente',
        en: 'Next flow',
        pt: 'Próximo fluxo'
      }
    },
    intentionsDialog: {
      title: {
        es: 'Intenciones',
        en: 'Intents',
        pt: 'Intenções'
      },
      name: {
        es: 'Nombre',
        en: 'Name',
        pt: 'Nome'
      },
      examples: {
        es: 'Ejemplos',
        en: 'Examples',
        pt: 'Exemplos'
      },
      accept: {
        es: 'Seleccionar',
        en: 'Select',
        pt: 'Selecionar'
      },
      cancel: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      }
    },
    mobileView: {
      emptyBotResponses: {
        es: 'Tu intención no tiene definida una respuesta del bot.',
        en: 'Your intent does not have a bot response defined.',
        pt: 'Sua intenção não tem uma resposta do bot definida.'
      },
      hasAlternativePath: {
        es: 'Existe un camino alternativo',
        en: 'There is an alternative way',
        pt: 'Existe um caminho alternativo'
      }
    },
    addIntent: {
      title: {
        es: 'Crear intención',
        en: 'Create intention',
        pt: 'Criar intenção'
      },
      success: {
        title: {
          es: 'Éxito!',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'La intención se ha creado exitosamente.',
          en: 'Intention created successfully.',
          pt: 'Intenção criada com sucesso.'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es: 'No se pudo crear la intención. Por favor, intente nuevamente.',
          en: 'The intention was not created. Please try again later.',
          pt: 'A intenção não foi criada. Tente de novo mais tarde.'
        }
      }
    },
    editIntent: {
      success: {
        title: {
          es: 'Éxito!',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'La intención se ha actualizado exitosamente.',
          en: 'Intention updated successfully.',
          pt: 'Intenção atualizada com sucesso.'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es:
            'No se pudo actualizar la intención. Por favor, intente nuevamente.',
          en: 'The intention was not updated. Please try again later.',
          pt: 'A intenção não pôde ser atualizada. Por favor, tente novamente.'
        }
      }
    },
    removeIntent: {
      confirm: {
        title: {
          es: 'Atención!',
          en: 'Warning!',
          pt: 'Aviso!'
        },
        text: {
          es:
            'Estas por borrar una intención, esta acción es irreversible. ¿Estás seguro?',
          en:
            'You are about to delete an intent, this action is irreversible. Are you sure?',
          pt:
            'Você está prestes a apagar uma intenção, esta ação é irreversível. Tem certeza?'
        }
      },
      success: {
        title: {
          es: 'Éxito!',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'La intención se ha eliminado exitosamente.',
          en: 'Intent deleted successfully.',
          pt: 'A intenção foi eliminada com sucesso.'
        }
      },
      error: {
        fail: {
          title: {
            es: 'Error',
            en: 'Ha ocurrido un error al aliminar la intención.',
            pt: 'Erro'
          }
        },
        using: {
          title: {
            es: 'Error',
            en: 'Error',
            pt: 'Erro'
          },
          text: {
            es:
              'No se puede eliminar la intención, pues está siendo utilizada en al menos un diálogo del bot. Por favor, elimine dichos diálogos e intente nuevamente.',
            en:
              'You cannot remove the intent, as it is being used in at least one bot dialog. Please remove these dialogs and try again.',
            pt:
              'Você não pode remover a intenção, pois ela está sendo usada em pelo menos um diálogo de bot. Por favor, remova esses diálogos e tente novamente.'
          }
        }
      }
    },
    removeEntity: {
      confirm: {
        title: {
          es: 'Atención!',
          en: 'Warning!',
          pt: 'Aviso!'
        },
        text: {
          es:
            'Estas por borrar una entidad, esta acción es irreversible. ¿Estás seguro?',
          en:
            'You are about to delete an entity, this action is irreversible. Are you sure?',
          pt:
            'Você está prestes a apagar uma entidade, esta ação é irreversível. Tem certeza?'
        }
      },
      success: {
        title: {
          es: 'Éxito!',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'La entidad se ha eliminado exitosamente.',
          en: 'Entity deleted successfully.',
          pt: 'Entidade excluída com sucesso.'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es:
            'No se puede eliminar la entidad, pues está siendo utilizada en al menos un diálogo del bot. Por favor, elimine dichos diálogos e intente nuevamente.',
          en:
            'You cannot remove the entity, as it is being used in at least one bot dialog. Please remove these dialogs and try again.',
          pt:
            'Você não pode remover a entidade, pois ela está sendo usada em pelo menos um diálogo de bot. Por favor, remova esses diálogos e tente novamente.'
        }
      }
    },
    jumpDisclaimer: {
      es: 'No es posible crear diálogos porque hay un salto',
      en: 'Unable to create dialogs because there is a jump',
      pt: 'Os diálogos não podem ser criados porque há um salto'
    },
    surveyDisclaimer: {
      es: 'No es posible crear diálogos porque hay una encuesta',
      en: 'Unable to create dialogs because there is a survey',
      pt: 'Os diálogos não podem ser criados porque há uma pesquisa'
    },
    validationDisclaimer: {
      es: 'No es posible crear diálogos porque hay una validación',
      en: 'Unable to create dialogs because there is a validation',
      pt: 'Os diálogos não podem ser criados porque há uma validação'
    },
    addConditionalMsg: {
      success: {
        title: {
          es: 'Éxito!',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'El mensaje se ha creado exitosamente.',
          en: 'Message created successfully.',
          pt: 'Mensagem criada com sucesso.'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es: 'No se pudo crear el mensaje. Por favor, intente nuevamente.',
          en: 'The message was not created. Please try again later.',
          pt: 'A mensagem não foi criada. Por favor, tente novamente.'
        }
      }
    },
    editConditionalMsg: {
      success: {
        title: {
          es: 'Éxito!',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'El mensaje se ha actualizado exitosamente.',
          en: 'Message updated successfully.',
          pt: 'Mensagem atualizada com sucesso.'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es:
            'No se pudo actualizar el mensaje. Por favor, intente nuevamente.',
          en: 'The message was not updated. Please try again later.',
          pt: 'A mensagem não foi atualizada. Por favor, tente novamente.'
        }
      }
    }
  },
  environment: {
    develop: {
      es: 'Desarrollo',
      en: 'Development',
      pt: 'Desenvolvimento'
    },
    testing: {
      es: 'Pruebas',
      en: 'Testing',
      pt: 'Teste'
    },
    dist: {
      es: 'En vivo',
      en: 'Live',
      pt: 'Ao vivo'
    }
  },
  integrations: {
    survey: {
      es: 'Encuestas',
      en: 'Surveys',
      pt: 'Pesquisas'
    },
    add: {
      es: 'Agregar integración',
      en: 'Add integration',
      pt: 'Adicione a integração'
    },
    disclaimer: {
      notAvailable: {
        prelink: {
          es: 'El bot no tiene integraciones disponibles.',
          en: 'This bot has not available integrations.',
          pt: 'Este bot não tem integraçãos disponíveis.'
        },
        link: {
          es: 'Click aquí ',
          en: 'Click here ',
          pt: 'Clique aqui'
        },
        postlink: {
          es: 'para administrar las integraciones.',
          en: 'to manage integrations.',
          pt: 'para gerenciar integrações.'
        }
      }
    },
    integratedApis: {
      es: 'APIs integradas',
      en: 'Already added APIs',
      pt: 'APIs integradas'
    },
    accept: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar'
    },
    cancel: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar'
    },
    title: {
      es: 'Integraciones',
      en: 'Integrations',
      pt: 'Integrações'
    },
    popup: {
      remove: {
        es: 'Quitar integración',
        en: 'Remove integration',
        pt: 'Remova a integração'
      }
    },
    update: {
      surveys: {
        success: {
          text: {
            es: 'Encuestas actualizadas correctamente',
            en: 'Surveys succesfully updated',
            pt: 'Pesquisas atualizadas com sucesso'
          }
        },
        error: {
          text: {
            es:
              'Error al actualizar las encuestas. Por favor, intente mas tarde.',
            en: "We couldn't update your surveys. Please, try again later.",
            pt:
              'Erro ao atualizar pesquisas. Por favor, tente de novo mais tarde.'
          }
        }
      },
      integrationsApis: {
        success: {
          text: {
            es: 'Integraciones actualizadas correctamente',
            en: 'Integrations succesfully updated',
            pt: 'Integrações atualizadas com sucesso'
          }
        },
        error: {
          text: {
            es:
              'Error al actualizar las integraciones. Por favor, intente mas tarde.',
            en:
              "We couldn't update your integrations. Please, try again later.",
            pt:
              'Erro ao atualizar integrações. Por favor, tente novamente mais tarde.'
          }
        }
      }
    },
    remove: {
      title: {
        es: 'Eliminar',
        en: 'Remove',
        pt: 'Remova'
      },
      success: {
        title: {
          es: 'Éxito',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'Integraciones actualizadas correctamente',
          en: 'Integrations succesfully removed',
          pt: 'Integrações atualizadas com sucesso'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es: 'Error al eliminar integración. Por favor, intente mas tarde.',
          en: "We couldn't remove your integration. Please, try again later.",
          pt: 'Erro ao remover integração. Por favor, tente de novo mais tarde.'
        }
      }
    },
    cyberbankApis: {
      es: 'APIs Cyberbank',
      en: 'Cyberbank APIs',
      pt: 'APIs Cyberbank'
    },
    onlyOne: {
      es: 'Puede seleccionar sólo una integración',
      en: 'You can select only one integration',
      pt: 'Você pode selecionar apenas uma integração'
    },
    tabs: {
      integrations: {
        es: 'Integraciones',
        en: 'Integrations',
        pt: 'Integrações'
      },
      cyberbank: {
        es: 'Cyberbank',
        en: 'Cyberbank',
        pt: 'Cyberbank'
      }
    },
    current: {
      es: 'Integración actual: ',
      en: 'Current integration: ',
      pt: 'Integração atual: '
    }
  },
  move: {
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso'
      },
      text: {
        es: 'Orden de los mensajes actualizado con éxito',
        en: 'Messages order succesfully updated',
        pt: 'Ordem de mensagens atualizada com sucesso'
      }
    },
    error: {
      title: {
        es: 'Error',
        en: 'Error',
        pt: 'Erro'
      },
      text: {
        es: 'Error al actualizar posiciones de mensajes.',
        en: 'Error updating message positions.',
        pt: 'Erro na atualização das posições da mensagem.'
      }
    }
  },
  jump: {
    title: {
      es: 'Salto de diálogo',
      en: 'Dialog jump',
      pt: 'Salto de diálogo'
    },
    current: {
      es: 'Actualmente salta a ',
      en: 'Currently jumps to ',
      pt: 'Atualmente pula para '
    },
    update: {
      title: {
        es: 'Agregar salto',
        en: 'Add jump',
        pt: 'Adicione salto'
      },
      success: {
        title: {
          es: 'Éxito',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'Salto de diálogo actualizado correctamente',
          en: 'Dialog jump succesfully updated',
          pt: 'Salto de diálogo atualizado com sucesso'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es:
            'Error al actualizar el salto de diálogo. Por favor, intente mas tarde.',
          en: "We couldn't update your dialog jump. Please, try again later.",
          pt:
            'Não foi possível atualizar seu salto de diálogo. Por favor, tente de novo mais tarde.'
        }
      }
    },
    remove: {
      title: {
        es: 'Eliminar salto',
        en: 'Remove jump',
        pt: 'Remover salto'
      },
      success: {
        title: {
          es: 'Éxito',
          en: 'Success',
          pt: 'Sucesso'
        },
        text: {
          es: 'Salto de diálogo actualizado correctamente',
          en: 'Dialog jump succesfully removed',
          pt: 'Salto de diálogo removido com sucesso'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es:
            'Error al eliminar el salto de diálogo. Por favor, intente mas tarde.',
          en: "We couldn't remove your dialog jump. Please, try again later.",
          pt:
            'Não foi possível remover o salto da diálogo. Por favor, tente de novo mais tarde.'
        }
      }
    },
    accept: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar'
    },
    cancel: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar'
    }
  },
  navBar: {
    previous: {
      es: 'Alternativa previa',
      en: 'Previous alternative',
      pt: 'Alternativa anterior'
    },
    delete: {
      es: 'Eliminar mensaje',
      en: 'Delete message',
      pt: 'Apagar mensagem'
    },
    integrations: {
      es: 'Agregar integración',
      en: 'Add integration',
      pt: 'Adicionar integração'
    },
    jump: {
      es: 'Agregar salto',
      en: 'Add jump',
      pt: 'Adicionar salto'
    },
    next: {
      es: 'Alternativa siguiente',
      en: 'Next alternative',
      pt: 'Próxima alternativa'
    },
    path: {
      es: 'Crear camino alternativo',
      en: 'Create alternative path',
      pt: 'Criar um caminho alternativo'
    },
    contextVariables: {
      es: 'Variables de contexto',
      en: 'Context variables',
      pt: 'Variáveis de contexto'
    }
  },
  virtualAssistant: {
    es: 'Asistente Virtual',
    en: 'Virtual Assistant',
    pt: 'Assistente Virtual'
  },
  intentions: {
    title: {
      es: 'Intenciones:',
      en: 'Intents:',
      pt: 'Intenções:'
    }
  },
  catalogs: {
    title: {
      es: 'Catálogos',
      en: 'Catalogs',
      pt: 'Catálogos'
    },
    tabs: {
      intents: {
        es: 'Intenciones',
        en: 'Intents',
        pt: 'Intenções'
      },
      entities: {
        es: 'Entidades',
        en: 'Entities',
        pt: 'Entidades'
      }
    }
  },
  jumpError: {
    es: 'Debe elegir el diálogo del salto',
    en: 'You must choose the jump dialog',
    pt: 'Você deve escolher o diálogo de salto'
  },
  botMessageValidation: {
    validateMessage: {
      es: 'Validar respuesta',
      en: 'Validate response',
      pt: 'Validar a resposta'
    },
    tooltip: {
      es: 'Permite validar respuestas',
      en: 'Allow to validate responses',
      pt: 'Permite validar as respostas'
    },
    form: {
      name: {
        label: {
          es: 'Nombre de validación',
          en: 'Validation name',
          pt: 'Nome da validação'
        },
        placeholder: {
          es: 'Ingrese nombre de validación',
          en: 'Insert validation name',
          pt: 'Insira o nome de validação'
        },
        error: {
          es: "El campo 'Nombre de validación' es requerido.",
          en: "Field 'Validation name' is required.",
          pt: "Campo 'Nome de validação' é necessário."
        }
      },
      question: {
        label: {
          es: 'Pregunta',
          en: 'Question',
          pt: 'Pergunta'
        },
        placeholder: {
          es: 'Ingrese una pregunta',
          en: 'Insert a question',
          pt: 'Insira uma pergunta'
        },
        error: {
          es: 'El campo "Pregunta" es requerido',
          en: 'Field "Question" is required.',
          pt: 'O campo "Pergunta" é necessário.'
        }
      },
      waitTime: {
        label: {
          es: 'Tiempo de espera (minutos)',
          en: 'Wait time (minutes)',
          pt: 'Tempo de espera (minutos)'
        },
        placeholder: {
          es: 'Ingrese el tiempo de espera',
          en: 'Insert wait time',
          pt: 'Inserir o tempo de espera'
        },
        error: {
          es: 'El campo "Tiempo de espera" es requerido',
          en: 'Field "Wait time" is required.',
          pt: 'Campo "Tempo de espera" é necessário.'
        }
      },
      waitTime2: {
        label: {
          es: 'Acción luego de derivar a agente (minutos)',
          en: 'Send question after (minutes):',
          pt: 'Enviar pergunta depois (minutos):'
        },
        placeholder: {
          es: 'Ingrese el tiempo de espera',
          en: 'Insert wait time',
          pt: 'Inserir o tempo de espera'
        },
        error: {
          es: 'El campo "Tiempo de espera" es requerido',
          en: 'Field "Wait time" is required.',
          pt: 'Campo "Tempo de espera" é necessário.'
        }
      },
      replyWhileWaitingForAgent: {
        label: {
          es: 'Responder cuando el usuario envía una pregunta',
          en: 'Respond when user submits a question',
          pt: 'Responder quando o usuário enviar uma pergunta',
        },
        placeholder: {
          es: 'El bot respondera al usuario cada vez que este envíe una consulta y se esté esperando por un agente',
          en: 'The bot will respond to the user every time he sends a query and is waiting for an agent',
          pt: 'O bot responderá ao usuário toda vez que ele enviar uma consulta e estiver esperando por um agente',
        },
      },
      answers: {
        title: {
          es: 'Configuración de respuestas',
          en: 'Responses settings',
          pt: 'Configuração das respostas'
        },
        table: {
          answer: {
            es: 'Respuesta',
            en: 'Response',
            pt: 'Resposta'
          },
          showOption: {
            es: 'Opción a mostrar',
            en: 'Show option',
            pt: 'Mostrar opção'
          },
          action: {
            es: 'Acción',
            en: 'Action',
            pt: 'Ação'
          },
          actionsPlaceholder: {
            es: 'Seleccionar acción',
            en: 'Select action',
            pt: 'Selecionar ação'
          },
          positive: {
            es: 'Positivo',
            en: 'Positive',
            pt: 'Positivo'
          },
          negative: {
            es: 'Negativo',
            en: 'Negative',
            pt: 'Negativo'
          },
          actionsErrorMsg: {
            key: {
              es: 'El campo "Acción" es requerido.',
              en: 'Field "Action" is required.',
              pt: 'Campo "Ação" é necessário.'
            },
            args: {
              es: 'El campo "Parámetros" es requerido.',
              en: 'Field "Parameters" is required.',
              pt: 'Campo "Parâmetros" é necessário.'
            }
          },
          positiveOptions: {
            option1: {
              es: 'Sí',
              en: 'Yes',
              pt: 'Sim'
            },
            option3: {
              es: '👍',
              en: '👍',
              pt: '👍'
            }
          },
          negativeOptions: {
            option1: {
              es: 'No',
              en: 'No',
              pt: 'Não'
            },
            option3: {
              es: '👎',
              en: '👎',
              pt: '👎'
            }
          },
          takeover: {
            es: 'Derivar a agente',
            en: 'Transfers to agent',
            pt: 'Transferência para o agente'
          },
          stopTakeoverAndJump: {
            es: 'Salto y terminar takeover',
            en: 'Jump and finish takeover',
            pt: 'Saltar e terminar takeover'
          },
          stopTakeoverAndText: {
            es: 'Texto y terminar takeover',
            en: 'Text and finish takeover',
            pt: 'Texto e terminar takeover'
          },
          stopTakeover: {
            es: 'Terminar takeover',
            en: 'Finish takeover',
            pt: 'Terminar takeover'
          },
          text: {
            es: 'Texto',
            en: 'Text',
            pt: 'Texto'
          },
          jump: {
            es: 'Salto',
            en: 'Jump',
            pt: 'Pule'
          }
        }
      },
      repeatTimes: {
        title: {
          es: '¿Preguntar nuevamente?',
          en: 'Ask again?',
          pt: 'Perguntar de novo?'
        },
        times: {
          es: 'veces',
          en: 'times',
          pt: 'vezes'
        },
        expirationTime: {
          title: {
            es: 'Tiempo de expiración al reenvío de la pregunta de validación',
            en: 'Expiration time when resending the validation question',
            pt: 'Tempo de expiração ao reenviar a pergunta de validação'
          },
          label: {
            es: 'No volver a preguntar si pasó más de:',
            en: 'Do not ask again if passed more than:',
            pt: 'Não pergunte novamente se passou mais de:'
          },
          tooltip: {
            es: 'Tiempo que debe transcurrir desde que se envía la primera pregunta de validación para no preguntar nuevamente en caso de una respuesta inválida',
            en: 'Time that must elapse since the first validation question is sent to not ask again in case of an invalid response',
            pt: 'Tempo que deve decorrer desde que a primeira pergunta de validação é enviada para não perguntar novamente em caso de resposta inválida'
          },
        }
      },
      repeatTimes2: {
        title: {
          es:
            'En caso de no recibir la respuesta esperada, preguntar nuevamente?',
          en: 'In case the User does not respond as expected, ask again?',
          pt: 'Caso não receba a resposta esperada, pergunte de novo?'
        },
        times: {
          es: 'veces',
          en: 'times',
          pt: 'vezes'
        }
      },
      applyAll: {
        es: 'Aplicar a todas las validaciones de respuestas',
        en: 'Apply to all response validations',
        pt: 'Aplicar a todas as validações de resposta'
      },
      validationUpdate: {
        title: {
          es: 'Atención!',
          en: 'Warning!',
          pt: 'Aviso!'
        },
        text: {
          es:
            'Modificar esta validación afectará a todos los diálogos que utilizan la misma validación. ¿Cómo desea proceder?',
          en:
            'Modifying this validation will affect all dialogs that use the same validation. How do you want to proceed?',
          pt:
            'Modificar esta validação afetará todas os diálogos que usam a mesma validação. Como você deseja proceder?'
        },
        option1: {
          es: 'Actualizar validación',
          en: 'Update validation',
          pt: 'Validação de atualização'
        },
        option2: {
          es: 'Guardar como nueva validación',
          en: 'Save as a new validation',
          pt: 'Salvar como uma nova validação'
        }
      }
    },
    messages: {
      error: {
        saveValidation: {
          es:
            'Ha ocurrido un error al guardar la validación. Por favor, intente nuevamente.',
          en:
            'An error occurred while saving the validation. Please try again.',
          pt: 'Ocorreu um erro ao salvar a validação.'
        }
      }
    }
  },
  widgetPreview: {
    error: {
      title: {
        es: 'Oops',
        en: 'Oops',
        pt: 'Opa'
      },
      text: {
        es: 'Widget no existe',
        en: "Widget doesn't exists",
        pt: 'O widget não existe'
      }
    }
  },
  journeysTemplates: {
    title: {
      es: 'Templates',
      en: 'Templates',
      pt: 'Templates'
    },
    botTab: {
      es: 'Bots',
      en: 'Bots',
      pt: 'Bots'
    },
    JourneyTab: {
      es: 'Journeys',
      en: 'Journeys',
      pt: 'Journeys'
    },
    intentTab: {
      es: 'Intenciones',
      en: 'Intents',
      pt: 'Intenções',
    },
    working: {
      es: 'Agregando template al AV. Esta acción puede tomar varios minutos.',
      en: 'Adding template to VA. This action may take several minutes.',
      pt: 'Adicionando modelo para VA. Esta ação pode levar vários minutos.'
    }
  },
  testGroups: {
    table: {
      empty: {
        en: 'No Test Suite yet',
        es: 'No hay Test Suites de prueba aun',
        pt: 'Nenhum Test Suite ainda'
      },
      groups: {
        testSuite: {
          en: 'Test Suite',
          es: 'Test Suite',
          pt: 'Test Suite'
        }
      },
      cases: {
        testCase: {
          en: 'Test Case',
          es: 'Test Case',
          pt: 'Test Case',
        }
      }
    },
    view: {
      messages: {
        executionDone: {
          en: 'Execution in progress. You will recieve a e-mail with the report.',
          es: 'Ejecución en curso. Recibirás un correo con el informe.',
          pt: 'Execução em andamento. Você receberá um e-mail com o relatório.',
        },
        groupDeletionTitle: {
          en: 'Delete Test Suite',
          es: 'Eliminar Test Suite',
          pt: 'Excluir Test Suite',
        },
        caseDeletionTitle: {
          en: 'Delete Test Case',
          es: 'Eliminar Test Case',
          pt: 'Excluir Test Case',
        },
        confirmGroupDeletion: {
          en: 'You are about to delete your Test suite, this action is irreversible. Are you sure?',
          es: 'Está a punto de eliminar su Test Suite, esta acción es irreversible. ¿Está seguro?',
          pt: 'Você está prestes a excluir seu Test Suite, esta ação é irreversível. Tem certeza?',
        },
        confirmCaseDeletion: {
          en: 'You are about to delete your Test Case, this action is irreversible. Are you sure?',
          es: 'Está a punto de eliminar su Test Case, esta acción es irreversible. ¿Está seguro?',
          pt: 'Você está prestes a excluir seu Test Case, esta ação é irreversível. Tem certeza?',
        },
        testGroupCreated: {
          en: 'Test suite created successfully.',
          es: 'Test Suite creado correctamente.',
          pt: 'Grupo de teste criado com sucesso.',
        },
        stepsUpdated: {
          en: 'Test steps updated successfully.',
          es: 'Test Suite creado correctamente.',
          pt: 'Passos de teste atualizadas com sucesso.',
        },
        testGroupEdited: {
          en: 'Test suite edited successfully.',
          es: 'Test Suite editado correctamente.',
          pt: 'Grupo de teste editado com sucesso.',
        },
        testCaseCreated: {
          en: 'Test case created successfully.',
          es: 'Test Case creado correctamente.',
          pt: 'Test Case criado com sucesso.',
        },
        testCaseDeleted: {
          en: 'Test case deleted successfully.',
          es: 'Test Case eliminado correctamente.',
          pt: 'Test Case excluir com sucesso.',
        },
        testGroupDeleted: {
          en: 'Test Suite deleted successfully.',
          es: 'Test Suite eliminado correctamente.',
          pt: 'Grupo de teste excluir com sucesso.',
        },
        testCaseEdited: {
          en: 'Test Case edited successfully.',
          es: 'Test Case editado correctamente.',
          pt: 'Test Case editado com sucesso.',
        }
      },
      description: {
        en: 'Description',
        es: 'Descripción',
        pt: 'Descrição'
      },
      addStep: {
        en: 'Add Step',
        es: 'Agregar Paso',
        pt: 'Adicionar Passo'
      },
      saveSteps: {
        en: 'Save Steps',
        es: 'Guardar Pasos',
        pt: 'Salvar Passos'
      },
      edit: {
        en: 'Edit',
        es: 'Editar',
        pt: 'Editar'
      },
      execute: {
        en: 'Execute',
        es: 'Ejecutar',
        pt: 'Executar'
      },
      createTestCase: {
        en: 'Create Test Case',
        es: 'Crear Test Case',
        pt: 'Criar Test Case'
      },
      createTestGroup: {
        en: 'Create Test Suite',
        es: 'Crear Test Suite',
        pt: 'Criar Grupo de Teste'
      },
      backToTestGroup: {
        en: 'Back to Test Suites List',
        es: 'Volver a lista de Test Suites',
        pt: 'Voltar para a lista de Test Suites'
      },
      backToTestCase: {
        en: 'Back to Test Cases List',
        es: 'Volver a lista de Test Cases',
        pt: 'Voltar para a lista de Test Cases'
      },
      name: {
        en: 'Name',
        es: 'Nombre',
        pt: 'Nome'
      },
      testManagement: {
        en: 'Test Management',
        es: 'Gestion de Pruebas',
        pt: 'Gerenciamento de Teste'
      },
      addTestGroup: {
        en: 'Create Test Suite',
        es: 'Crear Test Suite',
        pt: 'Criar Test Suite'
      },
      editTestGroup: {
        en: 'Edit Test Suite',
        es: 'Editar Test Suite',
        pt: 'Editar Test Suite'
      },
      addTestCase: {
        en: 'Create Test Case',
        es: 'Crear Test Case',
        pt: 'Criar Test Case'
      },
      editTestCase: {
        en: 'Edit Test Case',
        es: 'Editar Test Case',
        pt: 'Editar Test Case'
      },
      newTestGroup: {
        en: 'New Test Suite',
        es: 'Nuevo Test Suite',
        pt: 'Novo Grupo de Teste'
      },
      newTestCase: {
        en: 'New Test Case',
        es: 'Nuevo Test Case',
        pt: 'Novo Test Case'
      },
      addEmail: {
        es: 'Agregar email',
        en: 'Add email.',
        pt: 'Adicionar email.'
      },
      executeTestGroup: {
        es: 'Ejecutar Test Suite',
        en: 'Execute Test Suite',
        pt: 'Executar Test Suite'
      },
      mailsToSendExecution: {
        es: 'Ingrese email donde enviar la ejecución',
        en: 'Enter email where to send the execution',
        pt: 'Insira o e-mail para onde enviar a execução'
      },
      errors: {
        execution: {
          empty: {
            es: 'Por favor ingresa un email.',
            en: 'Please enter an email.',
            pt: 'Por favor insira um email.'
          },
          emptyList: {
            es: 'Debe agregar emails para enviar la ejecución.',
            en: 'You must add emails to submit the execution.',
            pt: 'Você deve adicionar e-mails para enviar a execução.'
          },
          duplicate: {
            es: 'Ese email ya fue ingresado',
            en: 'That email has already been entered',
            pt: 'Esse email já foi inserido'
          },
        },
        text: {
          en: 'Please fill in this field',
          es: 'Por favor llene este campo',
          pt: 'Por favor, preencha este campo'
        },
        name: {
          en: 'Please enter a name',
          es: 'Por favor ingrese un nombre',
          pt: 'Por favor insira um nome'
        },
        description: {
          en: 'Please enter a description',
          es: 'Por favor ingrese una descripción',
          pt: 'Por favor insira uma descrição'
        }
      }
    }
  },
  testCases: {
    table: {
      step: {
        en: 'Step',
        es: 'Paso',
        pt: 'Passo'
      },
      userSays: {
        en: 'User Says',
        es: 'Usuario escribe',
        pt: 'Usuário diz'
      },
      botResponse: {
        en: 'Bot response',
        es: 'Respuesta del bot',
        pt: 'Resposta do bot'
      },
      assertion: {
        en: 'Assertion',
        es: 'Assertion',
        pt: 'Assertion'
      },
    },
    assertionTypes: {
      equal: {
        en: 'Equal',
        es: 'Igual',
        pt: 'Igual',
      },
      include: {
        en: 'Include',
        es: 'Incluye',
        pt: 'Inclui',
      }
    }
  }
}
